import React, { Component } from 'react';

class CloseModalButton extends Component {

  render() {
    return (
    	<button type="button" className="close" onClick={this.props.onClick}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
    )

  }
} 
export default CloseModalButton
