import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb, Navbar, ListGroup, Dropdown, DropdownButton, Alert, Spinner, SplitButton } from 'react-bootstrap'
import { connect } from 'react-redux'
import OfflineAlert from '../components/OfflineAlert'
import { Offline, Online } from "react-detect-offline";
import {withGetScreen} from 'react-getscreen'
import { deleteCheckAutosave } from '../redux/actions/checkActions'
import { deleteInventoryAutosave } from '../redux/actions/inventoryActions'
import { removeOfflineQueueItem, setQueueItemPending, unsetQueueItemPending } from '../redux/actions/offlineActions'
import Sidebar from '../components/Sidebar'
import localForage from "localforage";

class OfflineQueuePage extends Component {

  constructor(props){
    super(props)
    this.state = { 
      messages: []
    };
  }

  componentWillReceiveProps(nextProps) {

  }

  addSuccessMessage = (item) => {
    item.status = 'success'
    let messages = Array.from(this.state.messages)
    messages.push(item)
    this.setState({
      messages
    })
  }

  removeMessage = (item) => {
    let messages = Array.from(this.state.messages)
    this.setState({
      messages: messages.filter(mItem => mItem.id !== item.id)
    })
  }

  handleRetryAll = () => {
    console.log("handleRetryAll")
    this.props.offlineQueue.forEach(item => this.handleRetry(item))
  }

  dataURLtoFile = (dataURI, filename) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: 'image/jpeg' });
  }

  collectImages = async (images, prefix, formData, imagePrefix) => {
    let thisComp = this
    console.log("collectImages", images, prefix, formData)
    if (images && images.length > 0){
      for (let x = 0; x < images.length; x++){
        console.log("images[x]", images[x], typeof images[x].url)
        let id = images[x].id ? `id${images[x].id}` : `${x}`
        if (images[x].url && typeof images[x].url === 'number'){
          // filesToDelete.push(images[x].url)
          images[x].url = await localForage.getItem(`file ${images[x].url}`)
          if (typeof images[x].url === 'string'){
            // let arrayBufferBlob = new Blob([images[x].url], {type: "text/plain"})
            images[x].url = thisComp.dataURLtoFile(images[x].url, 'savedFile.jpeg')
          }
          formData.append(`${prefix}_${imagePrefix}_${id}`, images[x].url ); // Add/replace with new image
        } else if (images[x] && (!images[x].url || typeof images[x].url === 'object')){
          console.log("adding")
          formData.append(`${prefix}_${imagePrefix}_${id}`, images[x].url ); // Add/replace with new image
        }
      }
    } else if (!images){
      formData.append(`${prefix}_${imagePrefix}`, null ); //Delete image
    }
    return formData
  }

  async retryCheckoutInventory(item, originalItem){
      console.log("retryCheckoutInventory", item, originalItem)
      let filesToDelete = []
      let formData = new FormData();
      let thisComp = this
      for (const key of Object.keys(item.validatedData)) {
        if (item.validatedData[key]){
          if (key === "checkoutRooms"){

            for (const roomItem of item.validatedData[key]) {
              let prefix = typeof roomItem.id === 'string' ? roomItem.id : `room_${roomItem.id}`
              roomItem.checkoutcomments && formData.append(`${prefix}_checkoutcomments`, roomItem.checkoutcomments )
              roomItem.order && formData.append(`${prefix}_order`, roomItem.order )
              formData = await this.collectImages(roomItem.checkout_images, prefix, formData, 'checkoutimage')
            }

          } else if (key === 'check_in_signature_image' || key === 'check_out_signature_image'){
            console.log("signature_image")
              if (typeof item.validatedData[key] === 'number'){
                  let result = await localForage.getItem(`file ${item.validatedData[key]}`)
                  console.log("result 1", result)
                  if (result && typeof result === 'string'){
                    // let arrayBufferBlob = new Blob([result], {type: "text/plain"})
                    result = thisComp.dataURLtoFile(result, 'savedFile.png')
                    console.log("result 2", result)
                    formData.append(key, result, 'signature.png'); // Add/replace with new image
                    filesToDelete.push(item.validatedData[key])
                  }
              }
          } else {
            formData.append(key, item.validatedData[key] );
          }
        }
      }

      item.lookupOptions.body = formData
      this.retryFetch(item, originalItem, filesToDelete)

  }

  async retryEditInventory(item, originalItem){
      console.log("retryEditInventory", item, originalItem)
      let filesToDelete = []
      let formData = new FormData();
      let thisComp = this
      for (const key of Object.keys(item.validatedData)) {
        if (item.validatedData[key]){
          if (key === "rooms"){

            for (const roomItem of item.validatedData[key]) {
              let prefix = typeof roomItem.id === 'string' ? roomItem.id : `room_${roomItem.id}`
              roomItem.name && formData.append(`${prefix}_name`, roomItem.name )
              roomItem.checkoutcomments && formData.append(`${prefix}_checkoutcomments`, roomItem.checkoutcomments )
              roomItem.order && formData.append(`${prefix}_order`, roomItem.order )
              if (roomItem.items){
                for (const itemItem of roomItem.items) {
                  let prefix = typeof itemItem.id === 'string' ? itemItem.id : `comment_${itemItem.id}`
                  formData = await this.collectImages(itemItem.images, prefix, formData, 'image')
                  formData.append(`${prefix}_name`, itemItem.name );
                  formData.append(`${prefix}_description`, itemItem.description );
                  formData.append(`${prefix}_conditioncleanliness`, itemItem.conditioncleanliness );
                  formData.append(`${prefix}_room`, roomItem.id );
                }
                }
            }

          } else if (key === 'check_in_signature_image' || key === 'check_out_signature_image'){
            console.log("signature_image")
              if (typeof item.validatedData[key] === 'number'){
                  let result = await localForage.getItem(`file ${item.validatedData[key]}`)
                  console.log("result 1", result)
                  if (result && typeof result === 'string'){
                    // let arrayBufferBlob = new Blob([result], {type: "text/plain"})
                    result = thisComp.dataURLtoFile(result, 'savedFile.png')
                    console.log("result 2", result)
                    formData.append(key, result, 'signature.png'); // Add/replace with new image
                    filesToDelete.push(item.validatedData[key])
                  }
              }
          } else {
            formData.append(key, item.validatedData[key] );
          }
        }
      }

      item.lookupOptions.body = formData
      this.retryFetch(item, originalItem, filesToDelete)

  }

  async retryEditCheck(item, originalItem){
      console.log("retryEditCheck", item, originalItem)
      let filesToDelete = []
      let formData = new FormData();
      let thisComp = this
      for (const key of Object.keys(item.validatedData)) {
        if (item.validatedData[key]){
          if (key === "rooms"){

            for (const roomItem of item.validatedData[key]) {
              let prefix = typeof roomItem.id === 'string' ? roomItem.id : `room_${roomItem.id}`
              formData.append(`${prefix}_name`, roomItem.name )
              formData.append(`${prefix}_condition`, roomItem.condition )
              formData.append(`${prefix}_order`, roomItem.order )
              for (const commentItem of roomItem.comments) {

                let prefix = typeof commentItem.id === 'string' ? commentItem.id : `comment_${commentItem.id}`
                formData = await this.collectImages(commentItem.image, prefix, formData, 'image')
                formData.append(`${prefix}_comment`, commentItem.comment );
                formData.append(`${prefix}_maintenance`, commentItem.maintenance );
                formData.append(`${prefix}_room`, roomItem.id );
              }
            }

          } else if (key === 'comments'){

            for (const commentItem of item.validatedData[key]) {
              let prefix = typeof commentItem.id === 'string' ? commentItem.id : `comment_${commentItem.id}`
              formData = await this.collectImages(commentItem.image, prefix, formData, 'image')
              formData.append(`${prefix}_comment`, commentItem.comment );
              formData.append(`${prefix}_maintenance`, commentItem.maintenance );
              formData.append(`${prefix}_overall`, false );
            }//)

          } else if (key === 'overall_comments'){

            for (const commentItem of item.validatedData[key]) {
              let prefix = typeof commentItem.id === 'string' ? commentItem.id : `comment_${commentItem.id}`
              formData = await this.collectImages(commentItem.image, prefix, formData, 'image')
              formData.append(`${prefix}_comment`, commentItem.comment );
              formData.append(`${prefix}_maintenance`, commentItem.maintenance );
              formData.append(`${prefix}_overall`, true );
            }//)

          } else if (key === 'signature_image'){
            console.log("signature_image")
              if (typeof item.validatedData[key] === 'number'){
                  let result = await localForage.getItem(`file ${item.validatedData[key]}`)
                  console.log("result 1", result)
                  if (result && typeof result === 'string'){
                    // let arrayBufferBlob = new Blob([result], {type: "text/plain"})
                    result = thisComp.dataURLtoFile(result, 'savedFile.png')
                    console.log("result 2", result)
                    formData.append(`signature_image`, result, 'signature.png'); // Add/replace with new image
                    filesToDelete.push(item.validatedData[key])
                  }
              }
          } else {
            formData.append(key, item.validatedData[key] );
          }
        }
      }

      item.lookupOptions.body = formData
      this.retryFetch(item, originalItem, filesToDelete)

  }

  getEditCheckFilesToDelete = item => {
      let filesToDelete = []
      for (const key of Object.keys(item.validatedData)) {
        if (item.validatedData[key]){
          if (key === "rooms"){
            for (const roomItem of item.validatedData[key]) {
              for (const commentItem of roomItem.comments) {
                if (commentItem.image && typeof commentItem.image === 'number'){
                  filesToDelete.push(commentItem.image)
                }
              }
            }
          } else if (key === 'comments'){
            for (const commentItem of item.validatedData[key]) {
              if (commentItem.image && typeof commentItem.image === 'number'){
                  filesToDelete.push(commentItem.image)
              }
            }
          } else if (key === 'overall_comments'){
            for (const commentItem of item.validatedData[key]) {
              if (commentItem.image && typeof commentItem.image === 'number'){
                  filesToDelete.push(commentItem.image)
              }
            }
          } else if (key === 'signature_image'){
            if (item.validatedData[key] && typeof item.validatedData[key] === 'number'){
                filesToDelete.push(item.validatedData[key])
            }
          }
        }
      }
      return filesToDelete
  }

  handleRetry = (item) => {
    let originalItem = Object.assign({}, item);
    switch (item.type) {
      case 'checkoutInventory':
        this.retryCheckoutInventory(item, originalItem)
        break
      case 'createInventory':
        this.retryEditInventory(item, originalItem)
        break
      case 'editInventory':
        this.retryEditInventory(item, originalItem)
        break
      case 'editCheck':
        this.retryEditCheck(item, originalItem)
        break
      case 'createAddress':
      case 'editAddress':
        const formData = new FormData();
        Object.keys(item.raw).forEach(key => {
          if (item.raw[key]){
            formData.append(key, item.raw[key] );
          }
        })
        item.lookupOptions.body = formData
        this.retryFetch(item, originalItem, [])
        break
    default:
      this.retryFetch(item, originalItem, [])
    }
  }

  deleteFiles = (filesToDelete) => {
    filesToDelete.forEach(id => {
      localForage.removeItem(`file ${id}`)
    })
  }

  retryFetch = (item, originalItem, filesToDelete) => {
    // if (!item.status || item.status !== 'pending'){
      this.props.setQueueItemPending(originalItem)
      let thisComp = this
      fetch(item.endpoint, item.lookupOptions)
      .then(function(response){
        if (response.ok){
          thisComp.props.removeOfflineQueueItem(originalItem)
          thisComp.addSuccessMessage(originalItem)
          thisComp.deleteFiles(filesToDelete)
          if (item.autosaveName) {
            if (item.autosaveName.startsWith('save_check')) thisComp.props.deleteCheckAutosave(item.autosaveName, null)
            else if (item.autosaveName.startsWith('save_inventory')) thisComp.props.deleteInventoryAutosave(item.autosaveName, null)
          }
        } else {
          this.props.unsetQueueItemPending(originalItem)
        }
      }).catch(function(error){
        console.log("error", error)
        this.props.unsetQueueItemPending(originalItem)
      })
    // }
  }

  handleCancelAll = () => {
    console.log("handleCancelAll")
    this.props.offlineQueue.forEach(item => this.handleCancel(item))
  }

  handleCancel = (item) => {
    switch (item.type) {
      case 'editCheck':
        let filesToDelete = this.getEditCheckFilesToDelete(item)
        this.deleteFiles(filesToDelete)
        this.props.removeOfflineQueueItem(item)
        break
      case 'createAddress':
      case 'editAddress':
        if (item.raw.image) {
          this.deleteFiles([item.raw.image])
        }
        this.props.removeOfflineQueueItem(item)
        break
      default:
        this.props.removeOfflineQueueItem(item)
    }
  }

  render() {
    const optionsLabel = this.props.isMobile() ? "" : "Options"
    const { messages } = this.state

    return (
      <div className="page offline-queue-page">
      <Sidebar/>
      <Navbar fixed="top">
        <Container>
          <h2 className="ml-5 ml-xl-0">Offline Queue</h2>
          <Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Offline Queue</Breadcrumb.Item>
        </Breadcrumb>
        </Container>
      </Navbar>
      <Container className="pt-5 pb-5 mt-4 mt-sm-5">
        <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
        {this.props.offlineQueue.length > 0 && <Online>
          <Row>
          <Col className="mb-3">
            <SplitButton title="Retry all" className="float-right" alignRight onClick={this.handleRetryAll}>
              <Dropdown.Item onClick={this.handleCancelAll}>Cancel all</Dropdown.Item>
            </SplitButton>
          </Col>
          </Row>
        </Online>}
        {messages.map(item => {
          if (item.status === 'success'){
            return (
              <Alert key={item.id} variant="success" onClose={() => this.removeMessage(item)} dismissible>
                Successfully fulfilled "{item.title}".
              </Alert>
            )
          } else {
            return null
          }
        })}
        <ListGroup>
          {this.props.offlineQueue.length > 0 ? 
            this.props.offlineQueue.map(item => 
              <ListGroup.Item key={item.id}>
                {item.status && item.status === 'pending' &&
                  <Spinner animation="border" role="status" size="sm" className="mr-3">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
                <h4 className="d-inline">{item.title}</h4>
                <DropdownButton id="dropdown-basic-button" title={optionsLabel} variant="sab" className="float-right" alignRight>
                  <Dropdown.Item onClick={() => this.handleRetry(item)}>Retry</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => this.handleCancel(item)}>Cancel</Dropdown.Item>
                </DropdownButton>
              </ListGroup.Item>
            )
            :
            <Alert variant="success">
              Offline queue is empty.
            </Alert>}
        </ListGroup>
      </Container>
      </div>
    )

  }
}

const mapStateToProps = state => ({
  offlineQueue: state.offline.offlineQueue,
});

export default connect(mapStateToProps, { removeOfflineQueueItem, setQueueItemPending, unsetQueueItemPending, deleteCheckAutosave, deleteInventoryAutosave })(withGetScreen(OfflineQueuePage));