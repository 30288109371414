import React, { Component } from 'react';
import { Modal, Button, Row, Col, ListGroup, Tab, Tabs } from 'react-bootstrap'
import { api_url } from '../api_url'
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'

class ViewAddressModal extends Component {

	constructor(props){
		super(props)
		this.state = {
			historyAddress: null,
		};
	}

	componentWillMount(){
		if (this.props.isMobile()) this.onShow()
	}

	fetchAddress = () => {
	    let itemID = null
	    if (this.props.item){
	      itemID = this.props.item.id
	    } else {
	      itemID = history.location.hash.split('-').pop()
	    }
		const endpoint = `${api_url}rest-auth/addresses/${itemID}/` 
		let thisComp = this
		let lookupOptions = {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
        		'Authorization': 'Token '+window.localStorage.getItem('authKey'),
			}
		}
		fetch(endpoint, lookupOptions)
		.then(function(response){
			return response.json()
		}).then(function(responseData){
			if (responseData){
				// console.log("responseData", responseData)
				responseData.history.forEach((item, i) => {
					// console.log("item, i", item, i)
					if (item.history_type === "~"){
						let changedFields = {}
						Object.keys(item).forEach(key => {
							// console.log("key, index", key)
							if (["history_date", "history_id", "history_type"].indexOf(key) === -1 && responseData.history[i][key] !== responseData.history[i+1][key]){
								changedFields[key] = responseData.history[i+1][key]
							}
						})
						responseData.history[i].changedFields = changedFields
						console.log("changedFields", responseData.history[i].changedFields)
					}
				})
				thisComp.setState({
					historyAddress: responseData,
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}

	onShow = () => {
		// console.log("ViewAddressModal onShow")
		this.fetchAddress()
	}

	componentDidMount(){
		this.props.show && this.fetchAddress()
	}



  renderTimestamp = timestamp => {
  	let d = new Date(timestamp)
  	return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    	d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
  };

  restoreAddress = (item) => {
  	console.log("restoreAddress", item)
  	this.props.handleClose()
  	this.props.handleShowEditAddressModal(item)
  }

  render(){
  	if (this.props.show && this.props.isMobile()) return this.getInner()
  	else {
	  	return (
	        <Modal size="lg" show={this.props.show} onHide={this.props.handleClose} onShow={this.onShow}>
	        {this.getInner()}
	        </Modal>
	  	)
  	}
  }

  getInner = () => {
  	console.log("this.props", this.props)
  	const {historyAddress} = this.state

    let item = null
    if (historyAddress) item = historyAddress
    else if (this.props.item) item = this.props.item

  	if (item){
  		const sm1 = 4
  		const sm2 = 5
  		const classes1 = "text-sm-right text-center"
  		const classes2 = "text-sm-left text-center mb-1"
	    return (
	        <>
	          <Modal.Header>
	            <Modal.Title>Viewing Address #{item.id}</Modal.Title>
            	<CloseModalButton onClick={this.props.handleClose}/>
	          </Modal.Header>
	          <Tabs defaultActiveKey="details" variant="pills" className="px-3 mt-3 mb-n3">
			  <Tab eventKey="details" title="Details">
			  <Modal.Body>
			  	<hr/>
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			ID:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.id ? item.id : "-"}
	          		</Col>
	          	</Row>
	          	{/**<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Tenant Name:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.tenant_name ? item.tenant_name : "-"}
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Tenant Phone Number:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.tenant_number ? item.tenant_number : "-"}
	          		</Col>
	          	</Row>**/}
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Tenant Address Line 1:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.tenant_address_line_1 ? item.tenant_address_line_1 : "-"}
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Tenant Address Line 2:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.tenant_address_line_2 ? item.tenant_address_line_2 : "-"}
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Tenant Town/City:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.tenant_town_city ? item.tenant_town_city : "-"}
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Tenant County:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.tenant_county ? item.tenant_county : "-"}
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Tenant Postcode:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.tenant_postcode ? item.tenant_postcode : "-"}
	          		</Col>
	          	</Row>
	          	
	          	<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Property Manager:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.property_manager_full ? `${item.property_manager_full.first_name} ${item.property_manager_full.last_name}` : "-"}
	          		</Col>
	          	</Row>
	          	{/**<Row>
	          		<Col sm={sm1} className={classes1}>
	          			Landlord Email:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.landlord_email ? item.landlord_email : "-"}
	          		</Col>
	          	</Row>**/}
	          	</Modal.Body>
			    
			  </Tab>
			  <Tab eventKey="history" title={historyAddress ? `History (${historyAddress.history.length})` : "History"}>
			    
<Modal.Body>
	            <ListGroup className="mt-3">
            		{historyAddress ? 
            			historyAddress.history.map(historyItem => {
	            			if (historyItem.history_type === '~'){
	            				return (
	            					<ListGroup.Item key={historyItem.history_id}>
	            						<Row>
	            						<Col sm={8}>
	            						<div className="small">{this.renderTimestamp(historyItem.history_date)}</div>
		            					{Object.keys(historyItem.changedFields).map((key, i) => {
		            						let t = `Changed ${key} from "${historyItem.changedFields[key]}" to "${historyItem[key]}"`
		            						// if (i > 0) t = <><br/>{t}</>
		            						return <div key={key}>{t}</div>
		            					})}
		            					</Col>
	            						<Col sm={4}>
	            						<Button variant="outline-primary" className="float-right" onClick={() => this.restoreAddress(historyItem)}>Restore</Button>
		            					</Col>
		            					</Row>
	            					</ListGroup.Item>
	            				)
	            			} else if (historyItem.history_type === '+'){
	            				return (
									<ListGroup.Item key={historyItem.history_id}>
	            						<Row>
	            						<Col sm={8}>
	            						<div className="small">{this.renderTimestamp(historyItem.history_date)}</div>
										<div>Address created</div>
		            					</Col>
	            						<Col sm={4}>
	            						<Button variant="outline-primary" className="float-right" onClick={() => this.restoreAddress(historyItem)}>Restore</Button>
		            					</Col>
		            					</Row>
									</ListGroup.Item>
	            				)
	            			} else {
	            				return (
									<ListGroup.Item key={historyItem.history_id}>Unknown history_type</ListGroup.Item>
	            				)
	            			}
	            		})
            			:
            			""
            		}
	            </ListGroup>
	            </Modal.Body>
			  </Tab>
			</Tabs>
	          <Modal.Footer>
	            <Button variant="secondary" onClick={this.props.handleClose} className="float-left ml-0">
	              Close
	            </Button>
	            <Button variant="sab" onClick={() => this.props.handleShowEditAddressModal(item)} className="float-left mr-auto ml-0">
	              Edit
	            </Button>
	          </Modal.Footer>
	        </>
	    )
  	} else {
  		return null
  	}

  }
}

export default ViewAddressModal;
