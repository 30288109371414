import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form, FormCheck, SplitButton, Dropdown, Tabs, Tab, ButtonGroup, InputGroup, FormControl, DropdownButton } from 'react-bootstrap'
import { editInventory, deleteInventoryAutosave } from '../redux/actions/inventoryActions'
import { saveInventory, incrementOfflineFileCount } from '../redux/actions/offlineActions'
import { connect } from 'react-redux'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import { api_url } from '../api_url'
import { stringify } from 'query-string';
import DatePicker from "react-datepicker";
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'
import ConfirmModal from '../modals/ConfirmModal'
import FormButtonGroup from '../components/FormButtonGroup'
import SignaturePad from 'signature_pad'
import { canvasToBlob } from 'blob-util'
import Resizer from 'react-image-file-resizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faTrash, faCaretUp, faCaretDown, faClock, faCamera, faCopy, faFileArchive } from '@fortawesome/free-solid-svg-icons'
import localForage from "localforage";
import cloneDeep from 'lodash/cloneDeep';
import { getBase64Strings } from 'exif-rotate-js/lib';
import JSZip from 'jszip';
import saveAs from 'file-saver';



// const imgPrefix = 'http://127.0.0.1:8000'
// const imgPrefix = 'https://sab.hjwebdev.com'
const imgPrefix = ''

const CONDITION_CLEANLINESS_CHOICES = [
  {value: '', label: '-'},
  {value: 'GC & CC', label: 'GC & CC'},
  {value: 'FC & CC', label: 'FC & CC'},
  {value: 'PC & CC', label: 'PC & CC'},
  {value: 'GC & NC', label: 'GC & NC'},
  {value: 'FC & NC', label: 'FC & NC'},
  {value: 'PC & NC', label: 'PC & NC'},
]

const YES_NO_CHOICES = [
  {value: '', label: '-'},
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
  {value: 'n/a', label: 'N/A'},
]

let signaturePad = null

class EditInventoryModal extends Component {

  constructor(props){
    super(props)
    this.state = this.getDefaultState()
  }

  getDefaultUserOffice = () => {
    const { currentID, userList } = this.props
    if (userList && (currentID || currentID === 0)){
      const user = userList.find((item) => item.id === currentID)
      if (user){
        return user.office
      }
    }
    return ''
  }

  getDefaultState = () => {
    let newRoomID = 0
    let newItemID = 0
    const {settings} = this.props
    let rooms = []
    let defaultItems = settings && settings.inventory_default_room_items && settings.inventory_default_room_items.length > 0 ? JSON.parse(settings.inventory_default_room_items) : []
    let defaultRooms = settings && settings.inventory_default_rooms && settings.inventory_default_rooms.length > 0 ? JSON.parse(settings.inventory_default_rooms) : []

    let roomOrder = 0
    defaultRooms.sort((a, b) => a.order - b.order).forEach((room, i) => {
      if (room.autoAdd){
        let itemOrder = 0
        let newItems = []
        defaultItems.sort((a, b) => a.order - b.order).forEach((item, i) => {
          item.autoAdd && newItems.push({
            id: `new_item_${newItemID++}`, 
            name: item.name, 
            description: item.description ? item.description : '', 
            conditioncleanliness: "GC & CC", 
            images: [],
            order: itemOrder++,
          })
        })
        room.items.forEach((item, i) => {
          if (item.autoAdd){
            const existingItem = newItems.find(ei => ei.name === item.name)
            if (existingItem){
              newItems = newItems.filter(ei => {
                if (ei.name === item.name){
                  ei.description = item.description ? item.description : ''
                }
                return ei
              })
            } else {
              newItems.push({
                id: `new_item_${newItemID++}`, 
                name: item.name, 
                description: item.description ? item.description : '', 
                conditioncleanliness: "GC & CC", 
                images: [],
                order: itemOrder++,
              })
            }
          }
        })
        rooms.push({id: `new_room_${newRoomID++}`, name: room.name, order: roomOrder++, items: newItems})
      }
    })
    // console.log("default rooms", rooms)

    return {
      isSavingZip: false,
      was_carried_over: false,
      validated: false,
      validationErrors: '',
      address: null,
      previousInventoryLoading: false,
      previousInventory: null,
      previousInventorySuccess: false,
      office: this.getDefaultUserOffice(),
      property_manager: '',
      tenancy_start_date: null,
      property_compiled_date: null,
      addressIsInvalid: false,
      searchValue: "",
      searchOptions: [],
      searchSelected: [],
      searchIsLoading: false,
      searchIsVisible: false,

      rooms: rooms,
      newItemID: newItemID,
      newImageID: 0,
      newRoomID: newRoomID,
      removedRoomItemItems: [],
      removedRooms: [],
      openRoom: 0,
      openRoomPreviousScroll: 0,

      tenant_details: "",
      let_landlord_details: "",
      let_only: false,
      electricity_meter_location: "",
      electricity_meter_readings: "",
      electricity_supplier: "",
      electricity_meter_images: [],
      gas_meter_location: "",
      gas_meter_readings: "",
      no_gas_oil_meter: false,
      gas_supplier: "",
      gas_meter_images: [],
      water_meter_location: "",
      water_meter_readings: "",
      no_water_meter: false,
      water_supplier: "",
      water_meter_images: [],
      fuse_board_location: "",
      fuse_board_type: "RCD",
      fuse_board_images: [],
      stop_tap_location: "",
      stop_tap_images: [],
      keys_codes: "",
      keys_codes_images: [],
      property_brief_description: "",

      signatureMethod: "draw",
      check_in_signature: "",
      signatureIsValid: false,

      submit_inventory: false,

      hasChanges: false,
      savedImagesToDelete: [],
      newInventoryAutosaveName: null,

    }
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  componentDidMount(){
  }

  setItemState = (item, saveChanges) => {
    // console.log("setItemState", item)

    const address_full = item && item.address_full ? [
      item.address_full.tenant_address_line_1, 
      item.address_full.tenant_address_line_2, 
      item.address_full.tenant_town_city, 
      item.address_full.tenant_county, 
      item.address_full.tenant_postcode
    ].filter(el => el !== null && el !== "") : [];

    let tenancy_start_date = null
    if (item.tenancy_start_date){
      let split_tenancy_start_date = item.tenancy_start_date.split('-')
      tenancy_start_date = new Date(`${split_tenancy_start_date[2]}-${split_tenancy_start_date[1]}-${split_tenancy_start_date[0]}`)
    }
    let property_compiled_date = null
    if (item.property_compiled_date){
      let split_property_compiled_date = item.property_compiled_date.split('-')
      property_compiled_date = new Date(`${split_property_compiled_date[2]}-${split_property_compiled_date[1]}-${split_property_compiled_date[0]}`)
    }

    if (item.rooms){
      item.rooms.sort((a, b) => a.order - b.order).forEach((roomItem, roomIndex) => {
        roomItem.order = roomIndex
        roomItem.items.sort((aa, bb) => aa.order - bb.order).forEach((roomItemItem, roomItemIndex) => {
          roomItemItem.order = roomItemIndex
        })
      })
    }

    let property_manager = ''
    if (item){
      if (item.property_manager){
        property_manager = item.property_manager
      } else if (item.address_full && item.address_full.property_manager){
        property_manager = item.address_full.property_manager
      }
    }

    this.setState({
      isSavingZip: false,
      validated: false,
      validationErrors: '',
      hasChanges: false,
      id: item.id,
      tenancy_start_date: tenancy_start_date,
      property_compiled_date: property_compiled_date,
      was_carried_over: item.was_carried_over ? item.was_carried_over : false,
      address: address_full.length > 0 ? {address_full: address_full.join(', '), id: item.address_full.id} : null,
      searchValue: address_full,
      searchOptions: [],
      searchSelected: address_full.length > 0 ? [{address_full: address_full.join(', '), id: item.address_full.id}] : [],
      searchIsLoading: false,
      searchIsVisible: false,
      office: item.office ? item.office : this.getDefaultUserOffice(),
      property_manager: property_manager,

      rooms: item.rooms ? item.rooms : [],
      openRoom: 0,
      openRoomPreviousScroll: 0,

      tenant_details: item.tenant_details ? item.tenant_details : "",
      let_landlord_details: item.let_landlord_details ? item.let_landlord_details : "",
      let_only: item.let_only ? item.let_only : false,
      electricity_meter_location: item.electricity_meter_location ? item.electricity_meter_location : "",
      electricity_meter_readings: item.electricity_meter_readings ? item.electricity_meter_readings : "",
      electricity_meter_images: item.electricity_meter_images ? item.electricity_meter_images : [],
      electricity_supplier: item.electricity_supplier ? item.electricity_supplier : "",
      gas_meter_location: item.gas_meter_location ? item.gas_meter_location : "",
      gas_meter_readings: item.gas_meter_readings ? item.gas_meter_readings : "",
      gas_supplier: item.gas_supplier ? item.gas_supplier : "",
      gas_meter_images: item.gas_meter_images ? item.gas_meter_images : [],
      water_meter_location: item.water_meter_location ? item.water_meter_location : "",
      water_meter_readings: item.water_meter_readings ? item.water_meter_readings : "",
      water_supplier: item.water_supplier ? item.water_supplier : "",
      water_meter_images: item.water_meter_images ? item.water_meter_images : [],
      fuse_board_location: item.fuse_board_location ? item.fuse_board_location : "",
      fuse_board_type: item.fuse_board_type ? item.fuse_board_type : "RCD",
      fuse_board_images: item.fuse_board_images ? item.fuse_board_images : [],
      stop_tap_location: item.stop_tap_location ? item.stop_tap_location : "",
      stop_tap_images: item.stop_tap_images ? item.stop_tap_images : [],
      keys_codes: item.keys_codes ? item.keys_codes : "",
      keys_codes_images: item.keys_codes_images ? item.keys_codes_images : [],
      property_brief_description: item.property_brief_description ? item.property_brief_description : "",
      no_water_meter: item.no_water_meter ? item.no_water_meter : false,
      no_gas_oil_meter: item.no_gas_oil_meter ? item.no_gas_oil_meter : false,

      check_in_signature: item.check_in_signature ? item.check_in_signature : "",
      signatureMethod: item.signatureMethod ? item.signatureMethod : "draw",

      submit_inventory: false,
    }, () => {
      if (saveChanges){
        // this.createAutosaveName();
        this.saveChanges();
      }
    });

    if (item.searchValue){
      this.setState({
        searchValue: item.searchValue,
        searchOptions: item.searchOptions,
        searchSelected: item.searchSelected,
        searchIsLoading: item.searchIsLoading,
        searchIsVisible: item.searchIsVisible,
      })
    }

  }

  createAutosaveName = () => {
    const { hasChanges } = this.state
    const { inventoryAutosave, item, inventoryAutosaveName } = this.props
    let thisComp = this
    let itemID = item && item.id ? item.id : 0
    if (!inventoryAutosaveName){
      localForage.keys().then(function(keys) {
        const autosaves = keys.filter(key => key === `save_inventory_${itemID}` || key.startsWith(`save_inventory_${itemID}_`))
        if (!autosaves){
          autosaves = []
        }
        let proposedNumber = 2
        let nameValid = false
        while (!nameValid){
          let exists = autosaves.find(autosaveItem => autosaveItem === `save_inventory_${itemID}_${proposedNumber}`)
          if (!exists){
            nameValid = true
            thisComp.setState({
              newInventoryAutosaveName: `save_inventory_${itemID}_${proposedNumber}`,
            })
          } else {
            proposedNumber++
          }
        }
      })
    }
  }

  onShow = () => {
    const { hasChanges } = this.state
    const { inventoryAutosave, item, inventoryAutosaveName } = this.props
    // console.log("EditInventoryModal, onShow", this.state, this.props)
    // const { hasChanges } = this.state
    // const { inventoryAutosave, item, inventoryAutosaveName } = this.props
    // let thisComp = this
    // let itemID = item && item.id ? item.id : 0
    // if (!inventoryAutosaveName){
    //   localForage.keys().then(function(keys) {
    //     const autosaves = keys.filter(key => key === `save_inventory_${itemID}` || key.startsWith(`save_inventory_${itemID}_`))
    //     if (!autosaves){
    //       autosaves = []
    //     }
    //     let proposedNumber = 2
    //     let nameValid = false
    //     while (!nameValid){
    //       let exists = autosaves.find(autosaveItem => autosaveItem === `save_inventory_${itemID}_${proposedNumber}`)
    //       if (!exists){
    //         nameValid = true
    //         thisComp.setState({
    //           newInventoryAutosaveName: `save_inventory_${itemID}_${proposedNumber}`,
    //         })
    //       } else {
    //         proposedNumber++
    //       }
    //     }
    //   })
    // }
    this.createAutosaveName();

    this.setState({
      validated: false,
      previousInventory: null,
      previousInventoryLoading: false,
      previousInventorySuccess: false,
    })
    this.initialiseSignaturePad()
    if (inventoryAutosave) {
      this.loadSave()
    } else if (item) {
      this.setItemState(item, false)
    } else {
      this.setState(this.getDefaultState())
    }
  }

  initialiseSignaturePad = () => {
    var canvas = document.querySelector("canvas");
    if (canvas) {
      canvas.width = canvas.parentElement.offsetWidth
      canvas.height = canvas.parentElement.offsetWidth / 2
      signaturePad = new SignaturePad(canvas);
    }
  }

  dataURLtoFile = (dataURI, filename) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: 'image/jpeg' });
  }

  blobToFile = (theBlob, fileName) => {
      return new File([theBlob], fileName, { type: "image/jpeg", });
  }

  getValidatedData = (handleSuccess) => {
      const { searchSelected, check_in_signature, signatureMethod, submit_inventory } = this.state

      function setValidatedImagesDateTaken(images) {
        if (images && images.length > 0){
          for (const imageItem of images) {
            if (imageItem.dateTaken){
              let d = imageItem.dateTaken
              imageItem.dateTaken = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
            }
          }
        }
      }

      let rooms = cloneDeep(this.state.rooms)
      for (const roomItem of rooms) {
        for (const roomItemItem of roomItem.items) {
          setValidatedImagesDateTaken(roomItemItem.images)
        }
      }

      let electricity_meter_images = cloneDeep(this.state.electricity_meter_images)
      let gas_meter_images = cloneDeep(this.state.gas_meter_images)
      let water_meter_images = cloneDeep(this.state.water_meter_images)
      let fuse_board_images = cloneDeep(this.state.fuse_board_images)
      let stop_tap_images = cloneDeep(this.state.stop_tap_images)
      let keys_codes_images = cloneDeep(this.state.keys_codes_images)

      setValidatedImagesDateTaken(electricity_meter_images)
      setValidatedImagesDateTaken(gas_meter_images)
      setValidatedImagesDateTaken(water_meter_images)
      setValidatedImagesDateTaken(fuse_board_images)
      setValidatedImagesDateTaken(stop_tap_images)
      setValidatedImagesDateTaken(keys_codes_images)

      let tenancy_start_date = null
      if (this.state.tenancy_start_date){
        let d = this.state.tenancy_start_date
        tenancy_start_date = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      }
      let property_compiled_date = null
      if (this.state.property_compiled_date){
        let d = this.state.property_compiled_date
        property_compiled_date = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      }

      let validatedData = {
        id: this.state.id,
        was_carried_over: this.state.was_carried_over,
        address: searchSelected && searchSelected.length > 0 && searchSelected[0].id ? searchSelected[0].id : "",
        office: this.state.office,
        property_manager: this.state.property_manager,
        tenancy_start_date: tenancy_start_date,
        property_compiled_date: property_compiled_date,

        tenant_details: this.state.tenant_details,
        let_landlord_details: this.state.let_landlord_details,
        let_only: this.state.let_only,
        electricity_meter_location: this.state.electricity_meter_location,
        electricity_meter_readings: this.state.electricity_meter_readings,
        electricity_supplier: this.state.electricity_supplier,
        electricity_meter_images: electricity_meter_images,
        gas_meter_location: this.state.gas_meter_location,
        gas_meter_readings: this.state.gas_meter_readings,
        no_gas_oil_meter: this.state.no_gas_oil_meter,
        gas_supplier: this.state.gas_supplier,
        gas_meter_images: gas_meter_images,
        water_meter_location: this.state.water_meter_location,
        water_meter_readings: this.state.water_meter_readings,
        no_water_meter: this.state.no_water_meter,
        water_supplier: this.state.water_supplier,
        water_meter_images: water_meter_images,
        fuse_board_location: this.state.fuse_board_location,
        fuse_board_type: this.state.fuse_board_type,
        fuse_board_images: fuse_board_images,
        stop_tap_location: this.state.stop_tap_location,
        stop_tap_images: stop_tap_images,
        keys_codes: this.state.keys_codes,
        keys_codes_images: keys_codes_images,
        property_brief_description: this.state.property_brief_description,

        submit_inventory: this.state.submit_inventory,
        check_in_signature: this.state.check_in_signature,

        rooms: rooms,
        removedRoomItemItems: this.state.removedRoomItemItems,
        removedRooms: this.state.removedRooms,
      }
      if (submit_inventory && signatureMethod === 'draw' && signaturePad){

        let canvas = document.querySelector("canvas")
        canvasToBlob(canvas, 'image/png').then(blob => {
          blob.name = "signature.png"
          validatedData.check_in_signature_image = blob

          // console.log("validatedData", validatedData)
          handleSuccess(validatedData)
        })
      } else {

        // console.log("validatedData", validatedData)
        handleSuccess(validatedData)
      }
  }

  getAutosaveName = () => {
    const { inventoryAutosaveName } = this.props
    const { newInventoryAutosaveName } = this.state
    // console.log("inventoryAutosaveName, newInventoryAutosaveName", inventoryAutosaveName, newInventoryAutosaveName)
    if (inventoryAutosaveName) return inventoryAutosaveName
    else if (newInventoryAutosaveName) return newInventoryAutosaveName
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    event.preventDefault();
    const { searchSelected, check_in_signature, signatureMethod, submit_inventory, property_manager } = this.state
    const inventoryAutosaveName = this.getAutosaveName()
    let submitValid = true
    if (submit_inventory === true){
      if (check_in_signature.length === 0 && (!signaturePad || signaturePad.isEmpty())) submitValid = false
    }
    if (!searchSelected || !searchSelected.length > 0 || !searchSelected[0].id || !property_manager){
      submitValid = false
    }
    if (form.checkValidity() === false || !submitValid) {
      // console.log("invalid")
      event.stopPropagation();
      this.setValidatedErrors()
      this.setState({ validated: true });
    } else {
      // console.log("valid")
      this.getValidatedData((validatedData) => {
        this.props.editInventory(validatedData, inventoryAutosaveName)
        this.setState({ hasChanges: false, promptLoadSave: false, hasPromptedLoadSave: false, validationErrors: '' })
        this.props.handleClose()
      })
    }
    // !submitValid && this.setState({ validated: true });
  }

  setValidatedErrors = () => {
    let message = ""
    const { searchSelected, check_in_signature, signatureMethod, submit_inventory, property_manager } = this.state
    const { electricity_meter_location, electricity_meter_readings, electricity_supplier } = this.state
    const { no_gas_oil_meter, gas_meter_location, gas_meter_readings, gas_supplier } = this.state
    const { no_water_meter, water_meter_location, water_meter_readings, water_supplier } = this.state
    if (submit_inventory === true){
      if (check_in_signature.length === 0 && (!signaturePad || signaturePad.isEmpty())){
        message = "Signature is required."
      } else if (!electricity_meter_location || electricity_meter_location.length === 0){
        message = "Electricity meter location field required."
      } else if (!electricity_meter_readings || electricity_meter_readings.length === 0){
        message = "Electricity readings field required."
      } else if (!electricity_supplier || electricity_supplier.length === 0){
        message = "Electricity meter supplier field required."
      }
      else if (!no_gas_oil_meter){
        if (!gas_meter_location || gas_meter_location.length === 0){
          message = "Gas/oil meter location field required."
        } else if (!gas_meter_readings || gas_meter_readings.length === 0){
          message = "Gas/oil readings field required."
        } else if (!gas_supplier || gas_supplier.length === 0){
          message = "Gas/oil meter supplier field required."
        }
      }
      else if (!no_water_meter){
        if (!water_meter_location || water_meter_location.length === 0){
          message = "Water meter location field required."
        } else if (!water_meter_readings || water_meter_readings.length === 0){
          message = "Water readings field required."
        }
      }
    }
    if (!water_supplier || water_supplier.length === 0){
      message = "Water supplier field required."
    }
    if (!searchSelected || !searchSelected.length > 0 || !searchSelected[0].id){
      message = "Address is required."
    }
    if (!property_manager) {
      message = "Property manager is required."
    }
    this.setState({validationErrors: message})
    // return <p className="mr-3 text-danger">{message}</p>
  }

  handleOnChange = (event) => {
    let { office } = this.state
    if (event.target.name === 'property_manager'){
      let property_manager = event.target.value
      const { userList } = this.props
      if (userList && property_manager){
        let userOffice = userList.find(userItem => userItem.id.toString() === property_manager)
        if (userOffice) {
          office = userOffice.office
        }
      }
    }
  	this.setState({ 
      office,
      [event.target.name]: event.target.value,
      hasChanges: true,
    }, () => this.saveChanges());
  }

  saveImage = (imageFile) => {
    const {offlineFileCount} = this.props
    const myFileID = offlineFileCount+1
    let reader = new FileReader();
    reader.onload = function(){
      localForage.setItem(`file ${myFileID}`, reader.result)
    };
    reader.readAsDataURL(imageFile);
    this.props.incrementOfflineFileCount()
    return myFileID
  }

  saveChanges = () => {
    // console.log("saveChanges")
    const inventoryAutosaveName = this.getAutosaveName()
    // console.log("inventoryAutosaveName", inventoryAutosaveName)
    const savedState = cloneDeep(this.state)

    function removeFileObjects(images) {
      if (images && images.length > 0){
        for (const imageItem of images) {
          if (imageItem.autosaveID){
            delete imageItem.url
          }
        }
      }
    }

    // Remove File objects
    for (const roomItem of savedState.rooms) {
      for (const roomItemItem of roomItem.items) {
        removeFileObjects(roomItemItem.images)
      }
    }

    removeFileObjects(savedState.electricity_meter_images)
    removeFileObjects(savedState.gas_meter_images)
    removeFileObjects(savedState.water_meter_images)
    removeFileObjects(savedState.fuse_board_images)
    removeFileObjects(savedState.stop_tap_images)
    removeFileObjects(savedState.keys_codes_images)

    // console.log("savedState", savedState)
    let validatedData = {
      id: savedState.id,
      tenancy_start_date: savedState.tenancy_start_date,
      property_compiled_date: savedState.property_compiled_date,
      was_carried_over: savedState.was_carried_over,
      address: savedState.address,
      property_manager: savedState.property_manager,
      office: savedState.office,
      searchValue: savedState.searchValue,
      searchOptions: savedState.searchOptions,
      searchSelected: savedState.searchSelected,
      searchIsLoading: savedState.searchIsLoading,
      searchIsVisible: savedState.searchIsVisible,

      newRoomID: savedState.newRoomID,
      newItemID: savedState.newItemID,
      removedRooms: savedState.removedRooms,
      removedRoomItemItems: savedState.removedRoomItemItems,

      rooms: savedState.rooms,
      tenant_details: savedState.tenant_details,
      let_landlord_details: savedState.let_landlord_details,
      let_only: savedState.let_only,
      electricity_meter_location: savedState.electricity_meter_location,
      electricity_meter_readings: savedState.electricity_meter_readings,
      electricity_supplier: savedState.electricity_supplier,
      electricity_meter_images: savedState.electricity_meter_images,
      gas_meter_location: savedState.gas_meter_location,
      gas_meter_readings: savedState.gas_meter_readings,
      no_gas_oil_meter: savedState.no_gas_oil_meter,
      gas_supplier: savedState.gas_supplier,
      gas_meter_images: savedState.gas_meter_images,
      water_meter_location: savedState.water_meter_location,
      water_meter_readings: savedState.water_meter_readings,
      no_water_meter: savedState.no_water_meter,
      water_supplier: savedState.water_supplier,
      water_meter_images: savedState.water_meter_images,
      fuse_board_location: savedState.fuse_board_location,
      fuse_board_type: savedState.fuse_board_type,
      fuse_board_images: savedState.fuse_board_images,
      stop_tap_location: savedState.stop_tap_location,
      stop_tap_images: savedState.stop_tap_images,
      keys_codes: savedState.keys_codes,
      keys_codes_images: savedState.keys_codes_images,
      property_brief_description: savedState.property_brief_description,
      check_in_signature: savedState.check_in_signature,
      signatureMethod: savedState.signatureMethod,
      check_in_signature_image: savedState.check_in_signature_image,
    }

    // console.log("validatedData", validatedData)

    this.props.saveInventory(validatedData, inventoryAutosaveName)
  }

  loadSave = () => {
    // console.log("loadSave")
    let itemID = null
    if (this.props.item){
      itemID = this.props.item.id
    } else {
      itemID = history.location.hash.split('-').pop()
    }

    let result = this.props.inventoryAutosave
    if (result){
      // console.log("result", result)
      let thisComp = this


      let getImages = async (result) => {

        let saveItem = JSON.parse(result)

        // Update old autosaves
        if (!saveItem.electricity_meter_images) saveItem.electricity_meter_images = []
        if (!saveItem.gas_meter_images) saveItem.gas_meter_images = []
        if (!saveItem.water_meter_images) saveItem.water_meter_images = []
        if (!saveItem.fuse_board_images) saveItem.fuse_board_images = []
        if (!saveItem.stop_tap_images) saveItem.stop_tap_images = []
        if (!saveItem.keys_codes_images) saveItem.keys_codes_images = []

        async function forageImages(images){
          if (images && images.length > 0){
            for (const imageItem of images) {
              if (imageItem.dateTaken) imageItem.dateTaken = new Date(imageItem.dateTaken)
              if (!imageItem.dateTaken) imageItem.dateTaken = new Date()
              if (imageItem.autosaveID){
                imageItem.url = await localForage.getItem(`file ${imageItem.autosaveID}`)
                if (typeof imageItem.url === 'string'){
                  let arrayBufferBlob = new Blob([imageItem.url], {type: "text/plain"})
                  imageItem.url = thisComp.dataURLtoFile(imageItem.url, 'savedFile.jpeg')
                }
              }
            }
          }
        }

        // async function forageItems(items){
        //   for (const roomItemItem of items) {
        //     await forageImages(roomItemItem.images)
        //   }
        //   return {result: items}
        // }

        saveItem.rooms.sort((a, b) => a.order - b.order).forEach((room, roomIndex) => {
          room.order = roomIndex
          room.items.sort((aa, bb) => aa.order - bb.order).forEach((roomItem, roomItemIndex) => {
            roomItem.order = roomItemIndex
          })
        })
        for (const roomItem of saveItem.rooms) {
          for (const roomItemItem of roomItem.items) {
            await forageImages(roomItemItem.images)
          }
          // let saveRoomItems = await forageItems(roomItem.items)
          // roomItem.items = saveRoomItems.result
        }
            
        await forageImages(saveItem.electricity_meter_images)
        await forageImages(saveItem.gas_meter_images)
        await forageImages(saveItem.water_meter_images)
        await forageImages(saveItem.fuse_board_images)
        await forageImages(saveItem.stop_tap_images)
        await forageImages(saveItem.keys_codes_images)

        if (saveItem.let_only !== true) saveItem.let_only = false

        // console.log("final saveItem", saveItem)

        // console.log("promise saveItem", saveItem)
        this.setState({
          isSavingZip: false,
          id: saveItem.id,
          tenancy_start_date: saveItem.tenancy_start_date ? new Date(saveItem.tenancy_start_date) : null,
          property_compiled_date: saveItem.property_compiled_date ? new Date(saveItem.property_compiled_date) : null,
          was_carried_over: saveItem.was_carried_over,
          address: saveItem.address,
          previousInventoryLoading: false,
          previousInventory: null,
          previousInventorySuccess: false,
          property_manager: saveItem.property_manager,
          office: saveItem.office,
          searchValue: saveItem.searchValue,
          searchOptions: saveItem.searchOptions,
          searchSelected: saveItem.searchSelected,
          searchIsLoading: saveItem.searchIsLoading,
          searchIsVisible: saveItem.searchIsVisible,

          rooms: saveItem.rooms,

          newRoomID: saveItem.newRoomID,
          newItemID: saveItem.newItemID,
          removedRooms: saveItem.removedRooms,
          removedRoomItemItems: saveItem.removedRoomItemItems,
          openRoom: 0,
          openRoomPreviousScroll: 0,

          tenant_details: saveItem.tenant_details,
          let_landlord_details: saveItem.let_landlord_details,
          let_only: saveItem.let_only,
          electricity_meter_location: saveItem.electricity_meter_location,
          electricity_meter_readings: saveItem.electricity_meter_readings,
          electricity_supplier: saveItem.electricity_supplier,
          electricity_meter_images: saveItem.electricity_meter_images,
          gas_meter_location: saveItem.gas_meter_location,
          gas_meter_readings: saveItem.gas_meter_readings,
          no_gas_oil_meter: saveItem.no_gas_oil_meter,
          gas_supplier: saveItem.gas_supplier,
          gas_meter_images: saveItem.gas_meter_images,
          water_meter_location: saveItem.water_meter_location,
          water_meter_readings: saveItem.water_meter_readings,
          no_water_meter: saveItem.no_water_meter,
          water_supplier: saveItem.water_supplier,
          water_meter_images: saveItem.water_meter_images,
          fuse_board_location: saveItem.fuse_board_location,
          fuse_board_type: saveItem.fuse_board_type,
          fuse_board_images: saveItem.fuse_board_images,
          stop_tap_location: saveItem.stop_tap_location,
          stop_tap_images: saveItem.stop_tap_images,
          keys_codes: saveItem.keys_codes,
          keys_codes_images: saveItem.keys_codes_images,
          property_brief_description: saveItem.property_brief_description,
          check_in_signature: saveItem.check_in_signature,

          submit_inventory: false,
        })
        // return saveItem
      }

      getImages(result)//.then(async (saveItem) => {
      // })
    }
    
  }

  handleDateChange = (d, fieldname) => {
    this.setState({
      [fieldname]: d,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  onKeyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13){
    	event.target.blur()
    }
  }

  onFocus = () => {
  	this.setState({
  		searchIsVisible: true,
  	})
  }

  onBlur = () => {
  	this.setState({
  		searchIsVisible: false,
  	})
  }

  onChange = (query) => {
  	// console.log("onChange", query)
    let property_manager = query[0] && query[0].property_manager ? query[0].property_manager : ''
    let { office } = this.state
    const { userList } = this.props
    if (userList && (property_manager || property_manager === 0)){
      let userOffice = userList.find(userItem => userItem.id === property_manager)
      if (userOffice) office = userOffice.office
    }
  	this.setState({
  		address: query[0],
  		searchIsVisible: false,
  		searchSelected: query,
      property_manager,
      office,
      hasChanges: true,
      previousInventoryLoading: true,
      previousInventory: null,
      previousInventorySuccess: false,
  	}, () => {
      if (query[0] && query[0].id) {
        this.loadPreviousInventory(query[0].id);
      } else {
        this.setState({
          previousInventoryLoading: false,
          previousInventorySuccess: false,
        })
      }
      this.saveChanges();
    })
  }

  onInputChange = (searchValue) => {
  	// console.log("handleChange", searchValue)
    this.setState({
    	searchValue: searchValue,
    	searchIsLoading: true,
		searchIsVisible: true,
    });
    const params = {
		q: searchValue,
	}
	const endpoint = `${api_url}rest-auth/address-autocomplete/?${stringify(params)}` 
	let lookupOptions = {
		method: "GET",
		headers: {
			'Content-Type': 'application/json',
      'Authorization': 'Token '+window.localStorage.getItem('authKey'),
		}
	}
	let thisComp = this
	fetch(endpoint, lookupOptions)
	.then(function(response){
		return response.json()
	}).then(function(responseData){
		// console.log("responseData", responseData)
		thisComp.setState({ searchIsLoading: false, })
		if (responseData){
			// console.log("responseData.results", responseData.results)
			thisComp.setState({
				searchOptions: responseData.results,
			})
		}
	}).catch(function(error){
		console.log("error", error)
	})
    }

  loadPreviousInventory = (address_id) => {
    const params = {
      address_id: address_id,
      page: 1,
      ordering: '-tenancy_start_date',
    }
    const endpoint = `${api_url}rest-auth/inventories/?${stringify(params)}` 
    let lookupOptions = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token '+window.localStorage.getItem('authKey'),
      }
    }
    let thisComp = this
    fetch(endpoint, lookupOptions)
    .then(function(response){
      return response.json()
    }).then(function(responseData){
      if (responseData){
        console.log("responseData.results[0]", responseData.results[0])
        if (responseData.results.length > 0){
          thisComp.setState({
            previousInventory: responseData.results[0],
            previousInventoryLoading: false,
          });
        } else {
          thisComp.setState({
            previousInventoryLoading: false,
            previousInventorySuccess: true,
          });
        }
      }
    }).catch(function(error){
      console.log("error", error)
      thisComp.setState({
        previousInventoryLoading: false,
        previousInventorySuccess: true,
      });
    })
  }

  duplicatePreviousInventory = (duplicateImages) => {
    this.setState({
      previousInventoryLoading: true,
    })
    const {previousInventory, newInventoryAutosaveName} = this.state
    const endpoint = `${api_url}rest-auth/inventories/${previousInventory.id}/duplicate_inventory/` 
    let lookupOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token '+window.localStorage.getItem('authKey'),
      },
      body: JSON.stringify({
        "duplicateImages": duplicateImages,
      })
    }
    let thisComp = this
    fetch(endpoint, lookupOptions)
    .then(function(response){
      return response.json()
    }).then(async function(responseData){
      if (responseData){
        console.log("duplicatePreviousInventory responseData", responseData, 'duplicateImages', duplicateImages)

        async function copyMasterCopyImages(rooms){
          if (rooms){
            for (const roomItem of rooms){
              for (const roomItemItem of roomItem.items){
                for (const imageItem of roomItemItem.images){
                  if (imageItem.master_url){
                    imageItem.url = await fetch(imageItem.master_url).then(r => r.blob());
                    imageItem.dateTaken = new Date();
                    const autosaveID = thisComp.saveImage(imageItem.url);
                    imageItem.autosaveID = autosaveID;
                  }
                }
              }
            }
          }
          return rooms;
        }

        async function copySingleFieldMasterCopyImages(singleField){
          if (singleField){
            for (const imageItem of singleField){
              if (imageItem.master_url){
                imageItem.url = await fetch(imageItem.master_url).then(r => r.blob());
                imageItem.dateTaken = new Date();
                const autosaveID = thisComp.saveImage(imageItem.url);
                imageItem.autosaveID = autosaveID;
              }
            }
          }
          return singleField;
        }

        if (duplicateImages){
          responseData.rooms = await copyMasterCopyImages(responseData.rooms);
          responseData.electricity_meter_images = await copySingleFieldMasterCopyImages(responseData.electricity_meter_images);
          responseData.gas_meter_images = await copySingleFieldMasterCopyImages(responseData.gas_meter_images);
          responseData.water_meter_images = await copySingleFieldMasterCopyImages(responseData.water_meter_images);
          responseData.fuse_board_images = await copySingleFieldMasterCopyImages(responseData.fuse_board_images);
          responseData.stop_tap_images = await copySingleFieldMasterCopyImages(responseData.stop_tap_images);
          responseData.keys_codes_images = await copySingleFieldMasterCopyImages(responseData.keys_codes_images);
        }

        thisComp.props.deleteInventoryAutosave(newInventoryAutosaveName, null);

        thisComp.setState({
          previousInventoryLoading: false,
          previousInventorySuccess: true,
          newInventoryAutosaveName: `save_inventory_${responseData.id}_${2}`,
        }, () => thisComp.setItemState(responseData, true));
      }
    }).catch(function(error){
      console.log("error", error)
      thisComp.setState({
        previousInventoryLoading: false,
      });
    })
  }

  removeRoomItemItem = (roomItemItem) => {
    let { rooms, removedRoomItemItems } = this.state
    typeof roomItemItem.id === 'number' && removedRoomItemItems.push(roomItemItem.id)

    rooms.forEach((room, roomIndex) => {
      room.items.forEach((item, itemIndex) => {
        if (item.id === roomItemItem.id){
          for (const imageItem of item.images) {
            if (imageItem.autosaveID) localForage.removeItem(`file ${imageItem.autosaveID}`)
          }
        }
      })
    })

    rooms.forEach(room => {
      room.items = room.items.filter(item => item.id !== roomItemItem.id)
    })

    rooms.sort((a, b) => a.order - b.order).forEach((roomItem, roomIndex) => {
      roomItem.order = roomIndex
      roomItem.items.sort((aa, bb) => aa.order - bb.order).forEach((roomItemItem, roomItemIndex) => {
        roomItemItem.order = roomItemIndex
      })
    })
    
    this.setState({ 
      rooms, 
      removedRoomItemItems,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  clearFileInput = (ctrl) => {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  handleSingleFieldImageAdd = (event) => {
    // console.log("handleSingleFieldImageAdd", event.target.name, event.target)
    const name = event.target.name
    let field = this.state[name]
    let images = []
    let files = []
    if (event.target.files){
      files = Array.from(event.target.files)
    } else if (event.dataTransfer) {
      files = Array.from(event.dataTransfer.files)
    }
    this.clearFileInput(event.target)
    let thisComp = this
    files && files.length > 0 && Promise.all(
      files.map(file => 
        new Promise(async (resolve, reject) => {
          if (file){
            let fileName = file.name.replace(/\.[^/.]+$/, "")+".jpeg"
            const data = await getBase64Strings([file], { maxSize: 600 });
            const newFile = thisComp.dataURLtoFile(data[0], fileName)
            const autosaveID = thisComp.saveImage(newFile)
            images.push({url: newFile, autosaveID, dateTaken: new Date()})
            resolve()
          } else {
            resolve()
          }
        })
      )
    ).then(() => {
      // console.log("roomItemItem.image", roomItemItem.image)
      field = field.concat(images)
      // console.log("resolved images", images)
      thisComp.setState({
        [name]: field,
        hasChanges: true,
      }, () => thisComp.saveChanges())
    })
  }

  handleRoomItemItemImageAdd = (event, roomItemItemID) => {
    // event.preventDefault();
    // console.log("handleRoomItemItemImageAdd", event.target)
    let { rooms } = this.state
    let roomItemItem = this.getRoomItemItemFromID(rooms, roomItemItemID)
    let images = []
    let files = []
    if (event.target.files){
      files = Array.from(event.target.files)
    } else if (event.dataTransfer) {
      files = Array.from(event.dataTransfer.files)
    }
    this.clearFileInput(event.target)
    if (roomItemItem){
      let thisComp = this
      files && files.length > 0 && Promise.all(
        files.map(file => 
          new Promise(async (resolve, reject) => {
            if (file){
              let fileName = file.name.replace(/\.[^/.]+$/, "")+".jpeg"
              const data = await getBase64Strings([file], { maxSize: 600 });
              const newFile = thisComp.dataURLtoFile(data[0], fileName)
              const autosaveID = thisComp.saveImage(newFile)
              images.push({url: newFile, autosaveID, dateTaken: new Date()})
              resolve()
            } else {
              resolve()
            }
          })
        )
      ).then(() => {
        // console.log("roomItemItem.image", roomItemItem.image)
        roomItemItem.images = roomItemItem.images.concat(images)
        // console.log("resolved images", images)
        thisComp.setState({
          rooms,
          hasChanges: true,
        }, () => thisComp.saveChanges())
      })
    }
  }

  handleRoomItemItemImageChange = (event, roomItemItemID) => {
    // event.preventDefault();
    // console.log("handleRoomItemItemImageChange", event.target.files)
    let { rooms } = this.state
    let roomItemItem = this.getRoomItemItemFromID(rooms, roomItemItemID)
    let images = []
    let files = []
    if (event.target.files){
      files = Array.from(event.target.files)
    } else if (event.dataTransfer) {
      files = Array.from(event.dataTransfer.files)
    }
    this.clearFileInput(event.target)
    if (roomItemItem){
      let thisComp = this
      files && files.length > 0 && Promise.all(
        files.map(file => 
          new Promise(async (resolve, reject) => {
            let fileName = file.name.replace(/\.[^/.]+$/, "")+".jpeg"
            const data = await getBase64Strings([file], { maxSize: 600 });
            const newFile = thisComp.dataURLtoFile(data[0], fileName)
            const autosaveID = thisComp.saveImage(newFile)
            images.push({url: newFile, autosaveID, dateTaken: new Date()})
            resolve()
          })
        )
      ).then(() => {
        roomItemItem.images = roomItemItem.images.concat(images)
        // console.log("resolved images", images)
        thisComp.setState({
          rooms,
          hasChanges: true,
        }, () => thisComp.saveChanges())
      })
    }
  }

  getRoomItemItemFromID = (rooms, roomItemItemID) => {
    let roomItemItem = null
    rooms.forEach(roomItem => {
      let result = roomItem.items.find(item => item.id === roomItemItemID)
      if (result){
        roomItemItem = result
      }
    })
    return roomItemItem
  }

  handleItemFieldChange = (event, roomItemItemID, fieldname) => {
    // console.log("handleItemFieldChange", event.target.value)
    let { rooms } = this.state
    let roomItemItem = this.getRoomItemItemFromID(rooms, roomItemItemID)
    if (roomItemItem){
      roomItemItem[fieldname] = event.target.value
      this.setState({ 
        rooms,
        hasChanges: true,
      }, () => this.saveChanges())
    }
  }

  handleRoomNameChange = (event, RoomID) => {
    let { rooms } = this.state
    let roomItem = rooms.find(item => item.id === RoomID)
    if (roomItem){
      roomItem.name = event.target.value
      this.setState({
        rooms,
        hasChanges: true,
      }, () => this.saveChanges())
    }
  }

  handleRoomExpandedChange = (event, value, RoomID) => {
    let { rooms } = this.state
    rooms.forEach(item => {
      item.expanded = false
    })
    let roomItem = rooms.find(item => item.id === RoomID)
    if (roomItem){
      roomItem.expanded = value
      this.setState({
        rooms,
        hasChanges: true,
      }, () => {
        if (value === true){
          let roomTop = document.querySelector(`.room-${RoomID}`).getBoundingClientRect().top
          if (this.props.isMobile()){ 
            window.scroll(0, window.scrollY+roomTop)
          } else {
            document.querySelector('.modal.show').scrollTop += roomTop
          }
        }
        this.saveChanges()
      })
    }
  }

  onImageError = e => {
    // console.log("onImageError", e.target)
    var element = document.createElement("div");
    element.innerHTML = "<b>Image unavailable.</b><p class=\"small\">This image cannot be edited offline or the link has expired.</p>";
    element.setAttribute("role", "alert");
    element.setAttribute("class", "fade alert alert-info show");
    e.target.parentNode.parentNode.parentNode.replaceChild(element, e.target.parentNode.parentNode)
  }

  roomItemItemImage = (url, alt, rotateMethod, removeMethod, imageItem, setDateMethod) => 
  <div className="mb-3">
    <div className="position-relative ">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={url} className="img-fluid" alt={alt} style={{ minWidth: '100%' }} onError={this.onImageError} />
      </a>
      <FontAwesomeIcon icon={faTrash} className="position-absolute fixed-top remove-image-button" onClick={removeMethod} />
      {rotateMethod && <FontAwesomeIcon icon={faUndo} className="position-absolute fixed-bottom rotate-button" onClick={rotateMethod} />}
    </div>
    {imageItem.dateTaken && 
      <DatePicker
        className="form-control "
        dateFormat="dd-MM-yyyy"
        isClearable={false}
        selected={imageItem.dateTaken}
        onChange={(d) => setDateMethod(d)}
        onFocus={(e) => e.target.readOnly = true}
        onBlur={(e) => e.target.readOnly = false}
      />
    }
  </div>

  findImage = (roomItemItem, imageIndex) => {
    let { rooms } = this.state
    let image = null
    rooms.forEach(room => {
      room.items.forEach(com => {
        if (com.id === roomItemItem.id){
          image = com.images[imageIndex]
        }
      })
    })
    return image
  }

  setSingleFieldImageDate = (name, imageIndex, d) => {
    let field = this.state[name]
    let foundImage = imageIndex <= field.length ? field[imageIndex] : null
    if (foundImage) {
      field[imageIndex].dateTaken = d
      this.setState({
        [name]: field, 
        hasChanges: true ,
      }, () => this.saveChanges())
    }
  }

  setImageDate = (roomItemItem, imageIndex, d) => {
    let { rooms } = this.state
    rooms.forEach((room, roomIndex) => {
      room.items.forEach((com, comIndex) => {
        if (com.id === roomItemItem.id){
          let foundImage = rooms[roomIndex].items[comIndex].images[imageIndex]
          // if (foundImage.autosaveID) localForage.setItem(`file ${foundImage.autosaveID}`, image)
          rooms[roomIndex].items[comIndex].images[imageIndex].dateTaken = d
        }
      })
    })
    this.setState({ rooms, hasChanges: true }, () => this.saveChanges())
  }

  setSingleFieldImageURL = (name, imageIndex, image) => {
    let field = this.state[name]
    let foundImage = imageIndex <= field.length ? field[imageIndex] : null
    if (foundImage) {
      if (foundImage.autosaveID) localForage.setItem(`file ${foundImage.autosaveID}`, image)
      field[imageIndex].url = image
      this.setState({
        [name]: field, 
        hasChanges: true ,
      }, () => this.saveChanges())
    }
  }

  setImageURL = (roomItemItem, imageIndex, image) => {
    let { rooms } = this.state
    rooms.forEach((room, roomIndex) => {
      room.items.forEach((com, comIndex) => {
        if (com.id === roomItemItem.id){
          let foundImage = rooms[roomIndex].items[comIndex].images[imageIndex]
          if (foundImage.autosaveID) localForage.setItem(`file ${foundImage.autosaveID}`, image)
          rooms[roomIndex].items[comIndex].images[imageIndex].url = image
        }
      })
    })
    this.setState({ rooms, hasChanges: true }, () => this.saveChanges())
  }

  deleteSingleFieldImage = (name, imageIndex) => {
    let field = this.state[name]
    let foundImage = imageIndex <= field.length ? field[imageIndex] : null
    if (foundImage) {
      if (foundImage.autosaveID) localForage.removeItem(`file ${foundImage.autosaveID}`)
      field = field.filter((image, index) => index !== imageIndex)
      this.setState({
        [name]: field, 
        hasChanges: true ,
      }, () => this.saveChanges())
    }
  }

  deleteImage = (roomItemItem, imageIndex) => {
    let { rooms } = this.state
    rooms.forEach((room, roomIndex) => {
      room.items.forEach((com, comIndex) => {
        if (com.id === roomItemItem.id){
          let foundImage = com.images.find((image, index) => index === imageIndex)
          if (foundImage.autosaveID) localForage.removeItem(`file ${foundImage.autosaveID}`)
          com.images = com.images.filter((image, index) => index !== imageIndex)
        }
      })
    })
    this.setState({ rooms, hasChanges: true }, () => this.saveChanges())
  }

  rotateNewSingleFieldImage = (name, imageIndex) => {
    // console.log("rotateNewSingleFieldImage", name, imageIndex)
    let field = this.state[name]
    let foundImage = imageIndex <= field.length ? field[imageIndex] : null
    // console.log("foundImage", this.state, field, foundImage)
    if (foundImage) {
      // console.log("foundImage", foundImage)
      let imageName = ""
      let imageURL = foundImage.url
      if (typeof foundImage.url === 'string'){
        imageURL = imgPrefix+foundImage.url
        imageName = foundImage.url.split('/').pop().split('.')[0]
      } else {
        imageName = foundImage.url.name
      }
      // console.log("imageURL, imageName", imageURL, imageName)
      window.loadImage(
        imageURL,
        (img) => {
          // console.log("img", img, typeof img)
          canvasToBlob(img, 'image/jpeg').then(blob => {
            blob.name = imageName
            // console.log("blob", blob)
            this.setSingleFieldImageURL(name, imageIndex, blob)
          })
        },
        { orientation: 6, canvas: true }
      );
    }
  }

  rotateNewImage = (roomItemItem, imageIndex) => {
    // console.log("roomItemItem, imageIndex", roomItemItem, imageIndex)
    let image = this.findImage(roomItemItem, imageIndex)
    
    // console.log("image", image)
    let imageName = ""
    let imageURL = image.url
    if (typeof image.url === 'string'){
      imageURL = imgPrefix+image.url
      imageName = image.url.split('/').pop().split('.')[0]
    } else {
      imageName = image.url.name
    }
    // console.log("imageURL, imageName", imageURL, imageName)
    window.loadImage(
      imageURL,
      (img) => {
        // console.log("img", img, typeof img)
        canvasToBlob(img, 'image/jpeg').then(blob => {
          blob.name = imageName
          // console.log("blob", blob)
          this.setImageURL(roomItemItem, imageIndex, blob)
        })
      },
      { orientation: 6, canvas: true }
    );
  }

  imagesNotNull = (images) => {
    let result = false
    images.forEach((image) => {
      if (image.url) result = true
    })
    return result
  }

  handleDrop = (e, roomItemItemID, hasImages) => {
    // console.log("handleDrop")
    // e.preventDefault();
    document.querySelectorAll(`.drag`).forEach(element => element.classList.remove("drag"))
    hasImages?this.handleRoomItemItemImageAdd(e, roomItemItemID):this.handleRoomItemItemImageChange(e, roomItemItemID)
  }

  handleDragEnter = (e, roomItemItemID) => {
    // console.log("handleDragEnter", e.clientX, e.target.id)
    e.preventDefault()
    e.stopPropagation()
      let itemElement = document.getElementById(`item-${roomItemItemID}`)
      itemElement.classList.add("drag")
  }
  handleDragLeave = (e, roomItemItemID) => {
    // console.log("handleDragLeave", e.clientX, e.target.id)
    e.stopPropagation()
    if (e.target.id === `item-${roomItemItemID}`){
      let itemElement = document.getElementById(`item-${roomItemItemID}`)
    document.querySelectorAll(`.drag`).forEach(element => element.classList.remove("drag"))
    }
  }

  cloneRoomItemItem = (roomItemItem, roomIndex) => {
    let { newItemID, rooms } = this.state
    let roomItem = rooms.find((cRoom, cRoomIndex) => cRoomIndex === roomIndex)
    // Check for existing item
    const existingRoomItemItem = roomItem.items.find(item => item.name === roomItemItem.name)
    if (existingRoomItemItem) {
      existingRoomItemItem.name = roomItemItem.name
      existingRoomItemItem.description = roomItemItem.description
      existingRoomItemItem.conditioncleanliness = roomItemItem.conditioncleanliness
    } else {
      roomItem.items.push({
        id: `new_item_${newItemID}`, 
        name: roomItemItem.name, 
        description: roomItemItem.description, 
        conditioncleanliness: roomItemItem.conditioncleanliness, 
        images: [],
        order: roomItem.items.length,
      })
    }
    this.setState({ 
      newItemID: ++newItemID,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  makeItemField = (roomItemItem, index, roomIndex, roomItem) => {
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const fileFieldName = `room_${roomIndex}_item_${index}_image`
    let hasImages = false
    if (roomItemItem && roomItemItem.images && roomItemItem.images.length > 0 && this.imagesNotNull(roomItemItem.images)){
      hasImages = true
    }
    const {rooms} = this.state
    const upDownInner = <>
      <div onClick={() => this.moveRoomItemUp(roomItem, roomItemItem)}><FontAwesomeIcon icon={faCaretUp} /></div>
      <div onClick={() => this.moveRoomItemDown(roomItem, roomItemItem)}><FontAwesomeIcon icon={faCaretDown} /></div>
    </>

    const removeItemButtons =
      <>
        <Button variant="danger" size="sm" className="float-left position-absolute d-none d-sm-block" onClick={() => this.removeRoomItemItem(roomItemItem)}>Remove item</Button>
        <h6 className="offset-sm-4 text-right d-block d-sm-none">Item #{index+1}<Button variant="danger" size="sm" className="ml-3" onClick={() => this.removeRoomItemItem(roomItemItem)}>Remove item</Button></h6>
      </>

    const roomItemItemImageItem = (imageItem, imageIndex) => 
      imageItem.url && typeof imageItem.url !== 'number' && (typeof imageItem.url === 'string' ? 
        this.roomItemItemImage(
          imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:'), 
          roomItemItem.comment, 
          null,// () => this.rotateNewImage(roomItemItem, imageIndex),
          () => this.setImageURL(roomItemItem, imageIndex, null),
          imageItem,
          null)
      :
        this.roomItemItemImage(
          URL.createObjectURL(imageItem.url),
          imageItem.url.name, 
          () => this.rotateNewImage(roomItemItem, imageIndex),
          () => this.deleteImage(roomItemItem, imageIndex),
          imageItem,
          (d) => this.setImageDate(roomItemItem, imageIndex, d))
      )

    const roomItemItemImagesList =
      <>
        {roomItemItem && roomItemItem.images && roomItemItem.images.length > 0 && 
          roomItemItem.images.map((imageItem, imageIndex) => roomItemItemImageItem(imageItem, imageIndex)
          ) 
        }
      </>

    const upDownButtons = 
      <>
        <div 
          className="up-down-button d-none d-sm-block" 
          style={{
            position: 'absolute',
            top: '65px',
            left: '0px',
          }}
        >{upDownInner}</div>
        <div 
          className="up-down-button d-block d-sm-none" 
          style={{
            position: 'absolute',
            top: '0px',
            left: '5px',
            top: '20px',
            right: '5px',
          }}
        >{upDownInner}</div>
      </>

    const nameField =
      <Form.Control 
        className="item-name-field"
        type="text" 
        name="name"
        value={roomItemItem.name && roomItemItem.name}
        onChange={(e) => this.handleItemFieldChange(e, roomItemItem.id, 'name')}
      />
    const descriptionField = 
      <InputGroup>
        <FormControl
          className="item-description-field"
          type="textarea" 
          as="textarea"
          rows={2}
          name="description"
          value={roomItemItem.description && roomItemItem.description}
          onChange={(e) => this.handleItemFieldChange(e, roomItemItem.id, 'description')}
          placeholder="Enter item description"
        />
        <InputGroup.Append>
          <DropdownButton 
            title={<FontAwesomeIcon icon={faCopy} />} 
            variant="outline-dark" 
            alignRight
            disabled={rooms.length>1?false:true}
          >
            <Dropdown.Header>Clone item to:</Dropdown.Header>
            {rooms.map((room, mappedRoomIndex) => {
              let activeProp = {}
              const existingClone = room.items.find(cRoomII => {
                return cRoomII.name === roomItemItem.name &&
                  cRoomII.description === roomItemItem.description &&
                  cRoomII.conditioncleanliness === roomItemItem.conditioncleanliness
              })
              if (existingClone) activeProp = {active: true}
              if (roomIndex!==mappedRoomIndex){
                return <Dropdown.Item 
                  onClick={() => this.cloneRoomItemItem(roomItemItem, mappedRoomIndex)}
                   {...activeProp}
                >{room.name!==""?room.name:'-'}</Dropdown.Item>
              }
            })}
          </DropdownButton>
        </InputGroup.Append>
      </InputGroup>
      // <Form.Control 
      //   type="textarea" 
      //   as="textarea"
      //   name="description"
      //   value={roomItemItem.description && roomItemItem.description}
      //   rows={2}
      //   onChange={(e) => this.handleItemFieldChange(e, roomItemItem.id, 'description')}
      //   placeholder="Enter item description"
      // />

    if (!this.props.isMobile()){
      return (
        <div 
          id={`item-${roomItemItem && roomItemItem.id}`} 
          key={roomItemItem && roomItemItem.id}
          className="roomItemItem pt-3"
          onDragEnter={e => this.handleDragEnter(e, roomItemItem.id)}
          onDragOver={e => this.handleDragEnter(e, roomItemItem.id)}
          onDragLeave={e => this.handleDragLeave(e, roomItemItem.id)}
          onDrop={(e) => this.handleDrop(e, roomItemItem.id, hasImages)}
        >
          {upDownButtons}
          <Row>
            <Col sm={3}>
              <InputGroup className="mb-3">
                <FormControl
                  className="item-name-field"
                  type="text" 
                  name="name"
                  value={roomItemItem.name && roomItemItem.name}
                  onChange={(e) => this.handleItemFieldChange(e, roomItemItem.id, 'name')}
                  placeholder="Enter item"
                />
                <InputGroup.Append>
                  <Button 
                    variant="danger"
                    onClick={() => this.removeRoomItemItem(roomItemItem)}
                  >
                  <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup className="mb-3">
                <FormControl
                  className="item-description-field"
                  type="textarea" 
                  as="textarea"
                  rows={2}
                  name="description"
                  value={roomItemItem.description && roomItemItem.description}
                  onChange={(e) => this.handleItemFieldChange(e, roomItemItem.id, 'description')}
                  placeholder="Enter item description"
                />
                <InputGroup.Append>
                  <DropdownButton 
                    title={<FontAwesomeIcon icon={faCopy} />} 
                    variant="outline-dark" 
                    alignRight
                    disabled={rooms.length>1?false:true}
                  >
                    <Dropdown.Header>Clone item to:</Dropdown.Header>
                    {rooms.map((room, mappedRoomIndex) => {
                      let activeProp = {}
                      const existingClone = room.items.find(cRoomII => {
                        return cRoomII.name === roomItemItem.name &&
                          cRoomII.description === roomItemItem.description &&
                          cRoomII.conditioncleanliness === roomItemItem.conditioncleanliness
                      })
                      if (existingClone) activeProp = {active: true}
                      if (roomIndex!==mappedRoomIndex){
                        return <Dropdown.Item 
                          onClick={() => this.cloneRoomItemItem(roomItemItem, mappedRoomIndex)}
                           {...activeProp}
                        >{room.name!==""?room.name:'-'}</Dropdown.Item>
                      }
                    })}
                  </DropdownButton>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col sm={2}>
              <Form.Control 
                as="select" 
                name="conditioncleanliness"
                onChange={(e) => this.handleItemFieldChange(e, roomItemItem.id, 'conditioncleanliness')}
              >
                {CONDITION_CLEANLINESS_CHOICES.map(choice =>
                  roomItemItem.conditioncleanliness && roomItemItem.conditioncleanliness === choice.value ?
                  <option key={choice.value} value={choice.value} selected="selected">{choice.label}</option> : 
                  <option key={choice.value} value={choice.value} >{choice.label}</option>
                )}
              </Form.Control>
            </Col>
            <Col sm={1}>
              {roomItemItem && 
                <>
                  <label htmlFor={fileFieldName} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=${fileFieldName}]`).click()}>
                      <FontAwesomeIcon icon={faCamera} />
                  </label>
                  <input name={fileFieldName} type="file" className="mb-3 d-none" onChange={(e) => hasImages?this.handleRoomItemItemImageAdd(e, roomItemItem.id):this.handleRoomItemItemImageChange(e, roomItemItem.id)} multiple/>
                </>
              }
            </Col>
          </Row>
          <Row className="mt-3">
            {roomItemItem && roomItemItem.images && roomItemItem.images.length > 0 && 
              roomItemItem.images.map((imageItem, imageIndex) => 
                imageItem && imageItem.url && <Col sm={4}>{roomItemItemImageItem(imageItem, imageIndex)}</Col>
              ) 
            }
          </Row>
          <hr className="mt-1 mb-0"/>
          <div className="draggableArea">Drag photos here <FontAwesomeIcon className="ml-2" icon={faCamera} /></div>
        </div>
      )
    }

    return (
      <div className="roomItemItem" id={`item-${roomItemItem && roomItemItem.id}`} key={roomItemItem && roomItemItem.id}>
        {(roomIndex || roomIndex === 0 || index !== 0) && <hr/>}
        {removeItemButtons}
        {upDownButtons}
        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Item name
          </Form.Label>
          <Col sm={sm2}>
            {nameField}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Description
          </Form.Label>
          <Col sm={sm2}>
            {descriptionField}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Condition & Cleanliness
          </Form.Label>
          <Col sm={sm2}>
            <FormButtonGroup 
              choices={CONDITION_CLEANLINESS_CHOICES} 
              name='conditioncleanliness' 
              value={roomItemItem.conditioncleanliness && roomItemItem.conditioncleanliness} 
              onChange={(e) => this.handleItemFieldChange(e, roomItemItem.id, 'conditioncleanliness')}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Images
          </Form.Label>
          <Col sm={5}>
            {roomItemItemImagesList}
            {roomItemItem && 
              <>
                <label htmlFor={fileFieldName} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=${fileFieldName}]`).click()}>
                    {hasImages?'Add more':'Select'}
                </label>
                <input name={fileFieldName} type="file" className="mb-3 d-none" onChange={(e) => hasImages?this.handleRoomItemItemImageAdd(e, roomItemItem.id):this.handleRoomItemItemImageChange(e, roomItemItem.id)} multiple/>
              </>
            }
          </Col>
        </Form.Group>
      </div>
    )
  }

  removeRoom = (roomItem) => {
    let { rooms, removedRooms } = this.state
    const {openRoomPreviousScroll, openRoom} = this.state
    typeof roomItem.id === 'number' && removedRooms.push(roomItem.id)

    let foundRoom = rooms.find(item => item.id === roomItem.id)
    foundRoom && foundRoom.items.forEach((item, itemIndex) => {
      for (const imageItem of item.images) {
        if (imageItem.autosaveID) localForage.removeItem(`file ${imageItem.autosaveID}`)
      }
    })

    rooms = rooms.filter(item => item.id !== roomItem.id)
    rooms.sort((a, b) => a.order - b.order).forEach((room, roomIndex) => {
      room.order = roomIndex
    })
    this.setState({
      rooms,
      removedRooms,
      hasChanges: true,
      openRoom: 0,
    }, () => {
      if (openRoom !== 0) window.scroll(0, openRoomPreviousScroll)
      this.saveChanges()
    })
  }

  moveRoomDown = (roomItem) => {
    let { rooms } = this.state
    const proposedOrder = roomItem.order + 1
    const next = rooms.find(item => item.order === proposedOrder)
    if (next){
      roomItem.order = proposedOrder
      next.order = proposedOrder - 1
    }
    this.setState({
      rooms,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  moveRoomUp = (roomItem) => {
    let { rooms } = this.state
    const proposedOrder = roomItem.order - 1
    const previous = rooms.find(item => item.order === proposedOrder)
    if (previous){
      roomItem.order = proposedOrder
      previous.order = proposedOrder + 1
    }
    this.setState({
      rooms,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  moveRoomItemUp = (roomItem, roomItemItem) => {
    const proposedOrder = roomItemItem.order - 1
    const previous = roomItem.items.find(item => item.order === proposedOrder)
    if (previous){
      roomItemItem.order = proposedOrder
      previous.order = proposedOrder + 1
    }
    this.setState({
      hasChanges: true,
    }, () => this.saveChanges())
  }

  moveRoomItemDown = (roomItem, roomItemItem) => {
    const proposedOrder = roomItemItem.order + 1
    const previous = roomItem.items.find(item => item.order === proposedOrder)
    if (previous){
      roomItemItem.order = proposedOrder
      previous.order = proposedOrder - 1
    }
    this.setState({
      hasChanges: true,
    }, () => this.saveChanges())
  }

  makeRoomField = (roomItem, roomIndex, isMobile) => {
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const upDownInner = <>
      <div onClick={() => this.moveRoomUp(roomItem)}><FontAwesomeIcon icon={faCaretUp} /></div>
      <div onClick={() => this.moveRoomDown(roomItem)}><FontAwesomeIcon icon={faCaretDown} /></div>
    </>
    let carriedOverDetails = this.state.was_carried_over && roomItem.history ? roomItem.history.slice(-1)[0] : {}
    const fileFieldName = `room_${roomIndex}_image`

    const removeRoomButtons = 
      <>
        <Button variant="danger" size="sm" className="float-left position-absolute d-none d-sm-block remove-room-button" onClick={() => this.removeRoom(roomItem)}>Remove room</Button>
        <h6 className="offset-sm-4 text-right d-block d-sm-none">Room #{roomIndex+1} <Button variant="danger" size="sm" className="ml-3" onClick={() => this.removeRoom(roomItem)}>Remove room</Button></h6>
      </>

    const upDownButtons = 
      <>
        <div 
          className="up-down-button d-none d-sm-block" 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >{upDownInner}</div>
        <div 
          className="up-down-button d-block d-sm-none" 
          style={{
            position: 'absolute',
            top: '0px',
            left: '5px',
            top: roomIndex === 0 ? '0px' : '20px',
            right: roomIndex === 0 ? '5px' : '5px',
          }}
        >{upDownInner}</div>
      </>

    const expandButton = 
      <div className="text-center mt-2">
          <Button variant={roomItem.expanded ? "outline-secondary" : "secondary"} onClick={(e) => this.handleRoomExpandedChange(e, !roomItem.expanded, roomItem.id)}>{roomItem.expanded ? "Hide items" : `Show all items (${roomItem.items.length} total)`}</Button>
      </div>

    if (!isMobile){

      const roomItemItemList = 
        roomItem.items && roomItem.items.length > 0 && 
        roomItem.items.sort((a, b) => a.order - b.order).map((roomItemItem, index) => 
          // (roomItem.expanded || index < 2) && this.makeItemField(roomItemItem, index, roomIndex, roomItem)
          this.makeItemField(roomItemItem, index, roomIndex, roomItem)
        )

      const addItemButton = 
        <div className="text-right">
          <SplitButton title="Add item" variant="primary" className="mt-2" alignRight onClick={() => this.addRoomItem(roomItem, '')}>
            {this.props.settings && this.props.settings.inventory_default_room_items && this.props.settings.inventory_default_room_items.length > 0 && 
              JSON.parse(this.props.settings.inventory_default_room_items).map(item => {
                if (roomItem.items && roomItem.items.find(roomItem => roomItem.name === item.name)){
                  return <Dropdown.Item onClick={() => this.addRoomItem(roomItem, `${item.name}`, item)} active>{item.name}</Dropdown.Item>
                } else {
                  return <Dropdown.Item onClick={() => this.addRoomItem(roomItem, `${item.name}`, item)}>{item.name}</Dropdown.Item>
                }
              })
            }
          </SplitButton>
        </div>

       return (
          <div 
            key={roomItem && roomItem.id} 
            className={`${(roomIndex!==0?'room':'room-0')} room-${roomItem.id}`}
          >
          {removeRoomButtons}
          {upDownButtons}
          <Row>
            <Col sm={3}>
              <Form.Control 
                className="room-name-field"
                type="text" 
                name="name"
                value={roomItem.name && roomItem.name}
                onChange={(e) => this.handleRoomNameChange(e, roomItem.id)}
                placeholder="Enter room name"
              />
            </Col>
            <Button variant="primary" size="sm" className="mr-3" onClick={() => this.openRoom(roomItem.id)}>Edit items</Button>
            {roomItem.items.length} items, {roomItem.items.length > 0 ? roomItem.items.map(item => item.images.length).reduce((accumulator, currentValue) => accumulator + currentValue) : '0'} images total
            {/** <Col sm={6}><strong>Notes</strong></Col>
            <Col sm={2}><strong>Condition & Cleanliness</strong></Col>
            <Col sm={1}></Col> **/}
          </Row>
          {/** roomItemItemList **/}
          {/** expandButton **/}
          {/** addItemButton **/}
          </div>
        )
    }
    return (
      <div 
        key={roomItem && roomItem.id} 
        className={`${(roomIndex!==0?'room':'room-0')} room-${roomItem.id}`}
      >
        {removeRoomButtons}
        {upDownButtons}
        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Room name
          </Form.Label>
          <Col sm={sm2}>
            <Form.Control 
              className="room-name-field"
              type="text" 
              name="name"
              value={roomItem.name && roomItem.name}
              onChange={(e) => this.handleRoomNameChange(e, roomItem.id)}
            />
          </Col>
        </Form.Group>
        <p className="text-right"><Button variant="primary" size="sm" className="mr-3 float-left" onClick={() => this.openRoom(roomItem.id)}>Edit items</Button> {roomItem.items.length} items, {roomItem.items.length > 0 ? roomItem.items.map(item => item.images.length).reduce((accumulator, currentValue) => accumulator + currentValue) : '0'} images total</p>
        {/** roomItemItemList **/}
        {/** expandButton **/}
        {/** addItemButton **/}
      </div>
    )
  }

  openRoom = (roomID) => {
    let openRoomPreviousScroll = window.scrollY
    if (!this.props.isMobile()){
      openRoomPreviousScroll = document.querySelector('.modal.show').scrollTop
    }
    this.setState({ 
      openRoom: roomID, 
      openRoomPreviousScroll,
    }, () => {
      if (this.props.isMobile()){
        window.scroll(0, 0)
      } else {
        document.querySelector('.modal.show').scrollTop = 0
      }
    })
  }

  closeRoom = () => {
    const {openRoomPreviousScroll} = this.state
    this.setState({ 
      openRoom: 0, 
    }, () => {
      if (this.props.isMobile()){
        window.scroll(0, openRoomPreviousScroll)
      } else {
        document.querySelector('.modal.show').scrollTop = openRoomPreviousScroll
      }
    })
  }

  addRoom = (roomName, defaultRoomItems) => {
    // console.log("addRoom", roomName)
    let { rooms, newRoomID, newItemID } = this.state
    const { settings } = this.props
    let beginSearching = false
    let closestRoom = rooms.length
    let defaultRooms = settings && settings.inventory_default_rooms && settings.inventory_default_rooms.length > 0 ? JSON.parse(settings.inventory_default_rooms) : []

    // console.log("defaultRooms", defaultRooms)
    
    if (roomName !== ''){
      for (const room of defaultRooms){
        if (room.name === roomName) {
          beginSearching = true
        } else if (beginSearching) {
          let result = rooms.find(roomItem => roomItem.name === room.name)
          if (result) {
            closestRoom = result.order
            break
          }
        }
      }
    }

    if (closestRoom <= rooms.length){
      rooms.forEach(roomItem => {
        if (roomItem.order >= closestRoom){
          roomItem.order = roomItem.order + 1
        }
      })
    }

    //
    let defaultItems = settings && settings.inventory_default_room_items && settings.inventory_default_room_items.length > 0 ? JSON.parse(settings.inventory_default_room_items) : []
    // let defaultRooms = settings && settings.inventory_default_rooms && settings.inventory_default_rooms.length > 0 ? JSON.parse(settings.inventory_default_rooms) : []

    let newItems = []
    let order = 0
    defaultItems.sort((a, b) => a.order - b.order).forEach((item, i) => {
      item.autoAdd && newItems.push({
        id: `new_item_${newItemID++}`, 
        name: item.name, 
        description: item.description ? item.description : '', 
        conditioncleanliness: "GC & CC", 
        images: [],
        order: order++,
      })
    })
    defaultRoomItems.forEach((item, i) => {
      if (item.autoAdd){
        const existingItem = newItems.find(ei => ei.name === item.name)
        if (existingItem){
          newItems = newItems.filter(ei => {
            if (ei.name === item.name){
              ei.description = item.description ? item.description : ''
            }
            return ei
          })
        } else {
          newItems.push({
            id: `new_item_${newItemID++}`, 
            name: item.name, 
            description: item.description ? item.description : '', 
            conditioncleanliness: "GC & CC", 
            images: [],
            order: order++,
          })
        }
      }
    })
    // rooms.push({id: `new_room_${newRoomID++}`, name: room.name, order: i, items: newItems})
    //

    // let items = []
    // if (defaultRoomItems){
    //   defaultRoomItems.forEach(defaultItem => {
    //     let newItem = this.getNewRoomItem(newRoomID, newItemID++)
    //     newItem.name = defaultItem.name
    //     newItem.description = defaultItem.description
    //     items.push(newItem)
    //   })
    // } else {
    //   items = [this.getNewRoomItem(newRoomID, newItemID++),]
    // }
    
    rooms.push({id: `new_room_${newRoomID}`, name: roomName, order: closestRoom, items: newItems})
    this.setState({ 
      rooms, 
      newRoomID: ++newRoomID,
      newItemID: newItemID,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  getNewRoomItem = (roomID, newItemID) => {
    // let { newItemID } = this.state
    let newRoomItem = {id: `new_item_${newItemID}`, name: "", description: "", conditioncleanliness: "GC & CC", images: []}
    // this.setState({ 
    //   newItemID: ++newItemID
    // })
    return newRoomItem
  }

  addRoomItem = (roomItem, itemName, item) => {
    let { rooms } = this.state
    rooms.forEach(sRoomItem => {
      sRoomItem.expanded = false
    })
    let { newItemID } = this.state
    let newItem = this.getNewRoomItem(roomItem.id, newItemID)
    newItem.name = itemName
    if (item) newItem.description = item.description
    newItem.order = roomItem.items.length
    roomItem.items.push(newItem)
    let wasExpanded = roomItem.expanded
    roomItem.expanded = true
    this.setState({ 
      rooms,
      newItemID: ++newItemID,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  handleCheck = (event) => {
    // console.log("submit_inventory", this.state.submit_inventory)
    let eventTargetName = event.target.name
    this.setState({ 
      [event.target.name]: !this.state[event.target.name],
      hasChanges: true,
    }, () => {
      this.saveChanges();
      if (eventTargetName === 'submit_inventory') this.initialiseSignaturePad();
    });
  }

  confirmClose = (e, callback) => {
    if (!callback) callback = this.props.handleClose
    this.setState({confirmCallback: callback})
    const { hasChanges } = this.state
    this.setState({ 
      hasChanges: false,
      hasPromptedLoadSave: false,
      promptLoadSave: false,
     }, () => callback())
    this.props.handleClose()
  }

  render(){
    const { promptLoadSave, historyInventory, rooms } = this.state
    if (this.props.show && !this.props.isMobile()){
      window.requestAnimationFrame(function() {
        window.addEventListener('mouseup', (e) => e.preventDefault());
        window.addEventListener('dragenter', (e) => {
          e.preventDefault()
        });
        window.addEventListener('dragover', (e) => e.preventDefault());
        window.addEventListener('drop', (e) => e.preventDefault());
        window.addEventListener('dragleave', (e) => {
          e.preventDefault()
        });
      })
    }

    if (this.props.show && this.props.isMobile()) return this.getInner()
    else return (
        <Modal size="xl" show={this.props.show} onHide={this.confirmClose} onShow={this.onShow}>
          {this.getInner()}
        </Modal>
    )
  }

  singleFieldImageThumbnails = (name, images) => {
    // console.log("singleFieldImageThumbnails", name, images)
    return images && images.length > 0 && 
    <>
      <div className="w-100 mt-2"/>
      {images.map((imageItem, imageIndex) => 
        imageItem && imageItem.url && <Col sm={4}>
        {typeof imageItem.url !== 'number' && (typeof imageItem.url === 'string' ? 
          this.roomItemItemImage(
            imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:'), 
            `${name} #${imageIndex}`, 
            null,
            () => this.setSingleFieldImageURL(name, imageIndex, null),
            imageItem,
            null)
        :
          this.roomItemItemImage(
            URL.createObjectURL(imageItem.url),
            `${name} #${imageIndex}`, 
            () => this.rotateNewSingleFieldImage(name, imageIndex),
            () => this.deleteSingleFieldImage(name, imageIndex),
            imageItem,
            (d) => this.setSingleFieldImageDate(name, imageIndex, d))
        )}
        </Col>
      )}
    </>
  }

  startSaveZip = () => {
    this.setState({
      isSavingZip: true,
    }, () => {
      this.saveZip()
    })
  }

  saveZip = () => {
    // let myZips = []
    let zip = new JSZip();
    // let imageNo = 0;

    const savedState = cloneDeep(this.state)


    function removeFileObjects(images) {
      if (images && images.length > 0){
        for (const imageItem of images) {
          if (imageItem.autosaveID){
            // if (imageNo > 75){
              // myZips.push(zip)
              // zip = new JSZip();
              // imageNo = 0
            // }
            zip.file(`${imageItem.autosaveID}.jpeg`, imageItem.url, {base64: true});
            delete imageItem.url
            // imageNo++
          }
        }
      }
    }

    // Remove File objects
    for (const roomItem of savedState.rooms) {
      for (const roomItemItem of roomItem.items) {
        removeFileObjects(roomItemItem.images)
      }
    }

    removeFileObjects(savedState.electricity_meter_images)
    removeFileObjects(savedState.gas_meter_images)
    removeFileObjects(savedState.water_meter_images)
    removeFileObjects(savedState.fuse_board_images)
    removeFileObjects(savedState.stop_tap_images)
    removeFileObjects(savedState.keys_codes_images)

    // console.log("savedState", savedState)
    let validatedData = {
      id: savedState.id,
      tenancy_start_date: savedState.tenancy_start_date,
      property_compiled_date: savedState.property_compiled_date,
      was_carried_over: savedState.was_carried_over,
      address: savedState.address,
      property_manager: savedState.property_manager,
      office: savedState.office,
      searchValue: savedState.searchValue,
      searchOptions: savedState.searchOptions,
      searchSelected: savedState.searchSelected,
      searchIsLoading: savedState.searchIsLoading,
      searchIsVisible: savedState.searchIsVisible,

      newRoomID: savedState.newRoomID,
      newItemID: savedState.newItemID,
      removedRooms: savedState.removedRooms,
      removedRoomItemItems: savedState.removedRoomItemItems,

      rooms: savedState.rooms,
      tenant_details: savedState.tenant_details,
      let_landlord_details: savedState.let_landlord_details,
      let_only: savedState.let_only,
      electricity_meter_location: savedState.electricity_meter_location,
      electricity_meter_readings: savedState.electricity_meter_readings,
      electricity_supplier: savedState.electricity_supplier,
      electricity_meter_images: savedState.electricity_meter_images,
      gas_meter_location: savedState.gas_meter_location,
      gas_meter_readings: savedState.gas_meter_readings,
      no_gas_oil_meter: savedState.no_gas_oil_meter,
      gas_supplier: savedState.gas_supplier,
      gas_meter_images: savedState.gas_meter_images,
      water_meter_location: savedState.water_meter_location,
      water_meter_readings: savedState.water_meter_readings,
      no_water_meter: savedState.no_water_meter,
      water_supplier: savedState.water_supplier,
      water_meter_images: savedState.water_meter_images,
      fuse_board_location: savedState.fuse_board_location,
      fuse_board_type: savedState.fuse_board_type,
      fuse_board_images: savedState.fuse_board_images,
      stop_tap_location: savedState.stop_tap_location,
      stop_tap_images: savedState.stop_tap_images,
      keys_codes: savedState.keys_codes,
      keys_codes_images: savedState.keys_codes_images,
      property_brief_description: savedState.property_brief_description,
      check_in_signature: savedState.check_in_signature,
      signatureMethod: savedState.signatureMethod,
      check_in_signature_image: savedState.check_in_signature_image,
    }

    zip.file("item.json", JSON.stringify(validatedData))
    zip.generateAsync({type:"blob"})
    .then(function (blob) {
      let filename = `inventory_backup.zip`
      if (savedState.searchSelected && savedState.searchSelected.length > 0 && savedState.searchSelected[0].address_full) filename = savedState.searchSelected[0].address_full.replace(/[^a-z0-9]/gi, '_').toLowerCase()+'.zip'
      document.querySelector('.saving-status').remove()
      var link = document.createElement('a');
      // saveAs(blob, filename);
      link.className = "btn btn-info";
      link.download = filename;
      link.innerHTML = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-archive" class="svg-inline--fa fa-file-archive fa-w-12 mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM128.4 336c-17.9 0-32.4 12.1-32.4 27 0 15 14.6 27 32.5 27s32.4-12.1 32.4-27-14.6-27-32.5-27zM224 136V0h-63.6v32h-32V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM95.9 32h32v32h-32zm32.3 384c-33.2 0-58-30.4-51.4-62.9L96.4 256v-32h32v-32h-32v-32h32v-32h-32V96h32V64h32v32h-32v32h32v32h-32v32h32v32h-32v32h22.1c5.7 0 10.7 4.1 11.8 9.7l17.3 87.7c6.4 32.4-18.4 62.6-51.4 62.6z"></path></svg> Confirm Download ZIP`;  
      link.target = '_blank';  
      link.href = "#";
      link.onclick = event => {
        event.preventDefault()
        saveAs(blob, filename);
      }
      document.querySelector('.save-zip-container').appendChild(link);
    });

    // return zip.generateAsync({
    //   type:"base64",
    // }).then(content => {
    //   let filename = `inventory_backup.zip`
    //   var link = document.createElement('a');
    //   if (savedState.searchSelected && savedState.searchSelected.length > 0 && savedState.searchSelected[0].address_full) filename = savedState.searchSelected[0].address_full.replace(/[^a-z0-9]/gi, '_').toLowerCase()+'.zip'
    //   link.download = filename;
    //   // link.innerHTML = filename;  
    //   link.target = '_blank';
    //   link.href = "data:application/zip;base64,"+content;
    //   // document.querySelector('.downloads-list').appendChild(link);
    //   link.click();
    // })

    // Promise.all(myZips.map((zipItem, zipIndex) => {
    //   // zipItem.file("item.json", JSON.stringify(validatedData))
    //   return zipItem.generateAsync({
    //     type:"base64",
    //   })
      
    // })).then(function (contentAll) {
    //   let finalZip = new JSZip();
    //   finalZip.file("item.json", JSON.stringify(validatedData))
    //   contentAll.forEach((content, contentIndex) => {
    //     let filename = `inventory_backup_${contentIndex+1}.zip`
    //     var link = document.createElement('a');
    //     if (savedState.searchSelected && savedState.searchSelected.length > 0 && savedState.searchSelected[0].address_full) filename = savedState.searchSelected[0].address_full.replace(/[^a-z0-9]/gi, '_').toLowerCase()+'_'+(contentIndex+1)+'.zip'
    //     link.download = filename;
    //     link.innerHTML = filename;  
    //     link.target = '_blank';  
    //     link.href = "data:application/zip;base64,"+content;
    //     document.querySelector('.downloads-list').appendChild(link);
    //     // link.click();
    //     // link.remove();
    //   })
    // });
     
  }

  getInner = () => {
  	// console.log("this.props", this.props)
    const { validated, validationErrors } = this.state;
  	const { searchOptions, searchIsLoading, searchIsVisible, searchSelected, addressIsInvalid } = this.state
    const {
      isSavingZip,
      property_manager,
      office,
      tenancy_start_date,
      property_compiled_date,
      tenant_details,
      let_landlord_details,
      let_only,
      electricity_meter_location,
      electricity_meter_readings,
      electricity_supplier,
      electricity_meter_images,
      gas_meter_location,
      gas_meter_readings,
      no_gas_oil_meter,
      gas_supplier,
      gas_meter_images,
      water_meter_location,
      water_meter_readings,
      no_water_meter,
      water_supplier,
      water_meter_images,
      fuse_board_location,
      fuse_board_type,
      fuse_board_images,
      stop_tap_location,
      stop_tap_images,
      keys_codes,
      keys_codes_images,
      property_brief_description,
      check_in_signature,
      signatureMethod,
      rooms,
      openRoom,
      submit_inventory,
      historyInventory,
      was_carried_over,
      hasPromptedLoadSave,
      previousInventoryLoading,
      previousInventorySuccess,
      previousInventory,
    } = this.state
    const { settings } = this.props
  	const sm1 = 4
  	const sm2 = 7
  	const classes1 = "d-flex justify-content-sm-end text-right"

    let item = null
    if (historyInventory) item = historyInventory
    else if (this.props.item) item = this.props.item

    const autosaveName = this.getAutosaveName()
    let autosaveNumber = ''
    if (autosaveName && autosaveName.split('_').length === 4) autosaveNumber = parseInt(autosaveName.split('_')[3])-1

    let carriedOverDetails = was_carried_over && historyInventory && historyInventory.history ? historyInventory.history.slice(-1)[0] : {}

    let officeOptions = []
    if (settings && settings.inventory_contact_details && settings.inventory_contact_details.length && settings.inventory_contact_details.startsWith('[')){
      officeOptions = JSON.parse(settings.inventory_contact_details).map(item => {
        return {value: item.name, label: item.name}
      })
    }
    
    const { userList, currentID } = this.props
    let foundUser = null
    if (userList && (currentID || currentID === 0)){
      foundUser = userList.find(userItem => userItem.id === currentID)
    }

    const isMobile = this.props.isMobile()

    if (openRoom !== 0){

      let previousRoomID = null
      let nextRoomID = null

      let roomIndex = 0
      let roomItem = rooms.find((ri, i) => {
        if (ri.id === openRoom){
          if (i-1 >= 0){ //has previous
            previousRoomID = rooms[i-1].id
          }
          if (i+1 < rooms.length){
            nextRoomID = rooms[i+1].id
          }
          roomIndex = i
          return ri
        }
      })

      const roomItemItemList = 
        roomItem.items && roomItem.items.length > 0 && 
        roomItem.items.sort((a, b) => a.order - b.order).map((roomItemItem, index) => 
          // (roomItem.expanded || index < 2) && this.makeItemField(roomItemItem, index, roomIndex, roomItem)
          this.makeItemField(roomItemItem, index, roomIndex, roomItem)
        )


      const nextPreviousRoomButtons = 
        <div className="row mt-2">
          {previousRoomID && 
            <div className={previousRoomID && nextRoomID ? 'col-6 pr-1' : 'col-12'}>
            <Button className="w-100" variant="primary" onClick={() => this.setState({openRoom: previousRoomID}, () => {
              if (this.props.isMobile()){
                window.scroll(0, 0)
              } else {
                document.querySelector('.modal.show').scrollTop = 0
              }
            })}>
              Previous room
            </Button>
          </div>}
          {nextRoomID && 
            <div className={previousRoomID && nextRoomID ? 'col-6 pl-1' : 'col-12'}>
            <Button className="w-100" variant="primary" onClick={() => this.setState({openRoom: nextRoomID}, () => {
              if (this.props.isMobile()){
                window.scroll(0, 0)
              } else {
                document.querySelector('.modal.show').scrollTop = 0
              }
            })}>
              Next room
            </Button>
          </div>}
        </div>

      const addItemButton = 
        <div className="text-right">
          <SplitButton title="Add item" variant="primary" className="mt-2" alignRight onClick={() => this.addRoomItem(roomItem, '')}>
            {this.props.settings && this.props.settings.inventory_default_room_items && this.props.settings.inventory_default_room_items.length > 0 && 
              JSON.parse(this.props.settings.inventory_default_room_items).map(item => {
                if (roomItem.items && roomItem.items.find(roomItem => roomItem.name === item.name)){
                  return <Dropdown.Item onClick={() => this.addRoomItem(roomItem, `${item.name}`, item)} active>{item.name}</Dropdown.Item>
                } else {
                  return <Dropdown.Item onClick={() => this.addRoomItem(roomItem, `${item.name}`, item)}>{item.name}</Dropdown.Item>
                }
              })
            }
          </SplitButton>
        </div>

      return (
        <>
          <Modal.Header>
            <Modal.Title className="w-100">Editing Room #{roomIndex+1} <Button variant="danger" size="sm" className="ml-3 float-right" onClick={() => this.removeRoom(roomItem)}>Remove room</Button></Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <Button variant="secondary" onClick={() => {
              this.closeRoom()
            }} className="w-100">
              Return to inventory
            </Button>
            {nextPreviousRoomButtons}
            <hr/>
            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Room name
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  className="room-name-field"
                  type="text" 
                  name="name"
                  value={roomItem.name && roomItem.name}
                  onChange={(e) => this.handleRoomNameChange(e, roomItem.id)}
                />
              </Col>
            </Form.Group>
            <Row className="d-none d-sm-flex">
              <Col sm={3}><strong>Item Name</strong></Col>
              <Col sm={6}><strong>Notes</strong></Col>
              <Col sm={2}><strong>Condition & Cleanliness</strong></Col>
              <Col sm={1}></Col>
            </Row>
            {roomItemItemList}
            {addItemButton}
            <hr/>
            {nextPreviousRoomButtons}
            <Button variant="secondary" onClick={() => {
              this.closeRoom()
            }} className="mt-2 w-100">
              Return to inventory
            </Button>
          </Modal.Body>
        </>
      )
    }

    if (isSavingZip){
      return (
        <>
          <Modal.Header>
            <Modal.Title>{!item ? 'Create New Inventory' : `Editing Inventory #${item.id}`}</Modal.Title>
            <CloseModalButton onClick={this.confirmClose}/>
          </Modal.Header>
          <Modal.Body className="pt-0 save-zip-container">
            <p className="saving-status">Preparing ZIP, please wait...</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              this.setState({
                isSavingZip: false,
              })
            }} className="float-left ml-0">
              Return to inventory
            </Button>
          </Modal.Footer>
        </>
      )
    }

    return (
        <>
          <Modal.Header>
            <Modal.Title>{!item ? 'Create New Inventory' : `Editing Inventory #${item.id}`}</Modal.Title>
            <CloseModalButton onClick={this.confirmClose}/>
          </Modal.Header>
          <Form
            noValidate
            validated={validated}
            onSubmit={e => this.handleSubmit(e)}
            autoComplete='off'
          >
          <Modal.Body className="pt-0">

            <div className="modal-subheading mt-0">
              <h5 className="offset-sm-4">Report Details</h5>
            </div>
          	<Form.Group as={Row} className="">
	            <Form.Label column sm={sm1} className={classes1}>
	              Property Address *
	            </Form.Label>
	            <Col sm={sm2}>
                <AsyncTypeahead
                  id={'check-address-search-typeahead'}
                  inputProps={{id: 'check-address-search', autoComplete: 'off', required: true}}
                  options={searchOptions}
                  selected={searchSelected}
                  isInvalid={addressIsInvalid}
                  isLoading={searchIsLoading}
                  maxVisible={10}
                  placeholder="Search"
                  onSearch={this.onInputChange}
                  labelKey={(option) => `${option.address_full}`}
                  filterBy={(option, props) => {
                    return option.address_full
                  }}
                  minLength={0}
                  useCache={false}
                  onKeyDown={this.onKeyDown}
                  selectHintOnEnter={true}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  open={searchIsVisible}
                  onChange={this.onChange}
                  clearButton={true}
              />
	            </Col>
          	</Form.Group>

            {(previousInventorySuccess || previousInventoryLoading || previousInventory) &&
              <Form.Group as={Row} className="">
                <Form.Label column sm={sm1} className={classes1}>
                </Form.Label>
                <Col sm={sm2}>
                  <Button 
                    disabled={previousInventorySuccess || !previousInventory || previousInventoryLoading}
                    onClick={() => this.duplicatePreviousInventory(false)}
                  >
                    {(previousInventorySuccess && previousInventory) ? 'Successfully duplicated' :
                      previousInventoryLoading ? 'Loading' : 
                     !previousInventory ? 'Previous inventory not found' :
                      'Duplicate previous inventory'
                    }
                  </Button>
                  {
                    !previousInventorySuccess && previousInventory && !previousInventoryLoading &&
                    <Button 
                      className="ml-2"
                      disabled={previousInventorySuccess || !previousInventory || previousInventoryLoading}
                      onClick={() => this.duplicatePreviousInventory(true)}
                    >
                      {(previousInventorySuccess && previousInventory) ? 'Successfully duplicated' :
                        previousInventoryLoading ? 'Loading' : 
                       !previousInventory ? 'Previous inventory not found' :
                        'Duplicate with images'
                      }
                    </Button>
                  }
                </Col>
              </Form.Group>
            }

            <Form.Group as={Row} className="">
              <Form.Label column sm={sm1} className={classes1}>
                Address Property Manager
              </Form.Label>
              <Col sm={sm2}>
            <Form.Control 
              className="novalidate"
              as="select"
                  name="property_manager"
                  value={property_manager}
                  onChange={this.handleOnChange}
                  required={true}
            >
              <option value="">None</option>
                  {this.props.userList && this.props.userList.map(userItem => <option key={userItem.id} value={userItem.id}>{`${userItem.first_name} ${userItem.last_name}`}</option>)}
            </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="">
              <Form.Label column sm={sm1} className={classes1}>
                Office
              </Form.Label>
              <Col sm={sm2}>
            <Form.Control 
              className="novalidate"
              as="select"
                  name="office"
                  value={office}
                  onChange={this.handleOnChange}
            >
                  {officeOptions && officeOptions.map(officeOption => <option key={officeOption.value} value={officeOption.value}>{officeOption.value}</option>)}
            </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Tenant details
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="textarea" 
                  as="textarea"
                  name="tenant_details"
                  value={tenant_details}
                  rows={2}
                  onChange={this.handleOnChange} 
                />
              </Col>
            </Form.Group>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Tenancy start date
	            </Form.Label>
	            <Col sm={sm2}>
            		<div className="d-inline-block" style={{ width: '100%' }}>
  		            <DatePicker
  		              className="form-control "
  		              dateFormat="dd-MM-yyyy"
  		              isClearable={true}
  		              selected={tenancy_start_date}
  		              onChange={(d) => this.handleDateChange(d, 'tenancy_start_date')}
                    onFocus={(e) => e.target.readOnly = true}
                    onBlur={(e) => e.target.readOnly = false}
  		            />
		            </div>
	            </Col>
          	</Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Report compiled date
              </Form.Label>
              <Col sm={sm2}>
                <div className="d-inline-block" style={{ width: '100%' }}>
                  <DatePicker
                    className="form-control "
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    selected={property_compiled_date}
                    onChange={(d) => this.handleDateChange(d, 'property_compiled_date')}
                    onFocus={(e) => e.target.readOnly = true}
                    onBlur={(e) => e.target.readOnly = false}
                  />
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="">
              <Form.Label column sm={sm1} className={classes1}></Form.Label>
              <Col sm={sm2}>
                <FormCheck>
                  <FormCheck.Input 
                    onChange={this.handleCheck} 
                    className="novalidate"
                    type="checkbox"
                    id="let_only"
                    name="let_only"
                    checked={let_only}
                  />
                  <FormCheck.Label className="novalidate">Let only?</FormCheck.Label>
                </FormCheck>
              </Col>
            </Form.Group>

            {let_only === true && 
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Landlord details
                </Form.Label>
                <Col sm={sm2}>
                  <Form.Control 
                    type="textarea" 
                    as="textarea"
                    name="let_landlord_details"
                    value={let_landlord_details}
                    rows={2}
                    onChange={this.handleOnChange} 
                  />
                </Col>
              </Form.Group>
            }

            <div className="modal-subheading mt-0">
              <h5 className="offset-sm-4">Utilities</h5>
            </div>

            <Row>
            <Col className="mb-1" sm={{offset: sm1, span: sm2}}><strong>Electricity Meter Location, Readings, & Supplier</strong></Col>
            </Row>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Electricity meter location *
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="electricity_meter_location"
                  value={electricity_meter_location}
                  onChange={this.handleOnChange} 
                  required={submit_inventory ? true : false}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Electricity meter move-in readings *
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="textarea" 
                  as="textarea"
                  name="electricity_meter_readings"
                  value={electricity_meter_readings}
                  rows={2}
                  onChange={this.handleOnChange} 
                  required={submit_inventory ? true : false}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Electricity supplier *
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="electricity_supplier"
                  value={electricity_supplier}
                  onChange={this.handleOnChange} 
                  required={submit_inventory ? true : false}
                />
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Form.Label column sm={sm1} className={classes1}>
                Electricity meter images
              </Form.Label>
              <Col sm={{span: 2}}>
                <label htmlFor={'electricity_meter_images'} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=electricity_meter_images]`).click()}>
                    <FontAwesomeIcon icon={faCamera} />
                </label>
                <input name={'electricity_meter_images'} type="file" className="mb-3 d-none" onChange={(e) => this.handleSingleFieldImageAdd(e)} multiple/>
              </Col>
              {this.singleFieldImageThumbnails('electricity_meter_images', electricity_meter_images)}
            </Row>

            <Row>
            <Col className="mb-1" sm={{offset: sm1, span: sm2}}><strong>Gas/Oil Meter Location, Readings, & Supplier</strong></Col>
            </Row>

            <Form.Group as={Row} className="">
              <Form.Label column sm={sm1} className={classes1}></Form.Label>
              <Col sm={sm2}>
                <FormCheck>
                  <FormCheck.Input 
                    onChange={this.handleCheck} 
                    className="novalidate"
                    type="checkbox"
                    id="no_gas_oil_meter"
                    name="no_gas_oil_meter"
                    checked={no_gas_oil_meter}
                  />
                  <FormCheck.Label className="novalidate">No gas/oil?</FormCheck.Label>
                </FormCheck>
              </Col>
            </Form.Group>

            {no_gas_oil_meter === false && 
              <>
                <Form.Group as={Row} className="validate-me">
                  <Form.Label column sm={sm1} className={classes1}>
                    Gas/oil meter location *
                  </Form.Label>
                  <Col sm={sm2}>
                    <Form.Control 
                      type="text" 
                      name="gas_meter_location"
                      value={gas_meter_location}
                      onChange={this.handleOnChange} 
                      required={submit_inventory ? true : false}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="validate-me">
                  <Form.Label column sm={sm1} className={classes1}>
                    Gas/oil meter move-in readings *
                  </Form.Label>
                  <Col sm={sm2}>
                    <Form.Control 
                      type="textarea" 
                      as="textarea"
                      name="gas_meter_readings"
                      value={gas_meter_readings}
                      rows={2}
                      onChange={this.handleOnChange} 
                      required={submit_inventory ? true : false}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="validate-me">
                  <Form.Label column sm={sm1} className={classes1}>
                    Gas/oil supplier *
                  </Form.Label>
                  <Col sm={sm2}>
                    <Form.Control 
                      type="text" 
                      name="gas_supplier"
                      value={gas_supplier}
                      onChange={this.handleOnChange} 
                      required={submit_inventory ? true : false}
                    />
                  </Col>
                </Form.Group>

                <Row className="mb-3">
                  <Form.Label column sm={sm1} className={classes1}>
                    Gas/oil meter images
                  </Form.Label>
                  <Col sm={{span: 2}}>
                    <label htmlFor={'gas_meter_images'} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=gas_meter_images]`).click()}>
                        <FontAwesomeIcon icon={faCamera} />
                    </label>
                    <input name={'gas_meter_images'} type="file" className="mb-3 d-none" onChange={(e) => this.handleSingleFieldImageAdd(e)} multiple/>
                  </Col>
                  {this.singleFieldImageThumbnails('gas_meter_images', gas_meter_images)}
                </Row>
              </>
            }

            <Row>
            <Col className="mb-1" sm={{offset: sm1, span: sm2}}><strong>Water Meter Location, Readings, & Supplier</strong></Col>
            </Row>

            <Form.Group as={Row} className="">
              <Form.Label column sm={sm1} className={classes1}></Form.Label>
              <Col sm={sm2}>
                <FormCheck>
                  <FormCheck.Input 
                    onChange={this.handleCheck} 
                    className="novalidate"
                    type="checkbox"
                    id="no_water_meter"
                    name="no_water_meter"
                    checked={no_water_meter}
                  />
                  <FormCheck.Label className="novalidate">No water meter?</FormCheck.Label>
                </FormCheck>
              </Col>
            </Form.Group>

            {no_water_meter === false && 
              <>
                <Form.Group as={Row} className="validate-me">
                  <Form.Label column sm={sm1} className={classes1}>
                    Water meter location *
                  </Form.Label>
                  <Col sm={sm2}>
                    <Form.Control 
                      type="text" 
                      name="water_meter_location"
                      value={water_meter_location}
                      onChange={this.handleOnChange} 
                      required={submit_inventory ? true : false}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="validate-me">
                  <Form.Label column sm={sm1} className={classes1}>
                    Water meter move-in readings *
                  </Form.Label>
                  <Col sm={sm2}>
                    <Form.Control 
                      type="textarea" 
                      as="textarea"
                      name="water_meter_readings"
                      value={water_meter_readings}
                      rows={2}
                      onChange={this.handleOnChange} 
                      required={submit_inventory ? true : false}
                    />
                  </Col>
                </Form.Group>

                <Row className="mb-3">
                  <Form.Label column sm={sm1} className={classes1}>
                    Water meter images
                  </Form.Label>
                  <Col sm={{span: 2}}>
                    <label htmlFor={'water_meter_images'} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=water_meter_images]`).click()}>
                        <FontAwesomeIcon icon={faCamera} />
                    </label>
                    <input name={'water_meter_images'} type="file" className="mb-3 d-none" onChange={(e) => this.handleSingleFieldImageAdd(e)} multiple/>
                  </Col>
                  {this.singleFieldImageThumbnails('water_meter_images', water_meter_images)}
                </Row>
              </>
            }

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Water supplier *
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="water_supplier"
                  value={water_supplier}
                  onChange={this.handleOnChange} 
                  required={submit_inventory ? true : false}
                />
              </Col>
            </Form.Group>

            <Row>
            <Col className="mb-1" sm={{offset: sm1, span: sm2}}><strong>Fuse Board Type & Location</strong></Col>
            </Row>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Fuse board type
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="fuse_board_type"
                  value={fuse_board_type}
                  onChange={this.handleOnChange} 
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Fuse board location
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="fuse_board_location"
                  value={fuse_board_location}
                  onChange={this.handleOnChange} 
                />
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Form.Label column sm={sm1} className={classes1}>
                Fuse board images
              </Form.Label>
              <Col sm={{span: 2}}>
                <label htmlFor={'fuse_board_images'} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=fuse_board_images]`).click()}>
                    <FontAwesomeIcon icon={faCamera} />
                </label>
                <input name={'fuse_board_images'} type="file" className="mb-3 d-none" onChange={(e) => this.handleSingleFieldImageAdd(e)} multiple/>
              </Col>
              {this.singleFieldImageThumbnails('fuse_board_images', fuse_board_images)}
            </Row>

            <Row>
            <Col className="mb-1" sm={{offset: sm1, span: sm2}}><strong>Stop Tap Location</strong></Col>
            </Row>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Stop tap location
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="stop_tap_location"
                  value={stop_tap_location}
                  onChange={this.handleOnChange} 
                />
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Form.Label column sm={sm1} className={classes1}>
                Stop tap images
              </Form.Label>
              <Col sm={{span: 2}}>
                <label htmlFor={'stop_tap_images'} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=stop_tap_images]`).click()}>
                    <FontAwesomeIcon icon={faCamera} />
                </label>
                <input name={'stop_tap_images'} type="file" className="mb-3 d-none" onChange={(e) => this.handleSingleFieldImageAdd(e)} multiple/>
              </Col>
              {this.singleFieldImageThumbnails('stop_tap_images', stop_tap_images)}
            </Row>

            <div className="modal-subheading">
              <h5 className="d-inline-block offset-sm-4">Keys & Codes</h5>
            </div>

            <Form.Group as={Row} className="validate-me">
              <Col sm={{span: sm2, offset: sm1}}>
                <Form.Control 
                  type="textarea" 
                  as="textarea"
                  name="keys_codes"
                  value={keys_codes}
                  rows={2}
                  onChange={this.handleOnChange} 
                />
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Form.Label column sm={sm1} className={classes1}>
                Images
              </Form.Label>
              <Col sm={{span: 2}}>
                <label htmlFor={'keys_codes_images'} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=keys_codes_images]`).click()}>
                    <FontAwesomeIcon icon={faCamera} />
                </label>
                <input name={'keys_codes_images'} type="file" className="mb-3 d-none" onChange={(e) => this.handleSingleFieldImageAdd(e)} multiple/>
              </Col>
              {this.singleFieldImageThumbnails('keys_codes_images', keys_codes_images)}
            </Row>

            <div className="modal-subheading">
              <h5 className="d-inline-block offset-sm-4">Property & Report</h5>
            </div>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Brief description of property
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="textarea" 
                  as="textarea"
                  name="property_brief_description"
                  value={property_brief_description}
                  rows={2}
                  onChange={this.handleOnChange} 
                />
              </Col>
            </Form.Group>

            {rooms && rooms.length > 0 && rooms.sort((a, b) => a.order - b.order).map((roomItem, index) => 
               this.makeRoomField(roomItem, index, isMobile)
            )}

            <div className="text-right">
              <SplitButton title="Add room" variant="secondary" className="mt-2" alignRight onClick={() => this.addRoom('', [])}>
                {this.props.settings && this.props.settings.inventory_default_rooms && this.props.settings.inventory_default_rooms.length > 0 && 
                  JSON.parse(this.props.settings.inventory_default_rooms).map(item => {
                    if (rooms && rooms.find(roomItem => roomItem.name === item.name)){
                      return <Dropdown.Item onClick={() => this.addRoom(`${item.name}`, item.items)} active>{item.name}</Dropdown.Item>
                    } else {
                      return <Dropdown.Item onClick={() => this.addRoom(`${item.name}`, item.items)}>{item.name}</Dropdown.Item>
                    }
                  })
                }
              </SplitButton>
            </div>
            

            <Form.Group as={Row} className="">
              <Form.Label column sm={sm1} className={classes1}></Form.Label>
              <Col sm={sm2}>
                <FormCheck>
                  <FormCheck.Input 
                    onChange={this.handleCheck} 
                    className="novalidate"
                    type="checkbox"
                    id="submit_inventory"
                    name="submit_inventory"
                    defaultChecked={false}
                  />
                  <FormCheck.Label className="novalidate">Submit check in?</FormCheck.Label>
                </FormCheck>
              </Col>
            </Form.Group>

            {submit_inventory && 
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Signature *
                </Form.Label>
                <Col sm={4}>
                  <Tabs variant="pills" className="mb-2" activeKey={signatureMethod} onSelect={k => this.setState({ signatureMethod: k})}>
                    <Tab eventKey="draw" title="Draw">
                      <canvas 
                        id="signature-pad" 
                        className={
                          (validated && signaturePad &&
                            !signaturePad.isEmpty() ? "is-valid" : "is-invalid")+" novalidate signature-pad"
                        }
                      />
                      <Button variant="secondary" onClick={() => signaturePad && signaturePad.clear()}>Clear</Button>
                    </Tab>
                    <Tab eventKey="type" title="Type name">
                      <Form.Control 
                        type="text" 
                        name="check_in_signature"
                        value={check_in_signature}
                        onChange={this.handleOnChange}
                        className={
                          (validated &&  
                            (check_in_signature.length > 0 ? "is-valid" : "is-invalid"))+" novalidate"
                        }
                      />
                    </Tab>
                  </Tabs>
                </Col>
              </Form.Group>
            }

          </Modal.Body>
              <div className="w-100 p-2">
              <Button className="d-block d-sm-none w-100" variant="info" onClick={this.startSaveZip}>
                <FontAwesomeIcon className="mr-2" icon={faFileArchive} />Save ZIP
              </Button>
              </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose} className="float-left ml-0">
              Close
            </Button>
            {!this.props.forceAutosave && <Button variant="sab" onClick={() => this.props.handleShowInventoryModal('handleShowViewInventoryModal', item, true)} className={`float-left mr-auto ml-0 ${!item&&"invisible"}`}>
              View
            </Button>}
              <Button className="d-none d-sm-inline-block" variant="info" onClick={this.startSaveZip}>
                <FontAwesomeIcon className="mr-2" icon={faFileArchive} />Download ZIP
              </Button>
     				{item && 
              <input type="hidden" name="id" value={item.id} />
            }
            {validated && <p className="mr-3 text-danger">{validationErrors}</p>}
            <div className="mx-2">Changes saved to autosave #{autosaveNumber}</div>
            <Button disabled={!this.props.isOnline} type="submit">Save</Button>
          </Modal.Footer>
        </Form>
        </>
    )

  }
}

const mapStateToProps = state => ({
  userList: state.user.userList,
  settings: state.settings.settings,
  offlineFileCount: state.offline.offlineFileCount,
  isOnline: state.offline.isOnline,
  currentID: state.auth.currentID,
});

export default connect(mapStateToProps, { editInventory, saveInventory, incrementOfflineFileCount, deleteInventoryAutosave })(EditInventoryModal);
