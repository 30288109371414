import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap'
import CloseModalButton from '../components/CloseModalButton'

class ConfirmModal extends Component {

  render(){
    if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return (
          <Modal size="lg" show={this.props.show} onHide={this.props.handleClose}>
          {this.getInner()}
          </Modal>
      )
    }
  }

  getInner = () => {
  	const {title, body} = this.props
    return (
        <>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
            <CloseModalButton onClick={this.props.handleClose}/>
          </Modal.Header>
		  <Modal.Body>
		  	{body}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose} className="float-left mr-auto">
              {this.props.cancelText ? this.props.cancelText : "Close"}
            </Button>
            <Button onClick={this.props.handleConfirm}>Confirm</Button>
          </Modal.Footer>
        </>
    )

  }
}

export default ConfirmModal;
