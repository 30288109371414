import React, { Component } from 'react';
import { Container, Modal, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Form, Alert, Spinner, ListGroup } from 'react-bootstrap'
import CloseModalButton from '../components/CloseModalButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import localForage from "localforage";
import ConfirmModal from '../modals/ConfirmModal'
import { deleteCheckAutosave } from '../redux/actions/checkActions'
import { connect } from 'react-redux'
import {withGetScreen} from 'react-getscreen'
import { addToAutosaveLog } from '../redux/actions/userActions'
import { deleteInventoryAutosave, deleteCheckoutAutosave } from '../redux/actions/inventoryActions'

class SelectAutosaveModal extends Component {

  constructor(props){
    super(props)
    this.state = {
      autosaves: null,
      hasOldSave: false,
      showConfirmModal: false,
      deleteAutosaveName: null,
      hiddenAutosaves: [],
    };
  }

  confirmDeleteAutosave = () => {
    const {deleteAutosaveName} = this.state
    let {hiddenAutosaves} = this.state
    if (deleteAutosaveName.startsWith('save_check_')) this.props.deleteCheckAutosave(deleteAutosaveName, null)
    else if (deleteAutosaveName.startsWith('save_inventory_')) this.props.deleteInventoryAutosave(deleteAutosaveName, null)
    else if (deleteAutosaveName.startsWith('save_checkout_')) this.props.deleteCheckoutAutosave(deleteAutosaveName, null)
    hiddenAutosaves.push(deleteAutosaveName)
    this.props.addToAutosaveLog(this.props.currentID, `deleted autosave: ${deleteAutosaveName}`)
    this.setState({
      showConfirmModal: false, 
      deleteAutosaveName: null,
      hiddenAutosaves,
    })
  }

  handleConfirmModalClose = () => {
    this.setState({showConfirmModal: false, deleteAutosaveName: null})
  }

  render(){
    if (this.state.showConfirmModal && this.props.show){
      return <ConfirmModal 
          show={true} 
          handleConfirm={this.confirmDeleteAutosave} 
          title={"Delete autosave"} 
          body={"Are you sure you want to delete this autosave?"} 
          handleClose={this.handleConfirmModalClose} 
          isMobile={this.props.isMobile} 
        />
    } else if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return (
          <Modal size="lg" show={this.props.show} onShow={this.onShow} onHide={this.handleClose}>
          {this.getInner()}
          </Modal>
      )
    }
  }

  getAutosaveList = () => {
    const {item, savePrefix} = this.props
    let thisComp = this
    let itemID = 0
    if (item && item.id) itemID = item.id
    localForage.keys().then(function(keys) {
      // An array of all the key names.
      const autosaveNames = 
        keys.filter(key => key === `${savePrefix}${itemID}` || key.startsWith(`${savePrefix}${itemID}_`))
      const hasOldSave = autosaveNames.find(key => key === `${savePrefix}${itemID}`)

      Promise.all(autosaveNames.map(autosaveName => localForage.getItem(autosaveName)))
      .then((result) => {
        let autosaves = {}
        result.forEach((autosaveData, i) => {
          autosaves[autosaveNames[i]] = JSON.parse(autosaveData)
        })
        // let saveItem = JSON.parse(result)
        thisComp.setState({
          autosaves,
          hasOldSave,
        })
      })
    })
  }

  onShow = () => {
    console.log("SelectAutosaveModal, onShow", this.state, this.props)
    this.getAutosaveList()
  }

  deleteAutosave = (e, autosaveName) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({showConfirmModal: true, deleteAutosaveName: autosaveName})
  }

  componentWillMount() {
    console.log("SelectAutosaveModal componentWillMount")
    if (this.props.isMobile()) this.onShow()
  }

  componentWillUnmount() { 
    console.log("SelectAutosaveModal componentWillMount")
  }

  handleClose = () => {
    this.setState({
      autosaves: null,
      hasOldSave: false,
      showConfirmModal: false,
      deleteAutosaveName: null,
      hiddenAutosaves: [],
    }, () => this.props.handleClose())
  }

  loadAutosave = (autosaveName, item) => {
    this.setState({
      autosaves: null,
      hasOldSave: false,
      showConfirmModal: false,
      deleteAutosaveName: null,
      hiddenAutosaves: [],
    })
    this.props.loadAutosave(autosaveName, item)
  }

  createNew = () => {
    const {item, handleShowEditModalTag, handleShowCreateModalTag} = this.props
    this.setState({
      autosaves: null,
      hasOldSave: false,
      showConfirmModal: false,
      deleteAutosaveName: null,
      hiddenAutosaves: [],
    })
    this.props.handleShowModal(item ? handleShowEditModalTag : handleShowCreateModalTag, item, true)
  }

  getInner = () => {
  	console.log("SelectAutosaveModal this.props", this.props)
  	// const {title, label, errorMessage} = this.props
    const {autosaves, hasOldSave, hiddenAutosaves} = this.state
    const {item, handleShowEditModalTag, handleShowCreateModalTag} = this.props
    const classes1 = "d-flex justify-content-sm-end text-right"
    const sm1 = 4
    const sm2 = 7
    return (
        <>
          <Modal.Header>
            <Modal.Title>Select autosave</Modal.Title>
            <CloseModalButton onClick={this.handleClose}/>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              {autosaves && Object.keys(autosaves).sort(function (a, b) {
                let aa = a.split('_').pop()
                try {
                  aa = parseInt(aa)
                } catch (e) {
                  aa = 0
                }
                let bb = b.split('_').pop()
                try {
                  bb = parseInt(bb)
                } catch (e) {
                  bb = 0
                }
                return aa - bb;
              }).map((autosaveName, i) => {
                if (hiddenAutosaves.find(item => item === autosaveName)) return null
                const autosaveName_split = autosaveName.split('_')
                let autosaveName_id = null
                if (hasOldSave){
                  autosaveName_id = autosaveName_split.length > 3 ? autosaveName_split[3] : '1'
                } else {
                  autosaveName_id = parseInt(autosaveName_split[3]) - 1
                }
                let title = `Autosave #${autosaveName_id}`
                return (
                  <ListGroup.Item variant={autosaves[autosaveName].autosaveSubmitted ? 'success' : null} key={autosaveName} action onClick={() => this.loadAutosave(autosaveName, item)}>
                    <div className="d-block align-top text-center">
                    {autosaves[autosaveName].autosaveSubmitted ? `${title} (submitted ${autosaves[autosaveName].autosaveSubmitted})` : title}
                    </div>
                    <div className="delete-autosave-button" onClick={(e) => this.deleteAutosave(e, autosaveName)}><FontAwesomeIcon icon={faTrash} className="" /></div>
                  </ListGroup.Item>
                )
              })}
              <ListGroup.Item variant="light" action onClick={() => this.createNew()}>
                <div className="d-block align-top text-center">Create new <FontAwesomeIcon icon={faPlus} className="ml-1" /></div>
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose} className="float-left mr-auto">
              {this.props.cancelText ? this.props.cancelText : "Close"}
            </Button>
          </Modal.Footer>
        </>
    )

  }
}

const mapStateToProps = state => ({
  currentID: state.auth.currentID,
});

export default connect(mapStateToProps, { deleteCheckAutosave, deleteInventoryAutosave, deleteCheckoutAutosave, addToAutosaveLog })(withGetScreen(SelectAutosaveModal));