import { FETCH_CHECKS, CREATE_CHECK, REMOVE_MESSAGE, EDIT_CHECK, DELETE_CHECK, DELETE_CHECK_AUTOSAVE, MASS_DELETE_CHECK, PUSH_OFFLINE_QUEUE, ADD_QUEUE_MESSAGE, ADD_CHECK_SERVER_ERROR_MESSAGE, PUSH_CHECK_TO_OFFLINE_QUEUE, ADD_CHECK_MESSAGE } from './types'
import { stringify } from 'query-string';
import { api_url } from '../../api_url'
import localForage from "localforage";
import { deleteCheckAutosaveFiles } from '../helpers'

function getMessageID(){
	let id = parseInt(window.localStorage.getItem('message-id') || "0")
	window.localStorage.setItem('message-id', id+1)
	return id
}

function getAuthKey(){
	return window.localStorage.getItem('authKey');
}

export const fetchChecks = (params, clearResults, forCache) => dispatch => {
	console.log("checkActions fetchChecks")
	console.log("params", params)
		const endpoint = `${api_url}rest-auth/checks/?${stringify(params)}` 
		let lookupOptions = {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Token '+getAuthKey(),
			}
		}
		fetch(endpoint, lookupOptions)
		.then(function(response){
			return response.json()
		}).then(function(responseData){
			if (responseData && !forCache){
				dispatch({
					type: FETCH_CHECKS,
					payload: responseData,
					params,
					clearResults,
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
}

export const addQueueMessage = (data) => dispatch => {
	if (!data.id) data.id = getMessageID()
	dispatch({
		type: ADD_QUEUE_MESSAGE,
		payload: data,
	})
}

export const createCheck = (form, isDuplicate) => dispatch => {
	console.log("checkActions createCheck", form)
	const endpoint = isDuplicate ? `${api_url}rest-auth/addresses/${form.address}/duplicate_last_check/` : `${api_url}rest-auth/checks/`
	console.log("endpoint", endpoint)
	let lookupOptions = {
		method: "POST",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Token '+getAuthKey(),
		},
		body: JSON.stringify(form),
	}
	function getOfflineQueueItem(endpoint, lookupOptions){
		return {type: 'createCheck', title: `Create Check`, endpoint: endpoint, lookupOptions: lookupOptions}
	}
	function offlineActions(){
		// dispatch({
		// 	type: PUSH_OFFLINE_QUEUE,
		// 	payload: getOfflineQueueItem(endpoint, lookupOptions),
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: 'createCheck-cache',
		// 		item: form
		// 	},
		// })
	}

	if (!navigator.onLine){
		offlineActions()
	} else {
		fetch(endpoint, lookupOptions)
		.then(function(response){
			if (response.ok){
				return response.json()
			} else {
				dispatch({
					type: ADD_CHECK_SERVER_ERROR_MESSAGE,
					payload: { type: 'server-error' }
				})
				offlineActions()
			}
		}).then(function(responseData){
			if (responseData){
				dispatch({
					type: CREATE_CHECK,
					payload: {
						type: 'createCheck',
						item: responseData
					},
				})
			}
		}).catch(function(error){
			console.log("error", error)
			dispatch({
				type: ADD_CHECK_SERVER_ERROR_MESSAGE,
				payload: { type: 'server-error' }
			})
			offlineActions()
		})
	}
}

function collectImages(images, prefix, formData){
	console.log("collectImages", images, prefix, formData)
	if (images && images.length > 0){
		for (let x = 0; x < images.length; x++){
			if (images[x] && (!images[x].url || typeof images[x].url === 'object')){
				console.log("adding")
				let id = images[x].id ? `id${images[x].id}` : `${x}`
				formData.append(`${prefix}_image_${id}`, images[x].url ); // Add/replace with new image
			}
		}
	} else if (!images){
		formData.append(`${prefix}_image`, null ); //Delete image
	}
	return formData
}

export const editCheck = (validatedData, autosaveName) => dispatch => {
	console.log("checkActions editCheck", validatedData)
	const endpoint = validatedData.id ? `${api_url}rest-auth/checks/${validatedData.id}/` : `${api_url}rest-auth/checks/`
	const method =validatedData.id ? "PATCH" : "POST"
	let formData = new FormData();
	Object.keys(validatedData).forEach(key => {
		console.log(key, validatedData[key])
		if (validatedData[key] || validatedData[key] === ''){
			if (key === "rooms"){
				validatedData[key].forEach(roomItem => {
					let prefix = typeof roomItem.id === 'string' ? roomItem.id : `room_${roomItem.id}`
					formData.append(`${prefix}_name`, roomItem.name )
					formData.append(`${prefix}_condition`, roomItem.condition )
					formData.append(`${prefix}_order`, roomItem.order )
					roomItem.comments.forEach(commentItem => {
						let prefix = typeof commentItem.id === 'string' ? commentItem.id : `comment_${commentItem.id}`
						formData = collectImages(commentItem.image, prefix, formData)
						formData.append(`${prefix}_comment`, commentItem.comment );
						formData.append(`${prefix}_maintenance`, commentItem.maintenance );
						formData.append(`${prefix}_room`, roomItem.id );
					})
				})

			} else if (key === 'comments'){

				validatedData[key].forEach(commentItem => {
					let prefix = typeof commentItem.id === 'string' ? commentItem.id : `comment_${commentItem.id}`
					formData = collectImages(commentItem.image, prefix, formData)
					formData.append(`${prefix}_comment`, commentItem.comment );
					formData.append(`${prefix}_maintenance`, commentItem.maintenance );
					formData.append(`${prefix}_overall`, false );
				})

			} else if (key === 'overall_comments'){

				validatedData[key].forEach(commentItem => {
					let prefix = typeof commentItem.id === 'string' ? commentItem.id : `comment_${commentItem.id}`
					formData = collectImages(commentItem.image, prefix, formData)
					formData.append(`${prefix}_comment`, commentItem.comment );
					formData.append(`${prefix}_maintenance`, commentItem.maintenance );
					formData.append(`${prefix}_overall`, true );
				})

			} else if (key === 'signature_image') {
				formData.append(key, validatedData[key], 'signature.png');
			} else {
				formData.append(key, validatedData[key] );
			}
		}
	})
	// console.log('---formData---')
	// for (var pair of formData.entries()) {
	//     console.log(pair[0]+ ', ' + pair[1]); 
	// }
	let lookupOptions = {
		method,
		body: formData,
		headers: {
			'Authorization': 'Token '+getAuthKey(),
		}
	}
	function offlineActions(){
		// delete lookupOptions.body
		// dispatch({
		// 	type: PUSH_CHECK_TO_OFFLINE_QUEUE,
		// 	payload: {
		// 		type: 'editCheck', 
		// 		title: `Edit Check #${validatedData.id}`, 
		// 		endpoint: endpoint, 
		// 		lookupOptions: lookupOptions, 
		// 		validatedData: validatedData,
		// 		autosaveName: autosaveName,
		// 	},
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: 'editCheck-cache',
		// 		item: {id: validatedData.id}
		// 	},
		// })
	}
	if (!navigator.onLine){
		offlineActions()
	} else {
		dispatch({
			type: ADD_CHECK_MESSAGE,
			payload: {
				type: 'check-fetch',
				id: `check-fetch-${validatedData.id}`,
				itemID: validatedData.id
			},
		})
		fetch(endpoint, lookupOptions)
		.then(function(response){
			dispatch({
				type: REMOVE_MESSAGE,
				payload: `check-fetch-${validatedData.id}`,
			})
			if (response.ok){
				return response.json()
			} else {
				dispatch({
					type: ADD_CHECK_SERVER_ERROR_MESSAGE,
					payload: { type: 'server-error' }
				})
				offlineActions()
			}
		}).then(function(responseData){
			if (responseData){
				dispatch({
					type: EDIT_CHECK,
					payload: {
						type: 'editCheck',
						item: responseData
					},
				})
				dispatch(deleteCheckAutosave(autosaveName, null))
			}
		}).catch(function(error){
			console.log("error", error)
			dispatch({
				type: REMOVE_MESSAGE,
				payload: `check-fetch-${validatedData.id}`,
			})
			dispatch({
				type: ADD_CHECK_SERVER_ERROR_MESSAGE,
				payload: {
					type: 'server-error',
					error: error
				},
			})
			offlineActions()
		})
	}
}

export const deleteCheckAutosave = (name, id) => dispatch => {
	console.log("checkActions deleteCheckAutosave", name)
	deleteCheckAutosaveFiles(name, id, dispatch)
}

export const clearInvalidAutosaves = () => dispatch => {
	console.log("checkActions clearInvalidAutosaves")
    localForage.keys().then((keys) => {
    	console.log("clearInvalidAutosaves keys", keys)
    	keys = keys.filter(key => key.startsWith('save_check_')).map(key => key.split('_')[2])
		const endpoint = `${api_url}rest-auth/checks/get_deleted_checks/`
		let lookupOptions = {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Token '+getAuthKey(),
			},
			body: JSON.stringify({keys}),
		}
		keys.length > 0 && fetch(endpoint, lookupOptions)
		.then(function(response){
			if (response.ok){
				return response.json()
			}
		}).then(function(responseData){
			if (responseData){
				console.log("clearInvalidAutosaves responseData", responseData)
				responseData.forEach(deletedID => {
					deletedID !== "0" && dispatch(deleteCheckAutosave(null, deletedID))
				})
			}
		}).catch(function(error){
			console.error(error)
		})
    })
}

export const auditLocalStorage = () => dispatch => {
	console.log("checkActions auditLocalStorage")

    localForage.keys().then((keys) => {

		// localForage.getItem('hasDeletedOldAutosaves').then(hasDeletedOldAutosaves => {
		// 	if (!hasDeletedOldAutosaves){
		// 		let invalidAutosaves = keys.filter(key => 
		// 			key.startsWith('save_check_') || 
		// 			key.startsWith('save_inventory_') ||
		// 			key.startsWith('save_checkout_') ||
		// 			key.startsWith('file ')
		// 		)
	 //    		invalidAutosaves.forEach(key => localForage.removeItem(key))
		//     	localForage.setItem('hasDeletedOldAutosaves', true)
		// 	}
		// })

    	try {
    		localForage.getItem('hasDeletedExtraChecksV4').then(hasDeletedExtraChecks => {
    			if (!hasDeletedExtraChecks){
		    		let saveCheckKeys = keys.filter(key => key.startsWith('save_check_'))
		    		let extraKeys = saveCheckKeys.filter(key => !key.endsWith('_2') && key.split('_').length > 3)
		    		// console.log("extraKeys", extraKeys)
		    		let keyDict = {}
		    		extraKeys.forEach(key => {
		    			let id = key.split('_')[2]
		    			if (id) {
		    				if (!keyDict[id]) keyDict[id] = [key]
		    				else keyDict[id].push(key)
		    			}
		    		})
		    		// console.log("extraKeys keyDict", keyDict)
		    		Object.keys(keyDict).forEach(id => {
		    			let currentHighestNo = 0
		    			let currentHighestIndex = null
		    			keyDict[id].forEach((key, i) => {
		    				let no = key.split('_')[3]
		    				try {
		    					no = parseInt(no)
		    				} catch (e) {}
		    				if (no && no > currentHighestNo){
				    			currentHighestNo = no
				    			currentHighestIndex = i
		    				}
		    			})
		    			if (currentHighestIndex) delete keyDict[id][currentHighestIndex]
		    		})
		    		// console.log("extraKeys keyDict", keyDict)
		    		Object.keys(keyDict).forEach(id => {
		    			keyDict[id].forEach((key, i) => {
		    				localForage.removeItem(key)
		    			})
		    		})
		    		// extraKeys.forEach(key => localForage.removeItem(key))
		    		localForage.setItem('hasDeletedExtraChecksV4', true)
    			}
    		})
    	} catch (e) {
    		console.log("auditLocalStorage error")
    	}
    })
}

export const deleteCheck = (id) => dispatch => {
	console.log("checkActions deleteCheck", id)
	const endpoint = `${api_url}rest-auth/checks/${id}/`
	let lookupOptions = {
		method: "DELETE",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Token '+getAuthKey(),
		}
	}
	if (!navigator.onLine){
		// dispatch({
		// 	type: PUSH_OFFLINE_QUEUE,
		// 	payload: {title: `Delete Check #${id}`, endpoint: endpoint, lookupOptions: lookupOptions},
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: 'deleteCheck-cache',
		// 		item: {id: id}
		// 	},
		// })
	} else {
		fetch(endpoint, lookupOptions)
		.then(function(response){
			if (response.ok){
				dispatch({
					type: DELETE_CHECK,
					payload: {
						type: 'deleteCheck',
						item: {id: id}
					},
				})
				dispatch(deleteCheckAutosave(null, id))
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}
}

export const massDeleteCheck = (id_array) => dispatch => {
	console.log("checkActions massDeleteCheck", id_array)
	const endpoint = `${api_url}rest-auth/checks/multi_delete/?id=${id_array.join(',')}`
	console.log("endpoint", endpoint)
	let lookupOptions = {
		method: "DELETE",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Token '+getAuthKey(),
		}
	}
	if (!navigator.onLine){
		// dispatch({
		// 	type: PUSH_OFFLINE_QUEUE,
		// 	payload: {title: `Delete Checks #${id_array.join(', #')}`, endpoint: endpoint, lookupOptions: lookupOptions},
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: 'massDeleteCheck-cache',
		// 		id_array: id_array,
		// 	},
		// })
	} else {
		fetch(endpoint, lookupOptions)
		.then(function(response){
			if (response.ok){
				dispatch({
					type: MASS_DELETE_CHECK,
					payload: {
						type: 'massDeleteCheck',
						id_array: id_array,
					},
				})
				id_array.forEach(id => {
					dispatch(deleteCheckAutosave(null, id))
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}
}

export const removeMessage = (id) => dispatch => {
	dispatch({
		type: REMOVE_MESSAGE,
		payload: id,
	})
}