import React, { Component } from 'react';
import { Container, Button, Row, Col, Navbar, Breadcrumb, Form, Alert, Spinner, ListGroup, InputGroup, FormControl } from 'react-bootstrap'
import { fetchSettings, submitInventorySettings, removeMessage } from '../redux/actions/settingsActions'
import { connect } from 'react-redux'
import {withGetScreen} from 'react-getscreen'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import RefreshButton from '../components/RefreshButton'
import InventorySettingsItemList from '../components/InventorySettingsItemList'
import InventorySettingsRoomList from '../components/InventorySettingsRoomList'
import SimpleSplitOptionsField from '../components/SimpleSplitOptionsField'
import RichTextEditor from 'react-rte';

class InventorySettingsPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      inventory_disclaimer: RichTextEditor.createEmptyValue(),
      inventory_abbreviations: RichTextEditor.createEmptyValue(),
      inventory_end_of_tenancy_requirements: RichTextEditor.createEmptyValue(),
      inventory_recommended_cleaners: [{name: 'default', value: RichTextEditor.createEmptyValue()}],
      inventory_recommended_cleaners_office: 'default',
      inventory_signature_disclaimer: RichTextEditor.createEmptyValue(),
      inventory_default_rooms: [],
      inventory_default_room_items: [],
      inventory_default_tenant_emails: '',
      inventory_contact_details: [{name: 'default', value: RichTextEditor.createEmptyValue()}],
      inventory_contact_details_office: 'default',
      validated: false,
      newContactDetailsOffice: '',
      newRecommendedCleanersOffice: '',
    };
  }

  componentWillMount() {
  	this.props.fetchSettings()
  }

  onRefresh = () => {
    this.setState({
      inventory_disclaimer: RichTextEditor.createEmptyValue(),
      inventory_abbreviations: RichTextEditor.createEmptyValue(),
      inventory_end_of_tenancy_requirements: RichTextEditor.createEmptyValue(),
      inventory_recommended_cleaners: [{name: 'default', value: RichTextEditor.createEmptyValue()}],
      inventory_recommended_cleaners_office: 'default',
      inventory_signature_disclaimer: RichTextEditor.createEmptyValue(),
      inventory_default_rooms: [],
      inventory_default_room_items: [],
      inventory_default_tenant_emails: '',
      inventory_contact_details: [{name: 'default', value: RichTextEditor.createEmptyValue()}],
      inventory_contact_details_office: 'default',
      validated: false,
      newContactDetailsOffice: '',
      newRecommendedCleanersOffice: '',
    });
    this.props.fetchSettings()

  }

  componentWillReceiveProps(nextProps) {
    // backwards compatibility
    let inventory_contact_details = []
    if (nextProps.settings.inventory_contact_details.startsWith('[')){
      inventory_contact_details = JSON.parse(nextProps.settings.inventory_contact_details)
      inventory_contact_details.forEach(item => {
        item.value = RichTextEditor.createValueFromString(item.value, 'html')
      })
    } else {
      inventory_contact_details = [{name: 'default', value: RichTextEditor.createValueFromString(nextProps.settings.inventory_contact_details, 'html')}]
    }
    let inventory_recommended_cleaners = []
    if (nextProps.settings.inventory_recommended_cleaners.startsWith('[')){
      inventory_recommended_cleaners = JSON.parse(nextProps.settings.inventory_recommended_cleaners)
      inventory_recommended_cleaners.forEach(item => {
        item.value = RichTextEditor.createValueFromString(item.value, 'html')
      })
    } else {
      inventory_recommended_cleaners = [{name: 'default', value: RichTextEditor.createValueFromString(nextProps.settings.inventory_recommended_cleaners, 'html')}]
    }
  	if (nextProps.settings){
      console.log("nextProps.settings", nextProps.settings)
  		this.setState({
        inventory_disclaimer: RichTextEditor.createValueFromString(nextProps.settings.inventory_disclaimer, 'html'),
        inventory_abbreviations: RichTextEditor.createValueFromString(nextProps.settings.inventory_abbreviations, 'html'),
        inventory_end_of_tenancy_requirements: RichTextEditor.createValueFromString(nextProps.settings.inventory_end_of_tenancy_requirements, 'html'),
        inventory_recommended_cleaners: inventory_recommended_cleaners,
        inventory_signature_disclaimer: RichTextEditor.createValueFromString(nextProps.settings.inventory_signature_disclaimer, 'html'),
        inventory_default_rooms: JSON.parse(nextProps.settings.inventory_default_rooms),
        inventory_default_room_items: JSON.parse(nextProps.settings.inventory_default_room_items),
        inventory_default_tenant_emails: nextProps.settings.inventory_default_tenant_emails,
        inventory_contact_details: inventory_contact_details,
  		})
  	}
  }

  handleOnChange = (event) => {
    this.setState({ 
      [event.target.name]: event.target.value,
    });
  }

  handleChange = (fieldname, value) => {
    console.log("InventorySettingsPage handleChange", fieldname, value)
    this.setState({ 
      [fieldname]: value,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const {inventory_disclaimer, inventory_abbreviations, inventory_end_of_tenancy_requirements, inventory_recommended_cleaners, inventory_signature_disclaimer, inventory_default_rooms, inventory_default_room_items, inventory_default_tenant_emails, inventory_contact_details } = this.state
    this.props.submitInventorySettings(inventory_disclaimer, inventory_abbreviations, inventory_end_of_tenancy_requirements, inventory_recommended_cleaners, inventory_signature_disclaimer, inventory_default_rooms, inventory_default_room_items,inventory_default_tenant_emails, inventory_contact_details)
  }

  handleRoomNamesChange = (room_names) => {
    this.setState({ room_names })
  }

  handleDefaultRoomsChange = (default_rooms) => {
    this.setState({ default_rooms })
  }

  onRichTextChange = (fieldname, value) => {
    // this.setState({value});
    this.setState({ 
      [fieldname]: value,
    });
    // console.log("onRichTextChange", value.toString('html'))
  };

  onOfficeRichTextChange = (officename, fieldname, value) => {
    // this.setState({value});
    let foundField = this.state[fieldname]
    foundField.length && foundField.forEach(item => {
      if (item.name === officename) item.value = value
    })
    this.setState({ 
      [fieldname]: foundField,
    });
    // console.log("onRichTextChange", value.toString('html'))
  };

  addOffice = (fieldname, newOfficeField) => {
    let foundField = this.state[fieldname]
    let newOffice = this.state[newOfficeField]
    if (newOffice !== ""){
      foundField.push({name: newOffice, value: RichTextEditor.createEmptyValue()})
      this.setState({
        [newOfficeField]: "",
        [fieldname]: foundField,
      })
    }
  }

  removeOffice = (removeName, removeField) => {
    if (removeName !== 'default'){
      let foundField = this.state[removeField]
      foundField = foundField.filter(item => item.name !== removeName)
      this.setState({
        [removeField]: foundField,
        inventory_recommended_cleaners_office: 'default',
        inventory_contact_details_office: 'default',
      })
    }
  }

  render() {

    const { validated, inventory_disclaimer, inventory_abbreviations, inventory_end_of_tenancy_requirements, inventory_recommended_cleaners, inventory_signature_disclaimer, inventory_default_rooms, inventory_default_room_items, inventory_default_tenant_emails, inventory_contact_details, inventory_contact_details_office, newContactDetailsOffice, inventory_recommended_cleaners_office, newRecommendedCleanersOffice } = this.state
    const { isLoading, settingsMessages } = this.props
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end"
    let found_inventory_contact_details = inventory_contact_details.find(item => item.name === inventory_contact_details_office)
    let found_inventory_recommended_cleaners = inventory_recommended_cleaners.find(item => item.name === inventory_recommended_cleaners_office)

    return (
    	<div className="page settings-page">
        <Sidebar/>
      	<Navbar fixed="top">
  	    	<Container>
  		    	<h2 className="ml-5 ml-xl-0 d-inline-block">Inventory Settings</h2><RefreshButton onClick={this.onRefresh} isRefreshing={isLoading}/>
  		    	<Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
  				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
  				  <Breadcrumb.Item active>Inventory Settings</Breadcrumb.Item>
  				</Breadcrumb>
  		    </Container>
      	</Navbar>
      	<Container className="pt-5 pb-5 mt-4 mt-sm-5">
          {isLoading ? 
            <div className="text-center pt-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          :
            <>
            <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
            {settingsMessages.map(message => {
              if (message.type === 'submitInventorySettings'){
              } else if (message.type === 'submitInventorySettings-cache'){
                return (
                  <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                    Successfully queued submit settings.
                  </Alert>
                )
              } else if (message.type === 'server-error'){
                return (
                  <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                    {message.error ? 
                    `There was an error: ${message.error}.`
                      :
                    "There was an unexpected error."
                    }
                  </Alert>
                )
              } else {
                return null
              }
          })}
            <Form
              noValidate
              validated={validated}
              onSubmit={e => this.handleSubmit(e)}
            >
              <Form.Group as={Row} className="validate-me">
                <Col sm={sm1}>
                  <Form.Label className={classes1}>
                    Default tenant notifications emails
                  </Form.Label>
                  <p className={`${classes1} small text-right`}>These emails receieve all notifications for tenants submitting comments</p>
                </Col>
                <Col sm={sm2}>
                  <SimpleSplitOptionsField
                    items={inventory_default_tenant_emails}
                    handleChange={(value) => this.handleChange('inventory_default_tenant_emails', value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Col sm={sm1}>
                  <Form.Label className={classes1}>
                    Default Item Options
                  </Form.Label>
                  <p className={`${classes1} small text-right`}>These item options appear on all rooms by default</p>
                </Col>
                <Col sm={sm2}>
                  <InventorySettingsItemList
                    items={inventory_default_room_items}
                    handleChange={(value) => this.handleChange('inventory_default_room_items', value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Col sm={sm1}>
                  <Form.Label className={classes1}>
                    Default Room Options
                  </Form.Label>
                  <p className={`${classes1} small text-right`}>These room options appear by default</p>
                </Col>
                <Col sm={sm2}>
                  <InventorySettingsRoomList
                    rooms={inventory_default_rooms}
                    handleChange={(value) => this.handleChange('inventory_default_rooms', value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Disclaimer
                </Form.Label>
                <Col sm={sm2}>
                  <RichTextEditor
                    className="richtext-editor"
                    value={inventory_disclaimer}
                    onChange={(v) => this.onRichTextChange('inventory_disclaimer', v)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid midterm declaration.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Disclaimer abbreviations
                </Form.Label>
                <Col sm={sm2}>
                  <RichTextEditor
                    className="richtext-editor"
                    value={inventory_abbreviations}
                    onChange={(v) => this.onRichTextChange('inventory_abbreviations', v)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid midterm declaration.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  End of tenancy requirements
                </Form.Label>
                <Col sm={sm2}>
                  <RichTextEditor
                    className="richtext-editor"
                    value={inventory_end_of_tenancy_requirements}
                    onChange={(v) => this.onRichTextChange('inventory_end_of_tenancy_requirements', v)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid midterm declaration.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>


              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Recommended cleaners
                </Form.Label>
                <Col sm={sm2}>
                <InputGroup className="mb-2">
                  <FormControl
                    name="newRecommendedCleanersOffice"
                    placeholder="Add new office"
                    aria-label="New office name"
                    value={newRecommendedCleanersOffice}
                    onChange={this.handleOnChange}
                  />
                  <InputGroup.Append>
                    <Button variant="primary" onClick={() => this.addOffice('inventory_recommended_cleaners', 'newRecommendedCleanersOffice')}>Add</Button>
                  </InputGroup.Append>
                </InputGroup>
                <div className="">
                  {inventory_recommended_cleaners.map(cdItem => 
                    <Button 
                      className="mr-2 mb-2" 
                      variant={cdItem.name === inventory_recommended_cleaners_office ? 'primary' : 'outline-primary'}
                      active={cdItem.name === inventory_recommended_cleaners_office}
                      onClick={() => this.handleChange('inventory_recommended_cleaners_office', cdItem.name)}
                    >{cdItem.name}</Button>
                  )}
                </div>
                <RichTextEditor
                  className="richtext-editor"
                  value={found_inventory_recommended_cleaners.value}
                  onChange={(v) => this.onOfficeRichTextChange(inventory_recommended_cleaners_office, 'inventory_recommended_cleaners', v)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid recommended cleaners.
                </Form.Control.Feedback>
                <Button 
                  className="mt-2" 
                  variant={'danger'}
                  onClick={() => this.removeOffice(inventory_recommended_cleaners_office, 'inventory_recommended_cleaners')}
                >Delete office</Button>
              </Col>
              </Form.Group>

              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Signature disclaimer
                </Form.Label>
                <Col sm={sm2}>
                  <RichTextEditor
                    className="richtext-editor"
                    value={inventory_signature_disclaimer}
                    onChange={(v) => this.onRichTextChange('inventory_signature_disclaimer', v)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid midterm declaration.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Contact details
                </Form.Label>
                <Col sm={sm2}>
                <InputGroup className="mb-2">
                  <FormControl
                    name="newContactDetailsOffice"
                    placeholder="Add new office"
                    aria-label="New office name"
                    value={newContactDetailsOffice}
                    onChange={this.handleOnChange}
                  />
                  <InputGroup.Append>
                    <Button variant="primary" onClick={() => this.addOffice('inventory_contact_details', 'newContactDetailsOffice')}>Add</Button>
                  </InputGroup.Append>
                </InputGroup>
                <div className="">
                  {inventory_contact_details.map(cdItem => 
                    <Button 
                      className="mr-2 mb-2" 
                      variant={cdItem.name === inventory_contact_details_office ? 'primary' : 'outline-primary'}
                      active={cdItem.name === inventory_contact_details_office}
                      onClick={() => this.handleChange('inventory_contact_details_office', cdItem.name)}
                    >{cdItem.name}</Button>
                  )}
                </div>
                <RichTextEditor
                  className="richtext-editor"
                  value={found_inventory_contact_details.value}
                  onChange={(v) => this.onOfficeRichTextChange(inventory_contact_details_office, 'inventory_contact_details', v)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid contact details.
                </Form.Control.Feedback>
                <Button 
                  className="mt-2" 
                  variant={'danger'}
                  onClick={() => this.removeOffice(inventory_contact_details_office, 'inventory_contact_details')}
                >Delete office</Button>
              </Col>
              </Form.Group>

              <Button disabled={!this.props.isOnline} className="float-right" type="submit">Save</Button>
            </Form>
            </>
          }
  		  </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings.settings,
  settingsMessages: state.settings.settingsMessages,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { fetchSettings, submitInventorySettings, removeMessage })(withGetScreen(InventorySettingsPage));