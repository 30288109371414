import { REMOVE_OFFLINE_QUEUE_ITEM, SET_QUEUE_ITEM_PENDING, UNSET_QUEUE_ITEM_PENDING, HANDLE_ONLINE, REFRESH_OFFLINE_QUEUE, SAVE_CHECK, SAVE_INVENTORY, CHECK_STORAGE, CHECKOUT_INVENTORY, SAVE_CHECKOUT, INCREMENT_OFFLINE_FILE_COUNT, SET_ONLINE, SET_OFFLINE_FILE_COUNT} from './types'
import localForage from "localforage";
import { addToAutosaveLog } from '../helpers'

export const checkStorage = () => dispatch => {
	console.log("checkStorage")

    localForage.keys().then((keys) => {

    	let allFiles = keys.filter(key => key.startsWith('file '))
    	if (allFiles.length > 400){
			dispatch({
				type: CHECK_STORAGE,
				payload: true,
			})
    	} else {
			dispatch({
				type: CHECK_STORAGE,
				payload: false,
			})
    	}

    })
  //   try {
  //       // Test up to 20 MB
  //       // localForage.setItem('test', new Array((10000 * 1024) + 1).join('ab'));
  //       localForage.removeItem('test');
		// dispatch({
		// 	type: CHECK_STORAGE,
		// 	payload: false,
		// })
  //   } catch (e) {
  //       localForage.removeItem('test');
		// dispatch({
		// 	type: CHECK_STORAGE,
		// 	payload: true,
		// })     
  //   }
}

export const clearStrayFiles = () => dispatch => {
	console.log("offlineActions clearStrayFiles")

	function getSavedImages(images){
		let savedImages = []
		if (images && images.length > 0){
			let newImages = []
			images.forEach(image => {
				if (image.autosaveID){
					savedImages.push(image.autosaveID)
				}
			})
		}
		return savedImages
	}

	function getMissingInventoryFiles(missingFiles, inventory){
		let savedImages = []

		savedImages = savedImages.concat(getSavedImages(inventory.electricity_meter_images))
		savedImages = savedImages.concat(getSavedImages(inventory.gas_meter_images))
		savedImages = savedImages.concat(getSavedImages(inventory.water_meter_images))
		savedImages = savedImages.concat(getSavedImages(inventory.fuse_board_images))
		savedImages = savedImages.concat(getSavedImages(inventory.stop_tap_images))
		savedImages = savedImages.concat(getSavedImages(inventory.keys_codes_images))
		
		inventory.rooms.forEach(roomItem => {
			roomItem.items && 
			roomItem.items.length > 0 && 
			roomItem.items.forEach(roomItemItem => {
				if (roomItemItem.images && roomItemItem.images.length > 0){
					savedImages = savedImages.concat(getSavedImages(roomItemItem.images))
				}
			})
		})

		if (inventory.signature_image && inventory.signature_image.url && typeof inventory.signature_image.url === 'number'){
			savedImages.push(inventory.signature_image.url)
		}

		missingFiles = missingFiles.filter(item => !savedImages.find(savedImage => savedImage === item))

		return missingFiles
	}

	function getMissingCheckoutFiles(missingFiles, checkout){
		let savedImages = []
		
		checkout.checkoutRooms.forEach(roomItem => {
			if (roomItem.checkout_images && roomItem.checkout_images.length > 0){
				savedImages = savedImages.concat(getSavedImages(roomItem.checkout_images))
			}
		})

		if (checkout.signature_image_checkout && checkout.signature_image_checkout.url && typeof checkout.signature_image_checkout.url === 'number'){
			savedImages.push(checkout.signature_image_checkout.url)
		}

		missingFiles = missingFiles.filter(item => !savedImages.find(savedImage => savedImage === item))

		return missingFiles
	}

	function getMissingCheckFiles(missingFiles, routineCheck){
		let savedImages = []

		routineCheck.comments && 
		routineCheck.comments.length > 0 && 
		routineCheck.comments.forEach(commentItem => {
			if (commentItem.image && commentItem.image.length > 0){
				savedImages = savedImages.concat(getSavedImages(commentItem.image))
			}
		})
		routineCheck.overall_comments && 
		routineCheck.overall_comments.length > 0 && 
		routineCheck.overall_comments.forEach(commentItem => {
			if (commentItem.image && commentItem.image.length > 0){
				savedImages = savedImages.concat(getSavedImages(commentItem.image))
			}
		})
		
		routineCheck.rooms.forEach(roomItem => {
			roomItem.comments && 
			roomItem.comments.length > 0 && 
			roomItem.comments.forEach(commentItem => {
				if (commentItem.image && commentItem.image.length > 0){
					savedImages = savedImages.concat(getSavedImages(commentItem.image))
				}
			})
		})

		if (routineCheck.signature_image && routineCheck.signature_image.url && typeof routineCheck.signature_image.url === 'number'){
			savedImages.push(routineCheck.signature_image.url)
		}

		missingFiles = missingFiles.filter(item => !savedImages.find(savedImage => savedImage === item))

		return missingFiles
	}

    localForage.keys().then((keys) => {

    	// Search all comments for autosaves and offlinequeue, also check signatures
    	let missingFiles = keys.filter(key => key.startsWith('file ')).map(key => parseInt(key.split(' ')[1]))
    	let originalFiles = [...missingFiles]
    	let autosaveKeys = keys.filter(key => key.startsWith('save_check_') || key.startsWith('save_inventory_') || key.startsWith('save_checkout_'))

    	Promise.all(autosaveKeys.map(item => localForage.getItem(item))).then((loadedAutosaves) => {
	    	localForage.getItem(`offlineQueue`).then((result) => {

	    		// Check offline queue
				let offlineQueue = result ? JSON.parse(result) : []
				offlineQueue.forEach(offlineItem => {
					if (offlineItem && offlineItem.validatedData){
						if (offlineItem.validatedData.type === 'editCheck') {
							missingFiles = getMissingCheckFiles(missingFiles, offlineItem.validatedData)
						} else if (offlineItem.validatedData.type === 'editInventory') {
							missingFiles = getMissingInventoryFiles(missingFiles, offlineItem.validatedData)
						} else if (offlineItem.validatedData.type === 'checkoutInventory') {
							missingFiles = getMissingCheckoutFiles(missingFiles, offlineItem.validatedData)
						}
					}
				})

				// Check autosaves
				loadedAutosaves.forEach((autosaveItem, i) => {
					autosaveItem = autosaveItem ? JSON.parse(autosaveItem) : {}
					if (autosaveKeys[i].startsWith('save_check_')) {
						missingFiles = getMissingCheckFiles(missingFiles, autosaveItem)
					} else if (autosaveKeys[i].startsWith('save_inventory_')) {
						missingFiles = getMissingInventoryFiles(missingFiles, autosaveItem)
					} else if (autosaveKeys[i].startsWith('save_checkout_')) {
						missingFiles = getMissingCheckoutFiles(missingFiles, autosaveItem)
					}
				})

				// Remove remaining missing files
				missingFiles.forEach(missingFile => {
					localForage.removeItem(`file ${missingFile}`).then(function() {
					    console.log(`successfully removed file ${missingFile}`);
					})
				})

				addToAutosaveLog('clearStrayFiles removed files: '+missingFiles.join(', '));
	    	})
		});

    })
}

export const setOfflineFileCount = (value) => dispatch => {
	dispatch({
		type: SET_OFFLINE_FILE_COUNT,
		payload: value,
	})
}

export const incrementOfflineFileCount = () => dispatch => {
	dispatch({
		type: INCREMENT_OFFLINE_FILE_COUNT,
	})
}

export const setOnline = (value) => dispatch => {
	dispatch({
		type: SET_ONLINE,
		payload: value,
	})

}

export const saveCheckout = (item, checkoutAutosaveName) => dispatch => {
	// console.log("offlineActions saveCheck", item)
	dispatch({
		type: SAVE_CHECKOUT,
		payload: item,
		checkoutAutosaveName: checkoutAutosaveName,
	})

}

export const saveInventory = (item, inventoryAutosaveName) => dispatch => {
	console.log("offlineActions saveInventory", item, inventoryAutosaveName)
	dispatch({
		type: SAVE_INVENTORY,
		payload: item,
		inventoryAutosaveName: inventoryAutosaveName,
	})

}

export const saveCheck = (item, checkAutosaveName) => dispatch => {
	// console.log("offlineActions saveCheck", item)
	dispatch({
		type: SAVE_CHECK,
		payload: item,
		checkAutosaveName: checkAutosaveName,
	})
	// setTimeout(dispatch(checkStorage()), 1000)
}

export const removeOfflineQueueItem = (item) => dispatch => {
	console.log("offlineActions removeOfflineQueueItem", item)

	dispatch({
		type: REMOVE_OFFLINE_QUEUE_ITEM,
		payload: item,
	})
}

export const setQueueItemPending = (item) => dispatch => {
	console.log("offlineActions setQueueItemPending", item)

	dispatch({
		type: SET_QUEUE_ITEM_PENDING,
		payload: item,
	})
}

export const unsetQueueItemPending = (item) => dispatch => {
	console.log("offlineActions unsetQueueItemPending", item)

	dispatch({
		type: UNSET_QUEUE_ITEM_PENDING,
		payload: item,
	})
}

export const handleOnline = (item) => dispatch => {
	console.log("offlineActions handleOnline", item)

	dispatch({
		type: HANDLE_ONLINE,
	})
}

export const refreshOfflineQueue = (item) => dispatch => {
	console.log("offlineActions refreshOfflineQueue", item)
	localForage.getItem('offlineQueue').then((result) => {
		// console.log("result", result)
		let offlineQueue = result ? JSON.parse(result) : []
		// console.log("offlineQueue", offlineQueue)
		dispatch({
			type: REFRESH_OFFLINE_QUEUE,
			offlineQueue,
		})
	})
	localForage.getItem('offlineFileCount').then((result) => {
		console.log("offlineFileCount result", result)
		let offlineFileCount = result ? result : 0
		console.log("offlineFileCount", offlineFileCount)
		dispatch({
			type: REFRESH_OFFLINE_QUEUE,
			offlineFileCount,
		})
	})

	// dispatch({
	// 	type: REFRESH_OFFLINE_QUEUE,
	// })
}