import React, { Component } from 'react';
import refresh from '../refresh.svg';

class RefreshButton extends Component {

  render() {
    return (
      <button 
        className="refresh-button d-block d-sm-none" 
        onClick={
          this.props.onClick ? this.props.onClick
          : () => window.location.reload()
        }
      >
        <img
        alt="Refresh"
        src={refresh}
        width="35"
        height="35"
        className={`d-inline-block float-right ${this.props.isRefreshing && 'rotateAnimation'}`}
        />
      </button>
    )

  }
} 
export default RefreshButton
