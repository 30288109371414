import React, { Component } from 'react';
import { Modal, Button, Row, Col, ListGroup, Tab, Tabs } from 'react-bootstrap'
import { api_url } from '../api_url'
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'

// const imgPrefix = 'http://127.0.0.1:8000'
const imgPrefix = ''

class ViewCheckModal extends Component {

	constructor(props){
		super(props)
		this.state = {
			historyCheck: null,
			historyItems: [],
		};
	}

	componentWillMount(){
		if (this.props.isMobile()) this.onShow()
	}

	processHistory = (historyList, type) => {
		historyList.forEach((item, i) => {
			// console.log("item, i", item, i)
			historyList[i].item_type = type
			if (item.history_type === "~"){
				let changedFields = {}
				Object.keys(item).forEach(key => {
					// console.log("key, index", key)
					if (["history_date", "history_id", "history_type", "item_type"].indexOf(key) === -1 && historyList[i][key] !== historyList[i+1][key]){
						changedFields[key] = historyList[i+1][key]
					}
				})
				historyList[i].changedFields = changedFields
				// console.log("changedFields", historyList[i].changedFields)
			}
		})
		return historyList
	}

	fetchCheck = () => {
		console.log("ViewCheckModal, fetchCheck")
		let itemID = null
		if (this.props.item){
			itemID = this.props.item.id
		} else {
			itemID = history.location.hash.split('-').pop()
		}
		const endpoint = `${api_url}rest-auth/checks/${itemID}/` 
		let thisComp = this
		let lookupOptions = {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
        		'Authorization': 'Token '+window.localStorage.getItem('authKey'),
			}
		}
		fetch(endpoint, lookupOptions)
		.then(function(response){
			return response.json()
		}).then(function(responseData){
			if (responseData){
				// console.log("responseData", responseData)
				let historyItems = thisComp.processHistory(responseData.history, 'Routine check')
				responseData.comments && responseData.comments.forEach(commentItem => {
					historyItems = historyItems.concat(thisComp.processHistory(commentItem.history, 'External property comment'))
				})
				responseData.rooms && responseData.rooms.forEach(roomItem => {
					historyItems = historyItems.concat(thisComp.processHistory(roomItem.history, 'Room'))
					roomItem.comments && roomItem.comments.forEach(commentItem => {
						historyItems = historyItems.concat(thisComp.processHistory(commentItem.history, 'Room comment'))
					})
				})
				historyItems.sort(function(a, b){
					let dateA = Date.parse(a.history_date)
					let dateB = Date.parse(b.history_date)
					if (dateA < dateB) {return 1;}
					if (dateA > dateB) {return -1;}
					return 0
				})
				// console.log("processed history: ", history)
				thisComp.setState({
					historyCheck: responseData,
					historyItems: historyItems,
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}

	onShow = () => {
		this.fetchCheck()
	}

	componentDidMount(){
		this.props.show && this.fetchCheck()
	}



  renderTimestamp = timestamp => {
  	let d = new Date(timestamp)
  	return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    	d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
  };

  // restoreCheck = (item) => {
  // 	console.log("restoreCheck", item)
  // 	this.props.handleClose()
  // 	this.props.handleEditCheck(item)
  // }

  getMaintenanceNeeded = () => {
  	const { historyCheck } = this.state
	const sm1 = 4
	const sm2 = 5
	const classes1 = "text-sm-right text-center"
	const classes2 = "text-sm-left text-center mb-1"

  	let item = null
  	if (historyCheck) item = historyCheck
  	else if (this.props.item) item = this.props.item

  	function addToDict(dict, field, data){
  		if ((field in dict) === false){
  			dict[field] = []
  		}
  		dict[field].push(data)
  	}

  	if (item){
  		let maintenanceList = {}
  		item.comments.forEach(commentItem => {
  			if (commentItem.maintenance){
  				addToDict(maintenanceList, 'External Property', commentItem.maintenance)
  			}
  		})
  		item.overall_comments && item.overall_comments.forEach(commentItem => {
  			if (commentItem.maintenance){
  				addToDict(maintenanceList, 'Overall', commentItem.maintenance)
  			}
  		})
  		item.rooms.forEach(roomItem => {
  			roomItem.comments.forEach(commentItem => {
	  			if (commentItem.maintenance){
	  				addToDict(maintenanceList, roomItem.name, commentItem.maintenance)
	  			}
  			})
  		})
  		if (Object.keys(maintenanceList).length > 0){
  			return Object.keys(maintenanceList).map(roomName => 
	          	<>
	          		<Col xs={6} sm={6}>
	          			<strong>{roomName}</strong><br/>
	          			{maintenanceList[roomName].map(maintenanceItem =>
	          				<>{maintenanceItem}<br/></>
	          			)}
	          		</Col>
	          	</>
  			)
  		}
  		
  	}
  	return <p className={`col-sm-${sm1}`}>None</p>
  }

  render(){
  	if (this.props.show && this.props.isMobile()) return this.getInner()
  	else {
	  	return (
	        <Modal size="lg" show={this.props.show} onHide={this.props.handleClose} onShow={this.onShow}>
	        {this.getInner()}
	        </Modal>
	  	)
  	}
  }

  onPlainImageError = e => {
    console.log("onPlainImageError", e.target)
    var element = document.createElement("div");
    element.innerHTML = "<b>Image unavailable.</b><p class=\"small\">This image cannot be edited offline or the link has expired.</p>";
    element.setAttribute("role", "alert");
    element.setAttribute("class", "fade alert alert-info show");
    e.target.parentNode.replaceChild(element, e.target)
  }

  getInner = () => {
  	console.log("this.props", this.props)
  	const {historyItems, historyCheck} = this.state
  	console.log("historyItems", historyItems)

  	let item = null
  	if (historyCheck) item = historyCheck
  	else if (this.props.item) item = this.props.item

  	if (item){
	  	const address_full = item && item.address_full ? [
	  		item.address_full.tenant_address_line_1, 
	  		item.address_full.tenant_address_line_2, 
	  		item.address_full.tenant_town_city, 
	  		item.address_full.tenant_county, 
	  		item.address_full.tenant_postcode
	  	].filter(function (el) {
		  return el !== null && el !== "";
		}) : [];
  		const sm1 = 4
  		const sm2 = 5
  		const classes1 = "text-sm-right text-center"
  		const classes2 = "text-sm-left text-center mb-1"
	    return (
	        <>
	          <Modal.Header>
	            <Modal.Title>Property Visit Report #{item.id}</Modal.Title>
            	<CloseModalButton onClick={this.props.handleClose}/>
	          </Modal.Header>
	          {/**<Tabs defaultActiveKey="details" variant="pills" className="px-3 mt-3 mb-n3">
			  <Tab eventKey="details" title="Details">**/}
			  <Modal.Body className="pt-0">

			  	<div className="modal-subheading mt-0">
			  		<h5 className="">Details</h5>
			  	</div>

	          	{/**<Row>
	          		<Col sm={sm1} className={classes1}>
	          			ID:
	          		</Col>
	          		<Col sm={sm2} className={classes2}>
	          			{item.id ? item.id : "-"}
	          		</Col>
	          	</Row>**/}
	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<strong>Address</strong><br/>
	          			{address_full ? address_full.join(', ') : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Report submitted</strong><br/>
	          			{item.date_submitted ? item.date_submitted : "-"}<br/>
	          			<strong>Appointment date</strong><br/>
	          			{item.appointment_date ? item.appointment_date : "-"}
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<strong>Tenant(s) present at inspection?</strong><br/>
	          			{item.tenants_present_at_inspection ? item.tenants_present_at_inspection : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Overall condition of property?</strong><br/>
	          			{item.condition_property ? item.condition_property : "-"}
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<strong>Overall cleanliness of property?</strong><br/>
	          			{item.cleanliness_property ? item.cleanliness_property : "-"}
	          		</Col>
	          	</Row>

        		{item.overall_comments 
        			? 
        			item.overall_comments.length > 0 
	        			?
	        			item.overall_comments.map((commentItem, index) => {
	        				return (
	        					<div key={commentItem.id}>
        						<hr/>
					          	<Row>
					          		<Col sm={12}>
					          			<strong>Comment:</strong> {commentItem.comment ? commentItem.comment : "-"}
					          		</Col>
					          		<Col sm={12}>
					          			<strong>Maintenance:</strong> {commentItem.maintenance ? commentItem.maintenance : "-"}
					          		</Col>
					          		{commentItem.image && commentItem.image.length > 0 ? 
			          				commentItem.image.map((imageItem, imageIndex) => 
						          		<Col sm={4} className={classes2+" mt-2"}>
		          							<a href={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} target="_blank" rel="noopener noreferrer"><img src={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} className={`img-fluid ${imageIndex !== 0 && 'pt-2'}`} alt={commentItem.comment} onError={this.onPlainImageError} /></a>
						          		</Col>
				          			)
					          		: ""}
					          	</Row>
					          	</div>
	        				)
	        			})
	        			:
	        			<p className={`col-sm-${sm1}`}>None</p>
        			:
        			"Loading"
        		}

			  	<div className="modal-subheading">
			  		<h5 className="">Maintenance Needed</h5>
			  	</div>

			  	<Row>
			  	{this.getMaintenanceNeeded()}
			  	</Row>

			  	<div className="modal-subheading">
			  		<h5 className="">Property - External</h5>
			  	</div>

        		{item.comments 
        			? 
        			item.comments.length > 0 
	        			?
	        			item.comments.map((commentItem, index) => {
	        				return (
	        					<div key={commentItem.id}>
        						{index !== 0 && <hr/>}
					          	<Row>
					          		<Col sm={12}>
					          			<strong>Comment:</strong> {commentItem.comment ? commentItem.comment : "-"}
					          		</Col>
					          		<Col sm={12}>
					          			<strong>Maintenance:</strong> {commentItem.maintenance ? commentItem.maintenance : "-"}
					          		</Col>
					          		{commentItem.image && commentItem.image.length > 0 ? 
			          				commentItem.image.map((imageItem, imageIndex) => 
						          		imageItem && imageItem.url && <Col sm={4} className={classes2+" mt-2"}>
		          							<a href={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} target="_blank" rel="noopener noreferrer"><img src={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} className={`img-fluid ${imageIndex !== 0 && 'pt-2'}`} alt={commentItem.comment} onError={this.onPlainImageError} /></a>
						          		</Col>
				          			)
					          		: ""}
					          	</Row>
					          	</div>
	        				)
	        			})
	        			:
	        			<p className={`col-sm-${sm1}`}>None</p>
        			:
        			"Loading"
        		}

			  	<div className="modal-subheading">
			  		<h5 className="">Property - Internal</h5>
			  	</div>

        		{item.rooms 
        			? 
        			item.rooms.length > 0 
	        			?
	        			item.rooms.map((roomItem, roomIndex) => 
							<div 
								key={roomItem && roomItem.id} 
								style={
								  roomIndex !== 0 ? 
								    {
								      'borderTop': '5px solid #cacaca',
								      'paddingTop': '20px',
								      'marginTop': '20px',
								    } 
								    : {}
								 }
							>
				          	<Row>
				          		<Col sm={12}>
				          			<strong>{roomItem.name ? roomItem.name : "-"}:</strong>
				          		</Col>
				          		<Col sm={12}>
				          			<strong>Condition:</strong> {roomItem.condition ? roomItem.condition : "-"}
				          		</Col>
				          	</Row>
		        			{roomItem.comments.length > 0 
			        			?
			        			roomItem.comments.map((commentItem, index) => {
			        				return (
			        					<>
	        								{(roomIndex || roomIndex === 0 || index !== 0) && <hr/>}
				        					<div key={commentItem.id}>
			        						{index !== 0 && <hr/>}
								          	<Row>
								          		<Col sm={12}>
								          			<strong>Comment:</strong> {commentItem.comment ? commentItem.comment : "-"}
								          		</Col>
								          		<Col sm={12}>
								          			<strong>Maintenance:</strong> {commentItem.maintenance ? commentItem.maintenance : "-"}
								          		</Col>
								          		{commentItem.image && commentItem.image.length > 0 ? 
						          				commentItem.image.map((imageItem, imageIndex) => 
									          		imageItem && imageItem.url && <Col sm={4} className={classes2+" mt-2"}>
					          							<a 
					          								href={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} 
					          								target="_blank" 
					          								rel="noopener noreferrer">
					          								<img 
					          									src={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} 
					          									className={`img-fluid ${imageIndex !== 0 && 'pt-2'}`}
					          									 alt={commentItem.comment} 
					          									 onError={this.onPlainImageError} 
					          								/>
					          							</a>
									          		</Col>
							          			)
								          		: ""}
								          	</Row>
								          	</div>
							          	</>
			        				)
			        				// return (
			        				// 	<div key={commentItem.id}>
        							// 	{(roomIndex || roomIndex === 0 || index !== 0) && <hr/>}
							        //   	<Row>
							        //   		<Col sm={sm2} className={classes2}>
							        //   			{commentItem.image && commentItem.image.length > 0 ? 
							        //   				commentItem.image.map((imageItem, imageIndex) => 
					          // 							<a href={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} target="_blank" rel="noopener noreferrer"><img src={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} className={`img-fluid ${imageIndex !== 0 && 'pt-2'}`} alt={commentItem.comment} /></a>
								       //    			)
							        //   			: "-"}
							        //   		</Col>
							        //   	</Row>
							        //   	<Row>
							        //   		<Col sm={sm1} className={classes1}>
							        //   			Comment:
							        //   		</Col>
							        //   		<Col sm={sm2} className={classes2}>
							        //   			{commentItem.comment ? commentItem.comment : "-"}
							        //   		</Col>
							        //   	</Row>
							        //   	<Row>
							        //   		<Col sm={sm1} className={classes1}>
							        //   			Maintenance:
							        //   		</Col>
							        //   		<Col sm={sm2} className={classes2}>
							        //   			{commentItem.maintenance ? commentItem.maintenance : "-"}
							        //   		</Col>
							        //   	</Row>
							        //   	</div>
			        				// )
			        			})
			        			:
			        			<p className={`col-sm-${sm1}`}>No comments</p>}
		        			</div>
	        			)
	        			:
	        			<p className={`col-sm-${sm1}`}>None</p>
        			:
        			"Loading"
        		}

			  	<div className="modal-subheading">
			  		<h5 className="">Health & Safety Checks</h5>
			  	</div>

	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<strong>Property secured and locks checked?:</strong><br/>
	          			{item.property_security_locks ? item.property_security_locks : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Boiler housing clear?:</strong><br/>
	          			{item.boiler_housing_clear ? item.boiler_housing_clear : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Smoke detectors on each floor?:</strong><br/>
	          			{item.smoke_detectors_each_floor ? item.smoke_detectors_each_floor : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Gas check up to date?:</strong><br/>
	          			{item.gas_check_up_to_date ? item.gas_check_up_to_date : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Electric check up to date?:</strong><br/>
	          			{item.electric_check_up_to_date ? item.electric_check_up_to_date : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Chimney sweep up to date?:</strong><br/>
	          			{item.chimney_sweep_up_to_date ? item.chimney_sweep_up_to_date : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Blind cleats installed?:</strong><br/>
	          			{item.blind_cleats_up_to_date ? item.blind_cleats_up_to_date : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			 <strong>Oil check up to date?:</strong><br/>
	          			{item.oil_check_up_to_date ? item.oil_check_up_to_date : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>CO detector present?:</strong><br/>
	          			{item.co_detector_present ? item.co_detector_present : "-"}
	          		</Col>
	          	</Row>

			  	<div className="modal-subheading">
			  		<h5 className="">General Observations</h5>
			  	</div>

	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<strong>Ambient odour?:</strong><br/>
	          			{item.ambient_odour ? item.ambient_odour : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Pets?:</strong><br/>
	          			{item.pets ? item.pets : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Hoarding?:</strong><br/>
	          			{item.hoarding ? item.hoarding : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Smoking?:</strong><br/>
	          			{item.smoking ? item.smoking : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Business activity?:</strong><br/>
	          			{item.business_activity ? item.business_activity : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Malicious damage?:</strong><br/>
	          			{item.malicious_damage ? item.malicious_damage : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Condensation/mould/damp present?:</strong><br/>
	          			{item.condensation_mould_damp ? item.condensation_mould_damp : "-"}
	          		</Col>
	          	</Row>

			  	<div className="modal-subheading">
			  		<h5 className="">Other Comments</h5>
			  	</div>

	          	<Row>
	          		<Col sm={12}>
	          			{item.other_comments ? item.other_comments : "-"}
	          		</Col>
	          	</Row>

			  	<div className="modal-subheading">
			  		<h5 className="">Signature</h5>
			  	</div>

	          	<Row>
	          		<Col sm={12}>
	          			{this.props.settings && this.props.settings.midterm_declaration ? this.props.settings.midterm_declaration : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<br/><strong>Carried out by:</strong><br/>
	          			{item.assigned_to_full ? `${item.assigned_to_full.first_name} ${item.assigned_to_full.last_name}` : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<br/>
	          			{item.signature_image ? 
  							<a href={imgPrefix === '' ? imgPrefix+item.signature_image.replace('http:', 'https:') : item.signature_image} target="_blank" rel="noopener noreferrer"><img src={imgPrefix === '' ? imgPrefix+item.signature_image.replace('http:', 'https:') : item.signature_image} className="img-fluid" alt="signature" onError={this.onPlainImageError} /></a>
	          			:
	          			item.signature ? item.signature : "-"}
	          		</Col>
	          	</Row>

			  	<div className="modal-subheading">
			  		<h5 className="">Disclaimer</h5>
			  	</div>
			  	
	          	<Row>
	          		<Col sm={12}>
	          			{this.props.settings && this.props.settings.disclaimer ? this.props.settings.disclaimer : "-"}
	          		</Col>
	          	</Row>

	          	</Modal.Body>
			    
			  {/**</Tab>
			  <Tab eventKey="history" title="History">
			    
				<Modal.Body>
	            <ListGroup className="mt-3">
            		{historyItems ? 
            			historyItems.map(item => {
	            			if (item.history_type === '~' && Object.keys(item.changedFields).length > 0){
	            				return (
	            					<ListGroup.Item key={item.item_type+item.history_id}>
	            						<Row>
	            						<Col sm={8}>
	            						<div className="small">{this.renderTimestamp(item.history_date)}</div>
		            					{Object.keys(item.changedFields).length > 0 ?
			            					Object.keys(item.changedFields).map((key, i) => {
			            						let t = `Changed ${key} from "${item.changedFields[key]}" to "${item[key]}" on ${item.item_type}`
			            						// if (i > 0) t = <><br/>{t}</>
			            						return <div key={key} className="text-break">{t}</div>
			            					})
			            				:
			            				'No changes detected'}
		            					</Col>
	            						<Col sm={4}>
		            					</Col>
		            					</Row>
	            					</ListGroup.Item>
	            				)
	            			} else if (item.history_type === '+'){
	            				return (
									<ListGroup.Item key={item.item_type+item.history_id}>
	            						<Row>
	            						<Col sm={8}>
	            						<div className="small">{this.renderTimestamp(item.history_date)}</div>
										<div className="text-break">{item.item_type} created</div>
		            					</Col>
	            						<Col sm={4}>
		            					</Col>
		            					</Row>
									</ListGroup.Item>
	            				)
	            			} else {
	            				return null
	        //     				return (
									// <ListGroup.Item key={item.item_type+item.history_id}>Unknown history_type {item.history_type}</ListGroup.Item>
	        //     				)
	            			}
	            		})
            			:
            			""
            		}
	            </ListGroup>
	            </Modal.Body>
			  </Tab>
			</Tabs>**/}
	          <Modal.Footer>
	            <Button variant="secondary" onClick={this.props.handleClose} className="float-left ml-0">
	              Close
	            </Button>
	            <Button variant="sab" onClick={() => this.props.startEditCheck(item)} className="float-left mr-auto ml-0">
	              Edit
	            </Button>
	          </Modal.Footer>
	        </>
	    )
  	} else {
  		return null
  	}

  }
}

export default ViewCheckModal;
