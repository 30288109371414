import React, { Component } from 'react';
import { Container, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Alert, Spinner } from 'react-bootstrap'
import { getUserList, removeMessage, deleteUser, massDeleteUser, setShowCreateUserModal } from '../redux/actions/userActions'
import { connect } from 'react-redux'
import ChangePasswordModal from '../modals/ChangePasswordModal'
import CreateUserModal from '../modals/CreateUserModal'
import ConfirmModal from '../modals/ConfirmModal'
import {withGetScreen} from 'react-getscreen'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import history from '../history';
import RefreshButton from '../components/RefreshButton'

class UserPage extends Component {

  constructor(props){
    super(props)
    this.state = this.getDefaultState()
  }

  getDefaultState = () => {
    return {
      currentPage: null,
      showChangePasswordModal: false,
      showConfirmModal: false,
      confirmModalTitle: "",
      confirmModalBody: "",
      confirmModalCallback: null,
      selectedDropdownItem: null,
      pageCount: 1,
      selected: [],
      messages: [],
    };
  }

  handleRefresh = () => {
    this.setState(this.getDefaultState(), this.initialiseComponent)
  }

  componentWillMount() {
    console.log("componentWillMount")
    this.initialiseComponent()
  }

  initialiseComponent = () => {
    this.displayModalFromHash()
    this.props.getUserList()
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps")
    if (nextProps.showCreateUserModal === false && history.location.hash.length > 0){
      history.push({
        search: ``
      })
    }
  }

  getUserRecord = (item, message) => {
    const optionsLabel = this.props.isMobile() ? "" : "Options"
    return (
      <tr key={item.id} onClick={(e) => this.checkFromRow(e, item.id)} className={message ? "success-row" : ""}>
        <td><input id={`select-${item.id}`} type="checkbox" style={{ pointerEvents: "none" }}/></td>
        <td>
          {item.username}
        </td>
        <td>
          {item.first_name}
        </td>
        <td>
          {item.last_name}
        </td>
        <td>
          {item.sabuser_role}
        </td>
        <td>
          {item.office}
        </td>
        <td>
          <DropdownButton title={optionsLabel} variant="sab" className="float-right" alignRight>
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.handleShowEditUserModal(item)}>Edit</Dropdown.Item>
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.handleChangePasswordModal(item)}>Change Password</Dropdown.Item>
            {message && 
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.props.removeMessage(message.id)}>Hide Message</Dropdown.Item>
              </>
            }
            <Dropdown.Divider />
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.confirmDelete(item.id)}>Delete</Dropdown.Item>
          </DropdownButton>
        </td>
      </tr>
    )
  }

  getUserContent = () => {
  	const { userList } = this.props
  	return (
  	  <>
        {userList.map(item => {
          return this.getUserRecord(item, false)
  	  	})}
  	  </>
  	)
  }

  massDelete = () => {
  	this.handleClose(false)
  	const { selected } = this.state
  	this.props.massDeleteUser(selected)
  	this.setState({
  		selected: [],
  	})
  }

  confirmDelete = (itemID) => {
    history.push(`#confirm`);
    this.setState({ 
      showConfirmModal: true,
      confirmModalTitle: "Delete User",
      confirmModalBody: "Are you sure you want to delete the selected user?",
      confirmModalCallback: () => {
        this.handleClose(false)
        this.props.deleteUser(itemID)
      },
    });
  }

  confirmMassDelete = () => {
    this.setState({ 
    	showConfirmModal: true,
    	confirmModalTitle: "Mass Deletion",
    	confirmModalBody: "Are you sure you want to delete the selected users?",
    	confirmModalCallback: this.massDelete
    });
  }

  displayModalFromHash = () => {
    const {showConfirmModal, showCreateUserModal, showChangePasswordModal } = this.state
    const modalOpen = showConfirmModal || showCreateUserModal || showChangePasswordModal
    if (history.location.hash.length === 0 && modalOpen === true){
      this.props.setShowCreateUserModal(false)
      this.setState({ 
        selectedDropdownItem: null,
        showConfirmModal: false,
        // showCreateUserModal: false,
        showChangePasswordModal: false,
      });
    } else if (history.location.hash.startsWith('#create')){
      this.props.setShowCreateUserModal(true)
      this.setState({ 
        selectedDropdownItem: null,
        // showCreateUserModal: true,
        showConfirmModal: false,
        showChangePasswordModal: false,
      });
    } else if (history.location.hash.startsWith('#edit')){
      this.props.setShowCreateUserModal(true)
      this.setState({ 
        selectedDropdownItem: history.location.state ? history.location.state.item : null,
        showConfirmModal: false,
        // showCreateUserModal: true,
        showChangePasswordModal: false,
      });
    }
  }

  componentDidUpdate(){
    window.onpopstate  = (e) => {
      this.displayModalFromHash()
    }
  }

  handleChangePasswordModal = (item) => {
    this.props.setShowCreateUserModal(false)
    this.setState({ 
      selectedDropdownItem: item,
      // showCreateUserModal: false,
      showConfirmModal: false,
      showViewAddressModal: false,
      showChangePasswordModal: true,
    });
    window.scrollTo(0, 0)
  }

  handleShowCreateUserModal = (item) => {
    history.push(`#create`);
    this.props.setShowCreateUserModal(true)
    this.setState({ 
    	selectedDropdownItem: null,
    	// showCreateUserModal: true,
      showConfirmModal: false,
      showViewAddressModal: false,
      showChangePasswordModal: false,
    });
    window.scrollTo(0, 0)
  }

  handleShowEditUserModal = (item) => {
    history.push(`#edit-${item.id}`, {item: item});
    
    this.setState({ 
    	selectedDropdownItem: item,
    	// showCreateUserModal: true,
      showConfirmModal: false,
      showViewAddressModal: false,
      showChangePasswordModal: false,
    }, () => this.props.setShowCreateUserModal(true));
    window.scrollTo(0, 0)
  }

  handleClose = (resetCurrentPage) => {
    if (resetCurrentPage !== false) this.resetCurrentPage()
    this.props.setShowCreateUserModal(false)
    this.setState({ 
      selectedDropdownItem: null,
    	showConfirmModal: false,
    	showViewAddressModal: false,
    	// showCreateUserModal: false,
      showChangePasswordModal: false,
    });
    window.scrollTo(0, 0)
  }

  handleCheckAllToggle = () => {
  	const allCheckbox = document.getElementById('select-all')
  	allCheckbox.checked = !allCheckbox.checked
  	const { currentPage } = this.state
  	let selected = []
  	if (allCheckbox.checked){
  		currentPage.results.forEach(item => {
  			selected.push(item.id)
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.add("selected");
    		checkbox.checked = true
  		})
  	} else {
  		currentPage.results.forEach(item => {
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.remove("selected");
    		checkbox.checked = false
  		})
  	}
  	this.setState({
  		selected
  	})
  }

  checkFromRow = (event, id) => {
  	if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'A'){
    	const { selected } = this.state
    	const checkbox = document.getElementById(`select-${id}`)
    	checkbox.checked = !checkbox.checked
    	if (checkbox.checked){
    		checkbox.parentElement.parentElement.classList.add("selected");
    		this.setState({
    			selected: selected.concat([id])
    		})
    	} else {
    		document.getElementById('select-all').checked = false
    		checkbox.parentElement.parentElement.classList.remove("selected");
	    	this.setState({
	    		selected: selected.filter(item => item !== id)
	    	})
    	}
  	}
  }

  resetCurrentPage = () => {
  	const allCheckbox = document.getElementById('select-all')
  	try{
      allCheckbox.checked = false
      this.setState({ selected: [] })
    } catch {}
		this.props.getUserList()
    history.push({
      search: ``
    })
  }

  render() {
    const { currentPage, selected } = this.state
    const { selectedDropdownItem } = this.state
    const { userMessages } = this.props
    const selectLabel = `${selected.length} selected`
    const selectDisabled = selected.length === 0 ? true : false
    const {confirmModalTitle, confirmModalBody, confirmModalCallback } = this.state
    const {showConfirmModal, showChangePasswordModal } = this.state
    const { showCreateUserModal } = this.props
    const { userList } = this.props

    const confirmModal = <ConfirmModal show={showConfirmModal} handleConfirm={confirmModalCallback} title={confirmModalTitle} body={confirmModalBody} handleClose={this.handleClose} isMobile={this.props.isMobile} />
    const createUserModal = <CreateUserModal show={showCreateUserModal} handleClose={this.handleClose} item={selectedDropdownItem} handleShowViewUserModal={this.handleShowViewUserModal} isMobile={this.props.isMobile} />
    const changePasswordModal = <ChangePasswordModal show={showChangePasswordModal} handleClose={this.handleClose} item={selectedDropdownItem} isMobile={this.props.isMobile} />

    const modalOpen = showConfirmModal || showCreateUserModal || showChangePasswordModal
    if (modalOpen && this.props.isMobile()){
      if (showConfirmModal) return confirmModal
      else if (showCreateUserModal) return createUserModal
      else if (showChangePasswordModal) return changePasswordModal
    }

    return (
    	<div className="page user-page">
        <Sidebar/>
      	<Navbar fixed="top">
  	    	<Container>
  		    	<h2 className="ml-5 ml-xl-0 d-inline-block">Users</h2><RefreshButton onClick={this.handleRefresh} isRefreshing={userList && userList.length > 0?false:true}/>
  		    	<Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
  				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
  				  <Breadcrumb.Item active>Users</Breadcrumb.Item>
  				</Breadcrumb>
  		    </Container>
      	</Navbar>
      	{confirmModal}{createUserModal}{changePasswordModal}
      	<Container className="pt-5 pb-5 mt-4 mt-sm-5">
          <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
      		{userMessages.map(message => {
            if (message.type === 'changePassword'){
              // return (
              //   <Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
              //     Successfully changed user password.
              //   </Alert>
              // )
            } else if (message.type === 'createUser'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully created user.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'createUser-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued create user.
                </Alert>
              )
            } else if (message.type === 'editUser'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully edited user #{message.item.id}.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'editUser-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued edit user #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'deleteUser'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully deleted user #{message.item.id}.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'deleteUser-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete user #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'massDeleteUser'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully deleted users #{message.id_array.join(', #')}.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'massDeleteUser-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete users #{message.id_array.join(', #')}.
                </Alert>
              )
            } else if (message.type === 'server-error'){
              return (
                <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  {message.error ? 
                  `There was an error: ${message.error}.`
                    :
                  "There was an unexpected error."
                  }
                </Alert>
              )
            } else {
      				return null
      			}
      		})}
      		<Row>
        		<Col xs={6} sm={0} className="d-block d-sm-none">
    	    		<Dropdown id="dropdown-basic-button" title="0 selected" variant="sab" className="mt-3">
      				  <Dropdown.Toggle variant="sab" id="dropdown-basic" className="btn-block" disabled={selectDisabled}>
      				    {selectLabel}
      				  </Dropdown.Toggle>
      					<Dropdown.Menu className="btn-block">
    		    		  <Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
    		    		</Dropdown.Menu>
    	    		</Dropdown>
        		</Col>
        		<Col xs={6} sm={0} className="d-block d-sm-none">
        			<Button disabled={!this.props.isOnline} variant="primary" className="mt-3 btn-block" onClick={this.handleShowCreateUserModal}>Create</Button>
        		</Col>
        		<div className="d-none d-sm-flex float-right mr-0 ml-auto">
    	    		<DropdownButton id="dropdown-basic-button" title={selectLabel} variant="sab" className="float-right align-self-end mr-3" alignRight disabled={selectDisabled}>
    		    		<Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
    	    		</DropdownButton>
        		</div>
        		<div className="d-none d-sm-flex float-right mr-0">
        			<Button disabled={!this.props.isOnline} variant="primary" className="float-right align-self-end mr-3" onClick={this.handleShowCreateUserModal}>Create</Button>
        		</div>
	        </Row>
  	    	<Row className="mt-3">
    	    	<Col sm={12}>
          		<Table striped size="sm">
        			  <thead>
        			    <tr>
        			      <th onClick={this.handleCheckAllToggle}><input id="select-all" type="checkbox" style={{ pointerEvents: "none" }}/></th>
        			      <th>Username/email</th>
        			      <th>First name</th>
                    <th>Last name</th>
                    <th>Role</th>
                    <th>Office</th>
        			      <th></th>
        			    </tr>
        			  </thead>
                <tbody>
                  {userList && userList.length > 0 ?
                    (userMessages && userMessages.length > 0) || (userList && userList.length > 0) ?
                    <>
                      {userMessages.map(message => {
                        if (message.type === 'createAddress'){
                          return this.getUserRecord(message.item, message)
                        } else {
                          return null
                        }
                      })}
                      {this.getUserContent()}
                    </>
                    :
                    <tr style={{ pointerEvents: "none" }}>
                      <td colSpan="7" className="text-center pt-3">
                        <p>No results</p>
                      </td>
                    </tr>
                  :
                    <tr style={{ pointerEvents: "none" }}>
                      <td colSpan="7" className="text-center pt-3">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  }
                </tbody>
        			</Table>
      			</Col>
    			</Row>
  		  </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userList: state.user.userList,
  userMessages: state.user.userMessages,
  showCreateUserModal: state.user.showCreateUserModal,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { getUserList, removeMessage, deleteUser, massDeleteUser, setShowCreateUserModal })(withGetScreen(UserPage));