import React, { Component } from 'react';
import { Container, Button, Row, Col, Navbar, Breadcrumb, Form, Alert, Spinner, ListGroup, InputGroup, FormControl, FormCheck } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import InventorySettingsItemList from './InventorySettingsItemList'

class InventorySettingsRoomList extends Component {

	constructor(props){
		super(props)
		this.state = {
			newRoom: "",
		};
	}

	addRoom = () => {
		const { newRoom } = this.state
		const { rooms } = this.props
		// let new_rooms = [newRoom].concat(rooms)
		if (newRoom !== ""){
			console.log("adding", {name: newRoom, items: []})
			rooms.push({name: newRoom, items: []})
			this.setState({
				newRoom: "",
			}, () => this.props.handleChange(rooms))
		}
	}

	removeRoom = (roomName) => {
		const { rooms } = this.props
		let new_rooms = rooms.filter(room => room.name !== roomName)
		console.log("new_rooms", new_rooms)
		this.props.handleChange(new_rooms)
	}

	moveUp = (room, roomIndex) => {
		const { rooms } = this.props
		// console.log("moveUp", roomName, rooms)
		// let roomIndex = rooms.indexOf(roomName)
		let new_rooms = rooms.filter(fRoom => fRoom.name !== room.name)
		new_rooms.splice(roomIndex-1, 0, room);
		this.props.handleChange(new_rooms)
	}

	moveDown = (room, roomIndex) => {
		const { rooms } = this.props
		// console.log("moveDown", roomName, rooms)
		// let roomIndex = rooms.indexOf(roomName)
		let new_rooms = rooms.filter(fRoom => fRoom.name !== room.name)
		new_rooms.splice(roomIndex+1, 0, room);
		this.props.handleChange(new_rooms)
	}

    handleItemAutoAddChange = (event, roomName) => {
		const { rooms } = this.props
		let new_rooms = rooms.filter(room => {
			if (room.name === roomName){
				room.autoAdd = event.target.checked
			}
			return room
		})
		this.props.handleChange(new_rooms)
    }

	handleOnChange = (event) => {
		this.setState({ 
			[event.target.name]: event.target.value,
		});
	}

  render() {
  	const { newRoom } = this.state
  	const { rooms } = this.props
    return (
    	<>
	      <InputGroup className="mb-2">
	        <FormControl
	          name="newRoom"
	          placeholder="Add new room"
	          aria-label="New Room Name"
	          value={newRoom}
	          onChange={this.handleOnChange}
	        />
	        <InputGroup.Append>
	          <Button variant="primary" onClick={() => this.addRoom(newRoom)}>Add</Button>
	        </InputGroup.Append>
	      </InputGroup>
	      
	        {rooms.map((room, index) => 
		        <ListGroup className="mb-3">
		          <ListGroup.Item variant="info" key={room.name}>
		            <div className="up-down-button mr-3">
		              <div onClick={() => index !== 0 && this.moveUp(room, index)}><FontAwesomeIcon icon={faCaretUp} /></div>
		              <div onClick={() => index !== rooms.length && this.moveDown(room, index)}><FontAwesomeIcon icon={faCaretDown} /></div>
		            </div>
		            <div className="d-inline-block align-top">{room.name}</div>
		            <Button 
		              className="float-right" 
		              variant="danger" 
		              size="sm" 
		              onClick={() => this.removeRoom(room.name)}
		            >Remove room</Button>
		              <div className="w-100 mt-3">
		                <FormCheck>
		                  <FormCheck.Input 
		                    onChange={e => this.handleItemAutoAddChange(e, room.name)}
		                    checked={room.autoAdd}
		                    className="novalidate"
		                    type="checkbox"
		                    name="roomAutoAdd"
		                  />
		                  <FormCheck.Label className="novalidate">Automatically add?</FormCheck.Label>
		                </FormCheck>
		              </div>
		          </ListGroup.Item>
	              <InventorySettingsItemList
	                items={room.items}
	                handleChange={(value) => {
						const { rooms } = this.props
						let new_rooms = rooms.filter(fRoom => fRoom.name !== room.name)
						room.items = value
						new_rooms.splice(index, 0, room);
						this.props.handleChange(new_rooms)
	                }}
	              />
	            </ListGroup>
	        )}
	      
    	</>
    )

  }
} 
export default InventorySettingsRoomList
