import { FETCH_INVENTORIES, PUSH_INVENTORY_TO_OFFLINE_QUEUE, ADD_QUEUE_MESSAGE, ADD_INVENTORY_SERVER_ERROR_MESSAGE, EDIT_INVENTORY, PUSH_OFFLINE_QUEUE, DELETE_INVENTORY, MASS_DELETE_INVENTORY, REMOVE_MESSAGE, CREATE_INVENTORY, PUSH_CHECKOUT_TO_OFFLINE_QUEUE, SAVE_CHECKOUT, CHECKOUT_INVENTORY, ADD_INVENTORY_MESSAGE } from '../actions/types'
import localForage from "localforage";

const initialState = {
    inventoryMessages: [],
	inventories: {},
	inventoriesStale: false,
}

function getMessageID(){
	let id = parseInt(window.localStorage.getItem('message-id') || "0")
	window.localStorage.setItem('message-id', id+1)
	return id
}

export default function(state = initialState, action) {
	// console.log("inventoryReducer action", action)
	let inventories = null
	let inventoryMessages = null
	switch(action.type) {
		case CREATE_INVENTORY:
			action.payload.id = getMessageID()
			return {
				...state,
				inventoriesStale: true,
				inventoryMessages: state['inventoryMessages'].concat([action.payload]).slice(-3),
			}
			/* falls through */
		case CHECKOUT_INVENTORY:
			// Update existing check
			inventories = Object.assign({}, state['inventories'])
			// Object.keys(inventories).forEach((page) => {
			// 	inventories[page].results.forEach((item, i) => {
			// 		if (item.id === action.payload.item.id)	inventories[page].results[i] = action.payload.item
			// 	})
			// })
			
			// Delete autosave
			// deleteAutoSave(action.payload.item.id)
			// Update messages
			inventoryMessages = state['inventoryMessages']
			// inventoryMessages.forEach((message) => {
			// 	if (message.item && message.item.id && message.item.id === action.payload.item.id){
			// 		message.item = action.payload.item
			// 	}
			// })
			action.payload.id = getMessageID()
			return {
				...state,
				inventories: inventories,
				inventoriesStale: true,
				inventoryMessages: inventoryMessages.concat([action.payload]).slice(-3),
			}
			/* falls through */
		case EDIT_INVENTORY:
			// Update existing check
			inventories = Object.assign({}, state['inventories'])
			Object.keys(inventories).forEach((page) => {
				inventories[page].results.forEach((item, i) => {
					if (item.id === action.payload.item.id)	inventories[page].results[i] = action.payload.item
				})
			})

			// Copy property_manager onto other inventory addresses
			if (action.payload.item.address && action.payload.item.property_manager) {
				// console.log("has address & property_manager", action.payload.item)
				Object.keys(inventories).forEach((page) => {
					inventories[page].results.forEach((item, i) => {
						if (item.address === action.payload.item.address){
							inventories[page].results[i].address_full = action.payload.item.address_full
						}
					})
				})
				inventoryMessages.forEach((message) => {
					if (message.item && message.item.address === action.payload.item.address){
						message.item.address_full = action.payload.item.address_full
					}
				})
			}
			// Delete autosave
			// deleteAutoSave(action.payload.item.id)
			// Update messages
			inventoryMessages = state['inventoryMessages']
			inventoryMessages.forEach((message) => {
				if (message.item && message.item.id && message.item.id === action.payload.item.id){
					message.item = action.payload.item
				}
			})
			action.payload.id = getMessageID()
			return {
				...state,
				inventoriesStale: true,
				inventories: inventories,
				inventoryMessages: inventoryMessages.concat([action.payload]).slice(-3),
			}
			/* falls through */
		case FETCH_INVENTORIES:
			if (action.clearResults === true){
				return {
					...state,
					inventoriesStale: false,
					inventories: {
				        [action.params.page]: action.payload
				      },
				}
			} else {
				return {
					...state,
					inventoriesStale: false,
					inventories: Object.assign({}, state['inventories'], {
				        [action.params.page]: action.payload
				      }),
				}
			}
			/* falls through */
		case DELETE_INVENTORY:
			// console.log("DELETE_INVENTORY", DELETE_INVENTORY)
			inventories = Object.assign({}, state['inventories'])
			// console.log("old inventories", state['inventories'])
			// console.log("action", action)
			inventoryMessages = state['inventoryMessages'].filter(message =>!message.item || message.item.id !== action.payload.item.id)
			Object.keys(inventories).forEach((page) => {
				inventories[page].results.forEach((item, i) => {
					// console.log("checking item", i, item)
					if (item.id === action.payload.item.id)	{
						// console.log("splicing")
						inventories[page].results.splice(i, 1)
						// console.log("spliced page", inventories[page].results)
					}
				})
			})
			// console.log("final inventories", inventories)
			action.payload.id = getMessageID()
			return {
				...state,
				inventories: inventories,
				inventoryMessages: inventoryMessages.concat([action.payload]).slice(-3),
			}
			/* falls through */
		case MASS_DELETE_INVENTORY:
			inventories = Object.assign({}, state['inventories'])
			inventoryMessages = state['inventoryMessages']
			action.payload.id_array.forEach(removeID => {
				inventoryMessages = inventoryMessages.filter(message =>!message.item || message.item.id !== removeID)
				Object.keys(inventories).forEach((page) => {
					inventories[page].results.forEach((item, i) => {
						if (removeID === item.id) inventories[page].results.splice(i, 1)
					})
				})
			})
			action.payload.id = getMessageID()
			return {
				...state,
				inventories: inventories,
				inventoryMessages: inventoryMessages.concat([action.payload]).slice(-3),
			}
			/* falls through */
		case REMOVE_MESSAGE:
			inventoryMessages = state['inventoryMessages'].filter(message => message.id !== action.payload)
			return {
				...state,
				inventoryMessages,
			}
			/* falls through */
		case ADD_QUEUE_MESSAGE:
			return {
				...state,
				inventoryMessages: state['inventoryMessages'].concat([action.payload]).slice(-3),
			}
			/* falls through */
		case ADD_INVENTORY_MESSAGE:
			if (!action.payload.id) action.payload.id = getMessageID()
			return {
				...state,
				inventoryMessages: state['inventoryMessages'].concat([action.payload]).slice(-3),
			}
		case ADD_INVENTORY_SERVER_ERROR_MESSAGE:
			action.payload.id = getMessageID()
			return {
				...state,
				inventoryMessages: state['inventoryMessages'].concat([action.payload]).slice(-3),
			}
			/* falls through */
		default:
			return state
	}
}