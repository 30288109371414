import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form, FormCheck, Tab, Tabs } from 'react-bootstrap'
import { checkoutInventory } from '../redux/actions/inventoryActions'
import { saveCheckout, incrementOfflineFileCount } from '../redux/actions/offlineActions'
import { connect } from 'react-redux'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import { api_url } from '../api_url'
import { stringify } from 'query-string';
import DatePicker from "react-datepicker";
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'
import ConfirmModal from '../modals/ConfirmModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faCamera, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons'
import ViewInventoryModal from '../modals/ViewInventoryModal'
import SignaturePad from 'signature_pad'
import { canvasToBlob } from 'blob-util'
import localForage from "localforage";
import { getBase64Strings } from 'exif-rotate-js/lib';
import cloneDeep from 'lodash/cloneDeep';

let signaturePad = null
const imgPrefix = ''

class CheckoutInventoryModal extends ViewInventoryModal {

  constructor(props){
    super(props)
    this.state = this.getDefaultState()
  }

  getDefaultState = () => {
    let itemID = null
    if (this.props.item){
      itemID = this.props.item.id
    } else {
      itemID = history.location.hash.split('-').pop()
    }
    return {
      item: null,
      id: itemID,
      validated: false,
      validationErrors: '',
      checkoutRooms: [],
      tenancy_end_date: null,
      property_visit_date: null,

      electricity_meter_readings_checkout: "",
      gas_meter_readings_checkout: "",
      water_meter_readings_checkout: "",

      signatureMethod: "draw",
      check_out_signature: "",
      signatureIsValid: false,

      hasChanges: false,
      displayConfirmation: false,
      signatureInitialised: false,
      newInventoryAutosaveName: null,
    }
  }

  getTitle() {
    let {item} = this.state
    if (this.props.item && !item) item = this.props.item
    return `Inventory Checkout #${item.id}`
  }

  handleSubmit(event) {
    event.preventDefault();
    const {checkoutRooms, electricity_meter_readings_checkout, gas_meter_readings_checkout, water_meter_readings_checkout} = this.state
    let { item } = this.state
    if (this.props.item) item = this.props.item
    console.log("handleSubmit checkoutRooms", checkoutRooms)
    const checkoutAutosaveName = this.getAutosaveName()
    const form = event.currentTarget;
    event.preventDefault();
    const { check_out_signature, signatureMethod } = this.state
    let submitValid = true
    if (check_out_signature.length === 0 && (!signaturePad || signaturePad.isEmpty())) submitValid = false
    if (form.checkValidity() === false || !submitValid) {
      console.log("invalid")
      event.stopPropagation();
      this.setValidatedErrors()
      this.setState({ validated: true });
    } else {
      console.log("valid")

      let tenancy_end_date = null
      if (this.state.tenancy_end_date){
        let d = this.state.tenancy_end_date
        console.log("d", d)
        tenancy_end_date = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      }

      let property_visit_date = null
      if (this.state.property_visit_date){
        let d = this.state.property_visit_date
        console.log("d", d)
        property_visit_date = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      }

      let rooms = cloneDeep(checkoutRooms)
      for (const roomItem of rooms) {
          if (roomItem.checkout_images && roomItem.checkout_images.length > 0){
            for (const imageItem of roomItem.checkout_images) {
              if (imageItem.dateTaken){
                let d = imageItem.dateTaken
                imageItem.dateTaken = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
              }
            }
          }
      }

      let validatedData = {
        id: item.id,
        rooms: rooms,
        tenancy_end_date: tenancy_end_date,
        property_visit_date: property_visit_date,
        electricity_meter_readings_checkout,
        gas_meter_readings_checkout,
        water_meter_readings_checkout,
      }

      if (signatureMethod === 'draw' && signaturePad){

        let canvas = document.querySelector("canvas")
        canvasToBlob(canvas, 'image/png').then(blob => {
          blob.name = "signature.png"
          validatedData.check_out_signature_image = blob

          console.log("validatedData", validatedData)
          this.props.checkoutInventory(validatedData, checkoutAutosaveName)
          this.setState({ hasChanges: false, promptLoadSave: false, hasPromptedLoadSave: false, validationErrors: '' })
          this.props.handleClose()
        })
      } else {

        console.log("validatedData", validatedData)
        this.props.checkoutInventory(validatedData, checkoutAutosaveName)
        this.setState({ hasChanges: false, promptLoadSave: false, hasPromptedLoadSave: false, validationErrors: '' })
        this.props.handleClose()
      }
    }
    // this.setState({ validated: true });
  }

  setValidatedErrors = () => {
    let message = ""
    const {checkoutRooms, electricity_meter_readings_checkout, gas_meter_readings_checkout, water_meter_readings_checkout} = this.state
    let { item } = this.state
    if (this.props.item) item = this.props.item
    const { check_out_signature, signatureMethod } = this.state
    if (check_out_signature.length === 0 && (!signaturePad || signaturePad.isEmpty())){
      message = "Signature is required."
    } else if (!electricity_meter_readings_checkout || electricity_meter_readings_checkout.length === 0){
      message = "Electricity readings field required."
    }
    else if (!item.no_gas_oil_meter){
      if (!gas_meter_readings_checkout || gas_meter_readings_checkout.length === 0){
        message = "Gas/oil readings field required."
      }
    }
    else if (!item.no_water_meter){
      if (!water_meter_readings_checkout || water_meter_readings_checkout.length === 0){
        message = "Water readings field required."
      }
    }
    this.setState({validationErrors: message})
    // return <p className="mr-3 text-danger">{message}</p>
  }


  getAutosaveName = () => {
    const { checkoutAutosaveName } = this.props
    const { newCheckoutAutosaveName } = this.state
    if (checkoutAutosaveName) return checkoutAutosaveName
    else return newCheckoutAutosaveName
  }

  saveImage = (imageFile) => {
    const {offlineFileCount} = this.props
    const myFileID = offlineFileCount+1
    let reader = new FileReader();
    reader.onload = function(){
      localForage.setItem(`file ${myFileID}`, reader.result)
    };
    reader.readAsDataURL(imageFile);
    this.props.incrementOfflineFileCount()
    return myFileID
  }

  saveChanges = () => {
    console.log("saveChanges")
    const checkoutAutosaveName = this.getAutosaveName()
    const savedState = cloneDeep(this.state)
    console.log("savedState", savedState)

    // Remove File objects
    for (const roomItem of savedState.checkoutRooms) {
      if (roomItem.checkout_images && roomItem.checkout_images.length > 0){
        for (const imageItem of roomItem.checkout_images) {
          if (imageItem.autosaveID){
            delete imageItem.url
          }
        }
      }
    }

    let {item} = this.state
    if (!item) item = this.props.item

    let displayAddress = null
    if (item && item.address_full){
      displayAddress = [
        item.address_full.tenant_address_line_1, 
        item.address_full.tenant_address_line_2, 
        item.address_full.tenant_town_city, 
        item.address_full.tenant_county, 
        item.address_full.tenant_postcode
      ].filter(function (el) {
        return el !== null && el !== "";
      }).join(', ');
    }

    let validatedData = {
      id: savedState.id,
      displayAddress,
      checkoutRooms: savedState.checkoutRooms,
      tenancy_end_date: savedState.tenancy_end_date,
      property_visit_date: savedState.property_visit_date,
      electricity_meter_readings_checkout: savedState.electricity_meter_readings_checkout,
      gas_meter_readings_checkout: savedState.gas_meter_readings_checkout,
      water_meter_readings_checkout: savedState.water_meter_readings_checkout,
      check_out_signature: savedState.check_out_signature,
      signatureMethod: savedState.signatureMethod,
      check_out_signature_image: savedState.check_out_signature_image,
    }

    console.log("validatedData", validatedData)

    this.props.saveCheckout(validatedData, checkoutAutosaveName)
  }

  getSubmitButton() {
    const autosaveName = this.getAutosaveName()
    let autosaveNumber = ''
    if (autosaveName && autosaveName.split('_').length === 4) autosaveNumber = parseInt(autosaveName.split('_')[3])-1
    const {item, validated, validationErrors} = this.state
    return <>
      {item && 
        <input type="hidden" name="id" value={item.id} />
      }
      <div className="mr-2">Changes saved to autosave #{autosaveNumber}</div>
      {validated && <p className="mr-3 text-danger">{validationErrors}</p>}
      <Button disabled={!this.props.isOnline} type="submit">Save</Button>
    </>
  }

  handleCheckoutRoomsChange = (e, room) => {
    let { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    if (checkoutRoom){
      checkoutRoom.checkoutcomments = e.target.value
    }
    this.setState({
      checkoutRooms,
      hasChanges: true,
    }, () => this.saveChanges()) 
  }

  clearFileInput = (ctrl) => {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  dataURLtoFile = (dataURI, filename) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: 'image/jpeg' });
  }

  handleDrop = (e, room, hasImages) => {
    console.log("handleDrop")
    // e.preventDefault();
    document.querySelectorAll(`.drag`).forEach(element => element.classList.remove("drag"))
    hasImages?this.handleRoomCheckoutImageAdd(e, room):this.handleRoomCheckoutImageChange(e, room)
  }

  handleDragEnter = (e, room) => {
    console.log("handleDragEnter", e.target, e.clientX, e.target.id)
    e.preventDefault()
    e.stopPropagation()
      let itemElement = document.getElementById(`checkout-room-${room.id}`)
      itemElement.classList.add("drag")
  }
  handleDragLeave = (e, room) => {
    console.log("handleDragLeave", e.target, e.clientX, e.target.id)
    e.stopPropagation()
    if (e.target.id === `checkout-room-${room.id}`){
      let itemElement = document.getElementById(`checkout-room-${room.id}`)
    document.querySelectorAll(`.drag`).forEach(element => element.classList.remove("drag"))
    }
  }

  handleRoomCheckoutImageAdd = (event, room) => {
    console.log("handleRoomCheckoutImageAdd", event.target)
    let { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    if (checkoutRoom){
      // checkoutRoom.checkoutcomments = e.target.value
      let images = []
      let files = []
      if (event.target.files){
        files = Array.from(event.target.files)
      } else if (event.dataTransfer) {
        files = Array.from(event.dataTransfer.files)
      }
      this.clearFileInput(event.target)
      let thisComp = this
      files && files.length > 0 && Promise.all(
        files.map(file => 
          new Promise(async (resolve, reject) => {
            if (file){
              let fileName = file.name.replace(/\.[^/.]+$/, "")+".jpeg"
              const data = await getBase64Strings([file], { maxSize: 600 });
              const newFile = thisComp.dataURLtoFile(data[0], fileName)
              const autosaveID = thisComp.saveImage(newFile)
              images.push({url: newFile, autosaveID, dateTaken: new Date()})
              resolve()
            } else {
              resolve()
            }
          })
        )
      ).then(() => {
        checkoutRoom.checkout_images = checkoutRoom.checkout_images.concat(images)
        thisComp.setState({
          checkoutRooms,
          hasChanges: true,
        }, () => this.saveChanges())
      })
    }
  }

  handleRoomCheckoutImageChange = (event, room) => {
    console.log("handleRoomCheckoutImageChange", event.target)
    let { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    console.log("checkoutRoom", checkoutRoom)
    if (checkoutRoom){
      let images = []
      let files = []
      if (event.target.files){
        files = Array.from(event.target.files)
      } else if (event.dataTransfer) {
        files = Array.from(event.dataTransfer.files)
      }
      this.clearFileInput(event.target)
      let thisComp = this
      files && files.length > 0 && Promise.all(
        files.map(file => 
          new Promise(async (resolve, reject) => {
            let fileName = file.name.replace(/\.[^/.]+$/, "")+".jpeg"
            const data = await getBase64Strings([file], { maxSize: 600 });
            const newFile = thisComp.dataURLtoFile(data[0], fileName)
            const autosaveID = thisComp.saveImage(newFile)
            images.push({url: newFile, autosaveID, dateTaken: new Date()})
            resolve()
          })
        )
      ).then(() => {
        checkoutRoom.checkout_images = checkoutRoom.checkout_images.concat(images)
        console.log("resolved images", images)
        thisComp.setState({
          checkoutRooms,
          hasChanges: true,
        }, () => this.saveChanges())
      })
    }
  }

  setImageURL = (room, imageIndex, image) => {
    const { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    if (checkoutRoom){
      if (checkoutRoom.checkout_images[imageIndex].autosaveID) localForage.setItem(`file ${checkoutRoom.checkout_images[imageIndex].autosaveID}`, image)
      checkoutRoom.checkout_images[imageIndex].url = image
    }
    this.setState({ checkoutRooms, hasChanges: true }, () => this.saveChanges())
  }

  deleteImage = (room, imageIndex) => {
    const { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    if (checkoutRoom){
        let foundImage = checkoutRoom.checkout_images.find((image, index) => index === imageIndex)
        if (foundImage.autosaveID) localForage.removeItem(`file ${foundImage.autosaveID}`)
        checkoutRoom.checkout_images = checkoutRoom.checkout_images.filter((image, index) => index !== imageIndex)
    }
    this.setState({ checkoutRooms, hasChanges: true }, () => this.saveChanges())
  }

  findCheckoutImage = (room, imageIndex) => {
    const { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    let image = null
    if (checkoutRoom){
      image = checkoutRoom.checkout_images[imageIndex]
    }
    return image
  }

  rotateNewImage = (room, imageIndex) => {
    const { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    console.log("room, imageIndex", room, imageIndex)
    let image = this.findCheckoutImage(room, imageIndex)
    
    console.log("image", image)
    let imageName = ""
    let imageURL = image.url
    if (typeof image.url === 'string'){
      imageURL = imgPrefix+image.url
      imageName = image.url.split('/').pop().split('.')[0]
    } else {
      imageName = image.url.name
    }
    console.log("imageURL, imageName", imageURL, imageName)
    window.loadImage(
      imageURL,
      (img) => {
        console.log("img", img, typeof img)
        canvasToBlob(img, 'image/jpeg').then(blob => {
          blob.name = imageName
          console.log("blob", blob)
          this.setImageURL(room, imageIndex, blob)
        })
      },
      { orientation: 6, canvas: true }
    );
  }

  onImageError = e => {
    console.log("onImageError", e.target)
    var element = document.createElement("div");
    element.innerHTML = "<b>Image unavailable.</b><p class=\"small\">This image cannot be edited offline or the link has expired.</p>";
    element.setAttribute("role", "alert");
    element.setAttribute("class", "fade alert alert-info show");
    e.target.parentNode.parentNode.parentNode.replaceChild(element, e.target.parentNode.parentNode)
  }

  roomCheckoutImage = (url, alt, rotateMethod, removeMethod, imageItem, setDateMethod) => 
  <div className="mb-3">
    <div className="position-relative ">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={url} className="img-fluid" alt={alt} style={{ minWidth: '100%' }} onError={this.onImageError} />
      </a>
      <FontAwesomeIcon icon={faTrash} className="position-absolute fixed-top remove-image-button" onClick={removeMethod} />
      {rotateMethod && <FontAwesomeIcon icon={faUndo} className="position-absolute fixed-bottom rotate-button" onClick={rotateMethod} />}
    </div>
    {setDateMethod && 
      <DatePicker
        className="form-control "
        dateFormat="dd-MM-yyyy"
        isClearable={true}
        selected={imageItem.dateTaken}
        onChange={(d) => setDateMethod(d)}
        onFocus={(e) => e.target.readOnly = true}
        onBlur={(e) => e.target.readOnly = false}
      />
    }
  </div>

  setImageDate = (room, imageIndex, d) => {
    const { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    if (checkoutRoom){
      checkoutRoom.checkout_images[imageIndex].dateTaken = d
    }
    this.setState({ checkoutRooms, hasChanges: true }, () => this.saveChanges())
  }


  getCheckoutComments(room) {
    const { checkoutRooms } = this.state
    const checkoutRoom = checkoutRooms.find(c => c.id === room.id)
    const fileFieldName = `room_${room.id}_checkoutimage`
    const hasImages = checkoutRoom && checkoutRoom.checkout_images.length > 0

    const roomCheckoutImageItem = (imageItem, imageIndex) => 
      imageItem.url && typeof imageItem.url !== 'number' && (typeof imageItem.url === 'string' ? 
        this.roomCheckoutImage(
          imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:'), 
          room.comment, 
          null,// () => this.rotateNewImage(room, imageIndex),
          () => this.setImageURL(room, imageIndex, null),
          imageItem,
          null)
      :
        this.roomCheckoutImage(
          URL.createObjectURL(imageItem.url),
          imageItem.url.name, 
          () => this.rotateNewImage(room, imageIndex),
          () => this.deleteImage(room, imageIndex),
          imageItem,
          (d) => this.setImageDate(room, imageIndex, d))
      )

    return <tr className="inventory-comments-box"><td colspan="3"><div
          id={`checkout-room-${room && room.id}`} 
          onDragEnter={e => this.handleDragEnter(e, room)}
          onDragOver={e => this.handleDragEnter(e, room)}
          onDragLeave={e => this.handleDragLeave(e, room)}
          onDrop={(e) => this.handleDrop(e, room, hasImages)}>
          
      <Row className="mb-2">
        <Col sm={11}><strong>Checkout comments:</strong><br/></Col>
        <Col sm={1}>
            <>
              <label htmlFor={fileFieldName} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`[name=${fileFieldName}]`).click()}>
                  <FontAwesomeIcon icon={faCamera} />
              </label>
              <input name={fileFieldName} type="file" className="mb-3 d-none" onChange={(e) => hasImages?this.handleRoomCheckoutImageAdd(e, room):this.handleRoomCheckoutImageChange(e, room)} multiple/>
            </>
        </Col>
      </Row>
      <Form.Control 
        type="textarea" 
        as="textarea"
        name="checkoutcomments"
        value={checkoutRoom && checkoutRoom.checkoutcomments && checkoutRoom.checkoutcomments}
        rows={2}
        onChange={(e) => this.handleCheckoutRoomsChange(e, room)}
        placeholder="Enter text"
      />

      <Row className="mt-3">
        {checkoutRoom && checkoutRoom.checkout_images && checkoutRoom.checkout_images.length > 0 && 
          checkoutRoom.checkout_images.map((imageItem, imageIndex) => 
            imageItem && imageItem.url && <Col sm={4}>{roomCheckoutImageItem(imageItem, imageIndex)}</Col>
          ) 
        }
      </Row>
          <div colspan="3" className="draggableArea">Drag photos here <FontAwesomeIcon className="ml-2" icon={faCamera} /></div>
      </div>
    </td>
    </tr>
  }

  handleDateChange = (d, fieldname) => {
    this.setState({
      [fieldname]: d,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  getCheckoutElectricityReadings(item) {
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const { electricity_meter_readings_checkout } = this.state
    console.log("getCheckoutElectricityReadings electricity_meter_readings_checkout", electricity_meter_readings_checkout)
    return <div>
        <div>
          Move-out readings *:
        </div>
          <Form.Control 
            type="textarea" 
            as="textarea"
            name="electricity_meter_readings_checkout"
            value={electricity_meter_readings_checkout}
            rows={2}
            onChange={this.handleOnChange} 
            required={true}
          />
      </div>
  }

  getCheckoutGasReadings(item) {
    if (item.no_gas_oil_meter){
      return null
    }
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const { gas_meter_readings_checkout } = this.state
    return <div>
        <div>
          Move-out readings *:
        </div>
          <Form.Control 
            type="textarea" 
            as="textarea"
            name="gas_meter_readings_checkout"
            value={gas_meter_readings_checkout}
            rows={2}
            onChange={this.handleOnChange} 
            required={true}
          />
      </div>
  }

  getCheckoutWaterReadings(item) {
    if (item.no_water_meter){
      return null
    }
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const { water_meter_readings_checkout } = this.state
    return <div>
        <div>
          Move-out readings *:
        </div>
          <Form.Control 
            type="textarea" 
            as="textarea"
            name="water_meter_readings_checkout"
            value={water_meter_readings_checkout}
            rows={2}
            onChange={this.handleOnChange} 
            required={true}
          />
      </div>
  }

  getTenancyEndDate(item) {
    const {tenancy_end_date} = this.state
    return <div className="d-inline-block" style={{ width: '100%' }}>
      <DatePicker
        className="form-control "
        dateFormat="dd-MM-yyyy"
        isClearable={true}
        selected={tenancy_end_date}
        onChange={(d) => this.handleDateChange(d, 'tenancy_end_date')}
        onFocus={(e) => e.target.readOnly = true}
        onBlur={(e) => e.target.readOnly = false}
      />
    </div>
  }

  getPropertyVisitDate(item) {
    const {property_visit_date} = this.state
    return <div className="d-inline-block" style={{ width: '100%' }}>
      <DatePicker
        className="form-control "
        dateFormat="dd-MM-yyyy"
        isClearable={true}
        selected={property_visit_date}
        onChange={(d) => this.handleDateChange(d, 'property_visit_date')}
        onFocus={(e) => e.target.readOnly = true}
        onBlur={(e) => e.target.readOnly = false}
      />
    </div>
  }

  getTenancyEndDateFieldName() {
    return 'Tenancy End Date'
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  onShow() {
  	console.log("CheckoutInventoryModal, onShow", this.props)
    const { hasChanges, validated } = this.state
    // let { item } = this.state
    let item = null
    if (this.props.item) item = this.props.item

    const { checkoutAutosave, checkoutAutosaveName } = this.props
    let thisComp = this
    let itemID = item && item.id ? item.id : 0
    if (!checkoutAutosaveName){
      localForage.keys().then(function(keys) {
        const autosaves = keys.filter(key => key === `save_checkout_${itemID}` || key.startsWith(`save_checkout_${itemID}_`))
        if (!autosaves){
          autosaves = []
        }
        let proposedNumber = 2
        let nameValid = false
        while (!nameValid){
          let exists = autosaves.find(autosaveItem => autosaveItem === `save_checkout_${itemID}_${proposedNumber}`)
          if (!exists){
            nameValid = true
            thisComp.setState({
              newCheckoutAutosaveName: `save_checkout_${itemID}_${proposedNumber}`,
            })
          } else {
            proposedNumber++
          }
        }
      })
    }

    this.setState({
      validated: false,
    })
    this.initialiseSignaturePad()
    if (checkoutAutosave) {
      this.loadSave()
    } else if (item) {
      this.setItemState(item)
      // this.setState(this.getDefaultState(item))
    } else {
      this.setState(this.getDefaultState())
    }
    super.onShow()
  }

  loadSave = () => {
    console.log("loadSave")
    let itemID = null
    if (this.props.item){
      itemID = this.props.item.id
    } else {
      itemID = history.location.hash.split('-').pop()
    }

    let result = this.props.checkoutAutosave
    if (result){
      console.log("result", result)
      let thisComp = this
      let getImages = async (result) => {
        let saveItem = JSON.parse(result)
        async function forageImages(images){
          if (images && images.length > 0){
            for (const imageItem of images) {
              if (imageItem.autosaveID){
                imageItem.url = await localForage.getItem(`file ${imageItem.autosaveID}`)
                if (imageItem.dateTaken) imageItem.dateTaken = new Date(imageItem.dateTaken)
                if (typeof imageItem.url === 'string'){
                  let arrayBufferBlob = new Blob([imageItem.url], {type: "text/plain"})
                  imageItem.url = thisComp.dataURLtoFile(imageItem.url, 'savedFile.jpeg')
                }
              }
            }
          }
          return {result: images}
        }

        for (const roomItem of saveItem.checkoutRooms) {
          let saveRoomItems = await forageImages(roomItem.checkout_images)
          roomItem.checkout_images = saveRoomItems.result
        }

        console.log("final saveItem", saveItem)
        return saveItem
      }

      getImages(result).then(async (saveItem) => {
        console.log("promise saveItem", saveItem)
        this.setState({
          id: itemID,
          validated: false,
          displayConfirmation: false,
          hasChanges: false,
          checkoutRooms: saveItem.checkoutRooms,
          tenancy_end_date: saveItem.tenancy_end_date ? new Date(saveItem.tenancy_end_date) : null,
          property_visit_date: saveItem.property_visit_date ? new Date(saveItem.property_visit_date) : null,
          electricity_meter_readings_checkout: saveItem ? saveItem.electricity_meter_readings_checkout : "",
          gas_meter_readings_checkout: saveItem ? saveItem.gas_meter_readings_checkout : "",
          water_meter_readings_checkout: saveItem ? saveItem.water_meter_readings_checkout : "",
          signatureInitialised: false,
        })
      })
    }
    
  }

  setItemState = item => {
    console.log("setItemState", item)
    let checkoutRooms = []
    if (item) checkoutRooms = item.rooms.map(room => {return {id: room.id, checkoutcomments: room.checkoutcomments ? room.checkoutcomments : "", checkout_images: room.checkout_images ? room.checkout_images : []}})

    let tenancy_end_date = null
    if (item && item.tenancy_end_date){
      let split_tenancy_end_date = item.tenancy_end_date.split('-')
      tenancy_end_date = new Date(`${split_tenancy_end_date[2]}-${split_tenancy_end_date[1]}-${split_tenancy_end_date[0]}`)
    }

    let property_visit_date = null
    if (item && item.property_visit_date){
      let split_property_visit_date = item.property_visit_date.split('-')
      property_visit_date = new Date(`${split_property_visit_date[2]}-${split_property_visit_date[1]}-${split_property_visit_date[0]}`)
    }

    // if (!hasChanges){
      this.setState({
        item,
        id: item.id,
        validated: false,
        displayConfirmation: false,
        hasChanges: false,
        checkoutRooms,
        tenancy_end_date,
        property_visit_date,
        electricity_meter_readings_checkout: item && item.electricity_meter_readings_checkout ? item.electricity_meter_readings_checkout : "",
        gas_meter_readings_checkout: item && item.gas_meter_readings_checkout ? item.gas_meter_readings_checkout : "",
        water_meter_readings_checkout: item && item.water_meter_readings_checkout ? item.water_meter_readings_checkout : "",
        signatureInitialised: false,
      })
    // }
  }

  initialiseSignaturePad = () => {
    const { signatureInitialised } = this.state
    console.log("initialiseSignaturePad")
    if (!signatureInitialised) {
        this.setState({
          signatureInitialised: true,
        }, () => {
          var canvas = document.querySelector("canvas");
          console.log("initialiseSignaturePad, canvas", canvas)
          if (canvas) {
            canvas.width = canvas.parentElement.offsetWidth
            canvas.height = canvas.parentElement.offsetWidth / 2
            signaturePad = new SignaturePad(canvas);
          }
        })
    }
  }

  handleOnChange = (event) => {
  	this.setState({ 
      [event.target.name]: event.target.value,
      hasChanges: true,
    }, () => this.saveChanges());
  }
  handleCheck = (event) => {
  	console.log("duplicate_previous_check", this.state.duplicate_previous_check)
     this.setState({ 
      [event.target.name]: !this.state.duplicate_previous_check,
      hasChanges: true,
    }, () => this.saveChanges());
  }

    handleAppointmentDateChange = (d) => {
      console.log("date", d)
      // let formatted = null
      // if (d) formatted = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      this.setState({
        appointment_date: d,
        hasChanges: true,
      }, () => this.saveChanges())
    }

    onKeyDown = (event) => {
      if (event.which === 13 || event.keyCode === 13){
      	console.log("submit", event.target)
      	event.target.blur()
      }
    }

    onFocus = () => {
    	this.setState({
    		searchIsVisible: true,
    	})
    }

    onBlur = () => {
    	this.setState({
    		searchIsVisible: false,
    	})
    }

    onChange = (query) => {
    	console.log("onChange", query)
      if (query[0] && query[0].property_manager){
        this.setState({
          assigned_to: query[0].property_manager,
        }, () => this.saveChanges())
      }
    	this.setState({
        hasChanges: true,
    		address: query[0],
    		searchIsVisible: false,
    		searchSelected: query,
    	}, () => {
    		// this.resetCurrentPage()
    	})

    }

  handleClose = () => {
    const { displayConfirmation, hasChanges } = this.state
    if (displayConfirmation || !hasChanges){
      this.setState({ 
        displayConfirmation: false,
        hasChanges: false,
        item: null,
       })
      this.props.handleClose()
    } 
    else this.setState({ displayConfirmation: true })
  }

  getCheckoutSignature(){
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const { signatureMethod, validated, check_out_signature } = this.state
    return (
      <Form.Group as={Row} className="validate-me">
        <Form.Label column sm={sm1} className={classes1}>
          Check out signature *
        </Form.Label>
        <Col sm={4}>
          <Tabs variant="pills" className="mb-2" activeKey={signatureMethod} onSelect={k => this.setState({ signatureMethod: k})}>
            <Tab eventKey="draw" title="Draw">
              <canvas 
                id="signature-pad" 
                className={
                  (validated && signaturePad &&
                    !signaturePad.isEmpty() ? "is-valid" : "is-invalid")+" novalidate signature-pad"
                }
              />
              <Button variant="secondary" onClick={() => signaturePad && signaturePad.clear()}>Clear</Button>
            </Tab>
            <Tab eventKey="type" title="Type name">
              <Form.Control 
                type="text" 
                name="check_out_signature"
                value={check_out_signature}
                onChange={this.handleOnChange}
                className={
                  (validated &&  
                    (check_out_signature.length > 0 ? "is-valid" : "is-invalid"))+" novalidate"
                }
              />
            </Tab>
          </Tabs>
        </Col>
      </Form.Group>
      )
  }

  render(){
    const thisComp = this
      // thisComp.initialiseSignaturePad()
    if (this.props.show && !this.props.isMobile()){
      window.requestAnimationFrame(function() {
        window.addEventListener('mouseup', (e) => e.preventDefault());
        window.addEventListener('dragenter', (e) => {
          e.preventDefault()
        });
        window.addEventListener('dragover', (e) => e.preventDefault());
        window.addEventListener('drop', (e) => e.preventDefault());
        window.addEventListener('dragleave', (e) => {
          e.preventDefault()
        });
      })
    }
    this.setInputReadOnly()
    const { displayConfirmation } = this.state
    if (displayConfirmation){
      return <ConfirmModal cancelText={"Go back"} show={this.props.show} handleConfirm={this.handleClose} title={"Discard changes?"} body={"Are you sure you want to discard current changes?"} handleClose={() => this.setState({ displayConfirmation: false })} isMobile={this.props.isMobile} />
    }
    else if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return super.render()
    }
  }

  setInputReadOnly = () => {
    window.requestAnimationFrame(function() {
      document.querySelectorAll('.react-datepicker__input-container input').forEach(element => element.readOnly = true)
    });
  }
}

const mapStateToProps = state => ({
  userList: state.user.userList,
  offlineFileCount: state.offline.offlineFileCount,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { checkoutInventory, saveCheckout, incrementOfflineFileCount })(CheckoutInventoryModal);
