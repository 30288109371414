import React, { Component } from 'react';
import { Container, Button, Row, Col, Navbar, Breadcrumb, Form, Alert, Spinner, ListGroup, InputGroup, FormControl } from 'react-bootstrap'
import { fetchSettings, submitSettings, removeMessage } from '../redux/actions/settingsActions'
import { connect } from 'react-redux'
import {withGetScreen} from 'react-getscreen'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import RefreshButton from '../components/RefreshButton'
import RoomOrderList from '../components/RoomOrderList'

class SettingsPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      midterm_declaration: "",
      disclaimer: "",
      room_names: [],
      default_rooms: [],
      validated: false,
    };
  }

  componentWillMount() {
  	this.props.fetchSettings()
  }

  onRefresh = () => {
    this.setState({
      midterm_declaration: "",
      disclaimer: "",
      room_names: [],
      default_rooms: [],
      validated: false,
    });
    this.props.fetchSettings()

  }

  componentWillReceiveProps(nextProps) {
  	if (nextProps.settings){
  		this.setState({
        midterm_declaration: nextProps.settings.midterm_declaration,
        disclaimer: nextProps.settings.disclaimer,
        room_names: nextProps.settings.room_names,
        default_rooms: nextProps.settings.default_rooms,
  		})
  	}
  }

  handleOnChange = (event) => {
    this.setState({ 
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const { midterm_declaration, disclaimer, room_names, default_rooms } = this.state
    this.props.submitSettings(midterm_declaration, disclaimer, room_names, default_rooms)
  }

  handleRoomNamesChange = (room_names) => {
    this.setState({ room_names })
  }

  handleDefaultRoomsChange = (default_rooms) => {
    this.setState({ default_rooms })
  }


  render() {

    const { validated, midterm_declaration, disclaimer, room_names, default_rooms } = this.state
    const { isLoading, settingsMessages } = this.props
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end"

    return (
    	<div className="page settings-page">
        <Sidebar/>
      	<Navbar fixed="top">
  	    	<Container>
  		    	<h2 className="ml-5 ml-xl-0 d-inline-block">Checks Settings</h2><RefreshButton onClick={this.onRefresh} isRefreshing={isLoading}/>
  		    	<Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
  				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
  				  <Breadcrumb.Item active>Checks Settings</Breadcrumb.Item>
  				</Breadcrumb>
  		    </Container>
      	</Navbar>
      	<Container className="pt-5 pb-5 mt-4 mt-sm-5">
          {isLoading ? 
            <div className="text-center pt-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          :
            <>
            <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
            {settingsMessages.map(message => {
              if (message.type === 'submitSettings'){
              } else if (message.type === 'submitSettings-cache'){
                return (
                  <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                    Successfully queued submit settings.
                  </Alert>
                )
              } else if (message.type === 'server-error'){
                return (
                  <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                    {message.error ? 
                    `There was an error: ${message.error}.`
                      :
                    "There was an unexpected error."
                    }
                  </Alert>
                )
              } else {
                return null
              }
          })}
            <Form
              noValidate
              validated={validated}
              onSubmit={e => this.handleSubmit(e)}
            >
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Midterm Declaration
                </Form.Label>
                <Col sm={sm2}>
                  <Form.Control 
                    as="textarea" rows="3"
                    name="midterm_declaration"
                    placeholder="..." 
                    value={midterm_declaration}
                    onChange={this.handleOnChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid midterm declaration.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Disclaimer
                </Form.Label>
                <Col sm={sm2}>
                  <Form.Control 
                    as="textarea" rows="3" 
                    name="disclaimer"
                    placeholder="..." 
                    value={disclaimer}
                    onChange={this.handleOnChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid disclaimer.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Col sm={sm1}>
                  <Form.Label className={classes1}>
                    Default Rooms
                  </Form.Label>
                  <p className={`${classes1} small text-right`}>These rooms appear on a routine check by default</p>
                </Col>
                <Col sm={sm2}>
                  <RoomOrderList
                    handleListChange={this.handleDefaultRoomsChange}
                    room_names={default_rooms}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="validate-me">
                <Col sm={sm1}>
                  <Form.Label className={classes1}>
                    Room Options
                  </Form.Label>
                  <p className={`${classes1} small text-right`}>These rooms can be added to a routine check</p>
                </Col>
                <Col sm={sm2}>
                  <RoomOrderList
                    handleListChange={this.handleRoomNamesChange}
                    room_names={room_names}
                  />
                </Col>
              </Form.Group>
              <Button disabled={!this.props.isOnline} className="float-right" type="submit">Save</Button>
            </Form>
            </>
          }
  		  </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings.settings,
  settingsMessages: state.settings.settingsMessages,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { fetchSettings, submitSettings, removeMessage })(withGetScreen(SettingsPage));