import { FETCH_CHECKS, CREATE_CHECK, REMOVE_MESSAGE, EDIT_CHECK, DELETE_CHECK, DELETE_CHECK_AUTOSAVE, MASS_DELETE_CHECK, ADD_QUEUE_MESSAGE, ADD_CHECK_SERVER_ERROR_MESSAGE, ADD_CHECK_MESSAGE } from '../actions/types'
import localForage from "localforage";
import { deleteAutoSave } from '../helpers'

const initialState = {
    checkMessages: [],
	checks: {},
	checksStale: false,
}

function getMessageID(){
	let id = parseInt(window.localStorage.getItem('message-id') || "0")
	window.localStorage.setItem('message-id', id+1)
	return id
}

export default function(state = initialState, action) {
	// console.log("checkReducer action", action)
	let checks = null
	let checkMessages = null
	switch(action.type) {
		case FETCH_CHECKS:
			// let newAddresses = state.checks
			// newAddresses[action.page] = action.payload
			if (action.clearResults === true){
				return {
					...state,
					checksStale: false,
					checks: {
				        [action.params.page]: action.payload
				      },
				}
			} else {
				return {
					...state,
					checksStale: false,
					checks: Object.assign({}, state['checks'], {
				        [action.params.page]: action.payload
				      }),
				}
			}
			/* falls through */
		case CREATE_CHECK:
			action.payload.id = getMessageID()
			return {
				...state,
				checkMessages: state['checkMessages'].concat([action.payload]).slice(-3),
				checksStale: true,
			}
			/* falls through */
		case EDIT_CHECK:
			// Update existing check
			checks = Object.assign({}, state['checks'])
			Object.keys(checks).forEach((page) => {
				checks[page].results.forEach((item, i) => {
					if (item.id === action.payload.item.id)	checks[page].results[i] = action.payload.item
				})
			})
			// Delete autosave
			// deleteAutoSave(action.payload.item.id)
			// Update messages
			checkMessages = state['checkMessages']
			checkMessages.forEach((message) => {
				if (message.item && message.item.id && message.item.id === action.payload.item.id){
					message.item = action.payload.item
				}
			})
			action.payload.id = getMessageID()
			return {
				...state,
				checks: checks,
				checkMessages: checkMessages.concat([action.payload]).slice(-3),
				checksStale: true,
			}
			/* falls through */
		case DELETE_CHECK_AUTOSAVE:
			// deleteAutoSave(action.payload)
			checks = Object.assign({}, state['checks'])
			return {
				...state,
				checks,
			}
			/* falls through */
		case DELETE_CHECK:
			console.log("DELETE_CHECK", DELETE_CHECK)
			checks = Object.assign({}, state['checks'])
			console.log("old checks", state['checks'])
			console.log("action", action)
			checkMessages = state['checkMessages'].filter(message =>!message.item || message.item.id !== action.payload.item.id)
			Object.keys(checks).forEach((page) => {
				checks[page].results.forEach((item, i) => {
					console.log("checking item", i, item)
					if (item.id === action.payload.item.id)	{
						console.log("splicing")
						checks[page].results.splice(i, 1)
						console.log("spliced page", checks[page].results)
					}
				})
			})
			console.log("final checks", checks)
			action.payload.id = getMessageID()
			return {
				...state,
				checks: checks,
				checkMessages: checkMessages.concat([action.payload]).slice(-3),
			}
			/* falls through */
		case MASS_DELETE_CHECK:
			checks = Object.assign({}, state['checks'])
			checkMessages = state['checkMessages']
			action.payload.id_array.forEach(removeID => {
				checkMessages = checkMessages.filter(message =>!message.item || message.item.id !== removeID)
				Object.keys(checks).forEach((page) => {
					checks[page].results.forEach((item, i) => {
						if (removeID === item.id) checks[page].results.splice(i, 1)
					})
				})
			})
			action.payload.id = getMessageID()
			return {
				...state,
				checks: checks,
				checkMessages: checkMessages.concat([action.payload]).slice(-3),
			}
			/* falls through */
		case REMOVE_MESSAGE:
			checkMessages = state['checkMessages'].filter(message => message.id !== action.payload)
			return {
				...state,
				checkMessages,
			}
			/* falls through */
		case ADD_QUEUE_MESSAGE:
			return {
				...state,
				checkMessages: state['checkMessages'].concat([action.payload]).slice(-3),
			}
			/* falls through */
		case ADD_CHECK_MESSAGE:
			if (!action.payload.id) action.payload.id = getMessageID()
			return {
				...state,
				checkMessages: state['checkMessages'].concat([action.payload]).slice(-3),
			}
		case ADD_CHECK_SERVER_ERROR_MESSAGE:
			action.payload.id = getMessageID()
			return {
				...state,
				checkMessages: state['checkMessages'].concat([action.payload]).slice(-3),
			}
			/* falls through */
		default:
			return state
		// case FETCH_SITEOPTIONS:

	}
}