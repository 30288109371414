import React, { Component } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

class FormButtonGroup extends Component {

  handleClick = (choiceItem) => {
    let value = choiceItem.value
    if (!this.props.alwaysSelect && choiceItem.value === this.props.value){
      value = ''
    }
    this.props.onChange({target: {value: value, name: this.props.name}})
  }

  inner = () => {
    return <>
        {this.props.choices.map(choiceItem => 
          choiceItem.value && 
          <Button 
            className={`${this.props.multiline && 'mr-2 mb-2'}`}
            variant={this.props.value === choiceItem.value ? "primary" : "outline-primary"}
            onClick={() => this.handleClick(choiceItem)}
          >
          {choiceItem.label} <FontAwesomeIcon icon={faClock} className="carried-over-icon" style={this.props.carriedOverValue && this.props.carriedOverValue === choiceItem.value ? {visibility: 'visible'} : {visibility: 'hidden'} } />
          </Button>
        )}
      </>
  }

  render() {
    if (this.props.multiline){
      return this.inner()
    } else {
      return (
        <ButtonGroup aria-label={this.props.name} className="w-100" style={{zIndex: 0}}>
        {this.inner()}
        </ButtonGroup>
      )
    }

  }
} 
export default FormButtonGroup
