import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { changePassword } from '../redux/actions/userActions'
import { connect } from 'react-redux'
import CloseModalButton from '../components/CloseModalButton'
import ConfirmModal from '../modals/ConfirmModal'

class ChangePasswordModal extends Component {

  constructor(props){
    super(props)
    this.state = { 
      validated: false,
      id: null,
      password: "",
      passwordConfirm: "",

      hasChanges: false,
      displayConfirmation: false,
      confirmCallback: null,
    };
  }

  handleModalConfirm = () => {
      this.setState({ 
        displayConfirmation: false,
        hasChanges: false,
       }, () => this.state.confirmCallback())
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  setItemState = item => {
    if (item){
      this.setState({
        validated: false,
        hasChanges: false,
        displayConfirmation: false,
        id: item.id,
        password: "",
        passwordConfirm: "",
      })
    }
  }


  onShow = () => {
    console.log("ChangePasswordModal, onShow", this.props)
    const { hasChanges } = this.state
    if (!hasChanges){
        this.setItemState(this.props.item)
    }
  }

  handleSubmit(event) {

    const form = event.currentTarget;
    event.preventDefault();

    const { historyUser } = this.state
    let item = null
    if (historyUser) item = historyUser
    else if (this.props.item) item = this.props.item

    const { password, passwordConfirm } = this.state
    if (password !== passwordConfirm) {
      document.getElementById('changePassword').setCustomValidity("Passwords do not match.");
      this.setState({ validated: true })
      return null
    } else {
      document.getElementById('changePassword').setCustomValidity("");
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true })
      return null
    }

    let raw = {id: item.id, password: password}
    this.props.changePassword(raw)
    this.props.handleClose()
  }

  handleOnChange = (event) => {
    this.setState({ 
      [event.target.name]: event.target.value,
      hasChanges: true,
    });
  }

  confirmClose = (e, callback) => {
    if (!callback) callback = this.props.handleClose
    this.setState({confirmCallback: callback})
    const { displayConfirmation, hasChanges } = this.state
    if (displayConfirmation || !hasChanges){
      this.setState({ 
        displayConfirmation: false,
        hasChanges: false,
       }, () => callback())
    } 
    else this.setState({ displayConfirmation: true })
  }

  render(){
    const { displayConfirmation } = this.state
    if (displayConfirmation){
      return <ConfirmModal cancelText={"Go back"} show={this.props.show} handleConfirm={this.handleModalConfirm} title={"Discard changes?"} body={"Are you sure you want to discard current changes?"} handleClose={() => this.setState({ displayConfirmation: false })} isMobile={this.props.isMobile} />
    }
    else if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return (
          <Modal size="lg" show={this.props.show} onHide={this.confirmClose} onShow={this.onShow}>
            {this.getInner()}
          </Modal>
      )
    }
  }

  getInner = () => {
    console.log("this.props", this.props)
    const { validated } = this.state;
    const { password, passwordConfirm } = this.state
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end"

    return (
      <>
          <Modal.Header>
            <Modal.Title>Change password</Modal.Title>
            <CloseModalButton onClick={this.confirmClose}/>
          </Modal.Header>
          <Form
            noValidate
            validated={validated}
            onSubmit={e => this.handleSubmit(e)}
            autoComplete='off'
          >
          <Modal.Body>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                New password
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="password" 
                  id="changePassword"
                  name="password"
                  placeholder="Password" 
                  required
                  value={password}
                  onChange={this.handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Password confirm
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="password" 
                  id="changePasswordConfirm"
                  name="passwordConfirm"
                  placeholder="Password confirm" 
                  required
                  value={passwordConfirm}
                  onChange={this.handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.confirmClose} className="float-left mr-auto">
              Close
            </Button>
            <input type="hidden" name="id" value={this.props.item && this.props.item.id} />
            <Button type="submit">Save</Button>
          </Modal.Footer>
        </Form>
        </>
    )

  }
}

export default connect(null, { changePassword })(ChangePasswordModal);
