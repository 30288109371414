import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { connect } from 'react-redux'
import { logout } from '../redux/actions/authActions'
import createNavigationLinks from './navigation';

class Sidebar extends Component {

  render() {
    return (
      <Menu pageWrapId={ "page-wrap" }
        outerContainerId={ "outer-container" }>
        {createNavigationLinks(this.props.offlineQueue.length, this.props.logout, 'md', this.props.userList, this.props.currentID)}
      </Menu>
    )

  }
}

const mapStateToProps = state => ({
  offlineQueue: state.offline.offlineQueue,
  currentID: state.auth.currentID,
  userList: state.user.userList,
});

export default connect(mapStateToProps, { logout })(Sidebar);
