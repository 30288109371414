import React, { Component } from 'react';
import { Alert } from 'react-bootstrap'

class OfflineAlert extends Component {

  render() {
    return (
      <Alert variant="danger">
        Offline mode: showing saved data.
      </Alert>
    )

  }
}

export default OfflineAlert;
