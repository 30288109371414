

export const addressParams = { page: 1, ordering: 'tenant_address_line_1' }

export const checkParams = () => { 
	const today = new Date()
	return {
	    page: 1, 
	    ordering: '-appointment_date', 
	    assigned_to: "",
	    status: 'all',
		appointment_date__gte: null,
		appointment_date__lte: null,
	    // appointment_date__gte: ("0" + today.getDate()).slice(-2) + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + today.getFullYear(),
	    // appointment_date__lte: ("0" + today.getDate()).slice(-2) + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + today.getFullYear(),
	}
}

export const inventoryParams = () => { 
	// const today = new Date()
	return {
	    page: 1, 
	    ordering: '-tenancy_start_date', 
	    // assigned_to: "",
	    // status: 'all',
	    // appointment_date__gte: ("0" + today.getDate()).slice(-2) + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + today.getFullYear(),
	    // appointment_date__lte: ("0" + today.getDate()).slice(-2) + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + today.getFullYear(),
	}
}

export const reportParams = { 
	page: 1, 
	ordering: '-appointment_date', 
	assigned_to: "",
	status: 'submitted',
	appointment_date__gte: null,
	appointment_date__lte: null,
}