import localForage from "localforage";
import { checkStorage } from './actions/offlineActions'
import { DELETE_CHECK_AUTOSAVE, DELETE_INVENTORY_AUTOSAVE, DELETE_CHECKOUT_AUTOSAVE, SAVE_INVENTORY, SAVE_CHECKOUT } from './actions/types'
import { api_url } from '../api_url'

export function deleteCheckAutosaveFiles(autosaveName, id, dispatch) {

	const deleteMethod = (result) => {
		if (result){
	      let getFilesToDelete = async (result) => {
	        async function forageComments(comments){
	          let filesToDelete = []
	          for (const commentItem of comments) {
	            if (commentItem.image && commentItem.image.length > 0){
	              for (const imageItem of commentItem.image) {
	                if (imageItem.autosaveID){
	                  filesToDelete.push(imageItem.autosaveID)
	                }
	              }
	            }
	          }
	          return filesToDelete
	        }

	        let filesToDelete = []
	        let saveItem = JSON.parse(result)

	        let epFiles = await forageComments(saveItem.comments)
	        filesToDelete = filesToDelete.concat(epFiles)

	        let overallFiles = await forageComments(saveItem.overall_comments)
	        filesToDelete = filesToDelete.concat(overallFiles)

	        for (const roomItem of saveItem.rooms) {
	          let roomFiles = await forageComments(roomItem.comments)
	          filesToDelete = filesToDelete.concat(roomFiles)
	        }
	        console.log("deleteCheckAutoSaveFiles filesToDelete", filesToDelete)
	        for (const imageItem of filesToDelete) {
	        	localForage.removeItem(`file ${imageItem}`)
	        }
	        localForage.removeItem(autosaveName)

			dispatch(checkStorage())
			dispatch({
				type: DELETE_CHECK_AUTOSAVE,
				payload: autosaveName,
			})
	      }
	      getFilesToDelete(result)
		}
	}

	if (autosaveName){
		localForage.getItem(autosaveName).then(deleteMethod)
	} else if (id) {
		localForage.keys().then(function(keys) {
			Promise.all(keys.map(autosaveKey => localForage.getItem(autosaveKey)))
			.then(autosaves => {
				autosaves.forEach((autosave, i) => {
					if (autosave){
						if (keys[i].startsWith(`save_check_${id}`)){
							deleteMethod(autosave)
						}
					}
				})
			})
		}).catch(function(err) {
			console.log(err);
		});
	}
}

export function markInventoryAutosaveSubmitted(autosaveName, dispatch) {
	localForage.getItem(autosaveName).then((result) => {
		if (result){
			let saveItem = JSON.parse(result)
			let d = new Date()
			saveItem.autosaveSubmitted = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
			localForage.setItem(autosaveName, JSON.stringify(saveItem))
			let dispatchType = SAVE_INVENTORY
			if (autosaveName.startsWith(`save_checkout_`)) dispatchType = SAVE_CHECKOUT
			dispatch({
				type: dispatchType,
				payload: autosaveName,
			})
		}
	}) 
}

export function deleteInventoryAutosaveFiles(autosaveName, id, dispatch) {

	const deleteMethod = (result) => {
		if (result){
	      let getFilesToDelete = async (result) => {
	      	
	        async function forageImages(images){
	        	let filesToDelete = []
	        	if (images && images.length > 0){
	        		for (const imageItem of images) {
	        			if (imageItem.autosaveID){
	        				filesToDelete.push(imageItem.autosaveID)
	        			}
	        		}
	        	}
	        	return filesToDelete
	        }

	        async function forageItems(items){
	          let filesToDelete = []
	          for (const item of items) {
	          	filesToDelete = filesToDelete.concat(await forageImages(item.images))
	          }
	          return filesToDelete
	        }

	        let filesToDelete = []
	        let saveItem = JSON.parse(result)

			filesToDelete = filesToDelete.concat(await forageImages(saveItem.electricity_meter_images))
			filesToDelete = filesToDelete.concat(await forageImages(saveItem.gas_meter_images))
			filesToDelete = filesToDelete.concat(await forageImages(saveItem.water_meter_images))
			filesToDelete = filesToDelete.concat(await forageImages(saveItem.fuse_board_images))
			filesToDelete = filesToDelete.concat(await forageImages(saveItem.stop_tap_images))
			filesToDelete = filesToDelete.concat(await forageImages(saveItem.keys_codes_images))

	        for (const roomItem of saveItem.rooms) {
	          let roomFiles = await forageItems(roomItem.items)
	          filesToDelete = filesToDelete.concat(roomFiles)
	        }
	        console.log("deleteInventoryAutoSaveFiles filesToDelete", filesToDelete)
    		addToAutosaveLog(`deleting ${filesToDelete.length} files`)
	        for (const imageItem of filesToDelete) {
	        	localForage.removeItem(`file ${imageItem}`)
	        }
	        localForage.removeItem(autosaveName)

			dispatch(checkStorage())
			dispatch({
				type: DELETE_INVENTORY_AUTOSAVE,
				payload: autosaveName,
			})
	      }
	      getFilesToDelete(result)
		}
	}

	if (autosaveName){
		localForage.getItem(autosaveName).then(deleteMethod)
	} else if (id) {
		localForage.keys().then(function(keys) {
			Promise.all(keys.map(autosaveKey => localForage.getItem(autosaveKey)))
			.then(autosaves => {
				autosaves.forEach((autosave, i) => {
					if (autosave){
						if (keys[i].startsWith(`save_inventory_${id}`)){
							deleteMethod(autosave)
						}
					}
				})
			})
		}).catch(function(err) {
			console.log(err);
		});
	}
	
}

export function deleteCheckoutAutosaveFiles(autosaveName, id, dispatch) {

	const deleteMethod = (result) => {
		if (result){
	      let getFilesToDelete = async (result) => {
	      	async function forageRooms(roomItem){
	      		let filesToDelete = []
	      		if (roomItem.checkout_images && roomItem.checkout_images.length > 0){
	      			for (const imageItem of roomItem.checkout_images) {
	      				if (imageItem.autosaveID){
	      					filesToDelete.push(imageItem.autosaveID)
	      				}
	      			}
	      		}
	          return filesToDelete
	        }

	        let filesToDelete = []
	        let saveItem = JSON.parse(result)
	        for (const roomItem of saveItem.checkoutRooms) {
	          let roomFiles = await forageRooms(roomItem)
	          filesToDelete = filesToDelete.concat(roomFiles)
	        }
	        console.log("deleteCheckoutAutosaveFiles filesToDelete", filesToDelete)
	        for (const imageItem of filesToDelete) {
	        	localForage.removeItem(`file ${imageItem}`)
	        }
	        localForage.removeItem(autosaveName)

			dispatch(checkStorage())
			dispatch({
				type: DELETE_CHECKOUT_AUTOSAVE,
				payload: autosaveName,
			})
	      }
	      getFilesToDelete(result)
		}
	}

	if (autosaveName){
		localForage.getItem(autosaveName).then(deleteMethod)
	} else if (id) {
		localForage.keys().then(function(keys) {
			Promise.all(keys.map(autosaveKey => localForage.getItem(autosaveKey)))
			.then(autosaves => {
				autosaves.forEach((autosave, i) => {
					if (autosave){
						if (keys[i].startsWith(`save_checkout_${id}`)){
							deleteMethod(autosave)
						}
					}
				})
			})
		}).catch(function(err) {
			console.log(err);
		});
	}
}

export function addToAutosaveLog(entry) {
	console.log("addToAutosaveLog", entry)
	localForage.getItem(`autosaveLog`).then((autosaveLog) => {
		if (!autosaveLog){
			autosaveLog = '';
		}
		autosaveLog += (new Date())+'\n'+entry+'\n';
		localForage.keys().then((keys) => {
			autosaveLog += 'keys: '+keys.join(', ')+'\n';
			localForage.setItem(`autosaveLog`, autosaveLog)
			postAutosaveLog(autosaveLog)
		});
	})

}

export function postAutosaveLog(log) {
	const id = parseInt(window.localStorage.getItem('currentID'))
	console.log("postAutosaveLog", log, id)
	const endpoint = `${api_url}rest-auth/accounts/${id}/post_autosave_log/`
	let lookupOptions = {
		method: "POST",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Token '+window.localStorage.getItem('authKey'),
		},
		body: JSON.stringify({
			log: log
		}),
	}
	if (navigator.onLine){
		fetch(endpoint, lookupOptions)
		.then(function(response){
			if (response.ok){
				return response.json()
			}
		}).then(function(responseData){
			if (responseData){
				console.log("submitAutosaveLog responseData", responseData)
				localForage.removeItem('autosaveLog')
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}
}