import React, { Component } from 'react';
import { Container, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Form, Alert, Spinner } from 'react-bootstrap'
import { fetchChecks, deleteCheck, massDeleteCheck, removeMessage, deleteCheckAutosave } from '../redux/actions/checkActions'
import { deleteInventoryAutosave, deleteCheckoutAutosave } from '../redux/actions/inventoryActions'
import { LinkContainer } from 'react-router-bootstrap'
import { connect } from 'react-redux'
import ViewCheckModal from '../modals/ViewCheckModal'
import CreateCheckModal from '../modals/CreateCheckModal'
import EditCheckModal from '../modals/EditCheckModal'
import ConfirmModal from '../modals/ConfirmModal'
import {withGetScreen} from 'react-getscreen'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import ReactPaginate from 'react-paginate';
import { stringify } from 'query-string';
import { api_url } from '../api_url'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import DatePicker from "react-datepicker";
import history from '../history';
import "react-datepicker/dist/react-datepicker.css";
import RefreshButton from '../components/RefreshButton'
import { checkParams, reportParams } from '../components/defaultParams'
import { getUserList } from '../redux/actions/userActions'
import { fetchSettings } from '../redux/actions/settingsActions'
import localForage from "localforage";
import { getClass } from './CheckPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { checkStorage } from '../redux/actions/offlineActions'

const today = new Date()

const CheckPage = getClass()

class AutosavePage extends Component {

  constructor(props){
    super(props)
    this.getDefaultState = this.getDefaultState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.resetCurrentPage = this.resetCurrentPage.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.state = this.getDefaultState()
  }

  refreshAutosaveList = () => {
    let thisComp = this
    let autosaveItems = {}
    let autosaveInventories = {}
    let autosaveCheckouts = {}
    localForage.keys().then(function(keys) {
      console.log("keys", keys)
      Promise.all(keys.map(autosaveKey => localForage.getItem(autosaveKey)))
      .then(autosaves => {
        autosaves.forEach((autosave, i) => {
          if (autosave){
            if (keys[i].startsWith('save_check_') || 
              keys[i].startsWith('save_inventory_') ||
              keys[i].startsWith('save_checkout_')){
            let savedItem = JSON.parse(autosave)
            console.log("savedItem", savedItem)
            autosaveItems[keys[i]] = savedItem
          }
        }
      })
        thisComp.setState({autosaveItems})
      })

    }).catch(function(err) {
      console.log(err);
    });
  }

  componentWillMount() {
    this.refreshAutosaveList()
    this.initialiseComponent()
    this.unlisten = history.listen((location, action) => {
      console.log("location", location, this.state.previousLocation)
      if (location.hash.length === 0 && this.state.previousLocation && this.state.previousLocation.hash.lenth === 0) this.initialiseComponent()
      this.setState({ previousLocation: location})
    });
  }

  initialiseComponent = () => {
    console.log("initialiseComponent")
    // this.displayModalFromHash()
    var urlParams = new URLSearchParams(window.location.search);
    // let { params } = this.state
    let params = window.location.pathname === '/reports' ? reportParams : checkParams()
    // if (urlParams.get('all')){
    //   params.all = urlParams.get('all')
    // }
    if (urlParams.get('page')){
      params.page = urlParams.get('page')
    }
    if (urlParams.get('ordering')){
      params.ordering = urlParams.get('ordering')
    }
    if (urlParams.get('q')){
      params.q = urlParams.get('q')
      this.setState({ 
        searchValue: urlParams.get('q'),
        searchOptions: [{address_full: urlParams.get('q')}],
        searchSelected: [{address_full: urlParams.get('q')}],
      })
    }
    if (urlParams.get('appointment_date__gte') || urlParams.get('appointment_date__gte') === ''){
      params.appointment_date__gte = urlParams.get('appointment_date__gte')
      this.setState({
        appointmentDateStart: this.parseDate(urlParams.get('appointment_date__gte'))
      })
    }
    if (urlParams.get('appointment_date__lte') || urlParams.get('appointment_date__lte') === ''){
      params.appointment_date__lte = urlParams.get('appointment_date__lte')
      this.setState({
        appointmentDateEnd: this.parseDate(urlParams.get('appointment_date__lte'))
      })
    }
    this.setState({ 
      params,
      fromDashboard: false,
    })
    
    localForage.keys().then(keys => {
      this.setState({localStorageKeys: keys})
    })
    // this.props.fetchChecks(params, false)
  }


  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps)
    const { params, searchValue } = this.state
      this.refreshAutosaveList()
  }

  // handleClose(resetCurrentPage) {
  //   super.handleClose(resetCurrentPage)
  //   this.refreshAutosaveList()
  // }

  handleClose (resetCurrentPage) {
    if (resetCurrentPage !== false) this.resetCurrentPage()
    this.setState({ 
      checkAutosave: null,
      selectedDropdownItem: null,
      showConfirmModal: false,
      showViewCheckModal: false,
      showCreateCheckModal: false,
      showEditCheckModal: false,
      showEmailModal: false,
      showSelectAutosaveModal: false,
      emailErrorMessage: null,
    });
    window.scrollTo(0, 0)
  }

  resetCurrentPage() {
    this.props.checkStorage()
    const allCheckbox = document.getElementById('select-all')
    try{
      allCheckbox.checked = false
      this.setState({ selected: [] })
    } catch {}
    this.refreshAutosaveList()
    // const { params } = this.state
    // history.push('')
    history.push({
      hash: ``
    })
    // this.props.fetchChecks(params, true)
  }

  getDefaultState() {
    return {
      fromDashboard: false,
      filterOpen: true,
      tableSpan: 12,
      filterSpan: 3,
      currentPage: null,
      pageCount: 1,
      showViewCheckModal: false,
      showCreateCheckModal: false,
      showConfirmModal: false,
      showEmailModal: false,
      showSelectAutosaveModal: false,
      emailErrorMessage: null,
      confirmModalTitle: "",
      confirmModalBody: "",
      confirmModalCallback: null,
      selectedDropdownItem: null,
      searchValue: "",
      searchOptions: [],
      searchSelected: [],
      searchIsLoading: false,
      searchIsVisible: false,
      params: window.location.pathname === '/reports' ? reportParams : checkParams(),
      // appointmentDateStart: window.location.pathname === '/reports' ? null : today,
      // appointmentDateEnd: window.location.pathname === '/reports' ? null : today,
      appointmentDateStart: null,
      appointmentDateEnd: null,
      selected: [],
      messages: [],
      previousLocation: null,
      authenticationCode: null,
      checkAutosave: null,
      checkAutosaveName: null,
      localStorageKeys: [],
      // isRefreshing: false,
      autosaveItems: {},
      autosaveImages: [],
      autosaveInventories: {},
      autosaveCheckouts: {},
    }
  }

  // getDefaultState(){
  //   console.log("AutosavePage getDefaultState")
  //   let result = super.getDefaultState()
  //   result.autosaveItems = {}
  //   result.autosaveImages = []
  //   return result
  // }

  getRecord(item, key) {
    const optionsLabel = this.props.isMobile() ? "" : "Options"

    let id = '-'
    if (item.id) id = item.id
    else if (key.split('_').length >= 2) {
      id = key.split('_')[2]
    }

    let autosaveNumber = '-'
    if (key.split('_').length >= 3) {
      autosaveNumber = key.split('_')[3]
    }

    let autosaveType = ''
    let openAutosaveLink = null
    if (key.startsWith('save_check_')){
      autosaveType = 'Check'
      openAutosaveLink = <LinkContainer to={`/checks?autosave=${key}#edit-${id}`}><Dropdown.Item>Open autosave</Dropdown.Item></LinkContainer>
    } else if (key.startsWith('save_inventory_')){
      autosaveType = 'Inventory'
      openAutosaveLink = <LinkContainer to={`/inventories?autosave=${key}#edit-${id}`}><Dropdown.Item>Open autosave</Dropdown.Item></LinkContainer>
    } else if (key.startsWith('save_checkout_')){
      autosaveType = 'Checkout'
      openAutosaveLink = <LinkContainer to={`/inventories?autosave=${key}#checkout-${id}`}><Dropdown.Item>Open autosave</Dropdown.Item></LinkContainer>
    }

    let address_full = []
    if (item.address_full) {
      address_full = [
        item.address_full.tenant_address_line_1, 
        item.address_full.tenant_address_line_2, 
        item.address_full.tenant_town_city, 
        item.address_full.tenant_county, 
        item.address_full.tenant_postcode
      ].filter(function (el) {
        return el !== null && el !== "";
      }).join(", ")
    } else if (item.address && item.address.address_full) {
      address_full = item.address.address_full
    } else if (item.displayAddress) {
      address_full = item.displayAddress
    }

    return (
      <tr key={key} className="" variant={item && item.autosaveSubmitted ? 'success' : null}>
        <td>
          {/**<input id={`select-${item.id}`} type="checkbox" style={{ pointerEvents: "none" }}/>**/}
        </td>
        <td>{id}</td>
        <td>{autosaveNumber-1}</td>
        {this.props.isMobile() ? 
          <td>
            {autosaveType}<br/>
            {address_full.length > 0 ? address_full : '-'}
            {item && item.autosaveSubmitted ? <><br/>{`submitted ${item.autosaveSubmitted}`}</> : ''}
          </td>
          :
          <>
            <td>{autosaveType}</td>
            <td>
              {address_full.length > 0 ? address_full : '-'}
            </td>
            <td>
              {item && item.autosaveSubmitted ? `submitted ${item.autosaveSubmitted}` : ''}
            </td>
          </>
        }
        <td>
          <DropdownButton title={optionsLabel} variant="sab" className="float-right" alignRight>
            {/**<Dropdown.Item onClick={() => this.loadAutosave(key, item)}>Open autosave</Dropdown.Item>**/}
            {openAutosaveLink}
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => this.confirmDeleteAutosave(key)}>Delete autosave</Dropdown.Item>
          </DropdownButton>
        </td>
        <td>
          <FontAwesomeIcon icon={faSave} className="" />
        </td>
      </tr>
    )
  }

  // checkFromRow = (event, id) => {
  //   if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'A'){
  //     const { selected } = this.state
  //     const checkbox = document.getElementById(`select-${id}`)
  //     checkbox.checked = !checkbox.checked
  //     if (checkbox.checked){
  //       checkbox.parentElement.parentElement.classList.add("selected");
  //       this.setState({
  //         selected: selected.concat([id])
  //       })
  //     } else {
  //       document.getElementById('select-all').checked = false
  //       checkbox.parentElement.parentElement.classList.remove("selected");
  //       this.setState({
  //         selected: selected.filter(item => item !== id)
  //       })
  //     }
  //   }
  // }

  confirmDeleteAutosave = (name) => {
    history.push(`#confirm`);
    let thisComp = this
    this.setState({ 
      showConfirmModal: true,
      confirmModalTitle: "Delete Autosave",
      confirmModalBody: "Are you sure you want to delete the selected autosave?",
      confirmModalCallback: () => {
        if (name.startsWith('save_check_')) this.props.deleteCheckAutosave(name, null)
        else if (name.startsWith('save_inventory_')) this.props.deleteInventoryAutosave(name, null)
        if (name.startsWith('save_checkout_')) this.props.deleteCheckoutAutosave(name, null)
        this.handleClose(false)
        let {autosaveItems} = thisComp.state
        delete autosaveItems[name]
        thisComp.setState({autosaveItems: autosaveItems})
      },
    });
  }

  render() {
    const { autosaveItems, autosaveInventories, autosaveCheckouts } = this.state
    const { tableSpan, params, currentPage, selected } = this.state
    const { showViewCheckModal, showCreateCheckModal, showEditCheckModal, showSelectAutosaveModal, selectedDropdownItem, checkAutosave, checkAutosaveName } = this.state
    const { showEmailModal, emailErrorMessage } = this.state
    const { showConfirmModal, confirmModalTitle, confirmModalBody, confirmModalCallback } = this.state
    const { searchOptions, searchIsLoading, searchIsVisible, searchSelected } = this.state
    const { checkMessages } = this.props
    const { appointmentDateStart, appointmentDateEnd } = this.state

    const selectLabel = `${selected.length} selected`
    const selectDisabled = selected.length === 0 ? true : false

    const confirmModal = 
      <ConfirmModal 
        show={showConfirmModal} 
        handleConfirm={confirmModalCallback} 
        title={confirmModalTitle} 
        body={confirmModalBody} 
        handleClose={this.handleClose} 
        isMobile={this.props.isMobile} 
      />
    // const viewCheckModal = 
    //   <ViewCheckModal 
    //     forceAutosave={true}
    //     show={showViewCheckModal} 
    //     handleClose={this.handleClose} 
    //     handleShowCheckModal={this.handleShowCheckModal} 
    //     item={selectedDropdownItem} 
    //     isMobile={this.props.isMobile} 
    //     settings={this.props.settings}
    //   />
    const createCheckModal = 
      <CreateCheckModal 
        forceAutosave={true}
        show={showCreateCheckModal} 
        handleClose={this.handleClose} 
        item={selectedDropdownItem} 
        isMobile={this.props.isMobile} 
      />
    
    const editCheckModal = <EditCheckModal show={showEditCheckModal} handleClose={this.handleClose} item={selectedDropdownItem} checkAutosave={checkAutosave} checkAutosaveName={checkAutosaveName} handleShowCheckModal={this.handleShowCheckModal} isMobile={this.props.isMobile} />

    const modalOpen = showConfirmModal || showViewCheckModal || showCreateCheckModal || showEditCheckModal
    if (modalOpen && this.props.isMobile()){
      if (showConfirmModal) return confirmModal
      // else if (showViewCheckModal) return viewCheckModal
      else if (showEditCheckModal) return editCheckModal
      else if (showCreateCheckModal) return createCheckModal
    }

    const title = "Autosaves"

    // console.log("this.props.userList", this.props.userList)

    return (
      <div className="page check-page">
        <Sidebar/>
        <Navbar fixed="top">
          <Container>
            <h2 className="ml-5 ml-xl-0 d-inline-block">{title}</h2><RefreshButton onClick={this.handleRefresh} isRefreshing={autosaveItems && Object.keys(autosaveItems).length > 0?false:true}/>
            <Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
          </Breadcrumb>
          </Container>
        </Navbar>
        {confirmModal}{/**viewCheckModal**/}{editCheckModal}{createCheckModal}
        <Container className="pt-5 pb-5 mt-4 mt-sm-5">
          <Alert variant="light">
            <p>Each time you make a change to an inventory or check, it saves your progress to an autosave. The autosave is deleted when you submit it. Otherwise, you can close it and continue off from where you were by selecting the associated autosave.</p>
            <p>It is not recommended to store large numbers of autosaves on your device. Please consider deleting unneeded autosaves when the low storage warning appears.</p>
          </Alert>
          <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
          {checkMessages.map(message => {
            if (message.type === 'createCheck'){
              // return (
              //  <Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
              //    Successfully created routine check #{message.item.id}. <Alert.Link onClick={() => this.handleShowCheckModal('handleShowViewCheckModal', message.item, true)}>Click here</Alert.Link> to view.
              //  </Alert>
              // )
            } else if (message.type === 'createCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued create routine check.
                </Alert>
              )
            } else if (message.type === 'editCheck'){
              // return (
              //  <Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
              //    Successfully edited check #{message.item.id}. <Alert.Link onClick={() => this.handleShowCheckModal('handleShowViewCheckModal', message.item, true)}>Click here</Alert.Link> to view.
              //  </Alert>
              // )
            } else if (message.type === 'editCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued edit check #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'deleteCheck'){
              // return (
              //  <Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
              //    Successfully deleted check #{message.item.id}.
              //  </Alert>
              // )
            } else if (message.type === 'deleteCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete check #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'massDeleteCheck'){
              // return (
              //  <Alert key={`${message.id_array.join(',')}-${message.type}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
              //    Successfully deleted checks #{message.id_array.join(', #')}.
              //  </Alert>
              // )
            } else if (message.type === 'massDeleteCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete checks #{message.id_array.join(', #')}.
                </Alert>
              )
            } else if (message.type === 'server-error'){
              return (
                <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  {message.error ? 
                  `There was an error: ${message.error}.`
                    :
                  "There was an unexpected error."
                  }
                </Alert>
              )
            } else {
              return null
            }
          })}
          <Row className="mt-3">
            <Col sm={tableSpan}>
              <Table striped size="sm">
                <thead>
                  <tr>
                    <th onClick={this.handleCheckAllToggle}>
                    {/**<input id="select-all" type="checkbox" style={{ pointerEvents: "none" }}/>**/}
                    </th>
                    <th>ID</th>
                    <th>#</th>
                    {this.props.isMobile() ? 
                      <th/>
                      :
                      <>
                        <th>Type</th>
                        <th>Address</th>
                        <th>Status</th>
                      </>
                    }
                    <th/>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {autosaveItems && Object.keys(autosaveItems).length > 0 ? 
                    Object.keys(autosaveItems).map(key => this.getRecord(autosaveItems[key], key)) : 
                  <tr>
                    <td colSpan="7" className="text-center pt-3" style={{ pointerEvents: "none" }}>
                      <p>No results</p>
                    </td>
                  </tr>}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings.settings,
  checks: state.check.checks,
  checkMessages: state.check.checkMessages,
  userList: state.user.userList,
  hiddenElement: state.settings.hiddenElement,
});

export default connect(mapStateToProps, { fetchChecks, removeMessage, deleteCheck, massDeleteCheck, getUserList, fetchSettings, deleteCheckAutosave, checkStorage, deleteInventoryAutosave, deleteCheckoutAutosave })(withGetScreen(AutosavePage));