import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form, Alert } from 'react-bootstrap'
import { createUser, editUser } from '../redux/actions/userActions'
import { connect } from 'react-redux'
import { api_url } from '../api_url'
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'
import ConfirmModal from '../modals/ConfirmModal'
import isEmail from 'validator/lib/isEmail'
import FormButtonGroup from '../components/FormButtonGroup'

class CreateUserModal extends Component {

  constructor(props){
    super(props)
    this.state = { 
      validated: false,
      id: null,
      username: "",
      first_name: "",
      last_name: "",
      sabuser_role: "",
      office: "default",
      password: "",
      passwordConfirm: "",

      historyUser: null,
      hasChanges: false,
      displayConfirmation: false,
      confirmCallback: null,
    };
  }

  fetchUser = () => {
    console.log("fetchUser")
    let itemID = null
    if (this.props.item){
      itemID = this.props.item.id
    } else {
      itemID = history.location.hash.split('-').pop()
    }
    const endpoint = `${api_url}rest-auth/users/${itemID}/` 
    let thisComp = this
    let lookupOptions = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token '+window.localStorage.getItem('authKey'),
      }
    }
    fetch(endpoint, lookupOptions)
    .then(function(response){
      return response.json()
    }).then(function(responseData){
      if (responseData){
        thisComp.setItemState(responseData)
        thisComp.setState({ historyUser: responseData })
      }
    }).catch(function(error){
      console.log("error", error)
    })
  }

  handleModalConfirm = () => {
      this.setState({ 
        displayConfirmation: false,
        hasChanges: false,
       }, () => this.state.confirmCallback())
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps)
    if (nextProps.showCreateUserModal === false){
        this.setState({
          hasChanges: false,
          validated: false,
          id: null,
          username: "",
          first_name: "",
          last_name: "",
          sabuser_role: "",
          office: "default",
          password: "",
          passwordConfirm: "",
        })
    }

  }

  setItemState = item => {
    console.log('setItemState', item)
    this.setState({
      validated: false,
      hasChanges: false,
      id: item.id,
      username: item.username,
      first_name: item.first_name,
      last_name: item.last_name,
      sabuser_role: item.sabuser_role,
      office: item.office,
    })
  }


  onShow = () => {
    console.log("CreateUserModal, onShow", this.props)
    const { hasChanges } = this.state
    if (!hasChanges){
      this.setState({
        validated: false,
        displayConfirmation: false,
        password: "",
        passwordConfirm: "",
      })
      if (this.props.item){
        this.setItemState(this.props.item)
        this.fetchUser()
      } else if (history.location.hash.split('-').length > 1) {
        this.fetchUser()
      } else {
        this.setState({
          validated: false,
          id: null,
          username: "",
          first_name: "",
          last_name: "",
          sabuser_role: "user",
        })
      }
    }
  }

  handleSubmit(event) {

    const form = event.currentTarget;
    event.preventDefault();

    const { historyUser } = this.state
    let item = null
    if (historyUser) item = historyUser
    else if (this.props.item) item = this.props.item

    const { username, first_name, last_name, sabuser_role, password, office } = this.state

    if (!isEmail(username)){
      document.getElementById('username').setCustomValidity("Invalid email.");
      this.setState({ validated: true })
      return null
    } else {
      document.getElementById('username').setCustomValidity("");
    }

    if (!item){
      const { password, passwordConfirm } = this.state
      if (password !== passwordConfirm) {
        document.getElementById('password').setCustomValidity("Passwords do not match.");
        this.setState({ validated: true })
        return null
      } else {
        document.getElementById('password').setCustomValidity("");
      }
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true })
      return null
    }
    // const formData = new FormData();
    let raw = {};

    if (item) raw['id'] = item.id
    // formData.append('username', username );
    raw['username'] = username
    // formData.append('email', username );
    raw['email'] = username
    // formData.append('first_name', first_name );
    raw['first_name'] = first_name
    // formData.append('last_name', last_name );
    raw['last_name'] = last_name
    raw['sabuser_role'] = sabuser_role
    raw['office'] = office

    if (item){
      this.props.editUser(raw)
    } else {
      // formData.append('password1', password );
      raw['password1'] = password
      // formData.append('password2', password );
      raw['password2'] = password
      this.props.createUser(raw)
    }

    // this.setState({ hasChanges: false })
    // this.props.handleClose()
  }

  handleOnChange = (event) => {
    this.setState({ 
      [event.target.name]: event.target.value,
      hasChanges: true,
    });
  }

  confirmClose = (e, callback) => {
    if (!callback) callback = this.props.handleClose
    this.setState({confirmCallback: callback})
    const { displayConfirmation, hasChanges } = this.state
    if (displayConfirmation || !hasChanges){
      this.setState({ 
        displayConfirmation: false,
        hasChanges: false,
       }, () => callback())
    } 
    else this.setState({ displayConfirmation: true })
  }

  render(){
    const { displayConfirmation } = this.state
    if (displayConfirmation){
      return <ConfirmModal cancelText={"Go back"} show={this.props.show} handleConfirm={this.handleModalConfirm} title={"Discard changes?"} body={"Are you sure you want to discard current changes?"} handleClose={() => this.setState({ displayConfirmation: false })} isMobile={this.props.isMobile} />
    }
    else if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return (
          <Modal size="lg" show={this.props.show} onHide={this.confirmClose} onShow={this.onShow}>
            {this.getInner()}
          </Modal>
      )
    }
  }

  getInner = () => {
    console.log("this.props", this.props)
    const { validated } = this.state;
    const { username, first_name, last_name, sabuser_role, office, historyUser} = this.state
    const { password, passwordConfirm } = this.state
    const { settings } = this.props
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end"

    let officeOptions = []
    if (settings && settings.inventory_contact_details && settings.inventory_contact_details.length && settings.inventory_contact_details.startsWith('[')){
      officeOptions = JSON.parse(settings.inventory_contact_details).map(item => {
        return {value: item.name, label: item.name}
      })
    }

    let item = null
    if (historyUser) item = historyUser
    else if (this.props.item) item = this.props.item

    return (
      <>
          <Modal.Header>
            <Modal.Title>{this.props.item ? `Editing User #${item.id}` : "Create New User" }</Modal.Title>
            <CloseModalButton onClick={this.confirmClose}/>
          </Modal.Header>
          <Form
            noValidate
            validated={validated}
            onSubmit={e => this.handleSubmit(e)}
            autoComplete='off'
          >
          <Modal.Body>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Email/username
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  id="username"
                  type="text" 
                  name="username"
                  placeholder="Email/username" 
                  value={username}
                  onChange={this.handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                First name
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="first_name"
                  placeholder="First name" 
                  value={first_name}
                  onChange={this.handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid first name.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Last name
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  type="text" 
                  name="last_name"
                  placeholder="Last name" 
                  required
                  value={last_name}
                  onChange={this.handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid last name.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Role
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={[
                    {value: 'user', label: 'User'},
                    {value: 'admin', label: 'Admin'},
                  ]} 
                  name='sabuser_role' 
                  value={sabuser_role} 
                  onChange={this.handleOnChange} 
                  alwaysSelect={true}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Office
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={officeOptions} 
                  name='office' 
                  value={office} 
                  onChange={this.handleOnChange} 
                  alwaysSelect={true}
                  multiline={true}
                />
              </Col>
            </Form.Group>


            {!this.props.item && 
              <>
                <Form.Group as={Row} className="validate-me">
                  <Form.Label column sm={sm1} className={classes1}>
                    Password
                  </Form.Label>
                  <Col sm={sm2}>
                    <Form.Control 
                      type="password" 
                      id="password"
                      name="password"
                      placeholder="Password" 
                      required
                      value={password}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid password.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="validate-me">
                  <Form.Label column sm={sm1} className={classes1}>
                    Password confirm
                  </Form.Label>
                  <Col sm={sm2}>
                    <Form.Control 
                      type="password" 
                      id="passwordConfirm"
                      name="passwordConfirm"
                      placeholder="Password confirm" 
                      required
                      value={passwordConfirm}
                      onChange={this.handleOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid password.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </>

            }

            {Object.values(this.props.createUserErrors).map(errorField => 
              errorField.map(errorItem => 
                <Col sm={{ span: sm2, offset: sm1 }}>
                  <Alert variant="danger">
                    {errorItem}
                  </Alert>
                </Col>
              )
            )}

          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={this.confirmClose} className="float-left mr-auto">
                Close
              </Button>
        {this.props.item ? 
          <>
            <input type="hidden" name="id" value={this.props.item.id} />
                <Button type="submit">Save</Button>
          </>
          :
              <Button type="submit">Create</Button>
        }
          </Modal.Footer>
        </Form>
        </>
    )

  }
}

const mapStateToProps = state => ({
  createUserErrors: state.user.createUserErrors,
  showCreateUserModal: state.user.showCreateUserModal,
  settings: state.settings.settings,
});

export default connect(mapStateToProps, { createUser, editUser })(CreateUserModal);
