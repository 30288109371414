import React, { Component } from 'react';
import { Modal, Button, Row, Col, ListGroup, Tab, Tabs, Form } from 'react-bootstrap'
import { api_url } from '../api_url'
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'

// const imgPrefix = 'http://127.0.0.1:8000'
const imgPrefix = ''

class ViewInventoryModal extends Component {

	constructor(props){
		super(props)
    	this.onShow = this.onShow.bind(this);
    	this.getSubmitButton = this.getSubmitButton.bind(this)
    	this.getCheckoutSignature = this.getCheckoutSignature.bind(this)
    	this.getCheckoutComments = this.getCheckoutComments.bind(this)
    	this.handleSubmit = this.handleSubmit.bind(this)
    	this.getTenancyEndDate = this.getTenancyEndDate.bind(this)
    	this.getTenancyEndDateFieldName = this.getTenancyEndDateFieldName.bind(this)
    	this.getPropertyVisitDate = this.getPropertyVisitDate.bind(this)
    	this.getPropertyVisitDateFieldName = this.getPropertyVisitDateFieldName.bind(this)
    	this.getCheckoutElectricityReadings = this.getCheckoutElectricityReadings.bind(this)
    	this.getCheckoutGasReadings = this.getCheckoutGasReadings.bind(this)
    	this.getCheckoutWaterReadings = this.getCheckoutWaterReadings.bind(this)
    	this.getTitle = this.getTitle.bind(this)
		this.state = {
			item: null,
			validated: false,
		};
	}

	handleSubmit(event) {

	}

	getTitle() {
	  	let {item} = this.state
	  	if (this.props.item && !item) item = this.props.item
		return `Inventory Report #${item.id}`
	}

	getCheckoutElectricityReadings(item) {
		return `Move-out readings: ${item.electricity_meter_readings_checkout ? item.electricity_meter_readings_checkout : '-'}`
	}

	getCheckoutGasReadings(item) {
	    if (item.no_gas_oil_meter){
	      return null
	    }
		return `Move-out readings: ${item.gas_meter_readings_checkout ? item.gas_meter_readings_checkout : '-'}`
	}

	getCheckoutWaterReadings(item) {
	    if (item.no_water_meter){
	      return null
	    }
		return `Move-out readings: ${item.water_meter_readings_checkout ? item.water_meter_readings_checkout : '-'}`
	}

	getSubmitButton() {
		return null
	}

	componentWillMount(){
		if (this.props.isMobile()) this.onShow()
	}

	fetchInventory = () => {
		console.log("ViewInventoryModal, fetchInventory")
		let itemID = null
		if (this.props.item){
			itemID = this.props.item.id
		} else {
			itemID = history.location.hash.split('-').pop()
		}
		const endpoint = `${api_url}rest-auth/inventories/${itemID}/` 
		let thisComp = this
		let lookupOptions = {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
        		'Authorization': 'Token '+window.localStorage.getItem('authKey'),
			}
		}
		fetch(endpoint, lookupOptions)
		.then(function(response){
			return response.json()
		}).then(function(responseData){
			if (responseData){
				thisComp.setState({
					item: responseData,
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}

	onShow() {
		this.fetchInventory()
	}

	componentDidMount(){
		this.props.show && this.fetchInventory()
	}

  renderTimestamp = timestamp => {
  	let d = new Date(timestamp)
  	return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    	d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
  };

  render(){
  	if (this.props.show && this.props.isMobile()) return this.getInner()
  	else {
	  	return (
	        <Modal size="xl" show={this.props.show} onHide={this.props.handleClose} onShow={this.onShow}>
	        {this.getInner()}
	        </Modal>
	  	)
  	}
  }

  multilineFields = (fields, fieldNames) => {
  	return fields.filter(field => field ? field : "-")
		.map((field, i) => {
			let fieldName = '';
			if (fieldNames && fieldNames.length > i) fieldName = fieldNames[i]+': ';
			return <>{fieldName}{field}{i < fields.length && <br/>}</>
			
		})
  }

  onPlainImageError = e => {
    console.log("onPlainImageError", e.target)
    var element = document.createElement("div");
    element.innerHTML = "<b>Image unavailable.</b><p class=\"small\">This image cannot be edited offline or the link has expired.</p>";
    element.setAttribute("role", "alert");
    element.setAttribute("class", "fade alert alert-info show");
    e.target.parentNode.replaceChild(element, e.target)
  }

  displayImages = images => {
	if (images.length === 0){
		return <Col sm={12} className="text-center">{"No photos"}</Col>
	}
	return images.map(imageItem => 
		imageItem && imageItem.url && typeof imageItem.url === 'string' && <Col sm={4} className="align-top mb-1 mt-2">
			<a href={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} target="_blank" rel="noopener noreferrer"><img src={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} className={`img-fluid`} alt={imageItem.alt} onError={this.onPlainImageError} /></a>
			{imageItem.isTenantImage && <b>Tenant Photo</b>}
  		</Col>
	)
  }

  getFieldImages = field => {
  	let images = []
	field && field.forEach((imageItem, imageIndex) => 
		images.push({url: imageItem.url, alt: "", isTenantImage: false})
	)
	return this.displayImages(images)
  }

  getRoomImages = room => {
  	let images = []
	room.items && room.items.forEach(roomItem => 
		roomItem.images.forEach((imageItem, imageIndex) => 
  			images.push({url: imageItem.url, alt: roomItem.name, isTenantImage: false})
		)
	)
	room.tenant_images && room.tenant_images.forEach((imageItem, imageIndex) => 
		// roomItem.images.forEach((imageItem, imageIndex) => 
  		images.push({url: imageItem.url, alt: room.name, isTenantImage: true})
		// )
	)
	return this.displayImages(images)
  }

  getRoomTenantImages = tenant_images => {
  	let images = []
	tenant_images.forEach((imageItem, imageIndex) => 
		images.push({url: imageItem.url, alt: `Tenant image #${imageIndex}`})
	)
	return this.displayImages(images)
  }

  linebreaks = (value) => {
  	if (!value) return "-"
  	// return value.split("\n").join(<br/>)
  	const lines = value.split("\n")
  	return <>
  		{lines.map((l, i) => 
	  		l && l !== '' && 
	  		<>
	  			{l}{i < lines.length-1 && <br/>}
	  		</>
	  	)}
  	</>
  }

  getCheckoutComments(room) {
  	return <tr className="inventory-comments-box">
      	<td colspan="3">
  		<strong>Checkout comments:</strong><br/>
  		{room.checkoutcomments ? room.checkoutcomments : "-"}
  		{room.checkout_images && 
  		<Row>{room.checkout_images.map(imageItem =>
			imageItem && imageItem.url && typeof imageItem.url === 'string' && <Col sm={4} className="align-top mb-1 mt-2">
				<a href={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} target="_blank" rel="noopener noreferrer"><img src={imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:')} className={`img-fluid`} alt={room.name} onError={this.onPlainImageError} /></a>
	  		</Col>
	  	)}</Row>
  		}
  	</td>
    </tr>
  }

  getCheckoutSignature() {
  	let {item, validated} = this.state
  	if (this.props.item) item = this.props.item
	return <Row>
  		<Col xs={6} sm={6}>
      	<br/><strong>Check out signature</strong><br/>
  		{
  			item.check_out_signature_image ? 
			<a href={imgPrefix === '' ? imgPrefix+item.check_out_signature_image.replace('http:', 'https:') : item.check_out_signature_image} target="_blank" rel="noopener noreferrer"><img src={imgPrefix === '' ? imgPrefix+item.check_out_signature_image.replace('http:', 'https:') : item.check_out_signature_image} className="img-fluid" alt="signature" onError={this.onPlainImageError} /></a>
			:
			item.check_out_signature ? item.check_out_signature : "-"
  		}
  		</Col>
  	</Row>
  }

  getTenancyEndDateFieldName() {
  	return 'Tenancy Start Date'
  }

  getTenancyEndDate(item) {
  	return item.tenancy_start_date ? item.tenancy_start_date : "-"
  }

  getPropertyVisitDateFieldName() {
  	return 'Report Compiled Date'
  }

  getPropertyVisitDate(item) {
  	return item.property_compiled_date ? item.property_compiled_date : "-"
  }

  getInner = () => {
  	let {item, validated} = this.state
  	if (this.props.item && !item) item = this.props.item

  	console.log("item", item)
  if (item && item.rooms){
  item.rooms.sort((a, b) => a.order - b.order).map(room => 
  	room.items.sort((a, b) => a.order - b.order).map(roomItem => {
  		console.log("roomItem", roomItem)
  	})
  )
}



  	if (item){
	  	const address_full = item && item.address_full ? [
	  		item.address_full.tenant_address_line_1, 
	  		item.address_full.tenant_address_line_2, 
	  		item.address_full.tenant_town_city, 
	  		item.address_full.tenant_county, 
	  		item.address_full.tenant_postcode
	  	].filter(function (el) {
		  return el !== null && el !== "";
		}) : [];
  		const sm1 = 4
  		const sm2 = 5
  		const classes1 = "text-sm-right text-center"
  		const classes2 = "text-sm-left text-center mb-1"
	    return (
	        <>
	          <Form
	            noValidate
	            validated={validated}
	            onSubmit={e => this.handleSubmit(e)}
	            autoComplete='off'
	          >
	          <Modal.Header>
	            <Modal.Title>{this.getTitle()}</Modal.Title>
            	<CloseModalButton onClick={this.props.handleClose}/>
	          </Modal.Header>
			  <Modal.Body className="pt-0">

			  	<div className="modal-subheading mt-0">
			  		<h5 className="">Report Details</h5>
			  	</div>

	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<strong>Address</strong><br/>
	          			{address_full ? address_full.join(', ') : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Tenant Details</strong><br/>
	          			{item.tenant_details ? this.linebreaks(item.tenant_details) : "-"}<br/>
	          		</Col>
	          	</Row>
	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<strong>{this.getTenancyEndDateFieldName()}</strong><br/>
	          			{this.getTenancyEndDate(item)}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>{this.getPropertyVisitDateFieldName()}</strong><br/>
	          			{this.getPropertyVisitDate(item)}
	          		</Col>
	          	</Row>
	          	{item.let_only && 
		          	<Row>
		          		<Col xs={12} sm={12}>
		          			<strong>Landlord Details</strong><br/>
		          			{item.let_landlord_details ? this.linebreaks(item.let_landlord_details) : "-"}<br/>
		          		</Col>
		          	</Row>
		          }

			  	<div className="modal-subheading">
			  		<h5 className="">Utilities</h5>
			  	</div>

	          	<Row>
	          		<Col xs={6} sm={6} className="mb-2">
	          			<strong>Electricity Meter Location, Readings, & Supplier</strong><br/>
	          			{this.multilineFields([
	          				item.electricity_meter_location ? item.electricity_meter_location : "-", 
	          				this.linebreaks(item.electricity_meter_readings),
	          				item.electricity_supplier ? item.electricity_supplier : "-", 
	          			], ['Location', 'Move-in readings', 'Supplier'])}
	          			{this.getCheckoutElectricityReadings(item)}
	          			{this.getFieldImages(item.electricity_meter_images)}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Gas/Oil Meter Location, Readings, & Supplier</strong><br/>
	          			{item.no_gas_oil_meter ? 
	          				"No gas/oil" :
		          			this.multilineFields([
		          				item.gas_meter_location ? item.gas_meter_location : "-", 
		          				this.linebreaks(item.gas_meter_readings),
		          				item.gas_supplier ? item.gas_supplier : "-", 
	          				], ['Location', 'Move-in readings', 'Supplier'])
		          		}
	          			{this.getCheckoutGasReadings(item)}
	          			{this.getFieldImages(item.gas_meter_images)}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Water Meter Location, Readings, & Supplier</strong><br/>
	          			{item.no_water_meter ? 
	          				<>No meter, rated<br/></> :
	          				this.multilineFields([
	          					item.water_meter_location ? item.water_meter_location : "-", 
	          					this.linebreaks(item.water_meter_readings),
	          				], ['Location', 'Move-in readings'])
	          			}
	          			Supplier: {item.water_supplier ? item.water_supplier : "-"}<br/>
	          			{this.getCheckoutWaterReadings(item)}
	          			{this.getFieldImages(item.water_meter_images)}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Fuse Board Type & Location</strong><br/>
	          			{this.multilineFields([item.fuse_board_type ? item.fuse_board_type : "-", item.fuse_board_location ? item.fuse_board_location : "-"], ['Type', 'Location'])}
	          			{this.getFieldImages(item.fuse_board_images)}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<strong>Stop Tap Location</strong><br/>
	          			{item.stop_tap_location ? item.stop_tap_location : "-"}
	          			{this.getFieldImages(item.stop_tap_images)}
	          		</Col>
	          	</Row>

			  	<div className="modal-subheading">
			  		<h5 className="">Keys & Codes</h5>
			  	</div>

	          	<Row>
	          		<Col sm={12}>
	          			{item.keys_codes ? this.linebreaks(item.keys_codes) : "-"}
	          			{this.getFieldImages(item.keys_codes_images)}
	          		</Col>
	          	</Row>

			  	<div className="modal-subheading">
			  		<h5 className="">Property & Report</h5>
			  	</div>

	          	<Row className="mb-3">
	          		<Col sm={12}>
	          			{item.property_brief_description ? this.linebreaks(item.property_brief_description) : "-"}
	          		</Col>
	          	</Row>

	          	<table className="table table-md">
	          	{item.rooms && item.rooms.map(room => 
	          		<>
          				<thead>
          					<tr>
          						<th>
          							{room.name ? room.name : "-"}
          						</th>
          						<th>
          							Notes
          						</th>
          						<th>
          							Condition & Cleanliness
          						</th>
          					</tr>
          				</thead>
          				<tbody>
          					{room.items.map(roomItem =>
          						<tr>
          							<td>
          								{roomItem.name ? roomItem.name : "-"}
          							</td>
          							<td>
          								{roomItem.description ? this.linebreaks(roomItem.description) : "-"}
          							</td>
          							<td>
          								{roomItem.conditioncleanliness ? roomItem.conditioncleanliness : "-"}
          							</td>
          						</tr>
          					)}
          					<tr><td colspan="3"></td></tr>
          					<tr className="inventory-comments-box">
      							<td colspan="3">
      								<strong>Tenant's comments:</strong><br/>
      								{room.tenant_comments ? room.tenant_comments : "-"}
      							</td>
          					</tr>
          					<tr><td colspan="3" className="borderless"></td></tr>
      						{this.getCheckoutComments(room)}
          					<tr><td colspan="3" className="borderless"></td></tr>
          					<tr>
          						<td colspan="3" className="borderless">
		          					<h3 className="text-center">{room.name}</h3>
		          					<Row>
		          						{this.getRoomImages(room)}
		          					</Row>
          						</td>
          					</tr>
          					<tr><td colspan="3" className="borderless"></td></tr>
          				</tbody>
          			</>
	          	)}
	          	</table>


			  	<div className="modal-subheading">
			  		<h5 className="">Signature</h5>
			  	</div>

	          	<Row>
	          		<Col xs={6} sm={6}>
	          			<br/><strong>Check in signature</strong><br/>
	          			{item.check_in_signature_image ? 
  							<a href={imgPrefix === '' ? imgPrefix+item.check_in_signature_image.replace('http:', 'https:') : item.check_in_signature_image} target="_blank" rel="noopener noreferrer"><img src={imgPrefix === '' ? imgPrefix+item.check_in_signature_image.replace('http:', 'https:') : item.check_in_signature_image} className="img-fluid" alt="signature" onError={this.onPlainImageError} /></a>
	          			:
	          			item.check_in_signature ? item.check_in_signature : "-"}
	          		</Col>
	          		<Col xs={6} sm={6}>
	          			<br/><strong>Tenant signature</strong><br/>
	          			{item.query_signature_image ? 
  							<a href={imgPrefix === '' ? imgPrefix+item.query_signature_image.replace('http:', 'https:') : item.query_signature_image} target="_blank" rel="noopener noreferrer"><img src={imgPrefix === '' ? imgPrefix+item.query_signature_image.replace('http:', 'https:') : item.query_signature_image} className="img-fluid" alt="signature" onError={this.onPlainImageError} /></a>
	          			:
	          			item.query_signature ? item.query_signature : "-"}
	          		</Col>
	          	</Row>

      			{
      				this.getCheckoutSignature()
      			}

	          	</Modal.Body>
	          <Modal.Footer>
	            <Button variant="secondary" onClick={this.props.handleClose} className="float-left ml-0">
	              Close
	            </Button>
	            <Button variant="sab" onClick={() => this.props.startEditInventory(item)} className="float-left mr-auto ml-0">
	              Edit
	            </Button>
	            {this.getSubmitButton()}
	          </Modal.Footer>
	          	</Form>
	        </>
	    )
  	} else {
  		return null
  	}

  }
}

export default ViewInventoryModal;
