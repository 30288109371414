import React, { Component } from 'react';
import { Container, Button, Row, Col, Navbar, Breadcrumb, Form, Alert, Spinner, ListGroup, InputGroup, FormControl, FormCheck } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

class InventorySettingsItemList extends Component {

	constructor(props){
		super(props)
		this.state = {
			newItem: "",
		};
	}

	addItem = () => {
		const { newItem } = this.state
		const { items } = this.props
		// let new_items = [newItem].concat(items)
		if (newItem !== ""){
			console.log("adding", {name: newItem})
			items.push({name: newItem})
			this.setState({
				newItem: "",
			}, () => this.props.handleChange(items))
		}
	}

	handleItemDescriptionChange = (event, itemName) => {
		const { items } = this.props
		let new_items = items.filter(item => {
			if (item.name === itemName){
				item.description = event.target.value
			}
			return item
		})
		this.props.handleChange(new_items)
	}

    handleItemAutoAddChange = (event, itemName) => {
		const { items } = this.props
		let new_items = items.filter(item => {
			if (item.name === itemName){
				item.autoAdd = event.target.checked
			}
			return item
		})
		this.props.handleChange(new_items)
    }

	removeItem = (itemName) => {
		const { items } = this.props
		let new_items = items.filter(item => item.name !== itemName)
		console.log("new_items", new_items)
		this.props.handleChange(new_items)
	}

	moveUp = (item, itemIndex) => {
		const { items } = this.props
		// console.log("moveUp", roomName, items)
		// let itemIndex = items.indexOf(roomName)
		let new_items = items.filter(fItem => fItem.name !== item.name)
		new_items.splice(itemIndex-1, 0, item);
		this.props.handleChange(new_items)
	}

	moveDown = (item, itemIndex) => {
		const { items } = this.props
		// console.log("moveDown", roomName, items)
		// let itemIndex = items.indexOf(roomName)
		let new_items = items.filter(fItem => fItem.name !== item.name)
		new_items.splice(itemIndex+1, 0, item);
		this.props.handleChange(new_items)
	}

	handleOnChange = (event) => {
		this.setState({ 
			[event.target.name]: event.target.value,
		});
	}

  render() {
  	const { newItem } = this.state
  	const { items } = this.props
    return (
    	<>
	      <InputGroup className="mb-2">
	        <FormControl
	          name="newItem"
	          placeholder="Add new item"
	          aria-label="New Item Name"
	          value={newItem}
	          onChange={this.handleOnChange}
	        />
	        <InputGroup.Append>
	          <Button variant="primary" onClick={() => this.addItem(newItem)}>Add</Button>
	        </InputGroup.Append>
	      </InputGroup>
	      <ListGroup>
	        {items.map((item, index) => 
	          <ListGroup.Item variant="light" key={item.name}>
	            <div className="up-down-button mr-3">
	              <div onClick={() => index !== 0 && this.moveUp(item, index)}><FontAwesomeIcon icon={faCaretUp} /></div>
	              <div onClick={() => index !== items.length && this.moveDown(item, index)}><FontAwesomeIcon icon={faCaretDown} /></div>
	            </div>
	            <div className="d-inline-block align-top">{item.name}</div>
	            <Button 
	              className="float-right" 
	              variant="danger" 
	              size="sm" 
	              onClick={() => this.removeItem(item.name)}
	            >Remove item</Button>
			      <InputGroup className="mb-2">
			        <FormControl
			          name="itemDescription"
			          placeholder="Default description"
			          aria-label="Default Description"
			          className="mt-2"
			          value={item.description}
			          onChange={e => this.handleItemDescriptionChange(e, item.name)}
			        />
		              <div className="w-100 mt-2">
		                <FormCheck>
		                  <FormCheck.Input 
		                    onChange={e => this.handleItemAutoAddChange(e, item.name)}
		                    checked={item.autoAdd}
		                    className="novalidate"
		                    type="checkbox"
		                    name="itemAutoAdd"
		                  />
		                  <FormCheck.Label className="novalidate">Automatically add?</FormCheck.Label>
		                </FormCheck>
		              </div>
			      </InputGroup>
	          </ListGroup.Item>
	          )
	        }
	      </ListGroup>
    	</>
    )

  }
} 
export default InventorySettingsItemList
