import { PUSH_OFFLINE_QUEUE, REMOVE_OFFLINE_QUEUE_ITEM, SET_QUEUE_ITEM_PENDING, UNSET_QUEUE_ITEM_PENDING, HANDLE_ONLINE, REFRESH_OFFLINE_QUEUE, PUSH_ADDRESS_TO_OFFLINE_QUEUE, PUSH_CHECK_TO_OFFLINE_QUEUE, SAVE_CHECK, SAVE_INVENTORY, SAVE_CHECKOUT, CHECKOUT_INVENTORY, CHECK_STORAGE, PUSH_INVENTORY_TO_OFFLINE_QUEUE, PUSH_CHECKOUT_TO_OFFLINE_QUEUE, INCREMENT_OFFLINE_FILE_COUNT, SET_ONLINE, SET_OFFLINE_FILE_COUNT } from '../actions/types'
import localForage from "localforage";

function setOfflineQueue(offlineQueue){

	localForage.setItem('offlineQueue', JSON.stringify(offlineQueue))

	console.log("registering sync")
	navigator.serviceWorker.ready.then(function(swRegistration) {
		console.log("registering sync ok")
		if (swRegistration.sync){
	 		return swRegistration.sync.register('onlineSync');
		}
	});
}

const initialState = {
	displayStorageWarning: false,
	offlineQueue: [],
	onlineCount: 0,
	offlineFileCount: 0,
	savedFilesToDelete: [],
	isOnline: navigator.onLine,
}

function saveImage(fileName, imageFile) {
    let reader = new FileReader();
    reader.onload = function(){
		localForage.setItem(fileName, reader.result)
    };
    reader.readAsDataURL(imageFile);
}



function stringifyCheckout(validatedData, offlineFileCount){
	console.log("stringifyCheckout", validatedData)

	let filesToDelete = []

	function saveItems(roomItem, offlineFileCount, filesToDelete){

		delete roomItem.history
		if (roomItem.checkout_images && roomItem.checkout_images.length > 0){
			let newImages = []
			roomItem.checkout_images.forEach(image => {
				if (image.url && typeof image.url === 'object'){
					const myFileID = offlineFileCount+1
					saveImage(`file ${myFileID}`, image.url)
					image.url = myFileID
					filesToDelete.push(myFileID)
					offlineFileCount++
				}
				newImages.push(image)
			})
			roomItem.checkout_images = newImages
		}
		return {roomItem: roomItem, offlineFileCount: offlineFileCount, filesToDelete: filesToDelete}
	}

	Object.keys(validatedData).forEach(key => {
		if (validatedData[key]){
			if (key === "checkoutRooms"){
				validatedData[key].forEach(roomItem => {
					// roomItem.items && roomItem.items.forEach(roomItemItem => {
					let commentResult = saveItems(roomItem, offlineFileCount, filesToDelete)
					roomItem = commentResult.roomItem
					offlineFileCount = commentResult.offlineFileCount
					filesToDelete = commentResult.filesToDelete
					// })
				})
			} else if (key === 'check_out_signature_image') {
				if (validatedData[key] && typeof validatedData[key] === 'object'){
					const myFileID = offlineFileCount+1
					saveImage(`file ${myFileID}`, validatedData[key])
					validatedData[key] = myFileID
					offlineFileCount++
				}
			}
		}
	})

	// console.log("new offline validatedData", validatedData)
	localForage.setItem('offlineFileCount', offlineFileCount)
	return {item: validatedData, offlineFileCount: offlineFileCount, filesToDelete: filesToDelete}
}

function stringifyInventory(validatedData, offlineFileCount){

	let filesToDelete = []

	function saveItems(roomItemItem, offlineFileCount, filesToDelete){

		delete roomItemItem.history
		if (roomItemItem.images && roomItemItem.images.length > 0){
			let newImages = []
			roomItemItem.images.forEach(image => {
				// if (image.url && typeof image.url === 'object'){
				// 	const myFileID = offlineFileCount+1
				// 	saveImage(`file ${myFileID}`, image.url)
				// 	image.url = myFileID
				// 	filesToDelete.push(myFileID)
				// 	offlineFileCount++
				// }
				newImages.push(image)
			})
			roomItemItem.images = newImages
		}
		return {roomItemItem: roomItemItem, offlineFileCount: offlineFileCount, filesToDelete: filesToDelete}
	}

	Object.keys(validatedData).forEach(key => {
		if (validatedData[key]){
			if (key === "rooms"){
				validatedData[key].forEach(roomItem => {
					delete roomItem.history
					roomItem.items && roomItem.items.forEach(roomItemItem => {
						let commentResult = saveItems(roomItemItem, offlineFileCount, filesToDelete)
						roomItemItem = commentResult.roomItemItem
						offlineFileCount = commentResult.offlineFileCount
						filesToDelete = commentResult.filesToDelete
					})
				})
			} else if (key === 'check_in_signature_image' || key === 'check_out_signature_image') {
				if (validatedData[key] && typeof validatedData[key] === 'object'){
					const myFileID = offlineFileCount+1
					saveImage(`file ${myFileID}`, validatedData[key])
					validatedData[key] = myFileID
					offlineFileCount++
				}
			}
		}
	})

	// console.log("new offline validatedData", validatedData)
	localForage.setItem('offlineFileCount', offlineFileCount)
	return {item: validatedData, offlineFileCount: offlineFileCount, filesToDelete: filesToDelete}
}

function stringifyCheck(validatedData, offlineFileCount){

	let filesToDelete = []

	function saveComments(commentItem, offlineFileCount, filesToDelete){

		delete commentItem.history
		if (commentItem.image && commentItem.image.length > 0){
			let newImages = []
			commentItem.image.forEach(image => {
				// if (image.url && typeof image.url === 'object'){
				// 	const myFileID = offlineFileCount+1
				// 	saveImage(`file ${myFileID}`, image.url)
				// 	image.url = myFileID
				// 	filesToDelete.push(myFileID)
				// 	offlineFileCount++
				// }
				newImages.push(image)
			})
			commentItem.image = newImages
		}
		return {commentItem: commentItem, offlineFileCount: offlineFileCount, filesToDelete: filesToDelete}
	}

	Object.keys(validatedData).forEach(key => {
		if (validatedData[key]){
			if (key === "rooms"){
				validatedData[key].forEach(roomItem => {
					delete roomItem.history
					roomItem.comments.forEach(commentItem => {
						let commentResult = saveComments(commentItem, offlineFileCount, filesToDelete)
						commentItem = commentResult.commentItem
						offlineFileCount = commentResult.offlineFileCount
						filesToDelete = commentResult.filesToDelete
					})
				})
			} else if (key === 'comments'){
				validatedData[key].forEach(commentItem => {
					let commentResult = saveComments(commentItem, offlineFileCount, filesToDelete)
					commentItem = commentResult.commentItem
					offlineFileCount = commentResult.offlineFileCount
					filesToDelete = commentResult.filesToDelete
				})
			} else if (key === 'overall_comments'){
				validatedData[key].forEach(commentItem => {
					let commentResult = saveComments(commentItem, offlineFileCount, filesToDelete)
					commentItem = commentResult.commentItem
					offlineFileCount = commentResult.offlineFileCount
					filesToDelete = commentResult.filesToDelete
				})
			} else if (key === 'signature_image') {
				if (validatedData[key] && typeof validatedData[key] === 'object'){
					const myFileID = offlineFileCount+1
					saveImage(`file ${myFileID}`, validatedData[key])
					validatedData[key] = myFileID
					offlineFileCount++
				}
			}
		}
	})

	// console.log("new offline validatedData", validatedData)
	localForage.setItem('offlineFileCount', offlineFileCount)
	return {item: validatedData, offlineFileCount: offlineFileCount, filesToDelete: filesToDelete}
}

export default function(state = initialState, action) {
	// console.log("offlineReducer action.payload", action.payload)
	let offlineQueue = []
	let offline = null
	switch(action.type) {
		case SET_ONLINE:
			return {
				...state,
				isOnline: action.payload,
			}
		case SET_OFFLINE_FILE_COUNT:
			return {
				...state,
				offlineFileCount: action.payload,
			}
		case INCREMENT_OFFLINE_FILE_COUNT:
			return {
				...state,
				offlineFileCount: ++state.offlineFileCount,
			}
		case SAVE_CHECK:
			delete action.payload.history
			offline = stringifyCheck(action.payload, state.offlineFileCount)
			localForage.setItem(action.checkAutosaveName, JSON.stringify(offline.item))
			
			return {
				...state,
				offlineFileCount: offline.offlineFileCount,
				savedFilesToDelete: offline.filesToDelete,
			}
			/* falls through */
		case SAVE_CHECKOUT:
			delete action.payload.history
			offline = stringifyCheckout(action.payload, state.offlineFileCount)
			localForage.setItem(action.checkoutAutosaveName, JSON.stringify(offline.item))
			
			return {
				...state,
				offlineFileCount: offline.offlineFileCount,
				savedFilesToDelete: offline.filesToDelete,
			}
			/* falls through */
		case SAVE_INVENTORY:
			delete action.payload.history
			offline = stringifyInventory(action.payload, state.offlineFileCount)
			localForage.setItem(action.inventoryAutosaveName, JSON.stringify(offline.item))
			
			return {
				...state,
				offlineFileCount: offline.offlineFileCount,
				savedFilesToDelete: offline.filesToDelete,
			}
			/* falls through */
		case CHECK_STORAGE:
			return {
				...state,
				displayStorageWarning: action.payload,
			}
			/* falls through */
		case PUSH_CHECKOUT_TO_OFFLINE_QUEUE:
			console.log("offline, storing in queue")
			offlineQueue = Array.from(state.offlineQueue)
			console.log("offlineQueue", offlineQueue)
			action.payload.id = offlineQueue.length > 0 ? offlineQueue[offlineQueue.length-1].id+1 : 1
			delete action.payload.validatedData.history
			offline = stringifyCheckout(action.payload.validatedData, state.offlineFileCount)
			console.log("offline", offline)
			action.payload.item = offline.item
			// offline.item.type = 'editCheck'
			offlineQueue.push(action.payload)
			console.log("offlineQueue", offlineQueue)
			setOfflineQueue(offlineQueue)
			return {
				...state,
				offlineQueue,
				offlineFileCount: offline.offlineFileCount
			}
			/* falls through */
		case PUSH_INVENTORY_TO_OFFLINE_QUEUE:
			console.log("offline, storing in queue")
			offlineQueue = Array.from(state.offlineQueue)
			console.log("offlineQueue", offlineQueue)
			action.payload.id = offlineQueue.length > 0 ? offlineQueue[offlineQueue.length-1].id+1 : 1
			delete action.payload.validatedData.history
			offline = stringifyInventory(action.payload.validatedData, state.offlineFileCount)
			console.log("offline", offline)
			action.payload.item = offline.item
			// offline.item.type = 'editCheck'
			offlineQueue.push(action.payload)
			console.log("offlineQueue", offlineQueue)
			setOfflineQueue(offlineQueue)
			return {
				...state,
				offlineQueue,
				offlineFileCount: offline.offlineFileCount
			}
			/* falls through */
		case PUSH_CHECK_TO_OFFLINE_QUEUE:
			console.log("offline, storing in queue")
			offlineQueue = Array.from(state.offlineQueue)
			console.log("offlineQueue", offlineQueue)
			action.payload.id = offlineQueue.length > 0 ? offlineQueue[offlineQueue.length-1].id+1 : 1
			delete action.payload.validatedData.history
			offline = stringifyCheck(action.payload.validatedData, state.offlineFileCount)
			console.log("offline", offline)
			action.payload.item = offline.item
			// offline.item.type = 'editCheck'
			offlineQueue.push(action.payload)
			console.log("offlineQueue", offlineQueue)
			setOfflineQueue(offlineQueue)
			return {
				...state,
				offlineQueue,
				offlineFileCount: offline.offlineFileCount
			}
			/* falls through */
		case PUSH_ADDRESS_TO_OFFLINE_QUEUE:
			offlineQueue = Array.from(state.offlineQueue)
			action.payload.id = offlineQueue.length > 0 ? offlineQueue[offlineQueue.length-1].id+1 : 1
			// offline = stringifyAddress(action.payload, state.offlineFileCount)
			offlineQueue.push(action.payload)
			setOfflineQueue(offlineQueue)
			return {
				...state,
				offlineQueue,
			}
			/* falls through */
		case PUSH_OFFLINE_QUEUE:
			console.log("offline, storing in queue")
			offlineQueue = Array.from(state.offlineQueue)
			action.payload.id = offlineQueue.length > 0 ? offlineQueue[offlineQueue.length-1].id+1 : 1
			offlineQueue.push(action.payload)
			console.log("offlineQueue", offlineQueue)
			setOfflineQueue(offlineQueue)
			return {
				...state,
				offlineQueue,
			}
			/* falls through */
		case REMOVE_OFFLINE_QUEUE_ITEM:
			offlineQueue = Array.from(state.offlineQueue).filter(item => item.id !== action.payload.id)
			console.log("offlineQueue", offlineQueue)
			localForage.setItem('offlineQueue', JSON.stringify(offlineQueue))
			return {
				...state,
				offlineQueue,
			}
			/* falls through */
		case SET_QUEUE_ITEM_PENDING:
			offlineQueue = Array.from(state.offlineQueue)
			offlineQueue.forEach(item => {
				if (item.id === action.payload.id) item.status = 'pending'
			})
			console.log("offlineQueue", offlineQueue)
			localForage.setItem('offlineQueue', JSON.stringify(offlineQueue))
			return {
				...state,
				offlineQueue,
			}
			/* falls through */
		case UNSET_QUEUE_ITEM_PENDING:
			offlineQueue = Array.from(state.offlineQueue)
			offlineQueue.forEach(item => {
				if (item.id === action.payload.id) item.status = null
			})
			console.log("offlineQueue", offlineQueue)
			setOfflineQueue(offlineQueue)
			return {
				...state,
				offlineQueue,
			}
			/* falls through */
		case HANDLE_ONLINE:
			return {
				...state,
				onlineCount: state.onlineCount+1,
			}
			/* falls through */
		case REFRESH_OFFLINE_QUEUE:
			if (action.offlineQueue){
				return {
					...state,
					offlineQueue: action.offlineQueue,
				}
			} else if (action.offlineFileCount){
				return {
					...state,
					offlineFileCount: action.offlineFileCount,
				}
			}
			/* falls through */
		default:
			return state
		// case FETCH_SITEOPTIONS:

	}
}