import React, { Component } from 'react';
import { Container, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Form, Alert, Spinner } from 'react-bootstrap'
import { fetchChecks, deleteCheck, massDeleteCheck, removeMessage, addQueueMessage } from '../redux/actions/checkActions'
import { connect } from 'react-redux'
import ViewCheckModal from '../modals/ViewCheckModal'
import CreateCheckModal from '../modals/CreateCheckModal'
import EditCheckModal from '../modals/EditCheckModal'
import ConfirmModal from '../modals/ConfirmModal'
import InputModal from '../modals/InputModal'
import SelectAutosaveModal from '../modals/SelectAutosaveModal'
import {withGetScreen} from 'react-getscreen'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import ReactPaginate from 'react-paginate';
import { stringify } from 'query-string';
import { api_url } from '../api_url'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import DatePicker from "react-datepicker";
import history from '../history';
import "react-datepicker/dist/react-datepicker.css";
import RefreshButton from '../components/RefreshButton'
import { checkParams, reportParams } from '../components/defaultParams'
import { getUserList } from '../redux/actions/userActions'
import { fetchSettings } from '../redux/actions/settingsActions'
import localForage from "localforage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import StorageWarning from '../components/StorageWarning'
import { checkStorage } from '../redux/actions/offlineActions'

const today = new Date()

class CheckPage extends Component {

  constructor(props){
    super(props)
    this.getDefaultState = this.getDefaultState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.resetCurrentPage = this.resetCurrentPage.bind(this);
    this.getCheckRecord = this.getCheckRecord.bind(this);
    this.state = this.getDefaultState()
  }

  getDefaultState() {
    return {
      fromDashboard: false,
      filterOpen: true,
      tableSpan: 12,
      filterSpan: 3,
      currentPage: null,
      pageCount: 1,
      showViewCheckModal: false,
      showCreateCheckModal: false,
      showConfirmModal: false,
      showEmailModal: false,
      showSelectAutosaveModal: false,
      emailErrorMessage: null,
      confirmModalTitle: "",
      confirmModalBody: "",
      confirmModalCallback: null,
      selectedDropdownItem: null,
      searchValue: "",
      searchOptions: [],
      searchSelected: [],
      searchIsLoading: false,
      searchIsVisible: false,
      params: window.location.pathname === '/reports' ? reportParams : checkParams(),
      // appointmentDateStart: window.location.pathname === '/reports' ? null : today,
      // appointmentDateEnd: window.location.pathname === '/reports' ? null : today,
      appointmentDateStart: null,
      appointmentDateEnd: null,
      selected: [],
      messages: [],
      previousLocation: null,
      authenticationCode: null,
      checkAutosave: null,
      checkAutosaveName: null,
      localStorageKeys: [],
      // isRefreshing: false,
    }
  }

  initialiseComponent = () => {
    console.log("initialiseComponent")
    this.displayModalFromHash()
    var urlParams = new URLSearchParams(window.location.search);
    // let { params } = this.state
    let params = window.location.pathname === '/reports' ? reportParams : checkParams()
    // if (urlParams.get('all')){
    //   params.all = urlParams.get('all')
    // }
    const checkAssignedTo = window.localStorage.getItem('checkAssignedTo')
    if (window.location.search.includes('assigned_to')){
      params.assigned_to = urlParams.get('assigned_to')
    } else if (checkAssignedTo){
      params.assigned_to = checkAssignedTo
    }
    if (urlParams.get('page')){
      params.page = urlParams.get('page')
    }
    if (urlParams.get('ordering')){
      params.ordering = urlParams.get('ordering')
    }
    if (urlParams.get('q')){
      params.q = urlParams.get('q')
      this.setState({ 
        searchValue: urlParams.get('q'),
        searchOptions: [{address_full: urlParams.get('q')}],
        searchSelected: [{address_full: urlParams.get('q')}],
      })
    }
    if (urlParams.get('appointment_date__gte') || urlParams.get('appointment_date__gte') === ''){
      params.appointment_date__gte = urlParams.get('appointment_date__gte')
      this.setState({
        appointmentDateStart: this.parseDate(urlParams.get('appointment_date__gte'))
      })
    }
    if (urlParams.get('appointment_date__lte') || urlParams.get('appointment_date__lte') === ''){
      params.appointment_date__lte = urlParams.get('appointment_date__lte')
      this.setState({
        appointmentDateEnd: this.parseDate(urlParams.get('appointment_date__lte'))
      })
    }
    this.setState({ 
      params,
      fromDashboard: false,
    })
    
    localForage.keys().then(keys => {
      this.setState({localStorageKeys: keys})
    })
    this.props.fetchChecks(params, false)
  }

  componentWillMount() {
    this.initialiseComponent()
    this.unlisten = history.listen((location, action) => {
      console.log("location", location, this.state.previousLocation)
      if (location.hash.length === 0 && this.state.previousLocation && this.state.previousLocation.hash.lenth === 0) this.initialiseComponent()
      this.setState({ previousLocation: location})
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  parseDate = dateString => {
    if (dateString){
      let dayMonthYear = dateString.split('-')
      let d = new Date()
      d.setDate(dayMonthYear[0])
      d.setMonth(dayMonthYear[1])
      d.setYear(dayMonthYear[2])
      return d
    }
    return null
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps)
  	const { params, searchValue } = this.state
    if (this.props.checksStale){
      this.handleRefresh()
    }
  	if (nextProps.checks[params.page]){
      localForage.keys().then(keys => {
        this.setState({localStorageKeys: keys})
      })
  		this.setState({
  			currentPage: nextProps.checks[params.page],
  		})
  	}
  }

  handleRefresh = () => {
    // this.setState({...this.getDefaultState(), isRefreshing: true}, this.initialiseComponent)
    this.setState(this.getDefaultState(), this.initialiseComponent)
  }

  handleToggleFilter = () => {
  	const { filterOpen, tableSpan, filterSpan } = this.state
  	this.setState({
  		filterOpen: !filterOpen,
  		tableSpan: tableSpan === 9 ? 11 : 9,
  		filterSpan: filterSpan === 3 ? 1 : 3,
  	})
  }

  handleDownloadSubmit = (form) => {
    // console.log("e", e)
    const { authenticationCode } = this.state
    if (!authenticationCode){
      const endpoint = `${api_url}rest-auth/get_authentication_code/` 
      let lookupOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+window.localStorage.getItem('authKey'),
        }
      }
      fetch(endpoint, lookupOptions)
      .then((response) => {
        if (response.ok){
          return response.json()
        }
      })
      .then((responseData) => {
        this.setState({ authenticationCode: responseData }, () => form.submit())
      })
    } else {
      form.submit()
    }
  }

  startEditCheck = (item) => {
    // this.handleShowCheckModal('handleShowEditCheckModal', item, true)
    let itemID = null
    if (item) itemID = item.id
    else if (history.location.hash.length > 0) itemID = history.location.hash.split('-').pop()

    let thisComp = this
    localForage.keys().then(function(keys) {
      // An array of all the key names.
      const autosaves = keys.filter(key => key === `save_check_${itemID}` || key.startsWith(`save_check_${itemID}_`))
      console.log("startEditCheck", autosaves)
      if (autosaves.length > 0){
        thisComp.handleShowCheckModal('handleShowSelectAutosaveModal', item, true)
      } else {
        thisComp.handleShowCheckModal('handleShowEditCheckModal', item, true)
      }
    })
  }

  loadAutosave = (autosave, item) => {
    console.log("loadAutosave", autosave, item)
    localForage.getItem(autosave).then((result) => {
      if (result){
        this.setState({ 
          checkAutosave: result,
          checkAutosaveName: autosave,
        }, () => this.handleShowCheckModal('handleShowEditCheckModal', item, true))
      } else {
        console.log("no autosave found. this is a problem.")
      }
    })
  }

  getCheckRecord(item, message) {
    const { authenticationCode, localStorageKeys } = this.state
    const hasSave = localStorageKeys.find(key => key === `save_check_${item.id}` || key.startsWith(`save_check_${item.id}_`)) || false
    const optionsLabel = this.props.isMobile() ? "" : "Options"
    let address_full = []
    if (item.address_full) {
      address_full = [
        item.address_full.tenant_address_line_1, 
        item.address_full.tenant_address_line_2, 
        item.address_full.tenant_town_city, 
        item.address_full.tenant_county, 
        item.address_full.tenant_postcode
      ].filter(function (el) {
        return el !== null && el !== "";
      })
    } else if (item.address && item.address.address_full) {
      address_full = item.address.address_full.split(', ')
    }

    return (
      <tr key={item.id} onClick={(e) => this.checkFromRow(e, item.id)} className={message ? "success-row" : ""}>
        <td><input id={`select-${item.id}`} type="checkbox" style={{ pointerEvents: "none" }}/></td>
        <td>{item.id}</td>
        <td>
          {address_full.length > 0 ? address_full.join(", ") : '-'}
          <div className="display-xxs"><b>{item.appointment_date}</b></div>
        </td>
        <td className="hidden-xxs">{item.appointment_date}</td>
        {!this.props.isMobile() &&
          <>
            <td>{item.assigned_to_full ? `${item.assigned_to_full.first_name} ${item.assigned_to_full.last_name}` : "-"}</td>
            <td>{item.date_submitted ? `Submitted ${item.date_submitted}` : "Pending"}</td>
          </>
        }
        <td>
          <DropdownButton title={optionsLabel} variant="sab" className="float-right" alignRight>
            <Dropdown.Item onClick={() => this.startEditCheck(item)}>{hasSave && <FontAwesomeIcon icon={faSave} className="mr-2" />}Start Check / Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => this.handleShowCheckModal('handleShowViewCheckModal', item, true)}>View</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item disabled={!this.props.isOnline} href={`${api_url.replace('.com', '.co.uk').replace('/sab.', '/sab-pdf.')}rest-auth/download_report_pdf/?id=${item.id}&he=${this.props.hiddenElement}`} target="_blank">Download PDF Report</Dropdown.Item>
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.handleShowCheckModal('handleShowEmailModal', item, true)}>Email PDF Report</Dropdown.Item>
            {/**<Dropdown.Item href={`${api_url}rest-auth/download_report_docx/?id=${item.id}&he=${this.props.hiddenElement}`} target="_blank">Download DOCX Report</Dropdown.Item>**/}
            <Dropdown.Divider />
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.confirmDelete(item.id)}>Delete</Dropdown.Item>
          </DropdownButton>
        </td>
        <td>
          {hasSave && <FontAwesomeIcon icon={faSave} className="" />}
        </td>
      </tr>
    )
  }

  renderTimestamp = timestamp => {
    if (!timestamp) return null
    const newTimestamp = `${timestamp.substring(6,10)}/${timestamp.substring(3,5)}/${timestamp.substring(0,2)} 12:00:00`
    const todayTimestamp = today.getFullYear() + '/' + ("0"+(today.getMonth()+1)).slice(-2) + "/" + ("0" + today.getDate()).slice(-2) +' 12:00:00'
    // return newTimestamp
    let prefix = "";
    const timeDiff = Math.round(
      (new Date(todayTimestamp).getTime() - new Date(newTimestamp).getTime()) / 60000
    );
    const daysDiff = Math.round(timeDiff / (60 * 24))
    if (timeDiff < 0){
      prefix = ''
    }
    else if (timeDiff < 24 * 60) {
      // less than 24 hours ago
      prefix = `Today`;
    } else if (timeDiff < (24 * 60) * 2) {
      // less than 7 days ago
      prefix = `Yesterday`;
    } else {
      // less than 7 days ago
      prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
    }
    return prefix;
  };

  getRowHeading = value => {
    // if (!value) return null
    return (
      <tr className="heading-row">
        <td colSpan={!this.props.isMobile()?8:5}>
          <h4 className="mt-2 ml-2">{value ? value : '-'}{value && <span className="small ml-2">{this.renderTimestamp(value)}</span>}</h4>
        </td>
      </tr>
    )
  }

  getCheckContent = () => {
  	const { currentPage, params } = this.state
    let heading = null
    const headingsEnabled = params.ordering && (params.ordering === 'appointment_date' || params.ordering === '-appointment_date')
    if (headingsEnabled && currentPage.results && currentPage.results.length && currentPage.results[0].appointment_date) heading = currentPage.results[0].appointment_date
  	return (
  	  <>
  	  	{currentPage.results.map((item, index) => {
          let showHeading = false
          if (item.appointment_date !== heading) {
            heading = item.appointment_date
            showHeading = true
          }
          return (
            <>
              {headingsEnabled && (showHeading||!index) && this.getRowHeading(heading)}
              {this.getCheckRecord(item, false)}
            </>
          )
  	  	})}
  	  </>
  	)
  }

  async getCheckHasAutosave(itemID){
    return new Promise(function(resolve, reject) {
      localForage.keys().then(function(keys) {
        const autosaves = keys.filter(key => key === `save_check_${itemID}` || key.startsWith(`save_check_${itemID}_`))
        if (autosaves.length > 0){
          resolve(true)
        } else {
          resolve(false)
        }
      })
    });
  }

  confirmCreateCheckOffline = () => {
    this.handleClose(false)
    this.setState({ 
      showConfirmModal: true,
      confirmModalTitle: "Create Routine Check Offline",
      confirmModalBody: "Cannot duplicate previous routine check offline. Would you like to continue?",
      confirmModalCallback: () => {
        this.handleClose(false)
        this.handleShowCheckModal('handleShowEditCheckModal', null, false)
      },
    });
  }

  massDelete = () => {
  	this.handleClose(false)
  	const { selected } = this.state
  	this.props.massDeleteCheck(selected)
  	this.setState({
  		selected: [],
  	})
  }

  confirmDelete = (itemID) => {
    history.push(`#confirm`);
    this.setState({ 
      showConfirmModal: true,
      confirmModalTitle: "Delete Routine Check",
      confirmModalBody: "Are you sure you want to delete the selected check?",
      confirmModalCallback: () => {
        this.handleClose(false)
        this.props.deleteCheck(itemID)
      },
    });
  }

  confirmMassDelete = () => {
    history.push(`#confirm`);
    this.setState({ 
    	showConfirmModal: true,
    	confirmModalTitle: "Mass Deletion",
    	confirmModalBody: "Are you sure you want to delete the selected checks?",
    	confirmModalCallback: this.massDelete
    });
  }

  displayModalFromHash = () => {
    const {showConfirmModal, showViewCheckModal, showCreateCheckModal, showEditCheckModal, showEmailModal, showSelectAutosaveModal} = this.state
    const modalOpen = showConfirmModal || showViewCheckModal || showCreateCheckModal || showEditCheckModal || showEmailModal || showSelectAutosaveModal
    if (history.location.hash.length === 0 && modalOpen === true){
      this.setState({ 
        selectedDropdownItem: null,
        showConfirmModal: false,
        showViewCheckModal: false,
        showCreateCheckModal: false,
        showEditCheckModal: false,
        showEmailModal: false,
        showSelectAutosaveModal: false,
      });
    } else if (history.location.hash.startsWith('#view')){
      this.handleShowCheckModal('handleShowViewCheckModal', null, false)
    } else if (history.location.hash.startsWith('#create')){
      this.handleShowCheckModal('handleShowCreateCheckModal', null, false)
    } else if (history.location.hash.startsWith('#edit')){

      let autosaveName = null
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('autosave')){
        autosaveName = urlParams.get('autosave')
      }

      let thisComp = this
      function fetchCheck() {
        console.log("displayModalFromHash, fetchCheck")
        let itemID = history.location.hash.split('-').pop()
        const endpoint = `${api_url}rest-auth/checks/${itemID}/` 
        let lookupOptions = {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token '+window.localStorage.getItem('authKey'),
          }
        }
        fetch(endpoint, lookupOptions)
        .then(function(response){
          if (response.ok){
            return response.json()
          } else {
            if (autosaveName) thisComp.loadAutosave(autosaveName, null)
            else {
              thisComp.startEditCheck(null)
            }
          }
        }).then(function(responseData){
          if (responseData){
            if (autosaveName) thisComp.loadAutosave(autosaveName, responseData)
            else {
              thisComp.startEditCheck(responseData)
            }
          }
        }).catch(function(error){
          console.log("error", error)
        })
      }

      if (!navigator.onLine){
        if (autosaveName) thisComp.loadAutosave(autosaveName, null)
        else {
          thisComp.startEditCheck(null)
        }
      } else {
        fetchCheck()
      }

      // this.handleShowCheckModal('handleShowEditCheckModal', null, false)
    }
  }

  // componentDidUpdate(){
  //   console.log("update", this.state)
  //   // window.onpopstate  = (e) => {
  //   //   this.initialiseComponent()
  //   // }
  // }

  handleShowCheckModal = (name, item, pushHistory) => {
    console.log("handleShowCheckModal")
    function historyPush(hash, item){
      item ? history.push(hash, {item: item}) : history.push(hash)
    }
    if (name === 'handleShowSelectAutosaveModal'){
      this.setState({ 
        checkAutosave: null,
        checkAutosaveName: null,
        selectedDropdownItem: item,
        showViewCheckModal: false,
        showConfirmModal: false,
        showCreateCheckModal: false,
        showEditCheckModal: false,
        showEmailModal: false,
        showSelectAutosaveModal: true,
      });
    } else if (name === 'handleShowEmailModal'){
      this.setState({ 
        checkAutosave: null,
        checkAutosaveName: null,
        selectedDropdownItem: item,
        showViewCheckModal: false,
        showConfirmModal: false,
        showCreateCheckModal: false,
        showEditCheckModal: false,
        showEmailModal: true,
        showSelectAutosaveModal: false,
      });
    } else if (name === 'handleShowViewCheckModal'){
      if (pushHistory) historyPush(`#view-${item.id}`, item)
      this.setState({ 
        checkAutosave: null,
        checkAutosaveName: null,
        selectedDropdownItem: item,
        showViewCheckModal: true,
        showConfirmModal: false,
        showCreateCheckModal: false,
        showEditCheckModal: false,
        showEmailModal: false,
        showSelectAutosaveModal: false,
      });
    } else if (name === 'handleShowCreateCheckModal'){
      if (!this.props.isOnline) {
        this.confirmCreateCheckOffline()
      } else {
        if (pushHistory) historyPush(`#create`);
        this.setState({ 
          checkAutosave: null,
          checkAutosaveName: null,
          selectedDropdownItem: null,
          showCreateCheckModal: true,
          showConfirmModal: false,
          showViewCheckModal: false,
          showEditCheckModal: false,
          showEmailModal: false,
          showSelectAutosaveModal: false,
        });
      }
    } else if (name === 'handleShowEditCheckModal'){
      if (pushHistory){
        if (item) historyPush(`#edit-${item.id}`, item)
        else {
          historyPush(`#create`);
        }
      }
      // let saveItemID = history.location.hash.split('-').pop()
      // localForage.getItem(`save_check_${saveItemID}`).then((result) => {
        // if (result){
        //   this.setState({ 
        //     checkAutosave: result,
        //   });
        // }
        this.setState({ 
          selectedDropdownItem: item,
          showEditCheckModal: true,
          showConfirmModal: false,
          showViewCheckModal: false,
          showCreateCheckModal: false,
          showEmailModal: false,
          showSelectAutosaveModal: false,
        });
      // })
    }
    window.scrollTo(0, 0)
  }

  // handleShowViewCheckModal = (item) => {
  //   history.push(`#view-${item.id}`, {item: item});
  //   this.setState({ 
  //   	selectedDropdownItem: item,
  //   	showViewCheckModal: true,
  //     showConfirmModal: false,
  //     showCreateCheckModal: false,
  //     showEditCheckModal: false,
  //   });
  //   window.scrollTo(0, 0)
  // }

  // handleShowCreateCheckModal = (item) => {
  //   history.push(`#create`);
  //   this.setState({ 
  //   	selectedDropdownItem: null,
  //   	showCreateCheckModal: true,
  //     showConfirmModal: false,
  //     showViewCheckModal: false,
  //     showEditCheckModal: false,
  //   });
  //   window.scrollTo(0, 0)
  // }

  // handleShowEditCheckModal = (item) => {
  //   history.push(`#edit-${item.id}`, {item: item});
  //   this.setState({ 
  //   	selectedDropdownItem: item,
  //   	showEditCheckModal: true,
  //     showConfirmModal: false,
  //     showViewCheckModal: false,
  //     showCreateCheckModal: false,
  //   });
  //   window.scrollTo(0, 0)
  // }

  handleClose (resetCurrentPage) {
    if (resetCurrentPage !== false) this.resetCurrentPage()
    this.setState({ 
      selectedDropdownItem: null,
    	showConfirmModal: false,
    	showViewCheckModal: false,
    	showCreateCheckModal: false,
      showEditCheckModal: false,
      showEmailModal: false,
      showSelectAutosaveModal: false,
      emailErrorMessage: null,
      checkAutosave: null,
      checkAutosaveName: null,
    });
    window.scrollTo(0, 0)
  }

	handlePageChange = (page) => {
  	const allCheckbox = document.getElementById('select-all')
  	allCheckbox.checked = false
  	this.setState({ selected: [] })
		const newPage = page.selected+1
		this.setState({
  		params: Object.assign({}, this.state.params, {
		        page: newPage
		      }),
		}, () => {
	  	this.resetCurrentPage()
		})
	}

  onKeyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13){
    	event.target.blur()
    }
  }

  onFocus = () => {
  	this.setState({
  		searchIsVisible: true,
  	})
  }

  onBlur = () => {
  	this.setState({
  		searchIsVisible: false,
  	})
  }

  onChange = (query) => {
    const q = query && query.length > 0 && query[0].address_full ? query[0].address_full : null
    const original_search = query && query.length > 0 && query[0].original_search ? query[0].original_search : null
    let params = Object.assign({}, this.state.params, {
      page: 1,
    })
    delete params.q
    // delete params.all
    if (q) params.q = q
    if (original_search) {
      params.q = original_search
      // params.all = 'true'
    }
  	this.setState({
  		params: params,
  		searchIsVisible: false,
  		searchSelected: query,
  	}, () => {
  		this.resetCurrentPage()
  	})
  }

  onInputChange = (searchValue) => {
    this.setState({
    	searchValue: searchValue,
    	searchIsLoading: true,
      searchIsVisible: true,
    });
    const params = {
  		q: searchValue,
  	}
  	const endpoint = `${api_url}rest-auth/address-autocomplete/?${stringify(params)}` 
  	let lookupOptions = {
  		method: "GET",
  		headers: {
  			'Content-Type': 'application/json',
        'Authorization': 'Token '+window.localStorage.getItem('authKey'),
      }
  	}
  	let thisComp = this
  	fetch(endpoint, lookupOptions)
  	.then(function(response){
  		return response.json()
  	}).then(function(responseData){
  		console.log("responseData", responseData)
  		thisComp.setState({ searchIsLoading: false, })
  		if (responseData){
  			responseData.results.unshift({original_search: searchValue})
  			thisComp.setState({
  				searchOptions: responseData.results,
  			})
  		}
  	}).catch(function(error){
  		console.log("error", error)
  	})
  }

  handleCheckAllToggle = () => {
  	const allCheckbox = document.getElementById('select-all')
  	allCheckbox.checked = !allCheckbox.checked
  	const { currentPage } = this.state
  	let selected = []
  	if (allCheckbox.checked){
  		currentPage.results.forEach(item => {
  			selected.push(item.id)
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.add("selected");
    		checkbox.checked = true
  		})
  	} else {
  		currentPage.results.forEach(item => {
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.remove("selected");
    		checkbox.checked = false
  		})
  	}
  	this.setState({
  		selected
  	})
  }

  checkFromRow = (event, id) => {
  	if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'A'){
    	const { selected } = this.state
    	const checkbox = document.getElementById(`select-${id}`)
    	checkbox.checked = !checkbox.checked
    	if (checkbox.checked){
    		checkbox.parentElement.parentElement.classList.add("selected");
    		this.setState({
    			selected: selected.concat([id])
    		})
    	} else {
    		document.getElementById('select-all').checked = false
    		checkbox.parentElement.parentElement.classList.remove("selected");
	    	this.setState({
	    		selected: selected.filter(item => item !== id)
	    	})
    	}
  	}
  }

  handleOrderByChange = (event) => {
  	this.setState({
  		params: Object.assign({}, this.state.params, {
  			ordering: event.target.value,
        page: 1,
  		}),
		}, () => {
			this.resetCurrentPage()
		})
  }

  handleAssignedToChange = (event) => {
    window.localStorage.setItem('checkAssignedTo', event.target.value)
    this.setState({
      params: Object.assign({}, this.state.params, {
        assigned_to: event.target.value,
        page: 1,
      }),
    }, () => {
      this.resetCurrentPage()
    })
  }

  handleStatusChange = (event) => {
    this.setState({
      params: Object.assign({}, this.state.params, {
        status: event.target.value,
        page: 1,
      }),
    }, () => {
      this.resetCurrentPage()
    })
  }

  handleAppointmentDateStartChange = (d) => {
    let formatted = null
    if (d) formatted = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
    this.setState({
      appointmentDateStart: d,
      params: Object.assign({}, this.state.params, {
        appointment_date__gte: formatted,
        page: 1,
      }),
    }, () => {
      this.resetCurrentPage()
    })
  }

  handleAppointmentDateEndChange = (d) => {
    let formatted = null
    if (d) formatted = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
    this.setState({
      appointmentDateEnd: d,
      params: Object.assign({}, this.state.params, {
        appointment_date__lte: formatted,
        page: 1,
      }),
    }, () => {
      this.resetCurrentPage()
    })
  }

  resetCurrentPage() {
    console.log("resetCurrentPage")
    this.props.checkStorage()
    localForage.keys().then(keys => {
      this.setState({localStorageKeys: keys})
    })
  	const allCheckbox = document.getElementById('select-all')
    try{
      allCheckbox.checked = false
      this.setState({ selected: [] })
    } catch {}
		const { params } = this.state
		history.push({
		  search: `?${stringify(params)}`
		})
		this.props.fetchChecks(params, true)
  }

  submitEmail = (email) => {
    let thisComp = this
    console.log("submitEmail", email)
    const {selectedDropdownItem} = this.state
    this.handleClose()
    function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (validateEmail(email)){
      // continue...
      console.log("okay")
      this.setState({
        emailErrorMessage: null,
      })

      const endpoint = `${api_url}rest-auth/email_report_pdf/` 
      let lookupOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+window.localStorage.getItem('authKey'),
        },
        body: JSON.stringify({
          'email': email,
          'id': selectedDropdownItem.id,
        })
      }
      fetch(endpoint, lookupOptions)
      .then((response) => {
        if (response.ok){
          return response.json()
        } else {
          thisComp.props.addQueueMessage({type: 'emailCheck-error'})
        }
      })
      .then((responseData) => {
        if (responseData && responseData.status === 'success'){
          //Add email success alert
          thisComp.props.addQueueMessage({type: 'emailCheck-success'})
        } else {
          thisComp.props.addQueueMessage({type: 'emailCheck-error'})
        }
      })
    } else {
      this.setState({
        emailErrorMessage: `"${email}" is not a valid email address.`,
      })
    }
  }

  setInputReadOnly = () => {
    window.requestAnimationFrame(function() {
      document.querySelectorAll('.react-datepicker__input-container input').forEach(element => element.readOnly = true)
    });
  }

  startCreateCheck = () => {
    let thisComp = this
    this.getCheckHasAutosave(0).then(result => {
      if (result){
          thisComp.handleShowCheckModal('handleShowSelectAutosaveModal', null, true)
      } else {
        thisComp.handleShowCheckModal('handleShowCreateCheckModal', null, false)
      }
    })
  }

  render() {
    this.setInputReadOnly()
  	const { tableSpan, params, currentPage, selected, localStorageKeys} = this.state
    const createHasSave = localStorageKeys.find(key => key === `save_check_0` || key.startsWith(`save_check_0_`)) || false
  	const { showViewCheckModal, showCreateCheckModal, showEditCheckModal, showSelectAutosaveModal, selectedDropdownItem, checkAutosave, checkAutosaveName } = this.state
    const { showEmailModal, emailErrorMessage } = this.state
  	const { showConfirmModal, confirmModalTitle, confirmModalBody, confirmModalCallback } = this.state
  	const { searchOptions, searchIsLoading, searchIsVisible, searchSelected } = this.state
  	const { checkMessages } = this.props
    const { appointmentDateStart, appointmentDateEnd } = this.state

  	const selectLabel = `${selected.length} selected`
  	const selectDisabled = selected.length === 0 ? true : false

    const emailModal = <InputModal show={showEmailModal} handleSubmit={this.submitEmail} title={"Email Routine Check PDF"} label={"Email"} handleClose={this.handleClose} isMobile={this.props.isMobile} item={selectedDropdownItem} errorMessage={emailErrorMessage} />

    const selectAutosaveModal = 
      <SelectAutosaveModal 
        show={showSelectAutosaveModal} 
        handleClose={this.handleClose} 
        isMobile={this.props.isMobile} 
        item={selectedDropdownItem} 
        loadAutosave={this.loadAutosave}
        handleShowModal={this.handleShowCheckModal}
        handleShowCreateModalTag={'handleShowCreateCheckModal'}
        handleShowEditModalTag={'handleShowEditCheckModal'}
        savePrefix={'save_check_'}
      />

    const confirmModal = <ConfirmModal show={showConfirmModal} handleConfirm={confirmModalCallback} title={confirmModalTitle} body={confirmModalBody} handleClose={this.handleClose} isMobile={this.props.isMobile} />

    const viewCheckModal = 
      <ViewCheckModal 
        show={showViewCheckModal} 
        handleClose={this.handleClose} 
        handleShowCheckModal={this.handleShowCheckModal} 
        item={selectedDropdownItem} 
        isMobile={this.props.isMobile} 
        settings={this.props.settings}
        startEditCheck={this.startEditCheck}
      />

    const createCheckModal = <CreateCheckModal show={showCreateCheckModal} handleClose={this.handleClose} item={selectedDropdownItem} isMobile={this.props.isMobile} />
    const editCheckModal = <EditCheckModal show={showEditCheckModal} handleClose={this.handleClose} item={selectedDropdownItem} checkAutosave={checkAutosave} checkAutosaveName={checkAutosaveName} handleShowCheckModal={this.handleShowCheckModal} isMobile={this.props.isMobile} />

    const modalOpen = showConfirmModal || showViewCheckModal || showCreateCheckModal || showEditCheckModal || showEmailModal || showSelectAutosaveModal
    if (modalOpen && this.props.isMobile()){
      if (showConfirmModal) return confirmModal
      else if (showViewCheckModal) return viewCheckModal
      else if (showEditCheckModal) return editCheckModal
      else if (showCreateCheckModal) return createCheckModal
      else if (showEmailModal) return emailModal
      else if (showSelectAutosaveModal) return selectAutosaveModal
    }

    const title = window.location.pathname === '/reports' ? 'Previous Reports' : "Checks"

    // console.log("this.props.userList", this.props.userList)

    return (
    	<div className="page check-page">
        <Sidebar/>
      	<Navbar fixed="top">
  	    	<Container>
  		    	<h2 className="ml-5 ml-xl-0 d-inline-block">{title}</h2><RefreshButton onClick={this.handleRefresh} isRefreshing={currentPage?false:true}/>
  		    	<Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
  				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
  				  <Breadcrumb.Item active>{title}</Breadcrumb.Item>
  				</Breadcrumb>
  		    </Container>
      	</Navbar>
        {confirmModal}{viewCheckModal}{editCheckModal}{createCheckModal}{emailModal}{selectAutosaveModal}
      	<Container className="pt-5 pb-5 mt-4 mt-sm-5">
          <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
          <StorageWarning />
      		{checkMessages.map(message => {
      			if (message.type === 'check-fetch'){
              return (
               <Alert key={`${message.id}`} variant="warning">
                 <Spinner animation="border" role="status" className="mr-3" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                 Submitting check {message.itemID}
               </Alert>
              )
            } else if (message.type === 'emailCheck-success'){
              return (
               <Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
                 Successfully emailed routine check.
               </Alert>
              )
            } else if (message.type === 'emailCheck-error'){
              return (
               <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                 Failed to email routine check.
               </Alert>
              )
            } else if (message.type === 'createCheck'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully created routine check #{message.item.id}. <Alert.Link onClick={() => this.handleShowCheckModal('handleShowViewCheckModal', message.item, true)}>Click here</Alert.Link> to view.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'createCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued create routine check.
                </Alert>
              )
            } else if (message.type === 'editCheck'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully edited check #{message.item.id}. <Alert.Link onClick={() => this.handleShowCheckModal('handleShowViewCheckModal', message.item, true)}>Click here</Alert.Link> to view.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'editCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued edit check #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'deleteCheck'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully deleted check #{message.item.id}.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'deleteCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete check #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'massDeleteCheck'){
      				// return (
  			    	// 	<Alert key={`${message.id_array.join(',')}-${message.type}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully deleted checks #{message.id_array.join(', #')}.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'massDeleteCheck-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete checks #{message.id_array.join(', #')}.
                </Alert>
              )
            } else if (message.type === 'server-error'){
              return (
                <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  {message.error ? 
                  `There was an error: ${message.error}.`
                    :
                  "There was an unexpected error."
                  }
                </Alert>
              )
            } else {
      				return null
      			}
      		})}
      		<Row>
        		<Col className="mb-2 mb-sm-0">
          		<label htmlFor="address-search">Search:</label>
              <form autoComplete='off'>
      	    	<AsyncTypeahead
      	    		id={'address-search-typeahead'}
      	    		inputProps={{id: 'address-search', autoComplete: 'off'}}
        				options={searchOptions}
        				selected={searchSelected}
        				isLoading={searchIsLoading}
        				maxVisible={10}
        				placeholder="Search"
          			onSearch={this.onInputChange}
        				minLength={0}
        				useCache={false}
        				onKeyDown={this.onKeyDown}
        				selectHintOnEnter={true}
        				onBlur={this.onBlur}
        				onFocus={this.onFocus}
        				open={searchIsVisible}
        				onChange={this.onChange}
        				clearButton={true}
                labelKey={(option) => `${option.address_full || option.original_search}`}
                filterBy={(option, props) => {
                  return option.address_full || option.original_search
                }}
              />
              </form>
            </Col>
        		<Col sm={3}>
    		      <Form.Label>Order by:</Form.Label>
    		      <Form.Control as="select" onChange={this.handleOrderByChange} value={params.ordering}>
    		        <option value="id">ID ascending</option>
    		        <option value="-id">ID descending</option>
                <option value="appointment_date">Appointment date ascending</option>
                <option value="-appointment_date">Appointment date descending</option>
                <option value="date_created">Date created ascending</option>
                <option value="-date_created">Date created descending</option>
                <option value="date_submitted">Date submitted ascending</option>
                <option value="-date_submitted">Date submitted descending</option>
    		        <option value="tenant_address_line_1">Address line 1 ascending</option>
    		        <option value="-tenant_address_line_1">Address line 1 descending</option>
    		        <option value="tenant_postcode">Postcode ascending</option>
    		        <option value="-tenant_postcode">Postcode descending</option>
    		      </Form.Control>
        		</Col>
        		<Col xs={6} sm={0} className="d-block d-sm-none">
    	    		<Dropdown id="dropdown-basic-button" title="0 selected" variant="sab" className="mt-3">
      				  <Dropdown.Toggle variant="sab" id="dropdown-basic" className="btn-block" disabled={selectDisabled}>
      				    {selectLabel}
      				  </Dropdown.Toggle>
      					<Dropdown.Menu className="btn-block">
    		    		  <Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
    		    		</Dropdown.Menu>
    	    		</Dropdown>
        		</Col>
        		<Col xs={6} sm={0} className="d-block d-sm-none">
        			<Button variant="primary" className="mt-3 btn-block" onClick={() => this.startCreateCheck()}>{createHasSave && <FontAwesomeIcon icon={faSave} className="mr-2" />}Create</Button>
        		</Col>
        		<div className="d-none d-sm-flex">
    	    		<DropdownButton id="dropdown-basic-button" title={selectLabel} variant="sab" className="float-right align-self-end mr-3" alignRight disabled={selectDisabled}>
  		    		  <Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
    	    		</DropdownButton>
        		</div>
        		<div className="d-none d-sm-flex">
        			<Button variant="primary" className="float-right align-self-end mr-3" onClick={() => this.startCreateCheck()}>{createHasSave && <FontAwesomeIcon icon={faSave} className="mr-2" />}Create</Button>
        		</div>
          </Row>
          <Row className="mt-2">
            <Col xs={6} sm={3}>
              <Form.Label>Assigned to:</Form.Label>
              <Form.Control as="select" onChange={this.handleAssignedToChange} value={params.assigned_to}>
                <option value="">All</option>
                {this.props.userList && this.props.userList.map(userItem => <option key={userItem.id} value={userItem.id}>{`${userItem.first_name} ${userItem.last_name}`}</option>)}
              </Form.Control>
            </Col>
            <Col xs={6} sm={3}>
              <Form.Label>Status:</Form.Label>
              <Form.Control as="select" onChange={this.handleStatusChange} value={params.status}>
                <option value="all">All</option>
                <option value="submitted">Submitted</option>
                <option value="pending">Pending</option>
              </Form.Control>
            </Col>
            <Col sm={6} className="mt-2 mt-sm-0">
              <Form.Label className="d-block">Appointment date:</Form.Label>
              <div className="d-inline-block" style={{ width: '49%' }}>
                <DatePicker
                  selectsStart
                  className="form-control"
                  placeholderText="from"
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  selected={appointmentDateStart}
                  startDate={appointmentDateStart}
                  endDate={appointmentDateEnd}
                  onChange={this.handleAppointmentDateStartChange}
                />
              </div>
              <div className="d-inline-block float-right" style={{ width: '49%' }}>
                <DatePicker
                  selectsEnd
                  className="form-control"
                  placeholderText="to"
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  selected={appointmentDateEnd}
                  startDate={appointmentDateStart}
                  endDate={appointmentDateEnd}
                  minDate={appointmentDateStart}
                  onChange={this.handleAppointmentDateEndChange}
                />
              </div>
            </Col>
          </Row>
  	    	<Row className="mt-3">
    	    	<Col sm={tableSpan}>
          		<Table striped size="sm">
        			  <thead>
        			    <tr>
        			      <th onClick={this.handleCheckAllToggle}><input id="select-all" type="checkbox" style={{ pointerEvents: "none" }}/></th>
        			      <th>ID</th>
        			      <th>Address</th>
                    <th className="hidden-xxs">Appointment date</th>
                    {!this.props.isMobile() &&
                      <>
                        <th>Assigned to</th>
                        <th>Status</th>
                      </>
                    }
        			      <th></th>
        			    </tr>
        			  </thead>
                <tbody>
                  {currentPage ?
                    (checkMessages && checkMessages.length > 0) || (currentPage.results && currentPage.results.length > 0) ?
                    <>
                      {checkMessages.map(message => {
                        if (message.type === 'createCheck'){
                          let result = currentPage.results.filter(checkItem => checkItem.id === message.item.id)
                          if (result.length === 0) return this.getCheckRecord(message.item, message)
                        }
                      })}
                      {this.getCheckContent()}
                    </>
                    :
                    <tr>
                      <td colSpan="7" className="text-center pt-3" style={{ pointerEvents: "none" }}>
                        <p>No results</p>
                      </td>
                    </tr>
                  :
                  <tr style={{ pointerEvents: "none" }}>
                    <td colSpan="7" className="text-center pt-3">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                  }
                </tbody>
              </Table>
              {currentPage &&
          			<ReactPaginate
          				previousLabel={'previous'}
          				nextLabel={'next'}
          				breakLabel={'...'}
          				pageCount={currentPage.total_pages}
                  marginPagesDisplayed={this.props.isMobile() ? 1 : 2}
                  pageRangeDisplayed={this.props.isMobile() ? 3 : 4}
          				onPageChange={this.handlePageChange}
          				containerClassName={'pagination d-flex justify-content-center'}
          				subContainerClassName={'pages pagination'}
          				pageClassName={'page-item'}
          				pageLinkClassName={'page-link'}
          				activeClassName={'active'}
          				previousClassName={'page-item'}
          				nextClassName={'page-item'}
          				previousLinkClassName={'page-link'}
          				nextLinkClassName={'page-link'}
          				breakClassName={'page-item'}
          				breakLinkClassName={'page-link'}
          				hrefBuilder={() => '#'}
          				forcePage={params.page-1}
          			/>
              }
            </Col>
          </Row>
        </Container>
		  </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings.settings,
  checks: state.check.checks,
  checksStale: state.check.checksStale,
  checkMessages: state.check.checkMessages,
  userList: state.user.userList,
  hiddenElement: state.settings.hiddenElement,
  isOnline: state.offline.isOnline,
});

export function getClass() {
  return CheckPage
}

export default connect(mapStateToProps, { fetchChecks, removeMessage, deleteCheck, massDeleteCheck, getUserList, fetchSettings, checkStorage, addQueueMessage })(withGetScreen(CheckPage));