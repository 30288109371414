import React, { Component } from 'react';
import { Container, Modal, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Form, Alert, Spinner } from 'react-bootstrap'
import CloseModalButton from '../components/CloseModalButton'

class InputModal extends Component {

  constructor(props){
    super(props)
    this.state = { 
      modalInput: "",
    };
  }

  render(){
    if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return (
          <Modal size="lg" show={this.props.show} onShow={this.onShow} onHide={this.props.handleClose}>
          {this.getInner()}
          </Modal>
      )
    }
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  onShow = () => {
    console.log("InputModal, onShow", this.state, this.props)
    const { hasChanges, hasPromptedLoadSave, promptLoadSave } = this.state
    this.setState({
      modalInput: this.props.defaultValue ? this.props.defaultValue : "",
    })
  }

  handleOnChange = (event) => {
    this.setState({ 
      [event.target.name]: event.target.value,
    });
  }

  onKeyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13){
      this.props.handleSubmit(this.state.modalInput)
    }
  }

  getInner = () => {
  	console.log("InputModal this.props", this.props)
  	const {title, label, errorMessage, body} = this.props
    const {modalInput} = this.state
    const classes1 = "d-flex justify-content-sm-end text-right"
    const sm1 = 4
    const sm2 = 7
    return (
        <>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
            <CloseModalButton onClick={this.props.handleClose}/>
          </Modal.Header>
          <Modal.Body>
            {body && body}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                {label}
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  className="input-modal-text-field"
                  type="text" 
                  name="modalInput"
                  value={modalInput}
                  onChange={this.handleOnChange}
                  onKeyDown={this.onKeyDown}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose} className="float-left mr-auto">
              {this.props.cancelText ? this.props.cancelText : "Close"}
            </Button>
            <Button onClick={() => this.props.handleSubmit(this.state.modalInput)}>Submit</Button>
          </Modal.Footer>
        </>
    )

  }
}

export default InputModal;
