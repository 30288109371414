import React, { Component } from 'react';
import { Container, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Form, Alert, Spinner } from 'react-bootstrap'
import { fetchInventories, deleteInventory, massDeleteInventory, removeMessage, addQueueMessage } from '../redux/actions/inventoryActions'
import { connect } from 'react-redux'
import ViewInventoryModal from '../modals/ViewInventoryModal'
import CreateCheckModal from '../modals/CreateCheckModal'
import EditInventoryModal from '../modals/EditInventoryModal'
import CheckoutInventoryModal from '../modals/CheckoutInventoryModal'
import EmailTenantModal from '../modals/EmailTenantModal'
import ConfirmModal from '../modals/ConfirmModal'
import InputModal from '../modals/InputModal'
import SelectAutosaveModal from '../modals/SelectAutosaveModal'
import {withGetScreen} from 'react-getscreen'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import ReactPaginate from 'react-paginate';
import { stringify } from 'query-string';
import { api_url } from '../api_url'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import DatePicker from "react-datepicker";
import history from '../history';
import "react-datepicker/dist/react-datepicker.css";
import RefreshButton from '../components/RefreshButton'
import { inventoryParams } from '../components/defaultParams'
import { getUserList } from '../redux/actions/userActions'
import { fetchSettings } from '../redux/actions/settingsActions'
import localForage from "localforage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faFileArchive } from '@fortawesome/free-solid-svg-icons'
import StorageWarning from '../components/StorageWarning'
import { checkStorage } from '../redux/actions/offlineActions'
import JSZip from 'jszip';
import fs from 'fs'
import { getBase64Strings } from 'exif-rotate-js/lib';

const today = new Date()

class InventoryPage extends Component {

  constructor(props){
    super(props)
    this.getDefaultState = this.getDefaultState.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.resetCurrentPage = this.resetCurrentPage.bind(this);
    this.getInventoryRecord = this.getInventoryRecord.bind(this);
    this.state = this.getDefaultState()
  }

  getDefaultState() {
    return {
      currentPage: null,
      pageCount: 1,
      showViewInventoryModal: false,
      showCreateInventoryModal: false,
      showConfirmModal: false,
      showEmailTenantModal: false,
      showEmailTenantPDFModal: false,
      emailTenantPDFEmail: null,
      emailTenantPDFError: null,
      showSelectAutosaveModal: false,
      showCheckoutInventoryModal: false,
      emailTenantErrorMessage: null,
      emailTenantBody: null,
      confirmModalTitle: "",
      confirmModalBody: "",
      confirmModalCallback: null,
      selectedDropdownItem: null,
      searchValue: "",
      searchOptions: [],
      searchSelected: [],
      searchIsLoading: false,
      searchIsVisible: false,
      params: inventoryParams(),
      // appointmentDateStart: window.location.pathname === '/reports' ? null : today,
      // appointmentDateEnd: window.location.pathname === '/reports' ? null : today,
      selected: [],
      messages: [],
      previousLocation: null,
      authenticationCode: null,
      inventoryAutosave: null,
      inventoryAutosaveName: null,
      checkoutAutosave: null,
      checkoutAutosaveName: null,
      localStorageKeys: [],
      displayAutosaveMode: 'inventory',
    }
  }

  initialiseComponent = () => {
    console.log("initialiseComponent")
    this.displayModalFromHash()
    var urlParams = new URLSearchParams(window.location.search);
    let params = inventoryParams()
    if (urlParams.get('page')){
      params.page = urlParams.get('page')
    }
    if (urlParams.get('ordering')){
      params.ordering = urlParams.get('ordering')
    }
    if (urlParams.get('q')){
      params.q = urlParams.get('q')
      this.setState({ 
        searchValue: urlParams.get('q'),
        searchOptions: [{address_full: urlParams.get('q')}],
        searchSelected: [{address_full: urlParams.get('q')}],
      })
    }
    // if (urlParams.get('appointment_date__gte') || urlParams.get('appointment_date__gte') === ''){
    //   params.appointment_date__gte = urlParams.get('appointment_date__gte')
    //   this.setState({
    //     appointmentDateStart: this.parseDate(urlParams.get('appointment_date__gte'))
    //   })
    // }
    // if (urlParams.get('appointment_date__lte') || urlParams.get('appointment_date__lte') === ''){
    //   params.appointment_date__lte = urlParams.get('appointment_date__lte')
    //   this.setState({
    //     appointmentDateEnd: this.parseDate(urlParams.get('appointment_date__lte'))
    //   })
    // }
    this.setState({ 
      params,
    })
    
    localForage.keys().then(keys => {
      this.setState({localStorageKeys: keys})
    })
    this.props.fetchInventories(params, false)
  }

  componentWillMount() {
    this.initialiseComponent()
    this.unlisten = history.listen((location, action) => {
      // console.log("location", location, this.state.previousLocation)
      if (location.hash.length === 0 && this.state.previousLocation && this.state.previousLocation.hash.lenth === 0) this.initialiseComponent()
      this.setState({ previousLocation: location})
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  parseDate = dateString => {
    if (dateString){
      let dayMonthYear = dateString.split('-')
      let d = new Date()
      d.setDate(dayMonthYear[0])
      d.setMonth(dayMonthYear[1])
      d.setYear(dayMonthYear[2])
      return d
    }
    return null
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps)
  	const { params, searchValue } = this.state
    if (this.props.inventoriesStale){
      this.handleRefresh()
    }
  	if (nextProps.inventories[params.page]){
      localForage.keys().then(keys => {
        this.setState({localStorageKeys: keys})
      })
  		this.setState({
  			currentPage: nextProps.inventories[params.page],
  		})
  	}
  }

  handleRefresh = () => {
    this.setState(this.getDefaultState(), this.initialiseComponent)
  }

  async getInventoryHasAutosave(itemID){
    return new Promise(function(resolve, reject) {
      localForage.keys().then(function(keys) {
        // An array of all the key names.
        const autosaves = keys.filter(key => key === `save_inventory_${itemID}` || key.startsWith(`save_inventory_${itemID}_`))
        if (autosaves.length > 0){
          resolve(true)
        } else {
          resolve(false)
        }
      })
    });
  }

  async getCheckoutHasAutosave(itemID){
    return new Promise(function(resolve, reject) {
      localForage.keys().then(function(keys) {
        // An array of all the key names.
        const autosaves = keys.filter(key => key === `save_checkout_${itemID}` || key.startsWith(`save_checkout_${itemID}_`))
        if (autosaves.length > 0){
          resolve(true)
        } else {
          resolve(false)
        }
      })
    });
  }

  startCheckoutInventory = (item) => {
    this.setState({displayAutosaveMode: 'checkout'})
    console.log("startCheckoutInventory", item)
    let itemID = null
    if (item) itemID = item.id
    else if (history.location.hash.length > 0) itemID = history.location.hash.split('-').pop()

    let thisComp = this
    this.getCheckoutHasAutosave(itemID).then(result => {
      console.log("getCheckoutHasAutosave result", result)
      if (result){
          thisComp.handleShowInventoryModal('handleShowSelectAutosaveModal', item, true)
        } else {
          thisComp.handleShowInventoryModal('handleShowCheckoutInventoryModal', item, false)
      }
    })
  }

  startEditInventory = (item) => {
    this.setState({displayAutosaveMode: 'inventory'})
    console.log("startEditInventory", item)
    let itemID = null
    if (item) itemID = item.id
    else if (history.location.hash.length > 0) itemID = history.location.hash.split('-').pop()

    let thisComp = this
    this.getInventoryHasAutosave(itemID).then(result => {
      console.log("getInventoryHasAutosave result", result)
      if (result){
          thisComp.handleShowInventoryModal('handleShowSelectAutosaveModal', item, true)
        } else {
          thisComp.handleShowInventoryModal('handleShowEditInventoryModal', item, false)
      }
    })
  }

  clearFileInput = (ctrl) => {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  dataURLtoFile = (dataURI, filename) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: 'image/jpeg' });
  }

  startLoadInventory = event => {
    console.log("startLoadInventory")
    let thisComp = this

    let images = []
    let files = []
    if (event.target.files){
      files = Array.from(event.target.files)
    } else if (event.dataTransfer) {
      files = Array.from(event.dataTransfer.files)
    }
    console.log("files", files)
    this.clearFileInput(event.target)

    if (files.length > 0) {

      JSZip.loadAsync(files[0]).then(function (zip) {

        console.log("zip.files", zip.files)

        Promise.all(
          Object.values(zip.files).map(file => {
            if (!file || file.dir){
              return null;
            }
            return (
              file.name.includes('item.json') ? 
                zip.file(file.name).async("string")
              :
                zip.file(file.name).async("arraybuffer")
            )
          })
        ).then(async (results) => {
          let itemIndex = Object.values(zip.files).findIndex(file => file.name.includes('item.json'))
          console.log("itemIndex", itemIndex)
          if (itemIndex !== -1){
            console.log("results", results)
            let savedState = JSON.parse(results[itemIndex])
            console.log("savedState", savedState)

            function addFileObjects(images) {
              if (images && images.length > 0){
                for (const imageItem of images) {
                  if (imageItem.dateTaken) imageItem.dateTaken = new Date(imageItem.dateTaken)
                  if (imageItem.autosaveID){
                    let imageIndex = Object.values(zip.files).findIndex(file => file.name === `${imageItem.autosaveID}.jpeg`)
                    if (imageIndex !== -1){
                      let fileName = Object.values(zip.files)[imageIndex].name
                      imageItem.url = new Blob([results[imageIndex]], {type : 'image/jpeg'})//URL.createObjectURL(new Blob([results[imageIndex]], {type : 'image/jpeg'}))
                    }
                  }
                }
              }
            }

            // Add File objects
            for (const roomItem of savedState.rooms) {
              for (const roomItemItem of roomItem.items) {
                addFileObjects(roomItemItem.images)
              }
            }

            addFileObjects(savedState.electricity_meter_images)
            addFileObjects(savedState.gas_meter_images)
            addFileObjects(savedState.water_meter_images)
            addFileObjects(savedState.fuse_board_images)
            addFileObjects(savedState.stop_tap_images)
            addFileObjects(savedState.keys_codes_images)

            // savedState.rooms.sort((a, b) => a.order - b.order).forEach((room, roomIndex) => {
            //   room.order = roomIndex
            //   room.items.sort((aa, bb) => aa.order - bb.order).forEach((roomItem, roomItemIndex) => {
            //     roomItem.order = roomItemIndex
            //   })
            // })

            if (savedState.tenancy_start_date){
              let d = new Date(savedState.tenancy_start_date)
              savedState.tenancy_start_date = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
            }
            if (savedState.property_compiled_date){
              let d = new Date(savedState.property_compiled_date)
              savedState.property_compiled_date = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
            }
            // savedState.tenancy_start_date = savedState.tenancy_start_date ? new Date(savedState.tenancy_start_date) : null
            // savedState.property_compiled_date = savedState.property_compiled_date ? new Date(savedState.property_compiled_date) : null

            console.log("zip result", savedState)
            
            thisComp.handleShowInventoryModal('handleShowEditInventoryModal', savedState, true)
          }


          // results.forEach((dataItem, index) => {
          //   if (typeof dataItem === 'object'){
          //     let fileName = Object.values(zip.files)[index].name
          //     let url = URL.createObjectURL(new Blob([dataItem], {type : 'image/jpeg'}))
          //   }
          // })
        })
      });
      // fs.readFile(files[0], function(err, data) {
      //   if (err) throw err;
      //   JSZip.loadAsync(data).then(function (zip) {
      //       // ...
      //       console.log("data", data)
      //   });
      // });
    }
  }

  startCreateInventory = () => {
    this.setState({displayAutosaveMode: 'inventory'})
    let thisComp = this
    this.getInventoryHasAutosave(0).then(result => {
      console.log("getInventoryHasAutosave result", result)
      if (result){
          thisComp.handleShowInventoryModal('handleShowSelectAutosaveModal', null, true)
        } else {
          thisComp.handleShowInventoryModal('handleShowCreateInventoryModal', null, false)
      }
    })
  }

  startEmailTenant = (item) => {
    const base_url = api_url.includes('sab.hjwebdev.com') ? 'https://sab.hjwebdev.com' : 'http://127.0.0.1:8000'
    this.setState({
      emailTenantBody:
      <>
        <form action={`${base_url}/tenant-copy/`} method="post" id={`email-tenant-preview-form-${item.id}`} target="_blank">
          <input type="hidden" name="id" value={item.id}/>
          <input type="hidden" name="token" value={window.localStorage.getItem('authKey')}/>
        </form>
        <p>Click <a href="#" onClick={(e) => {e.preventDefault(); document.getElementById(`email-tenant-preview-form-${item.id}`).submit();}}>here</a> to preview the tenant comment form.</p>
      </>
    }, () => this.handleShowInventoryModal('handleShowEmailTenantModal', item, true))
  }

  startEmailTenantPDF = (item) => {
    this.setState({
      emailTenantPDFEmail: item.tenant_email,
    }, () => this.handleShowInventoryModal('handleShowEmailTenantPDFModal', item, true))
  }

  submitEmailTenantPDF = (tenant_email) => {
    let thisComp = this
    console.log("submitEmailTenantPDF", tenant_email)
    const {selectedDropdownItem} = this.state
    this.handleClose()
    function validateEmail(tenant_email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(tenant_email).toLowerCase());
    }
    if (validateEmail(tenant_email)){
      // continue...
      console.log("okay")
      this.setState({
        emailTenantPDFError: null,
      })
      const endpoint = `${api_url}rest-auth/email_inventory_pdf/` 
      let lookupOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+window.localStorage.getItem('authKey'),
        },
        body: JSON.stringify({
          'email': tenant_email,
          'id': selectedDropdownItem.id,
        })
      }
      fetch(endpoint, lookupOptions)
      .then((response) => {
        if (response.ok){
          return response.json()
        } else {
          thisComp.props.addQueueMessage({type: 'emailTenantPDFInventory-error'})
        }
      })
      .then((responseData) => {
        if (responseData && responseData.status === 'success'){
          //Add email success alert
          thisComp.props.addQueueMessage({type: 'emailTenantPDFInventory-success'})
        } else {
          thisComp.props.addQueueMessage({type: 'emailTenantPDFInventory-error'})
        }
      })
    } else {
      this.setState({
        emailTenantPDFError: `"${tenant_email}" is not a valid email address.`,
      })
    }
  }

  loadAutosave = (autosave, item) => {
    console.log("loadAutosave", autosave, item)
    localForage.getItem(autosave).then((result) => {
      if (result){
        this.setState({ 
          inventoryAutosave: result,
          inventoryAutosaveName: autosave,
        }, () => this.handleShowInventoryModal('handleShowEditInventoryModal', item, true))
      } else {
        console.log("no autosave found. this is a problem.")
      }
    })
  }

  loadCheckoutAutosave = (autosave, item) => {
    console.log("loadCheckoutAutosave", autosave, item)
    localForage.getItem(autosave).then((result) => {
      if (result){
        this.setState({ 
          checkoutAutosave: result,
          checkoutAutosaveName: autosave,
        }, () => this.handleShowInventoryModal('handleShowCheckoutInventoryModal', item, true))
      } else {
        console.log("no autosave found. this is a problem.")
      }
    })
  }

  openInNewWindow = (e, url) => {
    e.preventDefault()
    let params = `scrollbars=yes,resizable=yes,status=yes,location=yes,toolbar=yes,menubar=yes`;
    window.open(url, null, params);
    // window.open(url, "_blank")
  }

  getInventoryRecord(item, message) {
    const { authenticationCode, localStorageKeys } = this.state
    // const { userList, currentID } = this.props
    const hasSave = localStorageKeys.find(key => key === `save_inventory_${item.id}` || key.startsWith(`save_inventory_${item.id}_`))
    const hasSaveCheckout = localStorageKeys.find(key => key === `save_checkout_${item.id}` || key.startsWith(`save_checkout_${item.id}_`))
    const optionsLabel = this.props.isMobile() ? "" : "Options"
    let address_full = []
    if (item.address_full) {
      address_full = [
        item.address_full.tenant_address_line_1, 
        item.address_full.tenant_address_line_2, 
        item.address_full.tenant_town_city, 
        item.address_full.tenant_county, 
        item.address_full.tenant_postcode
      ].filter(function (el) {
        return el !== null && el !== "";
      })
    } else if (item.address && item.address.address_full) {
      address_full = item.address.address_full.split(', ')
    }
    let tenant_has_responded_m = 'Not requested'
    let tenant_has_responded = 'N/A'
    if (item.tenant_has_responded) {
      tenant_has_responded_m = 'Tenant has responded'
      tenant_has_responded = 'Yes'
    } else if (item.tenant_deadline_expired) {
      tenant_has_responded_m = 'Deadline expired'
      tenant_has_responded = 'Deadline expired'
    } else if (item.tenant_deadline) {
      tenant_has_responded_m = `Deadline ${item.tenant_deadline}`
      tenant_has_responded = `Deadline ${item.tenant_deadline}`
    }

    let downloadName = `${item.id}`
    if (address_full.length > 0){
      downloadName = downloadName + ' ' + address_full[0]
    }

    // let office = 'default'
    // if (userList && (currentID || currentID === 0)){
    //   let foundUser = userList.find(userItem => userItem.id === currentID)
    //   if (foundUser && foundUser.office) office = foundUser.office
    // }

    return (
      <tr key={item.id} onClick={(e) => this.checkFromRow(e, item.id)} className={message ? "success-row" : ""}>
        <td><input id={`select-${item.id}`} type="checkbox" style={{ pointerEvents: "none" }}/></td>
        <td>{item.id}</td>
        <td>
          {address_full.length > 0 ? address_full.join(", ") : '-'}
          <div className="display-xxs"><b>TSD: {item.tenancy_start_date}</b></div>
          <div className="display-xxs"><b>RCD: {item.property_compiled_date}</b></div>
          <div className="display-xxs">{tenant_has_responded_m}</div>
        </td>
        {!this.props.isMobile() && 
          <>
            <td className="hidden-xxs">{item.tenancy_start_date}</td>
            <td className="hidden-xxs">{item.property_compiled_date}</td>
            <td className="hidden-xxs">{tenant_has_responded}</td>
          </>
        }
        <td>
          <DropdownButton title={optionsLabel} variant="sab" className="float-right" alignRight>
            <Dropdown.Item onClick={() => this.startEditInventory(item)}>{hasSave && <FontAwesomeIcon icon={faSave} className="mr-2" />}Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => this.handleShowInventoryModal('handleShowViewInventoryModal', item, true)}>View</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.startEmailTenant(item)}>Request tenant comments</Dropdown.Item>
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.startEmailTenantPDF(item)}>Email tenant PDF</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => this.startCheckoutInventory(item)}>{hasSaveCheckout && <FontAwesomeIcon icon={faSave} className="mr-2" />}Checkout</Dropdown.Item>
            <Dropdown.Divider />
            {/**<Dropdown.Item disabled={!this.props.isOnline} href={`${api_url}rest-auth/download_inventory_docx/?id=${item.id}&he=${this.props.hiddenElement}`} target="_blank">Download DOCX Inventory</Dropdown.Item>**/}
            {/**Note to self: the download tag won't function correctly on local due to cross-browser requirement**/}
            <Dropdown.Item disabled={!this.props.isOnline} rel="noopener noreferrer external" href={`${api_url.replace('.com', '.co.uk').replace('/sab.', '/sab-pdf.')}rest-auth/download_inventory_pdf/?id=${item.id}&he=${this.props.hiddenElement}`} target="_blank">Download PDF Inventory</Dropdown.Item>
            <Dropdown.Divider />
            {/**<Dropdown.Item disabled={!this.props.isOnline} href={`${api_url}rest-auth/download_checkout_docx/?id=${item.id}&he=${this.props.hiddenElement}`} target="_blank">Download DOCX Checkout</Dropdown.Item>**/}
            <Dropdown.Item disabled={!this.props.isOnline} href={`${api_url.replace('.com', '.co.uk').replace('/sab.', '/sab-pdf.')}rest-auth/download_checkout_pdf/?id=${item.id}&he=${this.props.hiddenElement}`} target="_blank">Download PDF Checkout</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.confirmDelete(item.id)}>Delete</Dropdown.Item>
          </DropdownButton>
        </td>
        <td>
          {(hasSave || hasSaveCheckout) && <FontAwesomeIcon icon={faSave} className="" />}
        </td>
      </tr>
    )
  }

  renderTimestamp = timestamp => {
    if (!timestamp) return null
    // 12-12-1212
    // 0123456789
    const newTimestamp = `${timestamp.substring(3,5)}/${timestamp.substring(0,2)}/${timestamp.substring(6,10)}`
    const todayTimestamp = ("0"+(today.getMonth()+1)).slice(-2) + "/" + ("0" + today.getDate()).slice(-2) + "/" + today.getFullYear()
    // return newTimestamp
    let prefix = "";
    const timeDiff = Math.round(
      (new Date(todayTimestamp).getTime() - new Date(newTimestamp).getTime()) / 60000
    );
    const daysDiff = Math.round(timeDiff / (60 * 24))
    if (timeDiff < 0){
      prefix = ''
    }
    else if (timeDiff < 24 * 60) {
      // less than 24 hours ago
      prefix = `Today`;
    } else if (timeDiff < (24 * 60) * 2) {
      // less than 7 days ago
      prefix = `Yesterday`;
    } else {
      // less than 7 days ago
      prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
    }
    return prefix;
  };

  getRowHeading = value => {
    // if (!value) return null
    return (
      <tr className="heading-row">
        <td colSpan={!this.props.isMobile()?8:5}>
          <h4 className="mt-2 ml-2">{value ? value : '-'}{value && <span className="small ml-2">{this.renderTimestamp(value)}</span>}</h4>
        </td>
      </tr>
    )
  }

  getInventoryContent = () => {
    const { currentPage, params } = this.state
    if (currentPage.results.length === 0 || currentPage.results[0].length === 0) return null
    const ordering = params.ordering ? params.ordering.replace('-', '') : null
    // if (ordering === 'date_created' || ordering === 'tenancy_start_date' || ordering === 'property_compiled_date' || ordering === 'tenancy_end_date')
    let heading = null
    const headingsEnabled = ordering === 'date_created' || ordering === 'tenancy_start_date' || ordering === 'property_compiled_date' || ordering === 'tenancy_end_date'
    if (headingsEnabled && currentPage.results && currentPage.results.length && currentPage.results[0].appointment_date) heading = currentPage.results[0][ordering]
    //params.ordering && (params.ordering === 'appointment_date' || params.ordering === '-appointment_date')
    return (
      <>
        {currentPage.results.map((item, index) => {
          let showHeading = false
          if (item[ordering] !== heading) {
            heading = item[ordering]
            showHeading = true
          }
          return (
            <>
              {headingsEnabled && (showHeading||!index) && this.getRowHeading(heading)}
              {this.getInventoryRecord(item, false)}
            </>
          )
        })}
      </>
    )
  }

  // getInventoryContent = () => {
  // 	const { currentPage } = this.state
  // 	return (
  // 	  <>
  // 	  	{currentPage.results.map(item => {
  //         return this.getInventoryRecord(item, false)
  // 	  	})}
  // 	  </>
  // 	)
  // }

  massDelete = () => {
  	this.handleCloseSilent()
  	const { selected } = this.state
  	this.props.massDeleteInventory(selected)
  	this.setState({
  		selected: [],
  	})
  }

  confirmDelete = (itemID) => {
    history.push(`#confirm`);
    this.setState({ 
      showConfirmModal: true,
      confirmModalTitle: "Delete Inventory",
      confirmModalBody: "Are you sure you want to delete the selected inventory?",
      confirmModalCallback: () => {
        this.handleCloseSilent()
        this.props.deleteInventory(itemID)
      },
    });
  }

  confirmMassDelete = () => {
    history.push(`#confirm`);
    this.setState({ 
    	showConfirmModal: true,
    	confirmModalTitle: "Mass Deletion",
    	confirmModalBody: "Are you sure you want to delete the selected inventories?",
    	confirmModalCallback: this.massDelete
    });
  }

  displayModalFromHash = () => {
    const {showConfirmModal, showViewInventoryModal, showCreateInventoryModal, showEditInventoryModal, showEmailTenantModal, showSelectAutosaveModal, showCheckoutInventoryModal, showEmailTenantPDFModal} = this.state
    const modalOpen = showConfirmModal || showViewInventoryModal || showCreateInventoryModal || showEditInventoryModal || showEmailTenantModal || showSelectAutosaveModal || showCheckoutInventoryModal || showEmailTenantPDFModal

    let autosaveName = null
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('autosave')){
      autosaveName = urlParams.get('autosave')
    }

    if (history.location.hash.length === 0 && modalOpen === true){
      this.setState({ 
        inventoryAutosave: null,
        checkoutAutosave: null,
        selectedDropdownItem: null,
        showConfirmModal: false,
        showViewInventoryModal: false,
        showCreateInventoryModal: false,
        showEditInventoryModal: false,
        showEmailTenantModal: false,
        showEmailTenantPDFModal: false,
        showSelectAutosaveModal: false,
        // emailTenantErrorMessage: null,
        showCheckoutInventoryModal: false,
      });
    } else if (history.location.hash.startsWith('#view')){
      this.handleShowInventoryModal('handleShowViewInventoryModal', null, false)
    } else if (history.location.hash.startsWith('#create')){
      // this.handleShowInventoryModal('handleShowCreateInventoryModal', null, false)
      this.startCreateInventory()
    } else if (history.location.hash.startsWith('#edit')){

      let thisComp = this
      function fetchInventory() {
        console.log("displayModalFromHash, fetchInventory")
        let itemID = history.location.hash.split('-').pop()
        const endpoint = `${api_url}rest-auth/inventories/${itemID}/` 
        let lookupOptions = {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token '+window.localStorage.getItem('authKey'),
          }
        }
        fetch(endpoint, lookupOptions)
        .then(function(response){
          if (response.ok){
            return response.json()
          } else {
            if (autosaveName) thisComp.loadAutosave(autosaveName, null)
            else {
              thisComp.startEditInventory(null)
            }
          }
        }).then(function(responseData){
          if (responseData){
            if (autosaveName) thisComp.loadAutosave(autosaveName, responseData)
            else {
              thisComp.startEditInventory(responseData)
            }
          }
        }).catch(function(error){
          console.log("error", error)
        })
      }

      if (!navigator.onLine){
        if (autosaveName) thisComp.loadAutosave(autosaveName, null)
        else {
          thisComp.startEditInventory(null)
        }
      } else {
        fetchInventory()
      }

    } else if (history.location.hash.startsWith('#checkout')){

      let thisComp = this
      function fetchInventory() {
        console.log("displayModalFromHash, fetchInventory")
        let itemID = history.location.hash.split('-').pop()
        const endpoint = `${api_url}rest-auth/inventories/${itemID}/` 
        let lookupOptions = {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token '+window.localStorage.getItem('authKey'),
          }
        }
        fetch(endpoint, lookupOptions)
        .then(function(response){
          if (response.ok){
            return response.json()
          } else {
            if (autosaveName) thisComp.loadCheckoutAutosave(autosaveName, null)
            else {
              thisComp.startCheckoutInventory(null)
            }
          }
        }).then(function(responseData){
          if (responseData){
            if (autosaveName) thisComp.loadCheckoutAutosave(autosaveName, responseData)
            else {
              thisComp.startCheckoutInventory(responseData)
            }
          }
        }).catch(function(error){
          console.log("error", error)
        })
      }

      if (!navigator.onLine){
        if (autosaveName) thisComp.loadCheckoutAutosave(autosaveName, null)
        else {
          thisComp.startCheckoutInventory(null)
        }
      } else {
        fetchInventory()
      }
    }
  }

  handleShowInventoryModal = (name, item, pushHistory) => {
    console.log("handleShowInventoryModal")
    const {inventoryAutosave, checkoutAutosave} = this.state
    function historyPush(hash, item){
      item ? history.push(hash, {item: item}) : history.push(hash)
    }
    
    if (name === 'handleShowCheckoutInventoryModal'){
      if (pushHistory) {
        if (item && item.id) historyPush(`#checkout-${item.id}`, item)
        else if (checkoutAutosave && checkoutAutosave.id) historyPush(`#checkout-${checkoutAutosave.id}`)
      }
      this.setState({ 
        inventoryAutosave: null,
        inventoryAutosaveName: null,
        selectedDropdownItem: item,
        showViewInventoryModal: false,
        showConfirmModal: false,
        showCreateInventoryModal: false,
        showEditInventoryModal: false,
        showEmailTenantModal: false,
        showEmailTenantPDFModal: false,
        showSelectAutosaveModal: false,
        showCheckoutInventoryModal: true,
      });

    } else if (name === 'handleShowSelectAutosaveModal'){
      this.setState({ 
        inventoryAutosave: null,
        inventoryAutosaveName: null,
        checkoutAutosave: null,
        checkoutAutosaveName: null,
        selectedDropdownItem: item,
        showViewInventoryModal: false,
        showConfirmModal: false,
        showCreateInventoryModal: false,
        showEditInventoryModal: false,
        showEmailTenantModal: false,
        showEmailTenantPDFModal: false,
        showSelectAutosaveModal: true,
        showCheckoutInventoryModal: false,
      });
    } else if (name === 'handleShowEmailTenantModal'){
      this.setState({ 
        inventoryAutosave: null,
        inventoryAutosaveName: null,
        checkoutAutosave: null,
        checkoutAutosaveName: null,
        selectedDropdownItem: item,
        showViewInventoryModal: false,
        showConfirmModal: false,
        showCreateInventoryModal: false,
        showEditInventoryModal: false,
        showEmailTenantModal: true,
        showEmailTenantPDFModal: false,
        showSelectAutosaveModal: false,
        showCheckoutInventoryModal: false,
      });
    } else if (name === 'handleShowEmailTenantPDFModal'){
      this.setState({ 
        inventoryAutosave: null,
        inventoryAutosaveName: null,
        checkoutAutosave: null,
        checkoutAutosaveName: null,
        selectedDropdownItem: item,
        showViewInventoryModal: false,
        showConfirmModal: false,
        showCreateInventoryModal: false,
        showEditInventoryModal: false,
        showEmailTenantModal: false,
        showEmailTenantPDFModal: true,
        showSelectAutosaveModal: false,
        showCheckoutInventoryModal: false,
      });
    } else if (name === 'handleShowViewInventoryModal'){
      if (pushHistory) historyPush(`#view-${item.id}`, item)
      this.setState({ 
        inventoryAutosave: null,
        inventoryAutosaveName: null,
        checkoutAutosave: null,
        checkoutAutosaveName: null,
        selectedDropdownItem: item,
        showViewInventoryModal: true,
        showConfirmModal: false,
        showCreateInventoryModal: false,
        showEditInventoryModal: false,
        showEmailTenantModal: false,
        showEmailTenantPDFModal: false,
        showSelectAutosaveModal: false,
        showCheckoutInventoryModal: false,
      });
    } else if (name === 'handleShowCreateInventoryModal'){
      if (pushHistory) historyPush(`#create`);
      this.setState({ 
        inventoryAutosave: null,
        inventoryAutosaveName: null,
        checkoutAutosave: null,
        checkoutAutosaveName: null,
        selectedDropdownItem: null,
        showCreateInventoryModal: true,
        showConfirmModal: false,
        showViewInventoryModal: false,
        showEditInventoryModal: false,
        showEmailTenantModal: false,
        showEmailTenantPDFModal: false,
        showSelectAutosaveModal: false,
        showCheckoutInventoryModal: false,
      });
    } else if (name === 'handleShowEditInventoryModal'){
      if (pushHistory) {
        if (item) historyPush(`#edit-${item.id ? item.id : 0}`, item)
        else if (inventoryAutosave) historyPush(`#edit-${inventoryAutosave.id ? inventoryAutosave.id : 0}`)
      }
      this.setState({ 
        selectedDropdownItem: item,
        showEditInventoryModal: true,
        showConfirmModal: false,
        showViewInventoryModal: false,
        showCreateInventoryModal: false,
        showEmailTenantModal: false,
        showEmailTenantPDFModal: false,
        showSelectAutosaveModal: false,
        showCheckoutInventoryModal: false,
      });
    }
    window.scrollTo(0, 0)
  }

  handleClose = () => {
    this.resetCurrentPage()
    this.setState({ 
      selectedDropdownItem: null,
    	showConfirmModal: false,
    	showViewInventoryModal: false,
    	showCreateInventoryModal: false,
      showEditInventoryModal: false,
      showEmailTenantModal: false,
      showEmailTenantPDFModal: false,      
      showSelectAutosaveModal: false,
      // emailTenantErrorMessage: null,
      showCheckoutInventoryModal: false,
      inventoryAutosave: null,
      inventoryAutosaveName: null,
      checkoutAutosave: null,
      checkoutAutosaveName: null,
    });
    window.scrollTo(0, 0)
  }

  handleCloseSilent = () => {
    this.setState({ 
      selectedDropdownItem: null,
      showConfirmModal: false,
      showViewInventoryModal: false,
      showCreateInventoryModal: false,
      showEditInventoryModal: false,
      showEmailTenantModal: false,
      showEmailTenantPDFModal: false,
      showSelectAutosaveModal: false,
      // emailTenantErrorMessage: null,
      showCheckoutInventoryModal: false,
      inventoryAutosave: null,
      inventoryAutosaveName: null,
      checkoutAutosave: null,
      checkoutAutosaveName: null,
    });
    window.scrollTo(0, 0)
  }


	handlePageChange = (page) => {
  	const allCheckbox = document.getElementById('select-all')
  	allCheckbox.checked = false
  	this.setState({ selected: [] })
		const newPage = page.selected+1
		this.setState({
  		params: Object.assign({}, this.state.params, {
		        page: newPage
		      }),
		}, () => {
	  	this.resetCurrentPage()
		})
	}

  onKeyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13){
    	event.target.blur()
    }
  }

  onFocus = () => {
  	this.setState({
  		searchIsVisible: true,
  	})
  }

  onBlur = () => {
  	this.setState({
  		searchIsVisible: false,
  	})
  }

  onChange = (query) => {
    const q = query && query.length > 0 && query[0].address_full ? query[0].address_full : null
    const original_search = query && query.length > 0 && query[0].original_search ? query[0].original_search : null
    let params = Object.assign({}, this.state.params, {
      page: 1,
    })
    delete params.q
    if (q) params.q = q
    if (original_search) {
      params.q = original_search
    }
  	this.setState({
  		params: params,
  		searchIsVisible: false,
  		searchSelected: query,
  	}, () => {
  		this.resetCurrentPage()
  	})
  }

  onInputChange = (searchValue) => {
    this.setState({
    	searchValue: searchValue,
    	searchIsLoading: true,
      searchIsVisible: true,
    });
    const params = {
  		q: searchValue,
  	}
  	const endpoint = `${api_url}rest-auth/address-autocomplete/?${stringify(params)}` 
  	let lookupOptions = {
  		method: "GET",
  		headers: {
  			'Content-Type': 'application/json',
        'Authorization': 'Token '+window.localStorage.getItem('authKey'),
      }
  	}
  	let thisComp = this
  	fetch(endpoint, lookupOptions)
  	.then(function(response){
  		return response.json()
  	}).then(function(responseData){
  		console.log("responseData", responseData)
  		thisComp.setState({ searchIsLoading: false, })
  		if (responseData){
  			responseData.results.unshift({original_search: searchValue})
  			thisComp.setState({
  				searchOptions: responseData.results,
  			})
  		}
  	}).catch(function(error){
  		console.log("error", error)
  	})
  }

  handleCheckAllToggle = () => {
  	const allCheckbox = document.getElementById('select-all')
  	allCheckbox.checked = !allCheckbox.checked
  	const { currentPage } = this.state
  	let selected = []
  	if (allCheckbox.checked){
  		currentPage.results.forEach(item => {
  			selected.push(item.id)
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.add("selected");
    		checkbox.checked = true
  		})
  	} else {
  		currentPage.results.forEach(item => {
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.remove("selected");
    		checkbox.checked = false
  		})
  	}
  	this.setState({
  		selected
  	})
  }

  checkFromRow = (event, id) => {
  	if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'A'){
    	const { selected } = this.state
    	const checkbox = document.getElementById(`select-${id}`)
    	checkbox.checked = !checkbox.checked
    	if (checkbox.checked){
    		checkbox.parentElement.parentElement.classList.add("selected");
    		this.setState({
    			selected: selected.concat([id])
    		})
    	} else {
    		document.getElementById('select-all').checked = false
    		checkbox.parentElement.parentElement.classList.remove("selected");
	    	this.setState({
	    		selected: selected.filter(item => item !== id)
	    	})
    	}
  	}
  }

  handleOrderByChange = (event) => {
  	this.setState({
  		params: Object.assign({}, this.state.params, {
  			ordering: event.target.value,
        page: 1,
  		}),
		}, () => {
			this.resetCurrentPage()
		})
  }

  resetCurrentPage = () => {
    console.log("resetCurrentPage")
    this.props.checkStorage()
    localForage.keys().then(keys => {
      this.setState({localStorageKeys: keys})
    })
  	const allCheckbox = document.getElementById('select-all')
    try{
      allCheckbox.checked = false
      this.setState({ selected: [] })
    } catch {}
		const { params } = this.state
		history.push({
		  search: `?${stringify(params)}`
		})
		this.props.fetchInventories(params, true)
  }

  submitEmailTenant = (email, deadlineDate) => {
    let thisComp = this
    console.log("submitEmail", email)
    const {selectedDropdownItem} = this.state
    this.handleClose()
    function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (validateEmail(email)){
      // console.log("okay")

      this.setState({
        emailTenantErrorMessage: null,
      })

      let deadline = null
      if (deadlineDate){
        let d = deadlineDate
        console.log("d", d)
        deadline = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      }
      const endpoint = `${api_url}rest-auth/request_tenant_comments/` 
      let lookupOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+window.localStorage.getItem('authKey'),
        },
        body: JSON.stringify({
          'email': email,
          'deadline': deadline,
          'id': selectedDropdownItem.id,
        })
      }

      fetch(endpoint, lookupOptions)
      .then((response) => {
        if (response.ok){
          return response.json()
        } else {
          // console.log("adding email error")
          thisComp.props.addQueueMessage({type: 'emailTenantInventory-error'})
        }
      })
      .then((responseData) => {
        if (responseData && responseData.status === 'success'){
          //Add email success alert
          thisComp.props.addQueueMessage({type: 'emailTenantInventory-success'})
        } else {
          // console.log("adding email error")
          thisComp.props.addQueueMessage({type: 'emailTenantInventory-error'})
        }
      }).catch(() => {
          // console.log("adding email error")
          thisComp.props.addQueueMessage({type: 'emailTenantInventory-error'})
      })
    } else {
      this.setState({
        emailTenantErrorMessage: `"${email}" is not a valid email address.`,
      })
    }
  }

  setInputReadOnly = () => {
    window.requestAnimationFrame(function() {
      document.querySelectorAll('.react-datepicker__input-container input').forEach(element => element.readOnly = true)
    });
  }

  render() {
    this.setInputReadOnly()
  	const { params, currentPage, selected, localStorageKeys } = this.state
    const createHasSave = localStorageKeys.find(key => key === `save_inventory_0` || key.startsWith(`save_inventory_0_`)) || false
  	// const { showViewInventoryModal, showCreateInventoryModal, showEditInventoryModal, showSelectAutosaveModal, selectedDropdownItem, inventoryAutosave, inventoryAutosaveName } = this.state
    const { showViewInventoryModal, showCreateInventoryModal, showEditInventoryModal, showSelectAutosaveModal, selectedDropdownItem, inventoryAutosave, inventoryAutosaveName, checkoutAutosave, checkoutAutosaveName, showCheckoutInventoryModal, showEmailTenantPDFModal, displayAutosaveMode } = this.state
    const { showEmailTenantModal, emailTenantErrorMessage, emailTenantBody } = this.state
    const { emailTenantPDFEmail, emailTenantPDFError } = this.state
  	const { showConfirmModal, confirmModalTitle, confirmModalBody, confirmModalCallback } = this.state
  	const { searchOptions, searchIsLoading, searchIsVisible, searchSelected } = this.state
  	const { inventoryMessages } = this.props
    // const { appointmentDateStart, appointmentDateEnd } = this.state
    const { userList, currentID } = this.props
    let foundUser = null
    if (userList && (currentID || currentID === 0)){
      foundUser = userList.find(userItem => userItem.id === currentID)
    }

  	const selectLabel = `${selected.length} selected`
  	const selectDisabled = selected.length === 0 ? true : false

    const checkoutInventoryModal = 
      <CheckoutInventoryModal
        show={showCheckoutInventoryModal} 
        handleClose={this.handleClose} 
        item={selectedDropdownItem} 
        handleShowInventoryModal={this.handleShowInventoryModal} 
        isMobile={this.props.isMobile} 
        settings={this.props.settings}
        checkoutAutosave={checkoutAutosave} 
        checkoutAutosaveName={checkoutAutosaveName} 
      />

    const emailTenantModal = 
      <EmailTenantModal 
        show={showEmailTenantModal} 
        handleSubmit={this.submitEmailTenant} 
        title={"Request Tenant Comments?"} 
        label={"Email"} 
        handleClose={this.handleClose} 
        isMobile={this.props.isMobile} 
        item={selectedDropdownItem} 
        errorMessage={emailTenantErrorMessage} 
        body={emailTenantBody}
      />

    const emailTenantPDFModal = 
      <InputModal 
        show={showEmailTenantPDFModal} 
        handleSubmit={this.submitEmailTenantPDF} 
        title={"Email Inventory PDF to Tenant"} 
        label={"Email"} 
        handleClose={this.handleClose} 
        isMobile={this.props.isMobile} 
        item={selectedDropdownItem} 
        errorMessage={emailTenantPDFError} 
        defaultValue={emailTenantPDFEmail}
      />

    const selectAutosaveModal = 
      <SelectAutosaveModal 
        show={showSelectAutosaveModal} 
        handleClose={this.handleClose} 
        isMobile={this.props.isMobile} 
        item={selectedDropdownItem} 
        loadAutosave={displayAutosaveMode === 'inventory' ? this.loadAutosave : this.loadCheckoutAutosave}
        handleShowModal={this.handleShowInventoryModal}
        handleShowCreateModalTag={displayAutosaveMode === 'inventory' ? 'handleShowCreateInventoryModal' : 'handleShowCheckoutInventoryModal'}
        handleShowEditModalTag={displayAutosaveMode === 'inventory' ? 'handleShowEditInventoryModal' : 'handleShowCheckoutInventoryModal'}
        savePrefix={displayAutosaveMode === 'inventory' ? 'save_inventory_' : 'save_checkout_'}
      />

    const confirmModal = 
      <ConfirmModal 
      show={showConfirmModal} 
      handleConfirm={confirmModalCallback} 
      title={confirmModalTitle} 
      body={confirmModalBody} 
      handleClose={this.handleClose} 
      isMobile={this.props.isMobile} 
    />

    const viewInventoryModal = 
      <ViewInventoryModal 
        show={showViewInventoryModal} 
        handleClose={this.handleClose} 
        handleShowInventoryModal={this.handleShowInventoryModal} 
        item={selectedDropdownItem} 
        isMobile={this.props.isMobile} 
        settings={this.props.settings}
        startEditInventory={this.startEditInventory}
      />

    const createInventoryModal = 
      <EditInventoryModal 
        show={showCreateInventoryModal} 
        handleClose={this.handleClose} 
        isMobile={this.props.isMobile} 
      />

    const editInventoryModal = 
      <EditInventoryModal 
        show={showEditInventoryModal} 
        handleClose={this.handleClose} 
        item={selectedDropdownItem} 
        inventoryAutosave={inventoryAutosave} 
        inventoryAutosaveName={inventoryAutosaveName} 
        handleShowInventoryModal={this.handleShowInventoryModal} 
        isMobile={this.props.isMobile} 
      />

    const modalOpen = showViewInventoryModal || showCreateInventoryModal || showEditInventoryModal || showSelectAutosaveModal || showCheckoutInventoryModal || showEmailTenantModal || showConfirmModal || showEmailTenantPDFModal
    if (modalOpen && this.props.isMobile()){
      if (showConfirmModal) return confirmModal
      else if (showCheckoutInventoryModal) return checkoutInventoryModal
      else if (showViewInventoryModal) return viewInventoryModal
      else if (showEditInventoryModal) return editInventoryModal
      else if (showCreateInventoryModal) return createInventoryModal
      else if (showEmailTenantModal) return emailTenantModal
      else if (showEmailTenantPDFModal) return emailTenantPDFModal
      else if (showSelectAutosaveModal) return selectAutosaveModal
    }

    const title = "Inventories"

    return (
    	<div className="page check-page">
        <Sidebar/>
      	<Navbar fixed="top">
  	    	<Container>
  		    	<h2 className="ml-5 ml-xl-0 d-inline-block">{title}</h2><RefreshButton onClick={this.handleRefresh} isRefreshing={currentPage?false:true}/>
  		    	<Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
  				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
  				  <Breadcrumb.Item active>{title}</Breadcrumb.Item>
  				</Breadcrumb>
  		    </Container>
      	</Navbar>
        {editInventoryModal}{createInventoryModal}{selectAutosaveModal}{viewInventoryModal}{emailTenantModal}{checkoutInventoryModal}{confirmModal}{emailTenantPDFModal}
      	<Container className="pt-5 pb-5 mt-4 mt-sm-5">
          <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
          <StorageWarning />
      		{inventoryMessages.map(message => {
            if (message.type === 'inventory-fetch'){
              return (
               <Alert key={`${message.id}`} variant="warning">
                 <Spinner animation="border" role="status" className="mr-3" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                 Submitting inventory {message.itemID}
               </Alert>
              )
            } else if (message.type === 'checkout-fetch'){
              return (
               <Alert key={`${message.id}`} variant="warning">
                 <Spinner animation="border" role="status" className="mr-3" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                 Submitting checkout {message.itemID}
               </Alert>
              )
            }	else if (message.type === 'emailTenantPDFInventory-success'){
              return (
               <Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
                 Successfully emailed inventory PDF to tenant.
               </Alert>
              )
            } else if (message.type === 'emailTenantPDFInventory-error'){
              return (
               <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                 Failed to email inventory PDF to tenant.
               </Alert>
              )
            } else if (message.type === 'emailTenantInventory-success'){
              return (
               <Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
                 Successfully emailed inventory.
               </Alert>
              )
            } else if (message.type === 'emailTenantInventory-error'){
              return (
               <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                 Failed to email inventory.
               </Alert>
              )
            } else if (message.type === 'checkoutInventory-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued checkout inventory.
                </Alert>
              )
            } else if (message.type === 'createInventory-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued create inventory.
                </Alert>
              )
            } else if (message.type === 'checkoutInventory-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued checkout inventory #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'editInventory-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued edit inventory #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'deleteInventory-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete inventory #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'massDeleteInventory-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete inventories #{message.id_array.join(', #')}.
                </Alert>
              )
            } else if (message.type === 'server-error'){
              return (
                <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  {message.error ? 
                  `There was an error: ${message.error}.`
                    :
                  "There was an unexpected error."
                  }
                </Alert>
              )
            } else {
      				return null
      			}
      		})}
      		<Row>
        		<Col className="mb-2 mb-sm-0">
          		<label htmlFor="address-search">Search:</label>
              <form autoComplete='off'>
      	    	<AsyncTypeahead
      	    		id={'address-search-typeahead'}
      	    		inputProps={{id: 'address-search', autoComplete: 'off'}}
        				options={searchOptions}
        				selected={searchSelected}
        				isLoading={searchIsLoading}
        				maxVisible={10}
        				placeholder="Search"
          			onSearch={this.onInputChange}
        				minLength={0}
        				useCache={false}
        				onKeyDown={this.onKeyDown}
        				selectHintOnEnter={true}
        				onBlur={this.onBlur}
        				onFocus={this.onFocus}
        				open={searchIsVisible}
        				onChange={this.onChange}
        				clearButton={true}
                labelKey={(option) => `${option.address_full || option.original_search}`}
                filterBy={(option, props) => {
                  return option.address_full || option.original_search
                }}
              />
              </form>
            </Col>
        		<Col sm={3}>
    		      <Form.Label>Order by:</Form.Label>
    		      <Form.Control as="select" onChange={this.handleOrderByChange} value={params.ordering}>
    		        <option value="id">ID ascending</option>
    		        <option value="-id">ID descending</option>
                <option value="date_created">Date created ascending</option>
                <option value="-date_created">Date created descending</option>
                <option value="tenancy_start_date">Tenancy start date ascending</option>
                <option value="-tenancy_start_date">Tenancy start date descending</option>
                <option value="property_compiled_date">Report compiled date ascending</option>
                <option value="-property_compiled_date">Report compiled date descending</option>
                <option value="tenancy_end_date">Tenancy end date ascending</option>
                <option value="-tenancy_end_date">Tenancy end date descending</option>
    		        <option value="tenant_address_line_1">Address line 1 ascending</option>
    		        <option value="-tenant_address_line_1">Address line 1 descending</option>
    		        <option value="tenant_postcode">Postcode ascending</option>
    		        <option value="-tenant_postcode">Postcode descending</option>
    		      </Form.Control>
        		</Col>
        		<Col xs={12} sm={0} className="d-block d-sm-none">
    	    		<Dropdown id="dropdown-basic-button" title="0 selected" variant="sab" className="mt-3">
      				  <Dropdown.Toggle variant="sab" id="dropdown-basic" className="btn-block" disabled={selectDisabled}>
      				    {selectLabel}
      				  </Dropdown.Toggle>
      					<Dropdown.Menu className="btn-block">
    		    		  <Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
    		    		</Dropdown.Menu>
    	    		</Dropdown>
        		</Col>
            {foundUser && (foundUser.username === 'harrycj@msn.com') &&
              <Col xs={6} sm={0} className="d-block d-sm-none">
                <label htmlFor={'load_inventory'} className="btn btn-info custom-file-upload mt-3 btn-block" onClick={()=>document.querySelector(`[name=load_inventory]`).click()}>
                    <FontAwesomeIcon className="mr-2" icon={faFileArchive} />Load ZIP
                </label>
                <input name={'load_inventory'} type="file" className="mb-3 d-none" onChange={(e) => this.startLoadInventory(e)}/>
              </Col>
            }
        		<Col xs={12} sm={0} className="d-block d-sm-none">
        			<Button variant="primary" className="mt-3 btn-block" onClick={() => this.startCreateInventory()}>{createHasSave && <FontAwesomeIcon icon={faSave} className="mr-2" />}Create</Button>
        		</Col>
            <div className="d-none d-sm-flex">
              <DropdownButton id="dropdown-basic-button" title={selectLabel} variant="sab" className="float-right align-self-end mr-3" alignRight disabled={selectDisabled}>
                <Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
              </DropdownButton>
            </div>
            {foundUser && (foundUser.username === 'harrycj@msn.com') &&
              <div className="d-none d-sm-flex">
                <label htmlFor={'load_inventory'} className="btn btn-info custom-file-upload mb-0 d-block float-right align-self-end mr-3" onClick={()=>document.querySelector(`[name=load_inventory]`).click()}>
                    <FontAwesomeIcon className="mr-2" icon={faFileArchive} />Load ZIP
                </label>
                <input name={'load_inventory'} type="file" className="mb-3 d-none" onChange={(e) => this.startLoadInventory(e)}/>
              </div>
            }
        		<div className="d-none d-sm-flex">
        			<Button variant="primary" className="float-right align-self-end mr-3" onClick={() => this.startCreateInventory()}>{createHasSave && <FontAwesomeIcon icon={faSave} className="mr-2" />}Create</Button>
        		</div>
          </Row>

          {/**<Row className="mt-2">
            <Col xs={6} sm={3}>
              <Form.Label>Assigned to:</Form.Label>
              <Form.Control as="select" onChange={this.handleAssignedToChange} value={params.assigned_to}>
                <option value="">All</option>
                {this.props.userList && this.props.userList.map(userItem => <option key={userItem.id} value={userItem.id}>{`${userItem.first_name} ${userItem.last_name}`}</option>)}
              </Form.Control>
            </Col>
            <Col xs={6} sm={3}>
              <Form.Label>Status:</Form.Label>
              <Form.Control as="select" onChange={this.handleStatusChange} value={params.status}>
                <option value="all">All</option>
                <option value="submitted">Submitted</option>
                <option value="pending">Pending</option>
              </Form.Control>
            </Col>
            <Col sm={6} className="mt-2 mt-sm-0">
              <Form.Label className="d-block">Appointment date:</Form.Label>
              <div className="d-inline-block" style={{ width: '49%' }}>
                <DatePicker
                  selectsStart
                  className="form-control"
                  placeholderText="from"
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  selected={appointmentDateStart}
                  startDate={appointmentDateStart}
                  endDate={appointmentDateEnd}
                  onChange={this.handleAppointmentDateStartChange}
                />
              </div>
              <div className="d-inline-block float-right" style={{ width: '49%' }}>
                <DatePicker
                  selectsEnd
                  className="form-control"
                  placeholderText="to"
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  selected={appointmentDateEnd}
                  startDate={appointmentDateStart}
                  endDate={appointmentDateEnd}
                  minDate={appointmentDateStart}
                  onChange={this.handleAppointmentDateEndChange}
                />
              </div>
            </Col>
          </Row>**/}
  	    	<Row className="mt-3">
    	    	<Col sm={12}>
          		<Table striped size="sm">
        			  <thead>
        			    <tr>
        			      <th onClick={this.handleCheckAllToggle}><input id="select-all" type="checkbox" style={{ pointerEvents: "none" }}/></th>
        			      <th>ID</th>
        			      <th>Address</th>
                    {!this.props.isMobile() &&
                      <>
                        <th className="hidden-xxs">Tenancy start date</th>
                        <th className="hidden-xxs">Report compiled date</th>
                        <th className="hidden-xxs">Tenant responded?</th>
                      </>
                    }
        			      <th></th>
        			    </tr>
        			  </thead>
                <tbody>
                  {currentPage ?
                    (inventoryMessages && inventoryMessages.length > 0) || (currentPage.results && currentPage.results.length > 0) ?
                    // (currentPage.results && currentPage.results.length > 0) ?
                    <>
                      {inventoryMessages.map(message => {
                        if (message.type === 'createInventory'){
                          let result = currentPage.results.filter(checkItem => checkItem.id === message.item.id)
                          if (result.length === 0) return this.getInventoryRecord(message.item, message)
                        }
                      })}
                      {this.getInventoryContent()}
                    </>
                    :
                    <tr>
                      <td colSpan="7" className="text-center pt-3" style={{ pointerEvents: "none" }}>
                        <p>No results</p>
                      </td>
                    </tr>
                  :
                  <tr style={{ pointerEvents: "none" }}>
                    <td colSpan="7" className="text-center pt-3">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                  }
                </tbody>
              </Table>
              {currentPage &&
          			<ReactPaginate
          				previousLabel={'previous'}
          				nextLabel={'next'}
          				breakLabel={'...'}
          				pageCount={currentPage.total_pages}
                  marginPagesDisplayed={this.props.isMobile() ? 1 : 2}
                  pageRangeDisplayed={this.props.isMobile() ? 3 : 4}
          				onPageChange={this.handlePageChange}
          				containerClassName={'pagination d-flex justify-content-center'}
          				subContainerClassName={'pages pagination'}
          				pageClassName={'page-item'}
          				pageLinkClassName={'page-link'}
          				activeClassName={'active'}
          				previousClassName={'page-item'}
          				nextClassName={'page-item'}
          				previousLinkClassName={'page-link'}
          				nextLinkClassName={'page-link'}
          				breakClassName={'page-item'}
          				breakLinkClassName={'page-link'}
          				hrefBuilder={() => '#'}
          				forcePage={params.page-1}
          			/>
              }
            </Col>
          </Row>
        </Container>
		  </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings.settings,
  inventories: state.inventory.inventories,
  inventoriesStale: state.inventory.inventoriesStale,
  inventoryMessages: state.inventory.inventoryMessages,
  userList: state.user.userList,
  currentID: state.auth.currentID,
  hiddenElement: state.settings.hiddenElement,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { fetchInventories, removeMessage, deleteInventory, massDeleteInventory, getUserList, fetchSettings, checkStorage, addQueueMessage })(withGetScreen(InventoryPage));