import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import logo from '../logo.svg';
import createNavigationLinks from '../components/navigation';
import { logout } from '../redux/actions/authActions'
import { connect } from 'react-redux'
import { fetchAddresses } from '../redux/actions/addressActions'
import { fetchChecks } from '../redux/actions/checkActions'
import { addressParams, checkParams } from '../components/defaultParams'
import StorageWarning from '../components/StorageWarning'

class IndexPage extends Component {

	componentWillMount() {
		let thisComp = this
	    if ('serviceWorker' in navigator) {
	      navigator.serviceWorker.ready.then(function(swRegistration) {
	        console.log("serviceWorker.ready")
	        for (let x = 0; x < 5; x++){
	          thisComp.props.fetchAddresses(
	            Object.assign({}, addressParams, { page: x+1 }), 
	            true,
	            true
	          )
	        }
	        for (let x = 0; x < 5; x++){
	          thisComp.props.fetchChecks(
	            Object.assign({}, checkParams, { page: x+1 }), 
	            true,
	            true
	          )
	        }
	      });
	    }
	}

  render() {

    return (
    	<div className="d-flex align-items-stretch flex-column" style={{ backgroundColor: '#222720', minHeight: '100vh'}}>
			<div style={{ backgroundColor: '#f2f4f1'}}>
		    	<Container className="text-center flex-column pt-3 pb-3">
			    	<img
				    	alt="Saint Andrews Bureau"
				    	src={logo}
				    	width="250"
				    	height="250"
				    	className="d-inline-block align-top"
			    	/>
			    	<h4>Routine Check App</h4>
				</Container>
			</div>
	    	<Container className="text-center d-flex flex-column my-5" style={{ flexGrow: 1 }}>
	    		<StorageWarning />
        		{createNavigationLinks(this.props.offlineQueue.length, this.props.logout, 'lg', this.props.userList, this.props.currentID)}
			</Container>
		</div>
    )

  }
}

const mapStateToProps = state => ({
  offlineQueue: state.offline.offlineQueue,
  currentID: state.auth.currentID,
  userList: state.user.userList,
});

export default connect(mapStateToProps, { logout, fetchAddresses, fetchChecks })(IndexPage);