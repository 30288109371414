import React from 'react';
import { Button, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {version} from '../version'

const createNavigationLinks = (offlineQueueLength, logout, size, userList, currentID) => {

	const user = userList.find((item) => item.id === currentID)
	let reportSize = 6; 
	if (user && user.sabuser_role !== 'admin') reportSize = 12
	return (<>
		<Row>
		<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
		<LinkContainer to="/addresses">
		  <Button variant="sab" size={size} block>
		  Addresses
		  </Button>
		</LinkContainer>
		</Col>
		<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
		<LinkContainer 
			to={{ 
				pathname: "/checks", 
				state: {fromDashboard: true }
			}}
		>
		  <Button variant="sab" size={size} block>
		  Checks
		  </Button>
		</LinkContainer>
		</Col>
		<Col lg={size === 'md' ? 12 : reportSize} md={size === 'md' ? 12 : reportSize} className="mb-3">
		<LinkContainer 
			to={{ 
				pathname: "/reports", 
				state: {fromDashboard: true }
			}}
		>
		  <Button variant="sab" size={size} block>
		  Previous Reports
		  </Button>
		</LinkContainer>
		</Col>
		<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
		<LinkContainer 
			to={{ 
				pathname: "/inventories", 
				state: {fromDashboard: true }
			}}
		>
		  <Button variant="sab" size={size} block>
		  Inventories
		  </Button>
		</LinkContainer>
		</Col>
		{/**<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
				<LinkContainer to="/offline">
				  <Button variant="info" size={size} block>
				  Offline Queue ({offlineQueueLength})
				  </Button>
				</LinkContainer>
				</Col>**/}
		<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
		<LinkContainer to="/autosaves">
		  <Button variant="info" size={size} block>
		  Autosaves
		  </Button>
		</LinkContainer>
		</Col>
		<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
		<LinkContainer to="/account-settings">
		  <Button variant="info" size={size} block>
		  Account Settings
		  </Button>
		</LinkContainer>
		</Col>
		{user && user.sabuser_role === 'admin' && 
			<>
				<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
					<LinkContainer to="/users">
					  <Button variant="secondary" size={size} block>
					  Users
					  </Button>
					</LinkContainer>
				</Col>
				<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
				<LinkContainer to="/checks-settings">
				  <Button variant="secondary" size={size} block>
				  Checks Settings
				  </Button>
				</LinkContainer>
				</Col>
				<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3">
				<LinkContainer to="/inventory-settings">
				  <Button variant="secondary" size={size} block>
				  Inventory Settings
				  </Button>
				</LinkContainer>
				</Col>
			</>
		}
		<Col lg={size === 'md' ? 12 : 6} md={size === 'md' ? 12 : 6} className="mb-3" style={{ display: 'none'}}>
		  <Button variant="success" size={size} block className="ad2hs-prompt">
		  Install App
		  </Button>
		</Col>
		 </Row>
		<br/>
		<Row  className="mt-auto">
		<div className="text-right w-100 mr-3 mb-1" style={{color: 'white'}}>v{version}</div>
		<Col lg={size === 'md' ? 12 : {span:4, offset:8}} md={size === 'md' ? 12 : {span:6, offset:6}} >
		  <Button onClick={logout} variant="danger" size={size} block>
		  Logout
		  </Button>
		</Col>
		 </Row>
		  </>)
}

export default createNavigationLinks;