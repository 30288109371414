import React, { Component } from 'react';
import { Container, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Form, Alert, Spinner } from 'react-bootstrap'
import { fetchAddresses, deleteAddress, massDeleteAddress, removeMessage } from '../redux/actions/addressActions'
import { connect } from 'react-redux'
import ViewAddressModal from '../modals/ViewAddressModal'
import CreateAddressModal from '../modals/CreateAddressModal'
import ConfirmModal from '../modals/ConfirmModal'
import {withGetScreen} from 'react-getscreen'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import ReactPaginate from 'react-paginate';
import { stringify } from 'query-string';
import { api_url } from '../api_url'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import { addressParams } from '../components/defaultParams'
import history from '../history';
import { Redirect } from "react-router";
import RefreshButton from '../components/RefreshButton'

class AddressPage extends Component {

  constructor(props){
    super(props)
    this.state = this.getDefaultState()
  }

  getDefaultState = () => {
    return {
      filterOpen: true,
      tableSpan: 12,
      filterSpan: 3,
      currentPage: null,
      showViewAddressModal: false,
      showCreateAddressModal: false,
      showConfirmModal: false,
      confirmModalTitle: "",
      confirmModalBody: "",
      confirmModalCallback: null,
      selectedDropdownItem: null,
      pageCount: 1,
      searchValue: "",
      searchOptions: [],
      searchSelected: [],
      searchIsLoading: false,
      searchIsVisible: false,
      params: addressParams,
      selected: [],
      messages: [],
      redirectToCheckPage: false,
    };
  }

  componentWillMount() {
    console.log("componentWillMount")
    this.initialiseComponent()
  }

  initialiseComponent = () => {
    this.displayModalFromHash()
    var urlParams = new URLSearchParams(window.location.search);
    let { params } = this.state
    if (urlParams.get('page')){
      params.page = urlParams.get('page')
    }
    if (urlParams.get('ordering')){
      params.ordering = urlParams.get('ordering')
    }
    if (urlParams.get('q')){
      params.q = urlParams.get('q')
      this.setState({ 
        searchValue: urlParams.get('q'),
        searchOptions: [{address_full: urlParams.get('q')}],
        searchSelected: [{address_full: urlParams.get('q')}],
      })
    }
    this.setState({ params })
    this.props.fetchAddresses(params, false)
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps")
  	const { params } = this.state
  	if (nextProps.addresses[params.page]){
  		this.setState({
  			currentPage: nextProps.addresses[params.page],
  		})
  	}
  }

  handleToggleFilter = () => {
  	const { filterOpen, tableSpan, filterSpan } = this.state
  	this.setState({
  		filterOpen: !filterOpen,
  		tableSpan: tableSpan === 9 ? 11 : 9,
  		filterSpan: filterSpan === 3 ? 1 : 3,
  	})
  }

  handleRefresh = () => {
    this.setState(this.getDefaultState(), this.initialiseComponent)
  }

  getAddressRecord = (item, message) => {
    const optionsLabel = this.props.isMobile() ? "" : "Options"
    const address = [
      item.tenant_address_line_1, 
      item.tenant_address_line_2, 
      item.tenant_town_city, 
      item.tenant_county, 
      item.tenant_postcode
    ].filter(function (el) {
       return el !== null && el !== "";
    });
    return (
      <tr key={item.id} onClick={(e) => this.checkFromRow(e, item.id)} className={message ? "success-row" : ""}>
        <td><input id={`select-${item.id}`} type="checkbox" style={{ pointerEvents: "none" }}/></td>
        <td>{item.id}</td>
        <td>
          {address.join(", ")}
          {/**<div className="display-xxs"><b>{item.tenant_name}</b></div>**/}
        </td>
        {/**<td className="hidden-xxs">{item.tenant_name}</td>**/}
        <td>
          <DropdownButton title={optionsLabel} variant="sab" className="float-right" alignRight>
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.setState({ redirectToCheckPage: `#create-${item.id}` })}>Create Check</Dropdown.Item>
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.setState({ redirectToCheckPage: `?q=${address.join(", ")}&status=all&appointment_date__gte&appointment_date__lte&assigned_to` })}>View Checks</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.handleShowViewAddressModal(item)}>View</Dropdown.Item>
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.handleShowEditAddressModal(item)}>Edit</Dropdown.Item>
            {/**message && 
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.props.removeMessage(message.id)}>Hide Message</Dropdown.Item>
              </>
            **/}
            <Dropdown.Divider />
            <Dropdown.Item disabled={!this.props.isOnline} onClick={() => this.confirmDelete(item.id)}>Delete</Dropdown.Item>
            {/**<Dropdown.Item onClick={() => this.props.deleteAddress(item.id)}>Delete</Dropdown.Item>**/}
          </DropdownButton>
        </td>
      </tr>
    )
  }

  getAddressContent = () => {
  	const { currentPage } = this.state
  	return (
  	  <>
        {currentPage.results.map(item => {
          return this.getAddressRecord(item, false)
  	  	})}
  	  </>
  	)
  }

  massDelete = () => {
  	this.handleClose(false)
  	const { selected } = this.state
  	this.props.massDeleteAddress(selected)
  	this.setState({
  		selected: [],
  	})
  }

  confirmDelete = (itemID) => {
    history.push(`#confirm`);
    this.setState({ 
      showConfirmModal: true,
      confirmModalTitle: "Delete Address",
      confirmModalBody: "Are you sure you want to delete the selected address?",
      confirmModalCallback: () => {
        this.handleClose(false)
        this.props.deleteAddress(itemID)
      },
    });
  }

  confirmMassDelete = () => {
    this.setState({ 
    	showConfirmModal: true,
    	confirmModalTitle: "Mass Deletion",
    	confirmModalBody: "Are you sure you want to delete the selected addresses?",
    	confirmModalCallback: this.massDelete
    });
  }

  displayModalFromHash = () => {
    const {showConfirmModal, showViewAddressModal, showCreateAddressModal } = this.state
    const modalOpen = showConfirmModal || showViewAddressModal || showCreateAddressModal
    if (history.location.hash.length === 0 && modalOpen === true){
      this.setState({ 
        selectedDropdownItem: null,
        showConfirmModal: false,
        showViewAddressModal: false,
        showCreateAddressModal: false,
      });
    } else if (history.location.hash.startsWith('#view')){
      this.setState({ 
        selectedDropdownItem: null,//history.location.state ? history.location.state.item : null,
        showViewAddressModal: true,
        showConfirmModal: false,
        showCreateAddressModal: false,
      });
    } else if (history.location.hash.startsWith('#create')){
      this.setState({ 
        selectedDropdownItem: null,
        showCreateAddressModal: true,
        showConfirmModal: false,
        showViewAddressModal: false,
      });
    } else if (history.location.hash.startsWith('#edit')){
      this.setState({ 
        selectedDropdownItem: null,//history.location.state ? history.location.state.item : null,
        showConfirmModal: false,
        showViewAddressModal: false,
        showCreateAddressModal: true,
      });
    }
  }

  componentDidUpdate(){
    window.onpopstate  = (e) => {
      this.displayModalFromHash()
    }
  }

  handleShowViewAddressModal = (item) => {
    history.push(`#view-${item.id}`, {item: item});
    this.setState({ 
    	selectedDropdownItem: item,
    	showViewAddressModal: true,
      showConfirmModal: false,
      showCreateAddressModal: false,
    });
    window.scrollTo(0, 0)
  }

  handleShowCreateAddressModal = (item) => {
    history.push(`#create`);
    this.setState({ 
    	selectedDropdownItem: null,
    	showCreateAddressModal: true,
      showConfirmModal: false,
      showViewAddressModal: false,
    });
    window.scrollTo(0, 0)
  }

  handleShowEditAddressModal = (item) => {
    history.push(`#edit-${item.id}`, {item: item});
    this.setState({ 
    	selectedDropdownItem: item,
    	showCreateAddressModal: true,
      showConfirmModal: false,
      showViewAddressModal: false,
    });
    window.scrollTo(0, 0)
  }

  handleClose = (resetCurrentPage) => {
    if (resetCurrentPage !== false) this.resetCurrentPage()
    this.setState({ 
      selectedDropdownItem: null,
    	showConfirmModal: false,
    	showViewAddressModal: false,
    	showCreateAddressModal: false,
    });
    window.scrollTo(0, 0)
  }

	handlePageChange = (page) => {
  	const allCheckbox = document.getElementById('select-all')
  	allCheckbox.checked = false
  	this.setState({ selected: [] })
		const newPage = page.selected+1
		this.setState({
  		params: Object.assign({}, this.state.params, {
		        page: newPage
		      }),
		}, () => {
	  	this.resetCurrentPage()
		})
	}

  onKeyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13){
    	event.target.blur()
    }
  }

  onFocus = () => {
  	this.setState({
  		searchIsVisible: true,
  	})
  }

  onBlur = () => {
  	this.setState({
  		searchIsVisible: false,
  	})
  }

  onChange = (query) => {
    const q = query && query.length > 0 && query[0].address_full ? query[0].address_full : null
    const original_search = query && query.length > 0 && query[0].original_search ? query[0].original_search : null
    let params = Object.assign({}, this.state.params, {
      page: 1,
    })
    delete params.q
    if (q) params.q = q
    if (original_search) params.q = original_search
    this.setState({
      params: params,
      searchIsVisible: false,
      searchSelected: query,
    }, () => {
      this.resetCurrentPage()
    })
  }

  onInputChange = (searchValue) => {
    this.setState({
    	searchValue: searchValue,
    	searchIsLoading: true,
  		searchIsVisible: true,
    });
    const params = {
  		q: searchValue,
  	}
  	const endpoint = `${api_url}rest-auth/address-autocomplete/?${stringify(params)}` 
  	let lookupOptions = {
  		method: "GET",
  		headers: {
  			'Content-Type': 'application/json',
        'Authorization': 'Token '+window.localStorage.getItem('authKey'),
      }
  	}
  	let thisComp = this
  	fetch(endpoint, lookupOptions)
  	.then(function(response){
  		return response.json()
  	}).then(function(responseData){
  		console.log("responseData", responseData)
  		thisComp.setState({ searchIsLoading: false, })
  		if (responseData){
  			responseData.results.unshift({original_search: searchValue})
  			thisComp.setState({
  				searchOptions: responseData.results,
  			})
  		}
  	}).catch(function(error){
  		console.log("error", error)
  	})
  }

  handleCheckAllToggle = () => {
  	const allCheckbox = document.getElementById('select-all')
  	allCheckbox.checked = !allCheckbox.checked
  	const { currentPage } = this.state
  	let selected = []
  	if (allCheckbox.checked){
  		currentPage.results.forEach(item => {
  			selected.push(item.id)
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.add("selected");
    		checkbox.checked = true
  		})
  	} else {
  		currentPage.results.forEach(item => {
    		const checkbox = document.getElementById(`select-${item.id}`)
    		checkbox.parentElement.parentElement.classList.remove("selected");
    		checkbox.checked = false
  		})
  	}
  	this.setState({
  		selected
  	})
  }

  checkFromRow = (event, id) => {
  	if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'A'){
    	const { selected } = this.state
    	const checkbox = document.getElementById(`select-${id}`)
    	checkbox.checked = !checkbox.checked
    	if (checkbox.checked){
    		checkbox.parentElement.parentElement.classList.add("selected");
    		this.setState({
    			selected: selected.concat([id])
    		})
    	} else {
    		document.getElementById('select-all').checked = false
    		checkbox.parentElement.parentElement.classList.remove("selected");
	    	this.setState({
	    		selected: selected.filter(item => item !== id)
	    	})
    	}
  	}
  }

  handleOrderByChange = (event) => {
  	this.setState({
  		params: Object.assign({}, this.state.params, {
  			ordering: event.target.value,
        page: 1,
  		}),
		}, () => {
			this.resetCurrentPage()
		})
  }

  resetCurrentPage = () => {
    console.log("resetCurrentPage")
  	const allCheckbox = document.getElementById('select-all')
  	try{
      allCheckbox.checked = false
      this.setState({ selected: [] })
    } catch {}
		const { params } = this.state
		history.push({
		  search: `?${stringify(params)}`
		})
		this.props.fetchAddresses(params, true)
  }

  render() {
    const { redirectToCheckPage } = this.state
    if (redirectToCheckPage) return <Redirect to={`/checks${redirectToCheckPage}`} />

    const { tableSpan, params, currentPage, selected } = this.state
    const { selectedDropdownItem } = this.state
    const { searchOptions, searchIsLoading, searchIsVisible, searchSelected } = this.state
    const { addressMessages } = this.props
    const selectLabel = `${selected.length} selected`
    const selectDisabled = selected.length === 0 ? true : false
    const {confirmModalTitle, confirmModalBody, confirmModalCallback } = this.state
    const {showConfirmModal, showViewAddressModal, showCreateAddressModal } = this.state

    const confirmModal = <ConfirmModal show={showConfirmModal} handleConfirm={confirmModalCallback} title={confirmModalTitle} body={confirmModalBody} handleClose={this.handleClose} isMobile={this.props.isMobile} />
    const viewAddressModal = <ViewAddressModal show={showViewAddressModal} handleClose={this.handleClose} handleShowEditAddressModal={this.handleShowEditAddressModal} item={selectedDropdownItem} isMobile={this.props.isMobile} />
    const createAddressModal = <CreateAddressModal show={showCreateAddressModal} handleClose={this.handleClose} item={selectedDropdownItem} handleShowViewAddressModal={this.handleShowViewAddressModal} isMobile={this.props.isMobile} />

    const modalOpen = showConfirmModal || showViewAddressModal || showCreateAddressModal
    if (modalOpen && this.props.isMobile()){
      if (showConfirmModal) return confirmModal
      else if (showViewAddressModal) return viewAddressModal
      else if (showCreateAddressModal) return createAddressModal
    }

    return (
    	<div className="page address-page">
        <Sidebar/>
      	<Navbar fixed="top">
  	    	<Container>
  		    	<h2 className="ml-5 ml-xl-0 d-inline-block">Addresses</h2><RefreshButton onClick={this.handleRefresh} isRefreshing={currentPage?false:true}/>
  		    	<Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
  				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
  				  <Breadcrumb.Item active>Addresses</Breadcrumb.Item>
  				</Breadcrumb>
  		    </Container>
      	</Navbar>
      	{confirmModal}{viewAddressModal}{createAddressModal}
      	<Container className="pt-5 pb-5 mt-4 mt-sm-5">
          <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
      		{addressMessages.map(message => {
      			if (message.type === 'createAddress'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully created address #{message.item.id}. <Alert.Link onClick={() => this.handleShowViewAddressModal(message.item)}>Click here</Alert.Link> to view.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'createAddress-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued create address.
                </Alert>
              )
            } else if (message.type === 'editAddress'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully edited address #{message.item.id}. <Alert.Link onClick={() => this.handleShowViewAddressModal(message.item)}>Click here</Alert.Link> to view.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'editAddress-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued edit address #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'deleteAddress'){
      				// return (
  			    	// 	<Alert key={`${message.id}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully deleted address #{message.item.id}.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'deleteAddress-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete address #{message.item.id}.
                </Alert>
              )
            } else if (message.type === 'massDeleteAddress'){
      				// return (
  			    	// 	<Alert key={`${message.id_array.join(',')}-${message.type}`} variant="success" onClose={() => this.props.removeMessage(message.id)} dismissible>
  			    	// 		Successfully deleted addresses #{message.id_array.join(', #')}.
  			    	// 	</Alert>
      				// )
      			} else if (message.type === 'massDeleteAddress-cache'){
              return (
                <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  Successfully queued delete addresses #{message.id_array.join(', #')}.
                </Alert>
              )
            } else if (message.type === 'server-error'){
              return (
                <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                  {message.error ? 
                  `There was an error: ${message.error}.`
                    :
                  "There was an unexpected error."
                  }
                </Alert>
              )
            } else {
      				return null
      			}
      		})}
      		<Row>
        		<Col className="mb-2 mb-sm-0">
          		<label htmlFor="address-search">Search:</label>
              <form autoComplete='off'>
      	    	<AsyncTypeahead
      	    		id={'address-search-typeahead'}
      	    		inputProps={{id: 'address-search', autoComplete: 'off'}}
        				options={searchOptions}
        				selected={searchSelected}
        				isLoading={searchIsLoading}
        				maxVisible={10}
        				placeholder="Search"
              	onSearch={this.onInputChange}
        				minLength={0}
        				useCache={false}
        				onKeyDown={this.onKeyDown}
        				selectHintOnEnter={true}
        				onBlur={this.onBlur}
        				onFocus={this.onFocus}
        				open={searchIsVisible}
        				onChange={this.onChange}
        				clearButton={true}
                labelKey={(option) => `${option.address_full || option.original_search}`}
                filterBy={(option, props) => {
                  return option.address_full || option.original_search
                }}
              />
              </form>
  	        </Col>
        		<Col sm={3}>
    		      <Form.Label>Order by:</Form.Label>
    		      <Form.Control as="select" onChange={this.handleOrderByChange} value={params.ordering}>
    		        <option value="id">ID ascending</option>
    		        <option value="-id">ID descending</option>
    		        <option value="tenant_name">Tenant name ascending</option>
    		        <option value="-tenant_name">Tenant name descending</option>
    		        <option value="tenant_address_line_1">Address line 1 ascending</option>
    		        <option value="-tenant_address_line_1">Address line 1 descending</option>
    		        <option value="tenant_address_line_2">Address line 2 ascending</option>
    		        <option value="-tenant_address_line_2">Address line 2 descending</option>
    		        <option value="tenant_town_city">Town/city ascending</option>
    		        <option value="-tenant_town_city">Town/city descending</option>
    		        <option value="tenant_county">County ascending</option>
    		        <option value="-tenant_county">County descending</option>
    		        <option value="tenant_postcode">Postcode ascending</option>
    		        <option value="-tenant_postcode">Postcode descending</option>
    		      </Form.Control>
        		</Col>
        		<Col xs={6} sm={0} className="d-block d-sm-none">
    	    		<Dropdown id="dropdown-basic-button" title="0 selected" variant="sab" className="mt-3">
      				  <Dropdown.Toggle variant="sab" id="dropdown-basic" className="btn-block" disabled={selectDisabled}>
      				    {selectLabel}
      				  </Dropdown.Toggle>
      					<Dropdown.Menu className="btn-block">
    		    		  <Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
    		    		</Dropdown.Menu>
    	    		</Dropdown>
        		</Col>
        		<Col xs={6} sm={0} className="d-block d-sm-none">
        			<Button disabled={!this.props.isOnline} variant="primary" className="mt-3 btn-block" onClick={this.handleShowCreateAddressModal}>Create</Button>
        		</Col>
        		<div className="d-none d-sm-flex">
    	    		<DropdownButton id="dropdown-basic-button" title={selectLabel} variant="sab" className="float-right align-self-end mr-3" alignRight disabled={selectDisabled}>
    		    		<Dropdown.Item disabled={!this.props.isOnline} onClick={this.confirmMassDelete}>Delete</Dropdown.Item>
    	    		</DropdownButton>
        		</div>
        		<div className="d-none d-sm-flex">
        			<Button disabled={!this.props.isOnline} variant="primary" className="float-right align-self-end mr-3" onClick={this.handleShowCreateAddressModal}>Create</Button>
        		</div>
	        </Row>
  	    	<Row className="mt-3">
    	    	<Col sm={tableSpan}>
          		<Table striped size="sm">
        			  <thead>
        			    <tr>
        			      <th onClick={this.handleCheckAllToggle}><input id="select-all" type="checkbox" style={{ pointerEvents: "none" }}/></th>
        			      <th>ID</th>
        			      <th>Address</th>
        			      {/**<th className="hidden-xxs">Tenant Name</th>**/}
        			      <th></th>
        			    </tr>
        			  </thead>
                <tbody>
                  {currentPage ?
                    (addressMessages && addressMessages.length > 0) || (currentPage.results && currentPage.results.length > 0) ?
                    <>
                      {addressMessages.map(message => {
                        if (message.type === 'createAddress'){
                          let result = currentPage.results.filter(addressItem => addressItem.id === message.item.id)
                          if (result.length === 0) return this.getAddressRecord(message.item, message)
                        }
                      })}
                      {this.getAddressContent()}
                    </>
                    :
                    <tr style={{ pointerEvents: "none" }}>
                      <td colSpan="7" className="text-center pt-3">
                        <p>No results</p>
                      </td>
                    </tr>
                  :
                    <tr style={{ pointerEvents: "none" }}>
                      <td colSpan="7" className="text-center pt-3">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  }
                </tbody>
        			</Table>
              {currentPage &&
          			<ReactPaginate
          				previousLabel={'previous'}
          				nextLabel={'next'}
          				breakLabel={'...'}
          				pageCount={currentPage.total_pages}
          				marginPagesDisplayed={this.props.isMobile() ? 0 : 1}
          				pageRangeDisplayed={this.props.isMobile() ? 3 : 4}
          				onPageChange={this.handlePageChange}
          				containerClassName={'pagination d-flex justify-content-center'}
          				subContainerClassName={'pages pagination'}
          				pageClassName={'page-item'}
          				pageLinkClassName={'page-link'}
          				activeClassName={'active'}
          				previousClassName={'page-item'}
          				nextClassName={'page-item'}
          				previousLinkClassName={'page-link'}
          				nextLinkClassName={'page-link'}
          				breakClassName={'page-item'}
          				breakLinkClassName={'page-link'}
          				hrefBuilder={() => '#'}
          				forcePage={params.page-1}
          			/>
              }
      			</Col>
    			</Row>
  		  </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  addresses: state.address.addresses,
  addressMessages: state.address.addressMessages,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { fetchAddresses, removeMessage, deleteAddress, massDeleteAddress })(withGetScreen(AddressPage));