import { FETCH_INVENTORIES, PUSH_INVENTORY_TO_OFFLINE_QUEUE, ADD_QUEUE_MESSAGE, ADD_INVENTORY_SERVER_ERROR_MESSAGE, EDIT_INVENTORY, PUSH_OFFLINE_QUEUE, DELETE_INVENTORY, MASS_DELETE_INVENTORY, REMOVE_MESSAGE, PUSH_CHECKOUT_TO_OFFLINE_QUEUE, ADD_INVENTORY_MESSAGE } from './types'
import { stringify } from 'query-string';
import { api_url } from '../../api_url'
import localForage from "localforage";
import { deleteInventoryAutosaveFiles, deleteCheckoutAutosaveFiles, markInventoryAutosaveSubmitted } from '../helpers'
import {version} from '../../version'

function getAuthKey(){
	return window.localStorage.getItem('authKey');
}

// export const createInventory = (form, isDuplicate) => dispatch => {
// }


function getMessageID(){
	let id = parseInt(window.localStorage.getItem('message-id') || "0")
	window.localStorage.setItem('message-id', id+1)
	return id
}

function collectImages(images, prefix, formData, imageTag){
	// console.log("collectImages", images, prefix, formData)
	if (images && images.length > 0){
		for (let x = 0; x < images.length; x++){
			if (images[x] && (!images[x].url || typeof images[x].url === 'object')){
				let id = images[x].id ? `id${images[x].id}` : `${x}`
				formData.append(`${prefix}_${imageTag}_${id}`, images[x].url ); // Add/replace with new image
				if (images[x].dateTaken) {
					formData.append(`${prefix}_${imageTag}date_${id}`, images[x].dateTaken );
				}
			}
		}
	} else if (!images){
		formData.append(`${prefix}_image`, null ); //Delete image
	}
	return formData
}

// {id: `new_room_${newRoomID}`, name: roomName, order: order, items: [this.getNewRoomItem(),]}
// {id: `new_item_${newItemID}`, name: "", description: "", conditioncleanliness: "", images: []}

function getInventoryFormData(formData, validatedData){
	formData.append(`version`, version);
	Object.keys(validatedData).forEach(key => {
		// console.log(key, validatedData[key])
		if (validatedData[key] || validatedData[key] === '' || validatedData[key] === false){
			if (key === "electricity_meter_images"){
				formData = collectImages(validatedData[key], 'electricity_meter', formData, 'image')

			} else if (key === "gas_meter_images"){
				formData = collectImages(validatedData[key], 'gas_meter', formData, 'image')

			} else if (key === "water_meter_images"){
				formData = collectImages(validatedData[key], 'water_meter', formData, 'image')

			} else if (key === "fuse_board_images"){
				formData = collectImages(validatedData[key], 'fuse_board', formData, 'image')

			} else if (key === "stop_tap_images"){
				formData = collectImages(validatedData[key], 'stop_tap', formData, 'image')

			} else if (key === "keys_codes_images"){
				formData = collectImages(validatedData[key], 'keys_codes', formData, 'image')

			} else if (key === "rooms"){
				validatedData[key].forEach(roomItem => {
					let prefix = typeof roomItem.id === 'string' ? roomItem.id : `room_${roomItem.id}`
					roomItem.name && formData.append(`${prefix}_name`, roomItem.name )
					roomItem.checkoutcomments && formData.append(`${prefix}_checkoutcomments`, roomItem.checkoutcomments )
					roomItem.order && formData.append(`${prefix}_order`, roomItem.order )
					if (roomItem.checkout_images) formData = collectImages(roomItem.checkout_images, prefix, formData, 'checkoutimage')
					roomItem.items && roomItem.items.forEach(itemItem => {
						let prefix = typeof itemItem.id === 'string' ? itemItem.id : `item_${itemItem.id}`
						formData = collectImages(itemItem.images, prefix, formData, 'image')
						formData.append(`${prefix}_name`, itemItem.name );
						formData.append(`${prefix}_order`, itemItem.order );
						formData.append(`${prefix}_description`, itemItem.description );
						formData.append(`${prefix}_conditioncleanliness`, itemItem.conditioncleanliness );
						formData.append(`${prefix}_room`, roomItem.id );
					})
				})

			} else if (key === 'check_in_signature_image' || key === 'check_out_signature_image') {
				formData.append(key, validatedData[key], 'signature.png');
			} else {
				formData.append(key, validatedData[key] );
			}
		}
	})
	return formData
}

export const checkoutInventory = (validatedData, checkoutAutosaveName) => dispatch => {
	// console.log("checkoutInventory", validatedData)
	const endpoint = `${api_url}rest-auth/inventories/${validatedData.id}/`
	const method = "PATCH"
	let formData = getInventoryFormData(new FormData(), validatedData)
	// console.log('---formData---')
	// for (var pair of formData.entries()) {
	//     console.log(pair[0]+ ', ' + pair[1]); 
	// }
	let lookupOptions = {
		method: method,
		body: formData,
		headers: {
			'Authorization': 'Token '+getAuthKey(),
		}
	}
	function offlineActions(){
		// delete lookupOptions.body
		// dispatch({
		// 	type: PUSH_CHECKOUT_TO_OFFLINE_QUEUE,
		// 	payload: {
		// 		type: 'checkoutInventory', 
		// 		title: `Checkout Inventory #${validatedData.id}`, 
		// 		endpoint: endpoint, 
		// 		lookupOptions: lookupOptions, 
		// 		validatedData: validatedData,
		// 		checkoutAutosaveName: checkoutAutosaveName,
		// 	},
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: 'checkoutInventory-cache',
		// 		item: validatedData
		// 	},
		// })
	}
	if (!navigator.onLine){
		offlineActions()
	} else {
		dispatch({
			type: ADD_INVENTORY_MESSAGE,
			payload: {
				type: 'checkout-fetch',
				id: `checkout-fetch-${validatedData.id}`,
				itemID: validatedData.id
			},
		})
		fetch(endpoint, lookupOptions)
		.then(function(response){
			dispatch({
				type: REMOVE_MESSAGE,
				payload: `checkout-fetch-${validatedData.id}`,
			})
			if (response.ok){
				return response.json()
			} else {
				dispatch({
					type: ADD_INVENTORY_SERVER_ERROR_MESSAGE,
					payload: { type: 'server-error' }
				})
				offlineActions()
			}
		}).then(function(responseData){
			if (responseData){
				dispatch({
					type: EDIT_INVENTORY,
					payload: {
						type: 'checkoutInventory',
						item: responseData
					},
				})
				// markInventoryAutosaveSubmitted(checkoutAutosaveName, dispatch)
				dispatch(deleteCheckoutAutosave(checkoutAutosaveName, null))
			}
		}).catch(function(error){
			console.log("error", error)
			dispatch({
				type: REMOVE_MESSAGE,
				payload: `checkout-fetch-${validatedData.id}`,
			})
			dispatch({
				type: ADD_INVENTORY_SERVER_ERROR_MESSAGE,
				payload: {
					type: 'server-error',
					error: error
				},
			})
			offlineActions()
		})
	}

}

export const editInventory = (validatedData, autosaveName) => dispatch => {
	// console.log("editInventory", validatedData, autosaveName)
	let endpoint = `${api_url}rest-auth/inventories/`
	let method = "POST"
	if (validatedData.id){
		endpoint = `${api_url}rest-auth/inventories/${validatedData.id}/`
		method = "PATCH"
	}
	let formData = getInventoryFormData(new FormData(), validatedData)
	// console.log('---formData---')
	// for (var pair of formData.entries()) {
	//     console.log(pair[0]+ ', ' + pair[1]); 
	// }
	let lookupOptions = {
		method: method,
		body: formData,
		headers: {
			'Authorization': 'Token '+getAuthKey(),
		}
	}
	function offlineActions(){
		// delete lookupOptions.body
		// dispatch({
		// 	type: PUSH_INVENTORY_TO_OFFLINE_QUEUE,
		// 	payload: {
		// 		type: validatedData.id ? `editInventory` : 'createInventory', 
		// 		title: validatedData.id ? `Edit Inventory #${validatedData.id}` : 'Create Inventory', 
		// 		endpoint: endpoint, 
		// 		lookupOptions: lookupOptions, 
		// 		validatedData: validatedData,
		// 		autosaveName: autosaveName,
		// 	},
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: validatedData.id ? `editInventory-cache` : 'createInventory-cache', 
		// 		item: validatedData
		// 	},
		// })
	}
	if (!navigator.onLine){
		offlineActions()
	} else {
		dispatch({
			type: ADD_INVENTORY_MESSAGE,
			payload: {
				type: 'inventory-fetch',
				id: `inventory-fetch-${validatedData.id}`,
				itemID: validatedData.id
			},
		})
		fetch(endpoint, lookupOptions)
		.then(function(response){
			dispatch({
				type: REMOVE_MESSAGE,
				payload: `inventory-fetch-${validatedData.id}`,
			})
			if (response.ok){
				return response.json()
			} else {
				dispatch({
					type: ADD_INVENTORY_SERVER_ERROR_MESSAGE,
					payload: { type: 'server-error' }
				})
				offlineActions()
			}
		}).then(function(responseData){
			if (responseData){
				dispatch({
					type: EDIT_INVENTORY,
					payload: {
						type: validatedData.id ? `editInventory` : 'createInventory', 
						item: responseData
					},
				})
				// markInventoryAutosaveSubmitted(autosaveName, dispatch)
				dispatch(deleteInventoryAutosave(autosaveName, null))
			}
		}).catch(function(error){
			console.log("error", error)
			dispatch({
				type: REMOVE_MESSAGE,
				payload: `inventory-fetch-${validatedData.id}`,
			})
			dispatch({
				type: ADD_INVENTORY_SERVER_ERROR_MESSAGE,
				payload: {
					type: 'server-error',
					error: error
				},
			})
			offlineActions()
		})
	}
}

export const deleteInventoryAutosave = (autosaveName, id) => dispatch => {
	// console.log("inventoryActions deleteInventoryAutosave", autosaveName)
	deleteInventoryAutosaveFiles(autosaveName, id, dispatch)
}

export const deleteCheckoutAutosave = (autosaveName, id) => dispatch => {
	// console.log("inventoryActions deleteCheckoutAutosave", autosaveName)
	deleteCheckoutAutosaveFiles(autosaveName, id, dispatch)
}

export const fetchInventories = (params, clearResults, forCache) => dispatch => {
	// console.log("inventoryActions fetchInventories")
	// console.log("params", params)
		const endpoint = `${api_url}rest-auth/inventories/?${stringify(params)}` 
		let lookupOptions = {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Token '+getAuthKey(),
			}
		}
		fetch(endpoint, lookupOptions)
		.then(function(response){
			return response.json()
		}).then(function(responseData){
			if (responseData && !forCache){
				dispatch({
					type: FETCH_INVENTORIES,
					payload: responseData,
					params,
					clearResults,
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
}

export const deleteInventory = (id) => dispatch => {
	// console.log("inventoryActions deleteInventory", id)
	const endpoint = `${api_url}rest-auth/inventories/${id}/`
	let lookupOptions = {
		method: "DELETE",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Token '+getAuthKey(),
		}
	}
	if (!navigator.onLine){
		// dispatch({
		// 	type: PUSH_OFFLINE_QUEUE,
		// 	payload: {title: `Delete Inventory #${id}`, endpoint: endpoint, lookupOptions: lookupOptions},
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: 'deleteInventory-cache',
		// 		item: {id: id}
		// 	},
		// })
	} else {
		fetch(endpoint, lookupOptions)
		.then(function(response){
			if (response.ok){
				dispatch({
					type: DELETE_INVENTORY,
					payload: {
						type: 'deleteInventory',
						item: {id: id}
					},
				})
				dispatch(deleteInventoryAutosave(null, id))
				dispatch(deleteCheckoutAutosave(null, id))
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}
}

export const massDeleteInventory = (id_array) => dispatch => {
	// console.log("inventoryActions massDeleteInventory", id_array)
	const endpoint = `${api_url}rest-auth/inventories/multi_delete/?id=${id_array.join(',')}`
	// console.log("endpoint", endpoint)
	let lookupOptions = {
		method: "DELETE",
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Token '+getAuthKey(),
		}
	}
	if (!navigator.onLine){
		// dispatch({
		// 	type: PUSH_OFFLINE_QUEUE,
		// 	payload: {title: `Delete Inventories #${id_array.join(', #')}`, endpoint: endpoint, lookupOptions: lookupOptions},
		// })
		// dispatch({
		// 	type: ADD_QUEUE_MESSAGE,
		// 	payload: {
		// 		type: 'massDeleteInventory-cache',
		// 		id_array: id_array,
		// 	},
		// })
	} else {
		fetch(endpoint, lookupOptions)
		.then(function(response){
			if (response.ok){
				dispatch({
					type: MASS_DELETE_INVENTORY,
					payload: {
						type: 'massDeleteInventory',
						id_array: id_array,
					},
				})
				id_array.forEach(id => {
					dispatch(deleteInventoryAutosave(id))
					dispatch(deleteCheckoutAutosave(id))
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}
}

export const removeMessage = (id) => dispatch => {
	dispatch({
		type: REMOVE_MESSAGE,
		payload: id,
	})
}


export const addQueueMessage = (data) => dispatch => {
	if (!data.id) data.id = getMessageID()
	dispatch({
		type: ADD_QUEUE_MESSAGE,
		payload: data,
	})
}