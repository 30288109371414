import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { createAddress, editAddress } from '../redux/actions/addressActions'
import { connect } from 'react-redux'
import { api_url } from '../api_url'
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import ConfirmModal from '../modals/ConfirmModal'

class CreateAddressModal extends Component {

	constructor(props){
		super(props)
		this.state = { 
			validated: false,
			id: null,
			tenant_name: "",
			// tenant_number: "",
			tenant_address_line_1: "",
			tenant_address_line_2: "",
			tenant_town_city: "",
			tenant_county: "",
			tenant_postcode: "",
			owner_name: "",
			property_manager: "",
			landlord_email: "",
			historyAddress: null,

			searchValue: "",
			searchOptions: [],
			searchSelected: [],
			searchIsLoading: false,
			searchIsVisible: false,

			hasChanges: false,
			displayConfirmation: false,
			confirmCallback: null,
		};
	}

	handleModalConfirm = () => {
  		this.setState({ 
  			displayConfirmation: false,
  			hasChanges: false,
  		 }, () => this.state.confirmCallback())
	}

	componentWillMount(){
		if (this.props.isMobile()) this.onShow()
	}

	fetchAddress = () => {
	    let itemID = null
	    if (this.props.item){
	      itemID = this.props.item.id
	    } else {
	      itemID = history.location.hash.split('-').pop()
	    }
		const endpoint = `${api_url}rest-auth/addresses/${itemID}/` 
		let thisComp = this
		let lookupOptions = {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
        		'Authorization': 'Token '+window.localStorage.getItem('authKey'),
			}
		}
		fetch(endpoint, lookupOptions)
		.then(function(response){
			return response.json()
		}).then(function(responseData){
			if (responseData){
				// console.log("responseData", responseData)
        		thisComp.setItemState(responseData)
				thisComp.setState({
					historyAddress: responseData,
				})
			}
		}).catch(function(error){
			console.log("error", error)
		})
	}

	setItemState = item => {
  		this.setState({
      		validated: false,
      		hasChanges: false,
			id: item.id,
			tenant_name: item.tenant_name,
			// tenant_number: item.tenant_number,
			tenant_address_line_1: item.tenant_address_line_1,
			tenant_address_line_2: item.tenant_address_line_2,
			tenant_town_city: item.tenant_town_city,
			tenant_county: item.tenant_county,
			tenant_postcode: item.tenant_postcode,
			owner_name: item.owner_name,
			property_manager: item.property_manager,
			landlord_email: item.landlord_email,
  		})

	}


  onShow = () => {
  	const { hasChanges } = this.state
  	if (!hasChanges){
	  	this.setState({
	  		validated: false,
	      	displayConfirmation: false,
	  	})
	  	if (this.props.item){
	  		this.setItemState(this.props.item)
	  	} else if (history.location.hash.split('-').length > 1) {
	  		this.fetchAddress()
	  	} else {
	  		this.setState({
	      		validated: false,
	      		id: null,
	      		tenant_name: "",
	      		// tenant_number: "",
	      		tenant_address_line_1: "",
	      		tenant_address_line_2: "",
	      		tenant_town_city: "",
	      		tenant_county: "",
	      		tenant_postcode: "",
	      		owner_name: "",
	      		property_manager: "",
	      		property_manager: "",
	      		landlord_email: "",
	  		})
	  	}
  	}
  }

  handleSubmit(event) {
  	// console.log("handle submit")
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true })
    }

	const formData = new FormData();
	Object.keys(this.state).forEach(key => {
		console.log(key, this.state[key])
		// if (this.state[key]){
		formData.append(key, this.state[key] );
		// }
	})

	const { historyCheck } = this.state
    let item = null
    if (historyCheck) item = historyCheck
    else if (this.props.item) item = this.props.item

    if (item){
    	// console.log("edit address")
    	this.props.editAddress(formData, Object.assign({}, this.state))
    } else {
    	// console.log("create address")
    	this.props.createAddress(formData, Object.assign({}, this.state))
    }

    this.setState({ hasChanges: false })

    // console.log("close")
    this.props.handleClose()
  }

  handleOnChange = (event) => {
  	this.setState({ 
  		[event.target.name]: event.target.value,
  		hasChanges: true,
  	});
  }

  onChange = (query) => {
  	console.log("query", query)
  	const placeID = query[0].place_id
    this.setState({
      searchIsVisible: false,
      // searchSelected: query,
    })
    const endpoint = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyCGbFbgJv94Zs4hTFZv0OMUY0FaH3MLpKc&fields=address_components&sessiontoken=baba&placeid=${placeID}`
  	// const endpoint = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/findplacefromtext/json?key=AIzaSyCGbFbgJv94Zs4hTFZv0OMUY0FaH3MLpKc&inputtype=textquery&fields=formatted_address,place_id&sessiontoken=baba&input=${encodeURIComponent(searchValue)}` 
  	let lookupOptions = {
  		method: "GET",
  	}
  	let thisComp = this
  	fetch(endpoint, lookupOptions)
  	.then(function(response){
  		return response.json()
  	}).then(function(responseData){
  		console.log("responseData", responseData)
  		// thisComp.setState({ searchIsLoading: false, })
  		if (responseData && responseData.result && responseData.result.address_components){
  			console.log("responseData.result.address_components", responseData.result.address_components)
  			let address_line_1 = [
  				responseData.result.address_components.find(item => item.types.includes('street_number')),
  				responseData.result.address_components.find(item => item.types.includes('route')),
  			]
  			// console.log("address_line_1 1", address_line_1)
  			address_line_1 = address_line_1.filter(item => item)
  			// console.log("address_line_1 2", address_line_1)
  			address_line_1 = address_line_1.map(item => item.long_name)
  			// console.log("address_line_1 3", address_line_1)
  			address_line_1 = address_line_1.join(' ')
  			// console.log("address_line_1 4", address_line_1)
  			const town_city = responseData.result.address_components.find(item => item.types.includes('postal_town') || item.types.includes('locality'))
  			const county = responseData.result.address_components.find(item => item.types.includes('administrative_area_level_2'))
  			const postcode = responseData.result.address_components.find(item => item.types.includes('postal_code'))
  			thisComp.setState({
  				hasChanges: true,
  				tenant_address_line_1: address_line_1,
  				tenant_town_city: town_city ? town_city.long_name : "",
  				tenant_county: county ? county.long_name : "",
  				tenant_postcode: postcode ? postcode.long_name : "",
  			})
  		}
  	}).catch(function(error){
  		console.log("error", error)
  	})
  }

  onInputChange = (searchValue) => {
  	console.log("searchValue", searchValue)
    this.setState({
    	searchValue: searchValue,
    	searchIsLoading: true,
  		searchIsVisible: true,
    });
  	const endpoint = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyCGbFbgJv94Zs4hTFZv0OMUY0FaH3MLpKc&components=country:gb&inputtype=textquery&fields=formatted_address,place_id&sessiontoken=baba&input=${encodeURIComponent(searchValue)}` 
  	let lookupOptions = {
  		method: "GET",
  		// mode: 'no-cors',
  	}
  	let thisComp = this
  	fetch(endpoint, lookupOptions)
  	.then(function(response){
  		return response.json()
  	}).then(function(responseData){
  		console.log("responseData", responseData)
  		thisComp.setState({ searchIsLoading: false, })
  		if (responseData){
  			thisComp.setState({
  				searchOptions: responseData.predictions,
  			})
  		}
  	}).catch(function(error){
  		console.log("error", error)
  	})
  }

    onFocus = () => {
    	this.setState({
    		searchIsVisible: true,
    	})
    }

    onBlur = () => {
    	this.setState({
    		searchIsVisible: false,
    	})
    }

  confirmClose = (e, callback) => {
  	if (!callback) callback = this.props.handleClose
  	this.setState({confirmCallback: callback})
  	const { displayConfirmation, hasChanges } = this.state
  	if (displayConfirmation || !hasChanges){
  		this.setState({ 
  			displayConfirmation: false,
  			hasChanges: false,
  		 }, () => callback())
  		// callback()
  	} 
  	else this.setState({ displayConfirmation: true })
  }

  render(){
  	const { displayConfirmation } = this.state
  	if (displayConfirmation){
  		return <ConfirmModal cancelText={"Go back"} show={this.props.show} handleConfirm={this.handleModalConfirm} title={"Discard changes?"} body={"Are you sure you want to discard current changes?"} handleClose={() => this.setState({ displayConfirmation: false })} isMobile={this.props.isMobile} />
  	}
  	else if (this.props.show && this.props.isMobile()) return this.getInner()
  	else {
	  	return (
	        <Modal size="lg" show={this.props.show} onHide={this.confirmClose} onShow={this.onShow}>
	        	{this.getInner()}
	        </Modal>
	  	)
  	}
  }

  getInner = () => {
  	console.log("this.props", this.props)
    const { validated } = this.state;
    const {tenant_name, tenant_address_line_1, tenant_address_line_2, tenant_town_city, tenant_county, tenant_postcode, owner_name, property_manager, landlord_email, historyCheck} = this.state
    const { searchOptions, searchSelected, searchIsLoading, searchIsVisible } = this.state
	const sm1 = 4
	const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end"

    let item = null
    if (historyCheck) item = historyCheck
    else if (this.props.item) item = this.props.item

    return (
    	<>
          <Modal.Header>
            <Modal.Title>{this.props.item ? `Editing Address #${item.id}` : "Create New Address" }</Modal.Title>
            <CloseModalButton onClick={this.confirmClose}/>
          </Modal.Header>
          <Form
	          noValidate
	          validated={validated}
	          onSubmit={e => this.handleSubmit(e)}
	          autoComplete='off'
          >
          <Modal.Body>

          	{/**<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Tenant Name
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="tenant_name"
	                placeholder="Tenant Name" 
	                value={tenant_name}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid tenant name.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>
          	<hr/>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Tenant Number
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="tenant_number"
	                placeholder="Tenant Number" 
	                value={tenant_number}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid tenant number.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>**/}

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	            </Form.Label>
	            <Col sm={sm2}>
	      	    	<AsyncTypeahead
	      	    		id={'create-address-search-typeahead'}
	      	    		inputProps={{id: 'create-address-search', autoComplete: 'off'}}
	    				options={searchOptions}
	    				selected={searchSelected}
	    				isLoading={searchIsLoading}
	    				maxVisible={10}
	    				placeholder="Address Search"
	          			onSearch={this.onInputChange}
	    				minLength={2}
	    				useCache={false}
	    				onKeyDown={this.onKeyDown}
	    				selectHintOnEnter={true}
	    				onBlur={this.onBlur}
	    				onFocus={this.onFocus}
	    				open={searchIsVisible}
	    				onChange={this.onChange}
                  		clearButton={true}
		                labelKey={(option) => `${option.description}`}
		                filterBy={(option, props) => {
		                  return option.description
		                }}
	              	/>
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Address Line 1 *
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="tenant_address_line_1"
	                placeholder="Address Line 1" 
	                required
	                value={tenant_address_line_1}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid address line 1.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Address Line 2
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="tenant_address_line_2"
	                placeholder="Address Line 2"
	                value={tenant_address_line_2}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid address line 2.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Town/City
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="tenant_town_city"
	                placeholder="Town/City"
	                value={tenant_town_city}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid town/city.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              County
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="tenant_county"
	                placeholder="County"
	                value={tenant_county}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid county.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Postcode *
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="tenant_postcode"
	                placeholder="Postcode" 
	                required
	                value={tenant_postcode}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid postcode.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>

          	<hr/>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Property Manager
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  className=""
                  as="select"
                  name="property_manager"
                  value={property_manager}
                  onChange={this.handleOnChange}
                >
                  <option value="">None</option>
                  {this.props.userList && this.props.userList.map(userItem => <option key={userItem.id} value={userItem.id}>{`${userItem.first_name} ${userItem.last_name}`}</option>)}
                </Form.Control>
              </Col>
            </Form.Group>

          	{/**<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Owner name
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="owner_name"
	                placeholder="Owner name" 
	                value={owner_name}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid owner name.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>**/}

          	{/**<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Landlord Email
	            </Form.Label>
	            <Col sm={sm2}>
	              <Form.Control 
	                type="text" 
	                name="landlord_email"
	                placeholder="Landlord Email" 
	                value={landlord_email}
	                onChange={this.handleOnChange}
	              />
	              <Form.Control.Feedback type="invalid">
	                Please enter a valid landlord email.
	              </Form.Control.Feedback>
	            </Col>
          	</Form.Group>**/}

          </Modal.Body>
          <Modal.Footer>
     		{!item ? 
	            <Button variant="secondary" onClick={this.confirmClose} className="float-left mr-auto">
	              Close
	            </Button>
            :	
            	<>
	            <Button variant="secondary" onClick={this.confirmClose} className="float-left ml-0">
	              Close
	            </Button>
	            <Button variant="sab" onClick={(e) => this.confirmClose(e, () => this.props.handleShowViewAddressModal(item))} className="float-left mr-auto ml-0">
	              View
	            </Button>
	            </>
        	}
     		{item ? 
     			<>
     				<input type="hidden" name="id" value={this.props.item.id} />
            		<Button type="submit">Save</Button>
     			</>
     			:
            	<Button type="submit">Create</Button>
     		}
          </Modal.Footer>
        </Form>
        </>
    )

  }
}

const mapStateToProps = state => ({
  userList: state.user.userList,
});

export default connect(mapStateToProps, { createAddress, editAddress })(CreateAddressModal);
