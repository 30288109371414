import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class StorageWarning extends Component {

  render() {
    return (
      this.props.displayStorageWarning ? <Alert variant="danger">Storage low: please delete unneeded <Alert.Link as={Link} to="/autosaves">autosaves</Alert.Link>. </Alert> : ""
    )

  }
}

const mapStateToProps = state => ({
  displayStorageWarning: state.offline.displayStorageWarning,
});

export default connect(mapStateToProps, { })(StorageWarning);
