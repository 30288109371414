import React, { Component } from 'react';
import { Container, Button, Row, Col, Navbar, Breadcrumb, Form, Alert, Spinner, ListGroup, InputGroup, FormControl } from 'react-bootstrap'
// import { fetchSettings, submitInventorySettings, removeMessage } from '../redux/actions/settingsActions'
import { getUserList, submitAccountSettings } from '../redux/actions/userActions'
import { connect } from 'react-redux'
import {withGetScreen} from 'react-getscreen'
import OfflineAlert from '../components/OfflineAlert'
import { Offline } from "react-detect-offline";
import Sidebar from '../components/Sidebar'
import RefreshButton from '../components/RefreshButton'
import SimpleSplitOptionsField from '../components/SimpleSplitOptionsField'
import RichTextEditor from 'react-rte';
import FormButtonGroup from '../components/FormButtonGroup'

class AccountSettingsPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      office: '',
      validated: false,
    };
  }

  componentWillMount() {
    this.props.getUserList()
  }

  onRefresh = () => {
    this.setState({
      office: '',
      validated: false,
    });
    this.props.getUserList()

  }

  componentWillReceiveProps(nextProps) {
    const { currentID } = this.props
  	if (nextProps.userList && (currentID || currentID === 0)){
      const user = nextProps.userList.find((item) => item.id === currentID)
      if (user){
        this.setState({
          office: user.office,
        })
      }
  		
  	}
  }

  handleOnChange = (event) => {
    this.setState({ 
      [event.target.name]: event.target.value,
    });
  }

  handleChange = (fieldname, value) => {
    console.log("AccountSettingsPage handleChange", fieldname, value)
    this.setState({ 
      [fieldname]: value,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const {office } = this.state
    const { currentID } = this.props
    this.props.submitAccountSettings(currentID, office)
  }

  // onRichTextChange = (fieldname, value) => {
  //   this.setState({ 
  //     [fieldname]: value,
  //   });
  // };

  render() {

    const { validated, office } = this.state
    const { isLoading, userList, currentID, settings } = this.props
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end"

    let officeOptions = []
    if (settings && settings.inventory_contact_details && settings.inventory_contact_details.length && settings.inventory_contact_details.startsWith('[')){
      officeOptions = JSON.parse(settings.inventory_contact_details).map(item => {
        return {value: item.name, label: item.name}
      })
    }

    return (
    	<div className="page settings-page">
        <Sidebar/>
      	<Navbar fixed="top">
  	    	<Container>
  		    	<h2 className="ml-5 ml-xl-0 d-inline-block">Account Settings</h2><RefreshButton onClick={this.onRefresh} isRefreshing={isLoading}/>
  		    	<Breadcrumb className="align-middle d-none d-sm-block" listProps={{ className: "mb-0" }}>
  				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
  				  <Breadcrumb.Item active>Account Settings</Breadcrumb.Item>
  				</Breadcrumb>
  		    </Container>
      	</Navbar>
      	<Container className="pt-5 pb-5 mt-4 mt-sm-5">
          {isLoading ? 
            <div className="text-center pt-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          :
            <>
            <Offline polling={{enabled: false}}><OfflineAlert/></Offline>
            {/**settingsMessages.map(message => {
              if (message.type === 'submitInventorySettings'){
              } else if (message.type === 'submitInventorySettings-cache'){
                return (
                  <Alert key={`${message.id}`} variant="info" onClose={() => this.props.removeMessage(message.id)} dismissible>
                    Successfully queued submit settings.
                  </Alert>
                )
              } else if (message.type === 'server-error'){
                return (
                  <Alert key={`${message.id}`} variant="danger" onClose={() => this.props.removeMessage(message.id)} dismissible>
                    {message.error ? 
                    `There was an error: ${message.error}.`
                      :
                    "There was an unexpected error."
                    }
                  </Alert>
                )
              } else {
                return null
              }
          })**/}
            <Form
              noValidate
              validated={validated}
              onSubmit={e => this.handleSubmit(e)}
            >

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Office
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={officeOptions} 
                  name='office' 
                  value={office} 
                  onChange={this.handleOnChange} 
                  alwaysSelect={true}
                  multiline={true}
                />
              </Col>
            </Form.Group>

              <Button disabled={!this.props.isOnline} className="float-right" type="submit">Save</Button>
            </Form>
            </>
          }
  		  </Container>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userList: state.user.userList,
  currentID: state.auth.currentID,
  settings: state.settings.settings,
  // settingsMessages: state.settings.settingsMessages,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { getUserList, submitAccountSettings })(withGetScreen(AccountSettingsPage));