import { FETCH_SETTINGS, SUBMIT_SETTINGS, SUBMIT_INVENTORY_SETTINGS, SET_SETTINGS_LOADING, ADD_SETTINGS_SERVER_ERROR_MESSAGE, REMOVE_MESSAGE, ADD_QUEUE_MESSAGE } from '../actions/types'
import RichTextEditor from 'react-rte';

const initialState = {
    settingsMessages: [],
    hiddenElement: null,
	settings: {
      midterm_declaration: "",
      disclaimer: "",
      room_names: [],
      default_rooms: [],
      inventory_disclaimer: "",
      inventory_abbreviations: "",
      inventory_end_of_tenancy_requirements: "",
      inventory_recommended_cleaners: "",
      inventory_signature_disclaimer: "",
      inventory_default_rooms: [],
      inventory_default_room_items: [],
      inventory_default_tenant_emails: '',
      inventory_contact_details: '',
	},
	isLoading: false,
}

function getMessageID(){
	let id = parseInt(window.localStorage.getItem('message-id') || "0")
	window.localStorage.setItem('message-id', id+1)
	return id
}

export default function(state = initialState, action) {
	// console.log("settingsReducer action", action)
	let settingsMessages = null
	switch(action.type) {
		case FETCH_SETTINGS:
			return {
				...state,
				isLoading: false,
				settings: Object.assign({}, state['settings'], {
			        midterm_declaration: action.midterm_declaration,
			        disclaimer: action.disclaimer,
			        room_names: action.room_names,
			        default_rooms: action.default_rooms,
			        inventory_disclaimer: action.inventory_disclaimer,
			        inventory_abbreviations: action.inventory_abbreviations,
			        inventory_end_of_tenancy_requirements: action.inventory_end_of_tenancy_requirements,
			        inventory_recommended_cleaners: action.inventory_recommended_cleaners,
			        inventory_signature_disclaimer: action.inventory_signature_disclaimer,
			        inventory_default_rooms: action.inventory_default_rooms,
			        inventory_default_room_items: action.inventory_default_room_items,
			        inventory_default_tenant_emails: action.inventory_default_tenant_emails,
			        inventory_contact_details: action.inventory_contact_details,
			      }),
				hiddenElement: action.hiddenElement,
			}
			/* falls through */
		case SUBMIT_SETTINGS:
			action.payload.id = getMessageID()
			return {
				...state,
				isLoading: false,
				settingsMessages: state['settingsMessages'].concat([action.payload]).slice(-3),
				settings: Object.assign({}, state['settings'], {
			        midterm_declaration: action.midterm_declaration,
			        disclaimer: action.disclaimer,
			        room_names: action.room_names,
			        default_rooms: action.default_rooms,
			        inventory_disclaimer: action.inventory_disclaimer,
			        inventory_abbreviations: action.inventory_abbreviations,
			        inventory_end_of_tenancy_requirements: action.inventory_end_of_tenancy_requirements,
			        inventory_recommended_cleaners: action.inventory_recommended_cleaners,
			        inventory_signature_disclaimer: action.inventory_signature_disclaimer,
			        inventory_default_rooms: action.inventory_default_rooms,
			        inventory_default_room_items: action.inventory_default_room_items,
			        inventory_default_tenant_emails: action.inventory_default_tenant_emails,
			        inventory_contact_details: action.inventory_contact_details,
			      }),
			}
			/* falls through */
		// case SUBMIT_INVENTORY_SETTINGS:
		// 	action.payload.id = getMessageID()
		// 	return {
		// 		...state,
		// 		isLoading: false,
		// 		settingsMessages: state['settingsMessages'].concat([action.payload]).slice(-3),
		// 		settings: Object.assign({}, state['settings'], {
		// 	        inventory_disclaimer: action.inventory_disclaimer,
		// 	        // inventory_abbreviations: RichTextEditor.createValueFromString(action.inventory_abbreviations, 'markdown'),
		// 	        // inventory_end_of_tenancy_requirements: RichTextEditor.createValueFromString(action.inventory_end_of_tenancy_requirements, 'markdown'),
		// 	        // inventory_recommended_cleaners: RichTextEditor.createValueFromString(action.inventory_recommended_cleaners, 'markdown'),
		// 	        // inventory_signature_disclaimer: RichTextEditor.createValueFromString(action.inventory_signature_disclaimer, 'markdown'),
		// 	        // inventory_default_rooms: action.inventory_default_rooms,
		// 	        // inventory_default_room_items: action.inventory_default_room_items,
		// 	      }),
		// 	}
		// 	/* falls through */
		case SET_SETTINGS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
			/* falls through */
		case ADD_SETTINGS_SERVER_ERROR_MESSAGE:
			action.payload.id = getMessageID()
			return {
				...state,
				settingsMessages: state['settingsMessages'].concat([action.payload]).slice(-3),
			}
			/* falls through */
		case REMOVE_MESSAGE:
			settingsMessages = state['settingsMessages'].filter(message => message.id !== action.payload)
			return {
				...state,
				settingsMessages,
			}
			/* falls through */
		case ADD_QUEUE_MESSAGE:
			action.payload.id = getMessageID()
			return {
				...state,
				settingsMessages: state['settingsMessages'].concat([action.payload]).slice(-3),
			}
			/* falls through */
		default:
			return state
	}
}