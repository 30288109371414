import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { login } from '../redux/actions/authActions'

class LoginPage extends Component {

  constructor(props){
    super(props)
    this.state = { 
      validated: false,
      emailInput: "",
      passwordInput: "",
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   const { authKey } = nextProps
  //   console.log("LoginPage componentWillMount nextProps", nextProps)
  //   if (authKey){
  //     console.log("Redirect to home")
  //     // Redirect to login
  //     history.push('/')

  //   }
  // }


  handleSubmit(event) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true })
    }
    const {emailInput, passwordInput } = this.state
    console.log("emailInput, passwordInput", emailInput, passwordInput)
    this.props.login(emailInput, passwordInput)
  }

  emailOnChange = (event) => {
    this.setState({emailInput: event.target.value});
  }

  passwordOnChange = (event) => {
    this.setState({passwordInput: event.target.value});
  }

  render() {
    const { validated } = this.state;
    const {emailInput, passwordInput } = this.state
    const classes1 = "d-flex justify-content-sm-end"
    return (
      <div className="app d-flex align-items-center">
      <Container>
        <Row className="">
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Header as="h5">Login</Card.Header>
            <Card.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={e => this.handleSubmit(e)}
            >
              <Form.Group as={Row} controlId="formHorizontalEmail" className="validate-me">
                <Form.Label column sm={4} className={classes1}>
                  Email
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="email" 
                    placeholder="Email" 
                    required
                    value={emailInput}
                    onChange={this.emailOnChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter an email.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalPassword">
                <Form.Label column sm={4} className={classes1}>
                  Password
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="password" 
                    placeholder="Password" 
                    required
                    value={passwordInput}
                    onChange={this.passwordOnChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a password.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {this.props.loginError && 
                <Alert variant="danger" className="text-right">
                  Your email or password were incorrect.
                </Alert>
              }

              <Form.Group as={Row}>
                <Col className="d-flex justify-content-end">
                  <Button type="submit">Sign in</Button>
                </Col>
              </Form.Group>
            </Form>
            </Card.Body>
          </Card>
          </Col>
        </Row>
      </Container>
      </div>
    )

  }
}

const mapStateToProps = state => ({
  authKey: state.auth.authKey,
  loginError: state.auth.loginError,
});

export default connect(mapStateToProps, { login })(LoginPage);