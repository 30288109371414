import React, { Component } from 'react';
import { Container, Button, Row, Col, Navbar, Breadcrumb, Form, Alert, Spinner, ListGroup, InputGroup, FormControl } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

class SimpleSplitOptionsField extends Component {

	constructor(props){
		super(props)
		this.state = {
			newItem: "",
		};
	}

	addItem = () => {
		const { newItem } = this.state
		let { items } = this.props
		if (newItem !== ""){
			if (items.length > 0) items += ','
			items += newItem
			this.setState({
				newItem: "",
			}, () => this.props.handleChange(items))
		}
	}

	removeItem = (itemName) => {
		const { items } = this.props
		let new_items = items.replace(itemName+',', '').replace(','+itemName, '').replace(itemName, '')
		console.log("new_items", new_items)
		this.props.handleChange(new_items)
	}

	handleOnChange = (event) => {
		this.setState({ 
			[event.target.name]: event.target.value,
		});
	}

  render() {
  	const { newItem } = this.state
  	const { items } = this.props
    return (
    	<>
	      <InputGroup className="mb-2">
	        <FormControl
	          name="newItem"
	          placeholder="Add new item"
	          aria-label="New Item Name"
	          value={newItem}
	          onChange={this.handleOnChange}
	        />
	        <InputGroup.Append>
	          <Button variant="primary" onClick={() => this.addItem(newItem)}>Add</Button>
	        </InputGroup.Append>
	      </InputGroup>
	      <ListGroup>
	        {items !== '' && items.split(',').map((item, index) => 
	          <ListGroup.Item variant="light" key={item}>
	            <div className="up-down-button mr-3">
	            </div>
	            <div className="d-inline-block align-top">{item}</div>
	            <Button 
	              className="float-right" 
	              variant="danger" 
	              size="sm" 
	              onClick={() => this.removeItem(item)}
	            >Remove item</Button>
	          </ListGroup.Item>
	          )
	        }
	      </ListGroup>
    	</>
    )

  }
} 
export default SimpleSplitOptionsField
