import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form, FormCheck, SplitButton, Dropdown, Tabs, Tab, ButtonGroup } from 'react-bootstrap'
import { editCheck } from '../redux/actions/checkActions'
import { saveCheck, incrementOfflineFileCount } from '../redux/actions/offlineActions'
import { connect } from 'react-redux'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import { api_url } from '../api_url'
import { stringify } from 'query-string';
import DatePicker from "react-datepicker";
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'
import ConfirmModal from '../modals/ConfirmModal'
import FormButtonGroup from '../components/FormButtonGroup'
import SignaturePad from 'signature_pad'
import { canvasToBlob } from 'blob-util'
import Resizer from 'react-image-file-resizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faTrash, faCaretUp, faCaretDown, faClock } from '@fortawesome/free-solid-svg-icons'
import localForage from "localforage";
import cloneDeep from 'lodash/cloneDeep';
import { getBase64Strings } from 'exif-rotate-js/lib';

// const imgPrefix = 'http://127.0.0.1:8000'
// const imgPrefix = 'https://sab.hjwebdev.com'
const imgPrefix = ''

const GOOD_FAIR_POOR_CHOICES = [
  {value: '', label: '-'},
  {value: 'good', label: 'Good'},
  {value: 'fair', label: 'Fair'},
  {value: 'poor', label: 'Poor'},
  {value: 'n/a', label: 'N/A'},
]

const YES_NO_CHOICES = [
  {value: '', label: '-'},
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
  {value: 'n/a', label: 'N/A'},
]

let signaturePad = null

class EditCheckModal extends Component {

  constructor(props){
    super(props)
    this.state = this.getDefaultState()
  }

  getDefaultState = () => {
    let newRoomID = 0
    const {settings} = this.props
    let rooms = []
    console.log("settings.default_rooms", settings.default_rooms)
    let defaultRooms = settings && settings.default_rooms && settings.default_rooms.length > 0 ? settings.default_rooms : []
    defaultRooms.forEach((room, i) => {
      rooms.push({id: `new_room_${newRoomID++}`, name: room, order: i, comments: []})
    })

    return { 
      was_carried_over: false,
      validated: false,
      address: null,
      assigned_to: "",
      appointment_date: null,
      duplicate_previous_check: true,
      addressIsInvalid: false,
      searchValue: "",
      searchOptions: [],
      searchSelected: [],
      searchIsLoading: false,
      searchIsVisible: false,

      rooms: rooms,
      comments: [],
      overall_comments: [],
      newCommentID: 0,
      newRoomID: newRoomID,
      removedComments: [],
      removedRooms: [],

      property_security_locks: "",
      boiler_housing_clear: "",
      smoke_detectors_each_floor: "",
      gas_check_up_to_date: "",
      electric_check_up_to_date: "",
      chimney_sweep_up_to_date: "",
      blind_cleats_up_to_date: "",
      oil_check_up_to_date: "",
      co_detector_present: "",

      ambient_odour: "",
      pets: "",
      hoarding: "",
      smoking: "",
      business_activity: "",
      malicious_damage: "",
      condensation_mould_damp: "",
      tenants_present_at_inspection: "",
      condition_property: "",
      cleanliness_property: "",
      other_comments: "",
      signatureMethod: "draw",
      signature: "",
      signatureIsValid: false,

      submit_check: false,

      hasChanges: false,
      displayConfirmation: false,
      confirmCallback: null,
      hasPromptedLoadSave: false,
      promptLoadSave: false,
      savedImagesToDelete: [],

      newCheckAutosaveName: null,
    }
  }

  handleModalConfirm = () => {
      this.setState({ 
        displayConfirmation: false,
        hasChanges: false,
       }, () => this.state.confirmCallback())
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  componentDidMount(){
  }

  setItemState = item => {
    console.log("setItemState", item)

    const address_full = item && item.address_full ? [
      item.address_full.tenant_address_line_1, 
      item.address_full.tenant_address_line_2, 
      item.address_full.tenant_town_city, 
      item.address_full.tenant_county, 
      item.address_full.tenant_postcode
    ].filter(el => el !== null && el !== "") : [];

    let appointment_date = null
    if (item.appointment_date){
      let split_appointment_date = item.appointment_date.split('-')
      appointment_date = new Date(`${split_appointment_date[2]}-${split_appointment_date[1]}-${split_appointment_date[0]}`)
      // console.log("parsed date", appointment_date)
    }

    if (item.rooms){
      item.rooms.sort((a, b) => a.order - b.order).forEach((roomItem, roomIndex) => {
        roomItem.order = roomIndex
      })
    }

    this.setState({
      validated: false,
      hasChanges: false,
      id: item.id,
      appointment_date: appointment_date,
      was_carried_over: item.was_carried_over ? item.was_carried_over : false,
      address: address_full.length > 0 ? {address_full: address_full.join(', '), id: item.address_full.id} : null,
      assigned_to: item.assigned_to ? item.assigned_to : "",
      searchValue: address_full,
      searchOptions: [],
      searchSelected: address_full.length > 0 ? [{address_full: address_full.join(', '), id: item.address_full.id}] : [],
      searchIsLoading: false,
      searchIsVisible: false,

      rooms: item.rooms ? item.rooms : [],
      comments: item.comments ? item.comments : [],
      overall_comments: item.overall_comments ? item.overall_comments : [],

      property_security_locks: item.property_security_locks ? item.property_security_locks : "",
      boiler_housing_clear: item.boiler_housing_clear ? item.boiler_housing_clear : "",
      smoke_detectors_each_floor: item.smoke_detectors_each_floor ? item.smoke_detectors_each_floor : "",
      gas_check_up_to_date: item.gas_check_up_to_date ? item.gas_check_up_to_date : "",
      electric_check_up_to_date: item.electric_check_up_to_date ? item.electric_check_up_to_date : "",
      chimney_sweep_up_to_date: item.chimney_sweep_up_to_date ? item.chimney_sweep_up_to_date : "",
      blind_cleats_up_to_date: item.blind_cleats_up_to_date ? item.blind_cleats_up_to_date : "",
      oil_check_up_to_date: item.oil_check_up_to_date ? item.oil_check_up_to_date : "",
      co_detector_present: item.co_detector_present ? item.co_detector_present : "",

      ambient_odour: item.ambient_odour ? item.ambient_odour : "",
      pets: item.pets ? item.pets : "",
      hoarding: item.hoarding ? item.hoarding : "",
      smoking: item.smoking ? item.smoking : "",
      business_activity: item.business_activity ? item.business_activity : "",
      malicious_damage: item.malicious_damage ? item.malicious_damage : "",
      condensation_mould_damp: item.condensation_mould_damp ? item.condensation_mould_damp : "",
      tenants_present_at_inspection: item.tenants_present_at_inspection ? item.tenants_present_at_inspection : "",
      condition_property: item.condition_property ? item.condition_property : "",
      cleanliness_property: item.cleanliness_property ? item.cleanliness_property : "",
      other_comments: item.other_comments ? item.other_comments : "",

      signature: item.signature ? item.signature : "",
      signatureMethod: item.signatureMethod ? item.signatureMethod : "draw",

      submit_check: false,
    })

  }

  onShow = () => {
    console.log("EditCheckModal, onShow", this.state, this.props)
    const { hasChanges, hasPromptedLoadSave, promptLoadSave } = this.state
    const { checkAutosave, item, checkAutosaveName } = this.props
    // console.log("initial checkAutosaveName", this.props.checkAutosaveName)
    // let itemID = item ? item.id : (checkAutosave ? checkAutosave.id : null)
    let itemID = item && item.id ? item.id : 0
    let thisComp = this
    if (!checkAutosaveName){
      localForage.keys().then(function(keys) {
        const autosaves = keys.filter(key => key === `save_check_${itemID}` || key.startsWith(`save_check_${itemID}_`))
        if (!autosaves){
          autosaves = []
        }
        let proposedNumber = 2
        let nameValid = false
        while (!nameValid){
          let exists = autosaves.find(autosaveItem => autosaveItem === `save_check_${itemID}_${proposedNumber}`)
          if (!exists){
            nameValid = true
            thisComp.setState({
              newCheckAutosaveName: `save_check_${itemID}_${proposedNumber}`,
            })
          } else {
            proposedNumber++
          }
        }
      })
    }

    this.setState({
      validated: false,
    })
    this.initialiseSignaturePad()
    if (checkAutosave) {
      this.loadSave()
    } else if (item) {
      this.setItemState(item)
    } else {
      this.setState(this.getDefaultState())
    }
  }

  initialiseSignaturePad = () => {
    console.log("initialiseSignaturePad")
    var canvas = document.querySelector("canvas");
    if (canvas) {
      canvas.width = canvas.parentElement.offsetWidth
      canvas.height = canvas.parentElement.offsetWidth / 2
      signaturePad = new SignaturePad(canvas);
    }
  }

  dataURLtoFile = (dataURI, filename) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: 'image/jpeg' });
  }

  blobToFile = (theBlob, fileName) => {
      return new File([theBlob], fileName, { type: "image/jpeg", });
  }

  getValidatedData = (handleSuccess) => {
      const { searchSelected, signature, signatureMethod, submit_check } = this.state
      let appointment_date = null
      if (this.state.appointment_date){
        let d = this.state.appointment_date
        console.log("d", d)
        appointment_date = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      }
      let validatedData = {
        id: this.state.id,
        was_carried_over: this.state.was_carried_over,
        address: searchSelected && searchSelected.length > 0 && searchSelected[0].id ? searchSelected[0].id : "",
        assigned_to: this.state.assigned_to,
        appointment_date: appointment_date,

        property_security_locks: this.state.property_security_locks,
        boiler_housing_clear: this.state.boiler_housing_clear,
        smoke_detectors_each_floor: this.state.smoke_detectors_each_floor,
        gas_check_up_to_date: this.state.gas_check_up_to_date,
        electric_check_up_to_date: this.state.electric_check_up_to_date,
        chimney_sweep_up_to_date: this.state.chimney_sweep_up_to_date,
        blind_cleats_up_to_date: this.state.blind_cleats_up_to_date,
        oil_check_up_to_date: this.state.oil_check_up_to_date,
        co_detector_present: this.state.co_detector_present,

        ambient_odour: this.state.ambient_odour,
        pets: this.state.pets,
        hoarding: this.state.hoarding,
        smoking: this.state.smoking,
        business_activity: this.state.business_activity,
        malicious_damage: this.state.malicious_damage,
        condensation_mould_damp: this.state.condensation_mould_damp,
        tenants_present_at_inspection: this.state.tenants_present_at_inspection,
        condition_property: this.state.condition_property,
        cleanliness_property: this.state.cleanliness_property,
        other_comments: this.state.other_comments,

        submit_check: this.state.submit_check,
        signature: this.state.signature,

        comments: this.state.comments,
        overall_comments: this.state.overall_comments,
        rooms: this.state.rooms,
        removedComments: this.state.removedComments,
        removedRooms: this.state.removedRooms,
      }
      if (submit_check && signatureMethod === 'draw' && signaturePad){

        let canvas = document.querySelector("canvas")
        canvasToBlob(canvas, 'image/png').then(blob => {
          blob.name = "signature.png"
          validatedData.signature_image = blob

          console.log("validatedData", validatedData)
          handleSuccess(validatedData)
        })
      } else {

        console.log("validatedData", validatedData)
        handleSuccess(validatedData)
      }
  }

  getAutosaveName = () => {
    const { checkAutosaveName } = this.props
    const { newCheckAutosaveName } = this.state
    if (checkAutosaveName) return checkAutosaveName
    else return newCheckAutosaveName
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    event.preventDefault();
    const { searchSelected, signature, signatureMethod, submit_check } = this.state
    const checkAutosaveName = this.getAutosaveName()
    let submitValid = true
    if (submit_check === true){
      if (signature.length === 0 && (!signaturePad || signaturePad.isEmpty())) submitValid = false
    }
    if (form.checkValidity() === false || !submitValid) {
      event.stopPropagation();
    } else {
      this.getValidatedData((validatedData) => {
        this.props.editCheck(validatedData, checkAutosaveName)
        this.setState({ hasChanges: false, promptLoadSave: false, hasPromptedLoadSave: false })
        this.props.handleClose()
      })
    }
    this.setState({ validated: true });
  }

  handleOnChange = (event) => {
  	this.setState({ 
      [event.target.name]: event.target.value,
      hasChanges: true,
    }, () => this.saveChanges());
  }

  saveImage = (imageFile) => {
    const {offlineFileCount} = this.props
    const myFileID = offlineFileCount+1
    let reader = new FileReader();
    reader.onload = function(){
      localForage.setItem(`file ${myFileID}`, reader.result)
    };
    reader.readAsDataURL(imageFile);
    this.props.incrementOfflineFileCount()
    return myFileID
  }

  saveChanges = () => {
    console.log("saveChanges")
    const checkAutosaveName = this.getAutosaveName()
    const savedState = cloneDeep(this.state)

    // Remove File objects
    for (const commentItem of savedState.comments) {
      for (const imageItem of commentItem.image) {
        if (imageItem.autosaveID){
          delete imageItem.url
        }
      }
    }
    for (const commentItem of savedState.overall_comments) {
      for (const imageItem of commentItem.image) {
        if (imageItem.autosaveID){
          delete imageItem.url
        }
      }
    }
    for (const roomItem of savedState.rooms) {
      for (const commentItem of roomItem.comments) {
        for (const imageItem of commentItem.image) {
          if (imageItem.autosaveID){
            delete imageItem.url
          }
        }
      }
    }

    console.log("savedState", savedState)
    let validatedData = {
      id: savedState.id,
      appointment_date: savedState.appointment_date,
      was_carried_over: savedState.was_carried_over,
      address: savedState.address,
      assigned_to: savedState.assigned_to,
      searchValue: savedState.searchValue,
      searchOptions: savedState.searchOptions,
      searchSelected: savedState.searchSelected,
      searchIsLoading: savedState.searchIsLoading,
      searchIsVisible: savedState.searchIsVisible,

      newCommentID: savedState.newCommentID,
      newRoomID: savedState.newRoomID,
      removedComments: savedState.removedComments,
      removedRooms: savedState.removedRooms,

      rooms: savedState.rooms,
      comments: savedState.comments,
      overall_comments: savedState.overall_comments,

      property_security_locks: savedState.property_security_locks,
      boiler_housing_clear: savedState.boiler_housing_clear,
      smoke_detectors_each_floor: savedState.smoke_detectors_each_floor,
      gas_check_up_to_date: savedState.gas_check_up_to_date,
      electric_check_up_to_date: savedState.electric_check_up_to_date,
      chimney_sweep_up_to_date: savedState.chimney_sweep_up_to_date,
      blind_cleats_up_to_date: savedState.blind_cleats_up_to_date,
      oil_check_up_to_date: savedState.oil_check_up_to_date,
      co_detector_present: savedState.co_detector_present,

      ambient_odour: savedState.ambient_odour,
      pets: savedState.pets,
      hoarding: savedState.hoarding,
      smoking: savedState.smoking,
      business_activity: savedState.business_activity,
      malicious_damage: savedState.malicious_damage,
      condensation_mould_damp: savedState.condensation_mould_damp,
      tenants_present_at_inspection: savedState.tenants_present_at_inspection,
      condition_property: savedState.condition_property,
      cleanliness_property: savedState.cleanliness_property,
      other_comments: savedState.other_comments,
      signature: savedState.signature,
      signatureMethod: savedState.signatureMethod,
      signature_image: savedState.signature_image,
    }

    console.log("validatedData", validatedData)

    this.props.saveCheck(validatedData, checkAutosaveName)
  }

  loadSave = () => {
    console.log("loadSave")
    this.setState({
      hasPromptedLoadSave: true,
      promptLoadSave: false,
    })
    let itemID = null
    if (this.props.item){
      itemID = this.props.item.id
    } else {
      itemID = history.location.hash.split('-').pop()
    }

    let result = this.props.checkAutosave
    if (result){
      console.log("result", result)
      let thisComp = this
      let getImages = async (result) => {
        let saveItem = JSON.parse(result)
        async function forageComments(comments){
          for (const commentItem of comments) {
            if (commentItem.image && commentItem.image.length > 0){
              for (const imageItem of commentItem.image) {
                if (imageItem.autosaveID){
                  imageItem.url = await localForage.getItem(`file ${imageItem.autosaveID}`)
                  if (typeof imageItem.url === 'string'){
                    let arrayBufferBlob = new Blob([imageItem.url], {type: "text/plain"})
                    imageItem.url = thisComp.dataURLtoFile(imageItem.url, 'savedFile.jpeg')
                  }
                }
              }
            }
          }
          return {result: comments}
        }

        let saveOverallComments = await forageComments(saveItem.overall_comments || [])

        saveItem.overall_comments = saveOverallComments.result

        let saveComments = await forageComments(saveItem.comments)
        console.log("saveComments", saveComments)
        saveItem.comments = saveComments.result

        for (const roomItem of saveItem.rooms) {
          let saveRoomComments = await forageComments(roomItem.comments)
          roomItem.comments = saveRoomComments.result
        }

        console.log("final saveItem", saveItem)
        return saveItem
      }
      getImages(result).then(async (saveItem) => {
        console.log("promise saveItem", saveItem)
        this.setState({
          id: saveItem.id,
          appointment_date: saveItem.appointment_date ? new Date(saveItem.appointment_date) : null,
          was_carried_over: saveItem.was_carried_over,
          address: saveItem.address,
          assigned_to: saveItem.assigned_to,
          searchValue: saveItem.searchValue,
          searchOptions: saveItem.searchOptions,
          searchSelected: saveItem.searchSelected,
          searchIsLoading: saveItem.searchIsLoading,
          searchIsVisible: saveItem.searchIsVisible,

          rooms: saveItem.rooms,
          comments: saveItem.comments,
          overall_comments: saveItem.overall_comments,

          newCommentID: saveItem.newCommentID,
          newRoomID: saveItem.newRoomID,
          removedComments: saveItem.removedComments,
          removedRooms: saveItem.removedRooms,

          property_security_locks: saveItem.property_security_locks,
          boiler_housing_clear: saveItem.boiler_housing_clear,
          smoke_detectors_each_floor: saveItem.smoke_detectors_each_floor,
          gas_check_up_to_date: saveItem.gas_check_up_to_date,
          electric_check_up_to_date: saveItem.electric_check_up_to_date,
          chimney_sweep_up_to_date: saveItem.chimney_sweep_up_to_date,
          blind_cleats_up_to_date: saveItem.blind_cleats_up_to_date,
          oil_check_up_to_date: saveItem.oil_check_up_to_date,
          co_detector_present: saveItem.co_detector_present,

          ambient_odour: saveItem.ambient_odour,
          pets: saveItem.pets,
          hoarding: saveItem.hoarding,
          smoking: saveItem.smoking,
          business_activity: saveItem.business_activity,
          malicious_damage: saveItem.malicious_damage,
          condensation_mould_damp: saveItem.condensation_mould_damp,
          tenants_present_at_inspection: saveItem.tenants_present_at_inspection,
          condition_property: saveItem.condition_property,
          cleanliness_property: saveItem.cleanliness_property,
          other_comments: saveItem.other_comments,
          signature: saveItem.signature,

          submit_check: false,
        })
      })
    }
    

  }


  handleAppointmentDateChange = (d) => {
    this.setState({
      appointment_date: d,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  onKeyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13){
    	console.log("submit", event.target)
    	event.target.blur()
    }
  }

  onFocus = () => {
  	this.setState({
  		searchIsVisible: true,
  	})
  }

  onBlur = () => {
  	this.setState({
  		searchIsVisible: false,
  	})
  }

  onChange = (query) => {
  	console.log("onChange", query)
  	this.setState({
  		address: query[0],
  		searchIsVisible: false,
  		searchSelected: query,
      hasChanges: true,
  	}, () => this.saveChanges())

  }

  onInputChange = (searchValue) => {
  	console.log("handleChange", searchValue)
    this.setState({
    	searchValue: searchValue,
    	searchIsLoading: true,
		searchIsVisible: true,
    });
    const params = {
		q: searchValue,
	}
	const endpoint = `${api_url}rest-auth/address-autocomplete/?${stringify(params)}` 
	let lookupOptions = {
		method: "GET",
		headers: {
			'Content-Type': 'application/json',
      'Authorization': 'Token '+window.localStorage.getItem('authKey'),
		}
	}
	let thisComp = this
	fetch(endpoint, lookupOptions)
	.then(function(response){
		return response.json()
	}).then(function(responseData){
		console.log("responseData", responseData)
		thisComp.setState({ searchIsLoading: false, })
		if (responseData){
			console.log("responseData.results", responseData.results)
			thisComp.setState({
				searchOptions: responseData.results,
			})
		}
	}).catch(function(error){
		console.log("error", error)
	})
    }

  removeComment = (commentItem) => {
    let { rooms, comments, overall_comments, removedComments } = this.state
    typeof commentItem.id === 'number' && removedComments.push(commentItem.id)

    let imagesDeleted = false
    comments.forEach((com, comIndex) => {
      if (com.id === commentItem.id){
        for (const imageItem of com.image) {
          if (imageItem.autosaveID) localForage.removeItem(`file ${imageItem.autosaveID}`)
        }
        imagesDeleted = true
      }
    })
    !imagesDeleted && overall_comments.forEach((com, comIndex) => {
      if (com.id === commentItem.id){
        for (const imageItem of com.image) {
          if (imageItem.autosaveID) localForage.removeItem(`file ${imageItem.autosaveID}`)
        }
        imagesDeleted = true
      }
    })
    !imagesDeleted && rooms.forEach((room, roomIndex) => {
      room.comments.forEach((com, comIndex) => {
        if (com.id === commentItem.id){
          for (const imageItem of com.image) {
            if (imageItem.autosaveID) localForage.removeItem(`file ${imageItem.autosaveID}`)
          }
        }
      })
    })

    comments = comments.filter(item => item.id !== commentItem.id)
    overall_comments = overall_comments.filter(item => item.id !== commentItem.id)
    rooms.forEach(room => {
      room.comments = room.comments.filter(item => item.id !== commentItem.id)
    })

    this.setState({ 
      rooms, 
      comments,
      overall_comments,
      removedComments,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  getCommentFromID = (comments, overall_comments, rooms, commentID) => {
    let commentItem = comments.find(item => item.id === commentID)
    if (!commentItem) commentItem = overall_comments.find(item => item.id === commentID)
    if (!commentItem) {
      rooms.forEach(roomItem => {
        let result = roomItem.comments.find(item => item.id === commentID)
        if (result){
          commentItem = result
        }
      })
    }
    return commentItem
  }

  clearFileInput = (ctrl) => {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  handleCommentImageAdd = (event, commentID) => {
    console.log("handleCommentImageAdd", event.target)
    let { comments, overall_comments, rooms } = this.state
    let commentItem = this.getCommentFromID(comments, overall_comments, rooms, commentID)
    let images = []
    let files = Array.from(event.target.files)
    this.clearFileInput(event.target)
    // event.target.value = null;
    if (commentItem){
      let thisComp = this
      files && files.length > 0 && Promise.all(
        files.map(file => 
          new Promise(async (resolve, reject) => {
            if (file){
              let fileName = file.name.replace(/\.[^/.]+$/, "")+".jpeg"
              const data = await getBase64Strings([file], { maxSize: 600 });
              const newFile = thisComp.dataURLtoFile(data[0], fileName)
              const autosaveID = thisComp.saveImage(newFile)
              images.push({url: newFile, autosaveID})
              resolve()
            } else {
              resolve()
            }
          })
        )
      ).then(() => {
        console.log("commentItem.image", commentItem.image)
        commentItem.image = commentItem.image.concat(images)
        console.log("resolved images", images)
        thisComp.setState({
          comments, 
          overall_comments,
          rooms,
          hasChanges: true,

        }, () => thisComp.saveChanges())
      })
    }
  }

  handleCommentImageChange = (event, commentID) => {
    console.log("handleCommentImageChange", event.target.files)
    let { comments, overall_comments, rooms } = this.state
    let commentItem = this.getCommentFromID(comments, overall_comments, rooms, commentID)
    let images = []
    let files = Array.from(event.target.files)
    this.clearFileInput(event.target)
    if (commentItem){
      let thisComp = this
      Promise.all(
        files.map(file => 
          new Promise(async (resolve, reject) => {
            let fileName = file.name.replace(/\.[^/.]+$/, "")+".jpeg"
            const data = await getBase64Strings([file], { maxSize: 600 });
            const newFile = thisComp.dataURLtoFile(data[0], fileName)
            const autosaveID = thisComp.saveImage(newFile)
            images.push({url: newFile, autosaveID})
            resolve()

          })
        )
      ).then(() => {
        commentItem.image = commentItem.image.concat(images)
        console.log("resolved images", images)
        thisComp.setState({
          comments, 
          overall_comments,
          rooms,
          hasChanges: true,
        }, () => thisComp.saveChanges())
      })
    }
  }

  handleCommentTextChange = (event, commentID) => {
    let { comments, overall_comments, rooms } = this.state
    let commentItem = this.getCommentFromID(comments, overall_comments, rooms, commentID)
    if (commentItem){
      commentItem.comment = event.target.value
      this.setState({ 
        comments, 
        overall_comments,
        rooms,
        hasChanges: true,
      }, () => this.saveChanges())
    }
  }

  handleCommentMaintenanceChange = (event, commentID) => {
    let { comments, overall_comments, rooms } = this.state
    let commentItem = this.getCommentFromID(comments, overall_comments, rooms, commentID)
    if (commentItem){
      commentItem.maintenance = event.target.value
      this.setState({
        comments,
        overall_comments,
        rooms,
        hasChanges: true,
      }, () => this.saveChanges())
    }
  }

  handleRoomNameChange = (event, RoomID) => {
    let { rooms } = this.state
    let roomItem = rooms.find(item => item.id === RoomID)
    if (roomItem){
      roomItem.name = event.target.value
      this.setState({
        rooms,
        hasChanges: true,
      }, () => this.saveChanges())
    }
  }

  handleRoomConditionChange = (event, RoomID) => {
    let { rooms } = this.state
    let roomItem = rooms.find(item => item.id === RoomID)
    if (roomItem){
      roomItem.condition = event.target.value
      this.setState({
        rooms,
        hasChanges: true,
      }, () => this.saveChanges())
    }
  }

  onImageError = e => {
    console.log("onImageError", e.target)
    var element = document.createElement("div");
    element.innerHTML = "<b>Image unavailable.</b><p class=\"small\">This image cannot be edited offline or the link has expired.</p>";
    element.setAttribute("role", "alert");
    element.setAttribute("class", "fade alert alert-info show");
    e.target.parentNode.parentNode.parentNode.replaceChild(element, e.target.parentNode.parentNode)
  }

  commentImage = (url, alt, rotateMethod, removeMethod) => 
    <div className="position-relative mb-3">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={url} className="img-fluid" alt={alt} style={{ minWidth: '100%' }} onError={this.onImageError} />
      </a>
      <FontAwesomeIcon icon={faTrash} className="position-absolute fixed-top remove-image-button" onClick={removeMethod} />
      <FontAwesomeIcon icon={faUndo} className="position-absolute fixed-bottom rotate-button" onClick={rotateMethod} />
    </div>

  findImage = (commentItem, imageIndex) => {
    let { rooms, comments, overall_comments } = this.state
    let image = null
    comments.forEach(com => {
      if (com.id === commentItem.id){
        image = com.image[imageIndex]
      }
    })
    !image && overall_comments.forEach(com => {
      if (com.id === commentItem.id){
        image = com.image[imageIndex]
      }
    })
    !image && rooms.forEach(room => {
      room.comments.forEach(com => {
        if (com.id === commentItem.id){
          image = com.image[imageIndex]
        }
      })
    })
    return image
  }

  setImageURL = (commentItem, imageIndex, image) => {
    let { rooms, comments, overall_comments } = this.state
    let isSet = false
    comments.forEach((com, comIndex) => {
      if (com.id === commentItem.id){
        let foundImage = comments[comIndex].image[imageIndex]
        if (foundImage.autosaveID) localForage.setItem(`file ${foundImage.autosaveID}`, image)
        comments[comIndex].image[imageIndex].url = image
        isSet = true
      }
    })
    !isSet && overall_comments.forEach((com, comIndex) => {
      if (com.id === commentItem.id){
        let foundImage = overall_comments[comIndex].image[imageIndex]
        if (foundImage.autosaveID) localForage.setItem(`file ${foundImage.autosaveID}`, image)
        overall_comments[comIndex].image[imageIndex].url = image
        isSet = true
      }
    })
    !isSet && rooms.forEach((room, roomIndex) => {
      room.comments.forEach((com, comIndex) => {
        if (com.id === commentItem.id){
          let foundImage = rooms[roomIndex].comments[comIndex].image[imageIndex]
          if (foundImage.autosaveID) localForage.setItem(`file ${foundImage.autosaveID}`, image)
          rooms[roomIndex].comments[comIndex].image[imageIndex].url = image
        }
      })
    })
    this.setState({ comments, overall_comments, rooms, hasChanges: true }, () => this.saveChanges())
  }

  deleteImage = (commentItem, imageIndex) => {
    let { rooms, comments, overall_comments } = this.state
    let isDeleted = false
    comments.forEach((com, comIndex) => {
      if (com.id === commentItem.id){
        let foundImage = com.image.find((image, index) => index === imageIndex)
        if (foundImage.autosaveID) localForage.removeItem(`file ${foundImage.autosaveID}`)
        com.image = com.image.filter((image, index) => index !== imageIndex)
        isDeleted = true
      }
    })
    !isDeleted && overall_comments.forEach((com, comIndex) => {
      if (com.id === commentItem.id){
        let foundImage = com.image.find((image, index) => index === imageIndex)
        if (foundImage.autosaveID) localForage.removeItem(`file ${foundImage.autosaveID}`)
        com.image = com.image.filter((image, index) => index !== imageIndex)
        isDeleted = true
      }
    })
    !isDeleted && rooms.forEach((room, roomIndex) => {
      room.comments.forEach((com, comIndex) => {
        if (com.id === commentItem.id){
          let foundImage = com.image.find((image, index) => index === imageIndex)
          if (foundImage.autosaveID) localForage.removeItem(`file ${foundImage.autosaveID}`)
          com.image = com.image.filter((image, index) => index !== imageIndex)
        }
      })
    })
    this.setState({ comments, overall_comments, rooms, hasChanges: true }, () => this.saveChanges())
  }

  rotateNewImage = (commentItem, imageIndex) => {
    console.log("commentItem, imageIndex", commentItem, imageIndex)
    let image = this.findImage(commentItem, imageIndex)
    
    console.log("image", image)
    let imageName = ""
    let imageURL = image.url
    if (typeof image.url === 'string'){
      imageURL = imgPrefix+image.url
      imageName = image.url.split('/').pop().split('.')[0]
    } else {
      imageName = image.url.name
    }
    console.log("imageURL, imageName", imageURL, imageName)
    window.loadImage(
      imageURL,
      (img) => {
        console.log("img", img, typeof img)
        canvasToBlob(img, 'image/jpeg').then(blob => {
          blob.name = imageName
          console.log("blob", blob)
          this.setImageURL(commentItem, imageIndex, blob)
        })
      },
      { orientation: 6, canvas: true }
    );
  }

  imagesNotNull = (images) => {
    let result = false
    images.forEach((image) => {
      if (image.url) result = true
    })
    return result
  }

  makeCommentField = (commentItem, index, roomIndex) => {
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const fileFieldName = roomIndex || roomIndex === 0 ? `room_${roomIndex}_comment_${index}_image` : `comment_${index}_image`
    return (
      <div id={`comment-${commentItem && commentItem.id}`} key={commentItem && commentItem.id}>
        {(roomIndex || roomIndex === 0 || index !== 0) && <hr/>}
        <Button variant="danger" size="sm" className="float-left position-absolute d-none d-sm-block" onClick={() => this.removeComment(commentItem)}>Remove comment</Button>
        <h6 className="offset-sm-4 text-right d-block d-sm-none">Comment #{index+1}<Button variant="danger" size="sm" className="ml-3" onClick={() => this.removeComment(commentItem)}>Remove comment</Button></h6>
        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Image
          </Form.Label>
          <Col sm={5}>
            {commentItem && commentItem.image && commentItem.image.length > 0 && 
              commentItem.image.map((imageItem, imageIndex) => 
                imageItem.url && typeof imageItem.url !== 'number' && (typeof imageItem.url === 'string' ? 
                  this.commentImage(
                    imageItem.url.replace && imgPrefix+imageItem.url.replace('http:', 'https:'), 
                    commentItem.comment, 
                    () => this.rotateNewImage(commentItem, imageIndex),
                    () => this.setImageURL(commentItem, imageIndex, null))
                :
                  this.commentImage(
                    URL.createObjectURL(imageItem.url),
                    imageItem.url.name, 
                    () => this.rotateNewImage(commentItem, imageIndex),
                    () => this.deleteImage(commentItem, imageIndex))
                )
              ) 
            }
            {commentItem && (!commentItem.image || commentItem.image.length === 0 || !this.imagesNotNull(commentItem.image)) ? 
              <>
                <label htmlFor={fileFieldName} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`#comment-${commentItem && commentItem.id} [name=${fileFieldName}]`).click()}>
                    Select
                </label>
                <input name={fileFieldName} type="file" className="mb-3 d-none" onChange={(e) => this.handleCommentImageChange(e, commentItem.id)} multiple/>
              </>
              :
              commentItem && 
              <>
                <label htmlFor={fileFieldName} className="btn btn-info custom-file-upload mb-0 d-block" onClick={()=>document.querySelector(`#comment-${commentItem && commentItem.id} [name=${fileFieldName}]`).click()}>
                    Add more
                </label>
                <input name={fileFieldName} type="file" className="mb-3 d-none" onChange={(e) => this.handleCommentImageAdd(e, commentItem.id)} multiple/>
              </>
            }

          </Col>
        </Form.Group>
        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Comment
          </Form.Label>
          <Col sm={sm2}>
            <Form.Control 
              type="textarea" 
              as="textarea"
              name="comment"
              value={commentItem.comment && commentItem.comment}
              rows={2}
              onChange={(e) => this.handleCommentTextChange(e, commentItem.id)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Maintenance
          </Form.Label>
          <Col sm={sm2}>
            <Form.Control 
              type="textarea" 
              as="textarea"
              name="maintenance"
              value={commentItem.maintenance && commentItem.maintenance}
              rows={2}
              onChange={(e) => this.handleCommentMaintenanceChange(e, commentItem.id)}
            />
          </Col>
        </Form.Group>
      </div>
    )
  }

  addExternalPropertyComment = () => {
    let { comments, newCommentID } = this.state
    comments.push({id: `new_comment_${newCommentID}`, comment: "", maintenance: "", image: []})
    this.setState({ comments, newCommentID: ++newCommentID })
  }

  addOverallComment = () => {
    let { overall_comments, newCommentID } = this.state
    overall_comments.push({id: `new_comment_${newCommentID}`, comment: "", maintenance: "", image: []})
    this.setState({ overall_comments, newCommentID: ++newCommentID })
  }

  addExternalPropertyPhoto = () => {
    const { comments } = this.state
    const index = comments.length
    this.addExternalPropertyComment()
    const fileFieldName = `comment_${index}_image`
    setTimeout(() => {
      console.log("addExternalPropertyPhoto", document.querySelector(`[name=${fileFieldName}]`))
      document.querySelector(`.external-comments [name=${fileFieldName}]`).click()
    }, 100)
  }

  addOverallPhoto = () => {
    const { overall_comments } = this.state
    const index = overall_comments.length
    this.addOverallComment()
    const fileFieldName = `comment_${index}_image`
    setTimeout(() => {
      document.querySelector(`.overall-comments [name=${fileFieldName}]`).click()
    }, 100)
  }

  removeRoom = (roomItem) => {
    let { rooms, removedRooms } = this.state
    if (typeof roomItem.id === 'number') removedRooms.push(roomItem.id)

    let foundRoom = rooms.find(item => item.id === roomItem.id)
    for (const commentItem of foundRoom.comments) {
      for (const imageItem of commentItem.image) {
        if (imageItem.autosaveID){
          localForage.removeItem(`file ${imageItem.autosaveID}`)
        }
      }
    }

    rooms = rooms.filter(item => item.id !== roomItem.id)
    rooms.sort((a, b) => a.order - b.order).forEach((room, roomIndex) => {
      room.order = roomIndex
    })
    this.setState({
      rooms,
      removedRooms,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  moveRoomDown = (roomItem) => {
    let { rooms } = this.state
    const proposedOrder = roomItem.order + 1
    const next = rooms.find(item => item.order === proposedOrder)
    if (next){
      roomItem.order = proposedOrder
      next.order = proposedOrder - 1
    }
    this.setState({
      rooms,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  moveRoomUp = (roomItem) => {
    let { rooms } = this.state
    const proposedOrder = roomItem.order - 1
    const previous = rooms.find(item => item.order === proposedOrder)
    if (previous){
      roomItem.order = proposedOrder
      previous.order = proposedOrder + 1
    }
    this.setState({
      rooms,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  makeRoomField = (roomItem, roomIndex) => {
    const sm1 = 4
    const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end text-right"
    const upDownInner = <>
      <div><FontAwesomeIcon icon={faCaretUp} onClick={() => this.moveRoomUp(roomItem)} /></div>
      <div><FontAwesomeIcon icon={faCaretDown} onClick={() => this.moveRoomDown(roomItem)} /></div>
    </>
    let carriedOverDetails = this.state.was_carried_over && roomItem.history ? roomItem.history.slice(-1)[0] : {}
    return (
      <div 
        key={roomItem && roomItem.id} 
        className={
          roomIndex !== 0 ? 'room' : 'room-0'
        }
      >
        <Button variant="danger" size="sm" className="float-left position-absolute d-none d-sm-block remove-room-button" onClick={() => this.removeRoom(roomItem)}>Remove room</Button>
        <h6 className="offset-sm-4 text-right d-block d-sm-none">Room #{roomIndex+1} <Button variant="danger" size="sm" className="ml-3" onClick={() => this.removeRoom(roomItem)}>Remove room</Button></h6>
        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Room name
          </Form.Label>
          <Col sm={sm2}>
            <Form.Control 
              className="room-name-field"
              type="text" 
              name="name"
              value={roomItem.name && roomItem.name}
              onChange={(e) => this.handleRoomNameChange(e, roomItem.id)}
            />
          </Col>
        </Form.Group>
        <div 
          className="up-down-button d-none d-sm-block" 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >{upDownInner}</div>
        <div 
          className="up-down-button d-block d-sm-none" 
          style={{
            position: 'absolute',
            top: '0px',
            left: '5px',
            top: roomIndex === 0 ? '0px' : '20px',
            right: roomIndex === 0 ? '5px' : '5px',
          }}
        >{upDownInner}</div>
        <Form.Group as={Row} className="validate-me">
          <Form.Label column sm={sm1} className={classes1}>
            Condition
          </Form.Label>
          <Col sm={sm2}>
            <FormButtonGroup 
              choices={GOOD_FAIR_POOR_CHOICES} 
              name='condition' 
              value={roomItem.condition && roomItem.condition} 
              onChange={(e) => this.handleRoomConditionChange(e, roomItem.id)}
              carriedOverValue={carriedOverDetails.condition}
            />
          </Col>
        </Form.Group>
        {roomItem.comments && roomItem.comments.length > 0 && roomItem.comments.map((commentItem, index) => this.makeCommentField(commentItem, index, roomIndex))}
        <div className="text-right">
          <ButtonGroup aria-label="Basic example" className="mt-2">
            <Button variant="primary" onClick={() => this.addRoomComment(roomItem)}>Add comment</Button>
            <Button variant="secondary" onClick={() => this.addRoomPhoto(roomItem, roomIndex)}>Add photo</Button>
          </ButtonGroup>
        </div>
      </div>
    )
  }

  addRoom = (roomName) => {
    console.log("addRoom", roomName)
    let { rooms, newRoomID } = this.state
    const { settings } = this.props
    let beginSearching = false
    let closestRoom = rooms.length
    
    if (roomName !== ''){
      for (const room of settings.room_names){
        if (room === roomName) {
          beginSearching = true
        } else if (beginSearching) {
          let result = rooms.find(roomItem => roomItem.name === room)
          if (result) {
            closestRoom = result.order
            break
          }
        }
      }
    }

    if (closestRoom <= rooms.length){
      rooms.forEach(roomItem => {
        if (roomItem.order >= closestRoom){
          roomItem.order = roomItem.order + 1
        }
      })
    }
    
    rooms.push({id: `new_room_${newRoomID}`, name: roomName, order: closestRoom, comments: []})
    this.setState({ 
      rooms, 
      newRoomID: ++newRoomID,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  addRoomPhoto = (roomItem, roomIndex) => {
    const index = roomItem.comments.length
    this.addRoomComment(roomItem)
    const fileFieldName = `room_${roomIndex}_comment_${index}_image`
    setTimeout(() => {
      console.log("addRoomPhoto", document.querySelector(`[name=${fileFieldName}]`))
      document.querySelector(`[name=${fileFieldName}]`).click()
    }, 100)
  }

  addRoomComment = (roomItem) => {
    let { newCommentID } = this.state
    roomItem.comments.push({id: `new_comment_${newCommentID}`, comment: "", maintenance: "", image: []})
    this.setState({ 
      newCommentID: ++newCommentID,
      hasChanges: true,
    }, () => this.saveChanges())
  }

  handleCheck = (event) => {
    console.log("submit_check", this.state.submit_check)
    let eventTargetName = event.target.name
    this.setState({ 
      [event.target.name]: !this.state.submit_check,
      hasChanges: true,
    }, () => {
      this.saveChanges();
      if (eventTargetName === 'submit_check') this.initialiseSignaturePad();
    });
    
  }

  confirmClose = (e, callback) => {
    if (!callback) callback = this.props.handleClose
    this.setState({confirmCallback: callback})
    const { displayConfirmation, hasChanges } = this.state
    this.setState({ 
      displayConfirmation: false,
      hasChanges: false,
      hasPromptedLoadSave: false,
      promptLoadSave: false,
     }, () => callback())
    this.props.handleClose()
  }

  render(){
    const { promptLoadSave, historyCheck } = this.state
    if (this.props.show && this.props.isMobile()) return this.getInner()
    else return (
        <Modal size="xl" show={this.props.show} onHide={this.confirmClose} onShow={this.onShow}>
          {this.getInner()}
        </Modal>
    )
  }

  getInner = () => {
  	console.log("this.props", this.props)
    const { validated } = this.state;
    const { assigned_to, appointment_date } = this.state
  	const { searchOptions, searchIsLoading, searchIsVisible, searchSelected, addressIsInvalid } = this.state
    const {
      property_security_locks,
      boiler_housing_clear,
      smoke_detectors_each_floor,
      gas_check_up_to_date,
      electric_check_up_to_date,
      chimney_sweep_up_to_date,
      blind_cleats_up_to_date,
      oil_check_up_to_date,
      co_detector_present,
      ambient_odour,
      pets,
      hoarding,
      smoking,
      business_activity,
      malicious_damage,
      condensation_mould_damp,
      tenants_present_at_inspection,
      condition_property,
      cleanliness_property,
      other_comments,
      signature,
      signatureMethod,
      rooms,
      comments,
      overall_comments,
      submit_check,
      historyCheck,
      was_carried_over,
      hasPromptedLoadSave,
    } = this.state
  	const sm1 = 4
  	const sm2 = 7
  	const classes1 = "d-flex justify-content-sm-end text-right"

    let item = null
    if (historyCheck) item = historyCheck
    else if (this.props.item) item = this.props.item

    let carriedOverDetails = was_carried_over && historyCheck && historyCheck.history ? historyCheck.history.slice(-1)[0] : {}
    // console.log("was_carried_over, historyCheck", was_carried_over, historyCheck)
    // console.log("carriedOverDetails", carriedOverDetails)

    const autosaveName = this.getAutosaveName()
    let autosaveNumber = ''
    if (autosaveName && autosaveName.split('_').length === 4) autosaveNumber = parseInt(autosaveName.split('_')[3])-1

    // if (!item && !hasPromptedLoadSave) return null

    return (
        <>
          <Modal.Header>
            <Modal.Title>{item ? `Editing Routine Check #${item.id}` : `Create New Routine Check`}</Modal.Title>
            <CloseModalButton onClick={this.confirmClose}/>
          </Modal.Header>
          <Form
            noValidate
            validated={validated}
            onSubmit={e => this.handleSubmit(e)}
            autoComplete='off'
          >
          <Modal.Body className="pt-0">

            <div className="modal-subheading mt-0">
              <h5 className="offset-sm-4">Details</h5>
            </div>

          	<Form.Group as={Row} className="">
	            <Form.Label column sm={sm1} className={classes1}>
	              Address *
	            </Form.Label>
	            <Col sm={sm2}>
                <AsyncTypeahead
                  id={'check-address-search-typeahead'}
                  inputProps={{id: 'check-address-search', autoComplete: 'off'}}
                  options={searchOptions}
                  selected={searchSelected}
                  isInvalid={addressIsInvalid}
                  isLoading={searchIsLoading}
                  maxVisible={10}
                  placeholder="Search"
                  onSearch={this.onInputChange}
                  labelKey={(option) => `${option.address_full}`}
                  filterBy={(option, props) => {
                    return option.address_full
                  }}
                  minLength={0}
                  useCache={false}
                  onKeyDown={this.onKeyDown}
                  selectHintOnEnter={true}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  open={searchIsVisible}
                  onChange={this.onChange}
                  clearButton={true}
              />
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Appointment date
	            </Form.Label>
	            <Col sm={sm2}>
            		<div className="d-inline-block" style={{ width: '100%' }}>
  		            <DatePicker
  		              className="form-control "
  		              dateFormat="dd-MM-yyyy"
  		              isClearable={true}
  		              selected={appointment_date}
  		              onChange={this.handleAppointmentDateChange}
                    onFocus={(e) => e.target.readOnly = true}
                    onBlur={(e) => e.target.readOnly = false}
  		            />
		            </div>
	            </Col>
          	</Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Tenant(s) present at inspection?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='tenants_present_at_inspection' 
                  value={tenants_present_at_inspection} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.tenants_present_at_inspection}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Overall condition of property?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={GOOD_FAIR_POOR_CHOICES} 
                  name='condition_property' 
                  value={condition_property} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.condition_property}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Overall cleanliness of property?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={GOOD_FAIR_POOR_CHOICES} 
                  name='cleanliness_property' 
                  value={cleanliness_property} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.cleanliness_property}
                />
              </Col>
            </Form.Group>

            <div className="overall-comments">
              {overall_comments && overall_comments.length > 0 && overall_comments.map((commentItem, index) => this.makeCommentField(commentItem, index))}
            </div>

            <div className="text-right">
              <ButtonGroup aria-label="Basic example" className="mt-2">
                <Button variant="primary" onClick={this.addOverallComment}>Add comment</Button>
                <Button variant="secondary" onClick={this.addOverallPhoto}>Add photo</Button>
              </ButtonGroup>
            </div>

            <div className="modal-subheading">
              <h5 className="d-inline-block offset-sm-4">Property - External</h5>
            </div>

            <div className="external-comments">
              {comments && comments.length > 0 && comments.map((commentItem, index) => this.makeCommentField(commentItem, index))}
            </div>

            <div className="text-right">
              <ButtonGroup aria-label="Basic example" className="mt-2">
                <Button variant="primary" onClick={this.addExternalPropertyComment}>Add comment</Button>
                <Button variant="secondary" onClick={this.addExternalPropertyPhoto}>Add photo</Button>
              </ButtonGroup>
            </div>

            <div className="modal-subheading">
              <h5 className="d-inline-block offset-sm-4">Property - Internal</h5>
            </div>

            {rooms && rooms.length > 0 && rooms.sort((a, b) => a.order - b.order).map((roomItem, index) => this.makeRoomField(roomItem, index))}

            <div className="text-right">
              <SplitButton title="Add room" variant="secondary" className="mt-2" alignRight onClick={() => this.addRoom('')}>
                {this.props.settings && this.props.settings.room_names && 
                  this.props.settings.room_names.map(item => {
                    if (rooms && rooms.find(roomItem => roomItem.name === item)){
                      return <Dropdown.Item onClick={() => this.addRoom(`${item}`)} active>{item}</Dropdown.Item>
                    } else {
                      return <Dropdown.Item onClick={() => this.addRoom(`${item}`)}>{item}</Dropdown.Item>
                    }
                  })
                }
              </SplitButton>
            </div>

            <div className="modal-subheading">
              <h5 className="offset-sm-4">Health & Safety Checks</h5>
            </div>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Property secured and locks checked?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='property_security_locks' 
                  value={property_security_locks} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.property_security_locks}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Boiler housing clear?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='boiler_housing_clear' 
                  value={boiler_housing_clear} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.boiler_housing_clear}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Smoke detectors on each floor?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='smoke_detectors_each_floor' 
                  value={smoke_detectors_each_floor} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.smoke_detectors_each_floor}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Gas check up to date?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='gas_check_up_to_date' 
                  value={gas_check_up_to_date} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.gas_check_up_to_date}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Electric check up to date?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='electric_check_up_to_date' 
                  value={electric_check_up_to_date} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.electric_check_up_to_date}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Chimney sweep up to date?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='chimney_sweep_up_to_date' 
                  value={chimney_sweep_up_to_date} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.chimney_sweep_up_to_date}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Blind cleats installed?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='blind_cleats_up_to_date' 
                  value={blind_cleats_up_to_date} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.blind_cleats_up_to_date}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Oil check up to date?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='oil_check_up_to_date' 
                  value={oil_check_up_to_date} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.oil_check_up_to_date}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                CO detector present?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='co_detector_present' 
                  value={co_detector_present} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.co_detector_present}
                />
              </Col>
            </Form.Group>

            <div className="modal-subheading">
              <h5 className="offset-sm-4">General Observations</h5>
            </div>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Ambient odour?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='ambient_odour' 
                  value={ambient_odour} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.ambient_odour}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Pets?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='pets' 
                  value={pets} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.pets}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Hoarding?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='hoarding' 
                  value={hoarding} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.hoarding}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Smoking?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='smoking' 
                  value={smoking} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.smoking}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Business activity?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='business_activity' 
                  value={business_activity} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.business_activity}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Malicious damage?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='malicious_damage' 
                  value={malicious_damage} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.malicious_damage}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Condensation/mould/damp present?
              </Form.Label>
              <Col sm={sm2}>
                <FormButtonGroup 
                  choices={YES_NO_CHOICES} 
                  name='condensation_mould_damp' 
                  value={condensation_mould_damp} 
                  onChange={this.handleOnChange} 
                  carriedOverValue={carriedOverDetails.condensation_mould_damp}
                />
              </Col>
            </Form.Group>

            <div className="modal-subheading">
              <h5 className="d-inline-block offset-sm-4">Other Comments</h5>
            </div>

            <Form.Group as={Row} className="validate-me">
              <Col sm={{span: sm2, offset: sm1}}>
                <Form.Control 
                  type="textarea" 
                  as="textarea"
                  name="other_comments"
                  value={other_comments}
                  rows={2}
                  onChange={this.handleOnChange} 
                />
              </Col>
            </Form.Group>

            <div className="modal-subheading">
              <h5 className="d-inline-block offset-sm-4">Signature</h5>
            </div>

            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Assigned to
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  className=""
                  as="select"
                  name="assigned_to"
                  value={assigned_to}
                  onChange={this.handleOnChange}
                >
                  <option value="">None</option>
                  {this.props.userList && this.props.userList.map(userItem => <option key={userItem.id} value={userItem.id}>{`${userItem.first_name} ${userItem.last_name}`}</option>)}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="">
              <Form.Label column sm={sm1} className={classes1}></Form.Label>
              <Col sm={sm2}>
                <FormCheck>
                  <FormCheck.Input 
                    onChange={this.handleCheck} 
                    className="novalidate"
                    type="checkbox"
                    id="submit_check"
                    name="submit_check"
                    defaultChecked={false}
                  />
                  <FormCheck.Label className="novalidate">Submit Routine Check?</FormCheck.Label>
                </FormCheck>
              </Col>
            </Form.Group>

            {submit_check && 
              <Form.Group as={Row} className="validate-me">
                <Form.Label column sm={sm1} className={classes1}>
                  Signature *
                </Form.Label>
                <Col sm={4}>
                  <Tabs variant="pills" className="mb-2" activeKey={signatureMethod} onSelect={k => this.setState({ signatureMethod: k})}>
                    <Tab eventKey="draw" title="Draw">
                      <canvas 
                        id="signature-pad" 
                        className={
                          (validated && signaturePad &&
                            !signaturePad.isEmpty() ? "is-valid" : "is-invalid")+" novalidate signature-pad"
                        }
                      />
                      <Button variant="secondary" onClick={() => signaturePad && signaturePad.clear()}>Clear</Button>
                    </Tab>
                    <Tab eventKey="type" title="Type name">
                      <Form.Control 
                        type="text" 
                        name="signature"
                        value={signature}
                        onChange={this.handleOnChange}
                        className={
                          (validated &&  
                            (signature.length > 0 ? "is-valid" : "is-invalid"))+" novalidate"
                        }
                      />
                    </Tab>
                  </Tabs>
                </Col>
              </Form.Group>
            }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose} className="float-left ml-0">
              Close
            </Button>
            {!this.props.forceAutosave && <Button variant="sab" onClick={() => this.props.handleShowCheckModal('handleShowViewCheckModal', item, true)} className="float-left mr-auto ml-0">
              View
            </Button>}
            {item && <input type="hidden" name="id" value={item.id} />}
            <div className="mr-2">Changes saved to autosave #{autosaveNumber}</div>
            <Button disabled={!this.props.isOnline} type="submit">Save</Button>
          </Modal.Footer>
        </Form>
        </>
    )

  }
}

const mapStateToProps = state => ({
  userList: state.user.userList,
  settings: state.settings.settings,
  offlineFileCount: state.offline.offlineFileCount,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { editCheck, saveCheck, incrementOfflineFileCount })(EditCheckModal);
