import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form, FormCheck, Table, Spinner } from 'react-bootstrap'
import { createCheck } from '../redux/actions/checkActions'
import { connect } from 'react-redux'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import { api_url } from '../api_url'
import { stringify } from 'query-string';
import DatePicker from "react-datepicker";
import history from '../history';
import CloseModalButton from '../components/CloseModalButton'
import ConfirmModal from '../modals/ConfirmModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

class CreateCheckModal extends Component {

  constructor(props){
    super(props)
    this.state = { 
      validated: false,
      address: null,
      assigned_to: "",
      appointment_date: null,
      duplicate_previous_check: true,
      addressIsInvalid: false,
      searchValue: "",
      searchOptions: [],
      searchSelected: [],
      searchIsLoading: false,
      searchIsVisible: false,

      hasChanges: false,
      displayConfirmation: false,

      previousChecksLoading: false,
      previousChecks: [],
      selectedPreviousCheck: null,

    };
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  fetchAddress = (itemID) => {
    console.log("CreateCheckModal fetchAddress", itemID)
    const endpoint = `${api_url}rest-auth/addresses/${itemID}/` 
    let thisComp = this
    let lookupOptions = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token '+window.localStorage.getItem('authKey'),
      }
    }
    fetch(endpoint, lookupOptions)
    .then(function(response){
      return response.json()
    }).then(function(responseData){
      if (responseData){
        console.log("responseData", responseData)

        const address = [responseData.tenant_address_line_1, 
          responseData.tenant_address_line_2, 
          responseData.tenant_town_city, 
          responseData.tenant_county, 
          responseData.tenant_postcode
          ].filter(function (el) {
            return el !== null && el !== "";
          });

          if (responseData && responseData.property_manager){
            thisComp.setState({
              assigned_to: responseData.property_manager,
            })
          }
          thisComp.setState({
            address: {id: responseData.id, address_full: address},
            searchValue: address,
            searchOptions: [{id: responseData.id, address_full: address}],
            searchSelected: [{id: responseData.id, address_full: address}],
          })
      }
    }).catch(function(error){
      console.log("error", error)
    })
  }

  onShow = () => {
  	console.log("CreateCheckModal, onShow", this.props)
    const { hasChanges } = this.state
    if (!hasChanges){
      this.setState({
        validated: false,
        displayConfirmation: false,
        hasChanges: false,
      })
      if (history.location.hash.split('-').length > 1) {
        this.fetchAddress(history.location.hash.split('-').pop())
      } else {
        this.setState({
          validated: false,
          address: null,
          assigned_to: "",
          appointment_date: null,
          duplicate_previous_check: true,
          addressIsInvalid: false,
          searchValue: "",
          searchOptions: [],
          searchSelected: [],
          searchIsLoading: false,
          searchIsVisible: false,
          previousChecks: [],
          previousChecksLoading: false,
          selectedPreviousCheck: null,
        })
      }
    }
  }

  handleSubmit(event) {
  	// const form = event.currentTarget;
  	event.preventDefault();
  	const { address, assigned_to, appointment_date, duplicate_previous_check, selectedPreviousCheck } = this.state
  	// if (!address || !address.id) {
  	// 	event.stopPropagation();
  	// 	this.setState({ 
  	// 		validated: true,
  	// 		addressIsInvalid: true,
  	// 	})
  	// } else {
		this.setState({ 
			validated: true,
			addressIsInvalid: false,
		})
    let result = {
      address: address && address.id ? address.id : null,
    }
    const is_duplicate_previous_check = address && address.id ? duplicate_previous_check : false
    if (assigned_to) result.assigned_to = assigned_to
    if (appointment_date) result.appointment_date = ("0" + appointment_date.getDate()).slice(-2) + "-" + ("0"+(appointment_date.getMonth()+1)).slice(-2) + "-" + appointment_date.getFullYear()
    if (selectedPreviousCheck) result.selectedPreviousCheck = selectedPreviousCheck;
		this.props.createCheck(result, is_duplicate_previous_check)

		console.log("result", result)

    this.setState({ hasChanges: false })
    this.props.handleClose()
  	// }


	// const formData = new FormData();
	// Object.keys(this.state).forEach(key => {
	// 	console.log(key, this.state[key])
	// 	if (this.state[key]){
	// 		formData.append(key, this.state[key] );
	// 	}
	// })
	// console.log("formData", formData)

 //    // const {email, password } = this.state
 //    // console.log("email, password", email, password)
 //    if (this.props.item){
 //    	this.props.editAddress(formData, Object.assign({}, this.state))
 //    } else {
 //    	this.props.createAddress(formData, Object.assign({}, this.state))
 //    }
 //    this.props.handleClose()
  }

  handleOnChange = (event) => {
  	this.setState({ 
      [event.target.name]: event.target.value,
      hasChanges: true,
    });
  }
  handleCheck = (event) => {
  	console.log("duplicate_previous_check", this.state.duplicate_previous_check)
     this.setState({ 
      [event.target.name]: !this.state.duplicate_previous_check,
      hasChanges: true,
    });
  }

    handleAppointmentDateChange = (d) => {
      console.log("date", d)
      // let formatted = null
      // if (d) formatted = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear()
      this.setState({
        appointment_date: d,
        hasChanges: true,
      })
    }

    onKeyDown = (event) => {
      if (event.which === 13 || event.keyCode === 13){
      	console.log("submit", event.target)
      	event.target.blur()
      }
    }

    onFocus = () => {
    	this.setState({
    		searchIsVisible: true,
    	})
    }

    onBlur = () => {
    	this.setState({
    		searchIsVisible: false,
    	})
    }



    getPreviousChecks = (adddressID) => {
      console.log("CreateCheckModal fetchAddress", adddressID)
      this.setState({
        previousChecksLoading: true
      });
      const endpoint = `${api_url}rest-auth/addresses/${adddressID}/get_previous_checks` 
      let thisComp = this
      let lookupOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+window.localStorage.getItem('authKey'),
        }
      }
      fetch(endpoint, lookupOptions)
      .then(function(response){
        return response.json()
      }).then(function(responseData){
        if (responseData){
          console.log("getPreviousChecks responseData", responseData)

          // const address = [responseData.tenant_address_line_1, 
          //   responseData.tenant_address_line_2, 
          //   responseData.tenant_town_city, 
          //   responseData.tenant_county, 
          //   responseData.tenant_postcode
          //   ].filter(function (el) {
          //     return el !== null && el !== "";
          //   });

          //   if (responseData && responseData.property_manager){
          //     thisComp.setState({
          //       assigned_to: responseData.property_manager,
          //     })
          //   }
            thisComp.setState({
              previousChecks: responseData,
              previousChecksLoading: false,
              selectedPreviousCheck: responseData.length > 0 ? responseData[0].id : null,
            });
        }
      }).catch(function(error){
        console.log("error", error)
        thisComp.setState({
          previousChecksLoading: false
        });
      })
    }

    onChange = (query) => {
    	console.log("onChange", query)
      if (query[0] && query[0].property_manager){
        this.setState({
          assigned_to: query[0].property_manager,
        })
      }
      if (query[0] && query[0].id){
        this.getPreviousChecks(query[0].id);
      }
    	this.setState({
        hasChanges: true,
    		address: query[0],
    		searchIsVisible: false,
    		searchSelected: query,
    	}, () => {
    		// this.resetCurrentPage()
    	})

    }

  onInputChange = (searchValue) => {
  	console.log("handleChange", searchValue)
    this.setState({
    	searchValue: searchValue,
    	searchIsLoading: true,
		searchIsVisible: true,
    });
    const params = {
		q: searchValue,
	}
	const endpoint = `${api_url}rest-auth/address-autocomplete/?${stringify(params)}` 
	let lookupOptions = {
		method: "GET",
		headers: {
			'Content-Type': 'application/json',
      'Authorization': 'Token '+window.localStorage.getItem('authKey'),
		}
	}
	let thisComp = this
	fetch(endpoint, lookupOptions)
	.then(function(response){
		return response.json()
	}).then(function(responseData){
		console.log("responseData", responseData)
		thisComp.setState({ searchIsLoading: false, })
		if (responseData){
			console.log("responseData.results", responseData.results)
			// responseData.results.unshift(searchValue)
			thisComp.setState({
				searchOptions: responseData.results,
			})
		}
	}).catch(function(error){
		console.log("error", error)
	})
    //   this.updateAutocompleteResults(event.target.value)
    }

  handleClose = () => {
    const { displayConfirmation, hasChanges } = this.state
    if (displayConfirmation || !hasChanges){
      this.setState({ 
        displayConfirmation: false,
        hasChanges: false,
       })
      this.props.handleClose()
    } 
    else this.setState({ displayConfirmation: true })
  }

  render(){
    this.setInputReadOnly()
    const { displayConfirmation } = this.state
    if (displayConfirmation){
      return <ConfirmModal cancelText={"Go back"} show={this.props.show} handleConfirm={this.handleClose} title={"Discard changes?"} body={"Are you sure you want to discard current changes?"} handleClose={() => this.setState({ displayConfirmation: false })} isMobile={this.props.isMobile} />
    }
    else if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return (
          <Modal size="lg" show={this.props.show} onHide={this.handleClose} onShow={this.onShow}>
          {this.getInner()}
          </Modal>
      )
    }
  }

  setInputReadOnly = () => {
    window.requestAnimationFrame(function() {
      document.querySelectorAll('.react-datepicker__input-container input').forEach(element => element.readOnly = true)
    });
  }

  setSelectedPreviousCheck = (id) => {
    this.setState({
      selectedPreviousCheck: id,
    });
  }

  getInner = () => {
  	console.log("this.props", this.props)
    const { assigned_to, appointment_date } = this.state
  	const { searchOptions, searchIsLoading, searchIsVisible, searchSelected, addressIsInvalid, previousChecks, previousChecksLoading, selectedPreviousCheck } = this.state
  	const sm1 = 4
  	const sm2 = 7
    const classes1 = "d-flex justify-content-sm-end"
    return (
        <>
          <Modal.Header>
            <Modal.Title>Create New Routine Check</Modal.Title>
            <CloseModalButton onClick={this.handleClose}/>
          </Modal.Header>
          <Form
	          onSubmit={e => this.handleSubmit(e)}
            autoComplete='off'
          >
          <Modal.Body>

          	<Form.Group as={Row} className="validate-me">
	            <Form.Label column sm={sm1} className={classes1}>
	              Address
	            </Form.Label>
	            <Col sm={sm2}>
    			    	<AsyncTypeahead
                  id={'check-address-search-typeahead'}
                  inputProps={{id: 'check-address-search', autoComplete: 'off'}}
                  options={searchOptions}
                  selected={searchSelected}
                  isInvalid={addressIsInvalid}
                  isLoading={searchIsLoading}
                  maxVisible={10}
                  placeholder="Search"
                  onSearch={this.onInputChange}
                  minLength={0}
                  useCache={false}
                  onKeyDown={this.onKeyDown}
                  selectHintOnEnter={true}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  open={searchIsVisible}
                  onChange={this.onChange}
                  clearButton={true}
                  labelKey={(option) => `${option.address_full}`}
                  filterBy={(option, props) => {
                    return option.address_full
                  }}
    		        />
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="">
	            <Form.Label column sm={sm1} className={classes1}>
	              Assigned to
	            </Form.Label>
	            <Col sm={sm2}>
			      <Form.Control 
			      	className="novalidate"
			      	as="select"
	                name="assigned_to"
	                value={assigned_to}
	                onChange={this.handleOnChange}
			      >
			        <option value="">None</option>
              		{this.props.userList && this.props.userList.map(userItem => <option key={userItem.id} value={userItem.id}>{`${userItem.first_name} ${userItem.last_name}`}</option>)}
			      </Form.Control>
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="">
	            <Form.Label column sm={sm1} className={classes1}>
	              Appointment date
	            </Form.Label>
	            <Col sm={sm2}>
            		<div className="d-inline-block" style={{ width: '100%' }}>
		            <DatePicker
		              className="form-control novalidate"
		              dateFormat="dd-MM-yyyy"
		              isClearable={true}
		              selected={appointment_date}
		              onChange={this.handleAppointmentDateChange}
		            />
		            </div>
	            </Col>
          	</Form.Group>

          	<Form.Group as={Row} className="">
	            <Form.Label column sm={sm1} className={classes1}>
	            </Form.Label>
	            <Col sm={sm2}>
	            <FormCheck>
				  <FormCheck.Input 
				  	onChange={this.handleCheck} 
			      	className="novalidate"
			        type="checkbox"
			        id="duplicate_previous_check"
	                name="duplicate_previous_check"
			        defaultChecked={true}
				  />
				  <FormCheck.Label className="novalidate"><FontAwesomeIcon icon={faClock} /> Duplicate previous routine check?</FormCheck.Label>
				</FormCheck>
          {searchSelected.length > 0 &&
          <Row className="mt-3">
            <Col sm={12}>
              <Table striped size="sm">
                <thead>
                  <tr>
                    <th colSpan={3}>Previous Checks</th>
                  </tr>
                </thead>
                <tbody>
                  {!previousChecksLoading ?
                    (previousChecks.length > 0) ?
                    <>
                      {previousChecks.map(item => {
                        return (
                          <tr key={item.id} onClick={() => this.setSelectedPreviousCheck(item.id)} className={item.id == selectedPreviousCheck ? "selected" : ""}>
                            <td>{item.id}</td>
                            <td>{item.date_submitted ? item.date_submitted : "pending"}</td>
                            <td>{item.rooms.length} room{item.rooms.length == 1 ? "" : "s"}</td>
                          </tr>
                          );
                      })}
                    </>
                    :
                    <tr>
                      <td colSpan="7" className="text-center pt-3" style={{ pointerEvents: "none" }}>
                        <p>No results</p>
                      </td>
                    </tr>
                  :
                  <tr style={{ pointerEvents: "none" }}>
                    <td colSpan="7" className="text-center pt-3">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        }
	            </Col>
          	</Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose} className="float-left mr-auto">
              Close
            </Button>
     		{this.props.item ? 
     			<>
     				<input type="hidden" name="id" value={this.props.item.id} />
            		<Button disabled={!this.props.isOnline} type="submit">Save</Button>
     			</>
     			:
            	<Button disabled={!this.props.isOnline} type="submit">Create</Button>
     		}
          </Modal.Footer>
        </Form>
        </>
    )

  }
}

const mapStateToProps = state => ({
  userList: state.user.userList,
  isOnline: state.offline.isOnline,
});

export default connect(mapStateToProps, { createCheck })(CreateCheckModal);
