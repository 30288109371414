import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux'
import history from './history';
import LoginPage from './pages/LoginPage';
import './css/sidebar.css';
import { refreshOfflineQueue, checkStorage, clearStrayFiles, setOnline, setOfflineFileCount } from './redux/actions/offlineActions'
import { getUserList, addToAutosaveLog } from './redux/actions/userActions'
import { fetchSettings } from './redux/actions/settingsActions'
import { Router } from "react-router";
import { Route, Switch } from 'react-router-dom'
import IndexPage from './pages/IndexPage';
import AddressPage from './pages/AddressPage';
import CheckPage from './pages/CheckPage';
import InventoryPage from './pages/InventoryPage';
import AutosavePage from './pages/AutosavePage';
import UserPage from './pages/UserPage';
import SettingsPage from './pages/SettingsPage';
import InventorySettingsPage from './pages/InventorySettingsPage';
import AccountSettingsPage from './pages/AccountSettingsPage';
import OfflineQueuePage from './pages/OfflineQueuePage';
import { fetchAddresses } from './redux/actions/addressActions'
import { fetchChecks, clearInvalidAutosaves, auditLocalStorage } from './redux/actions/checkActions'
import localForage from "localforage";
import * as Sentry from '@sentry/react';

class App extends Component {

  constructor(props){
    super(props)
    this.state = {
      redirectToLogin: false,
    }
  }
  componentDidMount() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOnline);
  }
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleOnline);
  // }
  handleOnline = (e) => {
    this.props.setOnline(navigator.onLine)
    // if (navigator.onLine && navigator.serviceWorker && navigator.serviceWorker.controller) navigator.serviceWorker.controller.postMessage("clearOfflineQueue");
  }

  initialise = () => {
    let thisComp = this
    localForage.getItem(`offlineFileCount`).then((result) => {
      thisComp.props.setOfflineFileCount(result)
    })
    this.props.getUserList()
    this.props.fetchSettings()
    // this.props.refreshOfflineQueue()
    this.props.clearInvalidAutosaves()
    this.props.clearStrayFiles()
    this.props.checkStorage()
    // this.props.auditLocalStorage()

  }

  componentWillMount() {
    console.log("App componentWillMount")
    const { authKey } = this.props
    if (!authKey){
      this.setState({
        redirectToLogin: true,
      })
    }
    this.initialise()

    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.ready.then(function(swRegistration) {
    //     // if (swRegistration.sync) return swRegistration.sync.register('onlineSync');
    //   });
    //   navigator.serviceWorker.addEventListener('message', event => {
    //     this.props.refreshOfflineQueue()
    //   });
    // }

  }

  componentWillReceiveProps(nextProps) {
    // console.log("componentWillReceiveProps nextProps, this.props", nextProps, this.props)
    const { redirectToLogin } = this.state
    const { authKey, userList, currentID } = nextProps

    if (userList && currentID){
      localForage.getItem(`autosaveLog`).then((autosaveLog) => {
        if (autosaveLog && autosaveLog.length > 0){
          this.props.addToAutosaveLog(currentID, `App opened`)
          // this.props.submitAutosaveLog(currentID, autosaveLog)
        }
      })
      const user = userList.find((item) => item.id === currentID)
      if (user){
        console.log("setting user:", user)
        Sentry.setUser(user)
      }
    }

    if (authKey){
      if (redirectToLogin === true){
        this.initialise()
      }
      this.setState({
        redirectToLogin: false,
      })
    } else if (window.location.pathname !== '/login') {
      this.setState({
        redirectToLogin: true,
      })
    }
  }

  render() {
    const { redirectToLogin } = this.state
    if (redirectToLogin){
      return <LoginPage />
    }
    return (
      <div id="outer-container">
        <Router history={history}>
        <div id="page-wrap">
            <Switch>
              <Route exact path="/" component={IndexPage}/>
              <Route exact path="/addresses" component={AddressPage}/>
              <Route exact key="checks" path="/checks" component={CheckPage}/>
              <Route exact key="autosaves" path="/autosaves" component={AutosavePage}/>
              <Route exact key="reports" path="/reports" component={CheckPage}/>
              <Route exact key="inventories" path="/inventories" component={InventoryPage}/>
              <Route exact path="/users" component={UserPage}/>
              <Route exact path="/checks-settings" component={SettingsPage}/>
              <Route exact path="/inventory-settings" component={InventorySettingsPage}/>
              <Route exact path="/account-settings" component={AccountSettingsPage}/>
              <Route exact path="/offline" component={OfflineQueuePage}/>
            </Switch>
        </div>
        </Router>
      </div>
    )

  }
}

const mapStateToProps = state => ({
  authKey: state.auth.authKey,
  currentID: state.auth.currentID,
  userList: state.user.userList,
});

export default connect(mapStateToProps, { refreshOfflineQueue, getUserList, fetchAddresses, fetchChecks, fetchSettings, clearInvalidAutosaves, clearStrayFiles, checkStorage, auditLocalStorage, setOnline, setOfflineFileCount, addToAutosaveLog })(App);