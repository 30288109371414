import React, { Component } from 'react';
import { Container, Button, Row, Col, Navbar, Breadcrumb, Form, Alert, Spinner, ListGroup, InputGroup, FormControl } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

class RoomOrderList extends Component {

	constructor(props){
		super(props)
		this.state = {
			newRoom: "",
		};
	}

	addRoom = () => {
		const { newRoom } = this.state
		let { room_names } = this.props
		let new_room_names = [newRoom].concat(room_names)
		if (newRoom !== ""){
			this.setState({
				newRoom: "",
			}, () => this.props.handleListChange(new_room_names))
		}
	}

	removeRoom = (roomName) => {
		let { room_names } = this.props
		let new_room_names = room_names.filter(item => item !== roomName)
		this.props.handleListChange(new_room_names)
	}

	moveRoomUp = (roomName) => {
		let { room_names } = this.props
		console.log("moveRoomUp", roomName, room_names)
		let roomIndex = room_names.indexOf(roomName)
		room_names = room_names.filter(item => item !== roomName)
		room_names.splice(roomIndex-1, 0, roomName);
		this.props.handleListChange(room_names)
	}

	moveRoomDown = (roomName) => {
		let { room_names } = this.props
		console.log("moveRoomDown", roomName, room_names)
		let roomIndex = room_names.indexOf(roomName)
		room_names = room_names.filter(item => item !== roomName)
		room_names.splice(roomIndex+1, 0, roomName);
		this.props.handleListChange(room_names)
	}

	handleOnChange = (event) => {
		this.setState({ 
			[event.target.name]: event.target.value,
		});
	}

  render() {
  	const { newRoom } = this.state
  	const { room_names } = this.props
    return (
    	<>
	      <InputGroup className="mb-2">
	        <FormControl
	          name="newRoom"
	          placeholder="Add new room"
	          aria-label="New Room Name"
	          value={newRoom}
	          onChange={this.handleOnChange}
	        />
	        <InputGroup.Append>
	          <Button variant="primary" onClick={() => this.addRoom(newRoom)}>Add</Button>
	        </InputGroup.Append>
	      </InputGroup>
	      <ListGroup>
	        {room_names.map((item, index) => 
	          <ListGroup.Item variant="light" key={item}>
	            <div className="up-down-button mr-3">
	              <div onClick={() => index !== 0 && this.moveRoomUp(item)}><FontAwesomeIcon icon={faCaretUp} /></div>
	              <div onClick={() => index !== room_names.length && this.moveRoomDown(item)}><FontAwesomeIcon icon={faCaretDown} /></div>
	            </div>
	            <div className="d-inline-block align-top">{item}</div>
	            <Button 
	              className="float-right" 
	              variant="danger" 
	              size="sm" 
	              onClick={() => this.removeRoom(item)}
	            >Remove</Button>
	          </ListGroup.Item>
	          )
	        }
	      </ListGroup>
    	</>
    )

  }
} 
export default RoomOrderList
