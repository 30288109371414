import React, { Component } from 'react';
import { Container, Modal, Button, Table, Row, Col, Navbar, Breadcrumb, Dropdown, DropdownButton, Form, Alert, Spinner } from 'react-bootstrap'
import CloseModalButton from '../components/CloseModalButton'
import DatePicker from "react-datepicker";

class EmailTenantModal extends Component {

  constructor(props){
    super(props)
    this.state = { 
      modalInput: "",
      modalDate: null,
      feed: null,
    };
  }

  getHolidayFeed = () => {
    let thisComp = this
    fetch('https://www.gov.uk/bank-holidays.json')
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        // console.log('data', data)
        thisComp.setState({feed: data}, () => thisComp.initialiseDate())
      }
    });
  }

  getHolidays = (startDate, endDate) => {
    const {feed} = this.state
    // let's ignore the start date
    let noHolidays = 0
    let testDate = startDate
    while(testDate.toDateString() !== endDate.toDateString()) {
      testDate = testDate.addDays(1)
      const testDay = testDate.getDay()
      if (testDay === 0 || testDay == 6) noHolidays++
      else {
        // Check holidays
        const events = feed['england-and-wales'].events
        // console.log("events", events)
        const testDateString = [testDate.getFullYear(),("0" + testDate.getMonth()).slice(-2),("0" + testDate.getDate()).slice(-2)].join('-')
        let found = events.find(e => e.date === testDateString)
        // console.log("found", found)
        if (found) noHolidays++
      }
    }
    return noHolidays
  }

  initialiseDate = () => {
    const {modalDate} = this.state
    if (!modalDate) {
      let today = new Date(Date.now())
      // console.log("todays date", today)
      let noDays = 2
      let deadline = today.addDays(noDays)
      // console.log("deadline date", deadline)
      let noHolidays = this.getHolidays(today, deadline)
      // console.log("noHolidays", noHolidays)
      let valid = false
      while(valid === false){
        // console.log("noDays, noHolidays", noDays, noHolidays)
        // console.log("noDays - noHolidays", noDays - noHolidays)
        if (noDays - noHolidays >= 2){
          valid = true
        } else {
          noDays++
          deadline = today.addDays(noDays)
          noHolidays = this.getHolidays(today, deadline)
        }
      }
      this.setState({modalDate: deadline})
      // console.log("final deadline", deadline)
    }
  }

  render(){
    if (this.props.show && this.props.isMobile()) return this.getInner()
    else {
      return (
          <Modal size="lg" show={this.props.show} onShow={this.onShow} onHide={this.props.handleClose}>
          {this.getInner()}
          </Modal>
      )
    }
  }

  componentWillMount(){
    if (this.props.isMobile()) this.onShow()
  }

  onShow = () => {
    console.log("EmailTenantModal, onShow", this.state, this.props)
    const { hasChanges, hasPromptedLoadSave, promptLoadSave } = this.state
    this.setState({
      modalInput: "",
    })
    this.getHolidayFeed()
  }

  handleOnChange = (event) => {
    this.setState({ 
      [event.target.name]: event.target.value,
    });
  }

  handleDateChange = (d, fieldname) => {
    this.setState({
      [fieldname]: d,
    })
  }

  onKeyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13){
      this.props.handleSubmit(this.state.modalInput, this.state.modalDate)
    }
  }

  getInner = () => {
  	console.log("EmailTenantModal this.props", this.props)
  	const {title, label, errorMessage, body} = this.props
    const {modalInput, modalDate} = this.state
    const classes1 = "d-flex justify-content-sm-end text-right"
    const sm1 = 4
    const sm2 = 7
    return (
        <>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
            <CloseModalButton onClick={this.props.handleClose}/>
          </Modal.Header>
          <Modal.Body>
            {body && body}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                {label}
              </Form.Label>
              <Col sm={sm2}>
                <Form.Control 
                  className="input-modal-text-field"
                  type="text" 
                  name="modalInput"
                  value={modalInput}
                  onChange={this.handleOnChange}
                  onKeyDown={this.onKeyDown}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="validate-me">
              <Form.Label column sm={sm1} className={classes1}>
                Deadline date
              </Form.Label>
              <Col sm={sm2}>
                <div className="d-inline-block" style={{ width: '100%' }}>
                  <DatePicker
                    className="form-control "
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    selected={modalDate}
                    onChange={(d) => this.handleDateChange(d, 'modalDate')}
                    onFocus={(e) => e.target.readOnly = true}
                    onBlur={(e) => e.target.readOnly = false}
                  />
                </div>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose} className="float-left mr-auto">
              {this.props.cancelText ? this.props.cancelText : "Close"}
            </Button>
            <Button onClick={() => this.props.handleSubmit(this.state.modalInput, this.state.modalDate)}>Submit</Button>
          </Modal.Footer>
        </>
    )

  }
}

export default EmailTenantModal;
